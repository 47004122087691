import {
  Actions,
  IGetUserDetailsData,
  IGetUserRolesAssignData,
  IGetUserSystemDetailsData,
  IGetSystemDescriptionData,
  IGetReqUserDetailsData,
  IGetNoSystemAssignedUserData,
  IPostUserCreateData,
  IGetApproverInboxData,
  IGetFindingTCodesRoleData,
  IGetFindingOrgValuesRoleData,
  IGetRoleOwnerRoleNameData,
  IGetListBusinessProcessData,
  IGetListRoleAssignedToOwnerData,
  IPostApiForApproveReqData,
  IGetSystemTypeData,
  IGetFunctionData,
  IGetSubFunctionData,
  IGetDepartmentData,
  IGetSubDepartmentData,
  IGetRoleOwnerData,
  IGetRoleData,
  IPostSodConflictData,
} from "../actions/SapIdAction";
import { combineReducers } from "redux";

export const getUserDetailsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_DETAILS_LOADING:
    case Actions.GET_USER_DETAILS_ERROR:
    case Actions.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getUserRolesAssignReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_ROLES_ASSIGN_LOADING:
    case Actions.GET_USER_ROLES_ASSIGN_ERROR:
    case Actions.GET_USER_ROLES_ASSIGN_SUCCESS:
    case Actions.GET_USER_ROLES_ASSIGN_CLEAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getUserSystemDetailsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_SYSTEM_DETAILS_LOADING:
    case Actions.GET_USER_SYSTEM_DETAILS_ERROR:
    case Actions.GET_USER_SYSTEM_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getSystemDescriptionReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_SYSTEM_DESCRIPTION_LOADING:
    case Actions.GET_SYSTEM_DESCRIPTION_ERROR:
    case Actions.GET_SYSTEM_DESCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getReqUserDetailsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_REQ_USER_DETAILS_LOADING:
    case Actions.GET_REQ_USER_DETAILS_ERROR:
    case Actions.GET_REQ_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getNoSystemAssignedUserReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_NO_SYSTEM_ASSIGNED_USER_LOADING:
    case Actions.GET_NO_SYSTEM_ASSIGNED_USER_ERROR:
    case Actions.GET_NO_SYSTEM_ASSIGNED_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const postUserCreateReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_USER_CREATE_LOADING:
    case Actions.POST_USER_CREATE_ERROR:
    case Actions.POST_USER_CREATE_SUCCESS:
    case Actions.POST_USER_CREATE_CLEAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getApproverInboxReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_APPROVER_INBOX_LOADING:
    case Actions.GET_APPROVER_INBOX_ERROR:
    case Actions.GET_APPROVER_INBOX_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getFindingTCodesRoleReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_FINDING_T_CODES_ROLE_LOADING:
    case Actions.GET_FINDING_T_CODES_ROLE_ERROR:
    case Actions.GET_FINDING_T_CODES_ROLE_SUCCESS:
    case Actions.GET_FINDING_T_CODES_ROLE_CLEAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getFindingOrgValuesRoleReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_FINDING_ORG_VALUES_ROLE_LOADING:
    case Actions.GET_FINDING_ORG_VALUES_ROLE_ERROR:
    case Actions.GET_FINDING_ORG_VALUES_ROLE_SUCCESS:
    case Actions.GET_FINDING_ORG_VALUES_ROLE_CLEAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getRoleOwnerRoleNameReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_ROLE_OWNER_ROLE_NAME_LOADING:
    case Actions.GET_ROLE_OWNER_ROLE_NAME_ERROR:
    case Actions.GET_ROLE_OWNER_ROLE_NAME_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getListBusinessProcessReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_LIST_BUSINESS_PROCESS_LOADING:
    case Actions.GET_LIST_BUSINESS_PROCESS_ERROR:
    case Actions.GET_LIST_BUSINESS_PROCESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getListRoleAssignedToOwnerReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_LOADING:
    case Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_ERROR:
    case Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_SUCCESS:
    case Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_CLEAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const postApiForApproveReqReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_API_FOR_APPROVE_REQ_LOADING:
    case Actions.POST_API_FOR_APPROVE_REQ_ERROR:
    case Actions.POST_API_FOR_APPROVE_REQ_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getSystemTypeReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_SYSTEM_TYPE_LOADING:
    case Actions.GET_SYSTEM_TYPE_ERROR:
    case Actions.GET_SYSTEM_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getFunctionReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_FUNCTION_LOADING:
    case Actions.GET_FUNCTION_ERROR:
    case Actions.GET_FUNCTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getSubFunctionReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_SUB_FUNCTION_LOADING:
    case Actions.GET_SUB_FUNCTION_ERROR:
    case Actions.GET_SUB_FUNCTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getDepartmentReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_DEPARTMENT_LOADING:
    case Actions.GET_DEPARTMENT_ERROR:
    case Actions.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getSubDepartmentReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_SUB_DEPARTMENT_LOADING:
    case Actions.GET_SUB_DEPARTMENT_ERROR:
    case Actions.GET_SUB_DEPARTMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getRoleOwnerReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_ROLE_OWNER_LOADING:
    case Actions.GET_ROLE_OWNER_ERROR:
    case Actions.GET_ROLE_OWNER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const getRoleReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_ROLE_LOADING:
    case Actions.GET_ROLE_ERROR:
    case Actions.GET_ROLE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const postSodConflictReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_SOD_CONFLICT_LOADING:
    case Actions.POST_SOD_CONFLICT_ERROR:
    case Actions.POST_SOD_CONFLICT_SUCCESS:
    case Actions.POST_SOD_CONFLICT_CLEAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export interface ISapIdData {
  getUserDetails: IGetUserDetailsData;
  getUserRolesAssign: IGetUserRolesAssignData;
  getUserSystemDetails: IGetUserSystemDetailsData;
  getSystemDescription: IGetSystemDescriptionData;
  getReqUserDetails: IGetReqUserDetailsData;
  getNoSystemAssignedUser: IGetNoSystemAssignedUserData;
  postUserCreate: IPostUserCreateData;
  getApproverInbox: IGetApproverInboxData;
  getFindingTCodesRole: IGetFindingTCodesRoleData;
  getFindingOrgValuesRole: IGetFindingOrgValuesRoleData;
  getRoleOwnerRoleName: IGetRoleOwnerRoleNameData;
  getListBusinessProcess: IGetListBusinessProcessData;
  getListRoleAssignedToOwner: IGetListRoleAssignedToOwnerData;
  postApiForApproveReq: IPostApiForApproveReqData;
  getSystemType: IGetSystemTypeData;
  getFunction: IGetFunctionData;
  getSubFunction: IGetSubFunctionData;
  getDepartment: IGetDepartmentData;
  getSubDepartment: IGetSubDepartmentData;
  getRoleOwner: IGetRoleOwnerData;
  getRole: IGetRoleData;
  getSodConflict: IPostSodConflictData;
}

const sapIdData = combineReducers({
  getUserDetails: getUserDetailsReducer,
  getUserRolesAssign: getUserRolesAssignReducer,
  getUserSystemDetails: getUserSystemDetailsReducer,
  getSystemDescription: getSystemDescriptionReducer,
  getReqUserDetails: getReqUserDetailsReducer,
  getNoSystemAssignedUser: getNoSystemAssignedUserReducer,
  postUserCreate: postUserCreateReducer,
  getApproverInbox: getApproverInboxReducer,
  getFindingTCodesRole: getFindingTCodesRoleReducer,
  getFindingOrgValuesRole: getFindingOrgValuesRoleReducer,
  getRoleOwnerRoleName: getRoleOwnerRoleNameReducer,
  getListBusinessProcess: getListBusinessProcessReducer,
  getListRoleAssignedToOwner: getListRoleAssignedToOwnerReducer,
  postApiForApproveReq: postApiForApproveReqReducer,
  getSystemType: getSystemTypeReducer,
  getFunction: getFunctionReducer,
  getSubFunction: getSubFunctionReducer,
  getDepartment: getDepartmentReducer,
  getSubDepartment: getSubDepartmentReducer,
  getRoleOwner: getRoleOwnerReducer,
  getRole: getRoleReducer,
  getSodConflict: postSodConflictReducer,
});

export default sapIdData;
