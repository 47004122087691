import { Box, Dropdown, Flex, FlexItem, Loader, RadioGroup, Text, Status, Table, Grid, Skeleton, Dialog, Divider, CloseIcon, Image } from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../App";
import { StatusList, InputMonth, WeeksNames, EmpValueByCode, ServiceName } from "../../constant";
import * as AttendanceAction from "../../redux/actions/AttendanceAction";
import * as LeaveAppliedAction from "../../redux/actions/LeaveActions";
import { RootSchema } from "../../redux/reducer";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import inTime from "../../assets/img/in_time.svg";
import outTime from "../../assets/img/out_time.svg";
import "./attendance.scss";
import ServicePermission from "../HOC/ServicePermission";

interface IAttendanceEnquiryProps {
    QueryData: IState;
}

interface IApplyLeavesDate {
    approved: string[];
    error_in_deletion: string[];
    error_in_posting: string[];
    pending_for_approval: string[];
    rejected: string[];
    approved_and_post_pending: string[];
    delete_approval_pending: string[];
    deleted_and_update_in_progress: string[];
    lwop: string[];
}

interface ILeaveStatus {
    value: string;
    code: string;
    color: string;
}

const AttendanceEnquiry: React.FC<IAttendanceEnquiryProps> = ({
    QueryData,
}) => {
    const [view, setView] = React.useState<string>("Calendar");
    const [month, setMonth] = React.useState<number>(new Date().getMonth() + 1);
    const [emptyDate, setemptyDate] = React.useState<number[]>([]);
    const [leaveStatus, setLeaveStatus] = React.useState<ILeaveStatus[]>([{
        value: "",
        code: "",
        color: ""
    }]);
    const [leaveColor, setLeaveColor] = React.useState<any>();

    const [isDateInfo, setIsDateInfo] = React.useState<{ isActive: boolean; data: AttendanceAction.IAttendanceEnquiry }>({
        isActive: false, data: {
            LDATE: "",
            SWIPE_IN: "",
            SWIPE_OUT: "",
            SHIFT: "",
            DAY_TYPE: "",
            FH: "",
            SH: ""
        }
    });
    const dispatch = useDispatch();

    const [applyLeavesDate, setApplyLeavesDate] = React.useState<IApplyLeavesDate>({
        approved: [],
        error_in_deletion: [],
        error_in_posting: [],
        pending_for_approval: [],
        rejected: [],
        approved_and_post_pending: [],
        delete_approval_pending: [],
        deleted_and_update_in_progress: [],
        lwop: []
    });

    const state = useSelector((state: RootSchema) => state);
    const accessToken = state.authData.userAuth.token;
    let empCode = EmpValueByCode[state.authData.userAuth.comp_code as keyof typeof EmpValueByCode];

    const getMonthChangeHandler = (item: string) => {
        const monthIndex = InputMonth.indexOf(item);
        setMonth(monthIndex + 1);
    };

    const TableHeader = [
        {
            content: <Text size="medium" content="Date" weight="semibold" />,
            key: "date",
            styles: { justifyContent: "center", maxWidth: "40px" },
            className: "tableHeadMargin",
        },
        {
            content: <Text size="medium" content="In Time" weight="semibold" />,
            key: "it",
            styles: { justifyContent: "center", maxWidth: "70px" },
            className: "tableHeadMargin",
        },
        {
            content: <Text size="medium" content="Out Time" weight="semibold" />,
            key: "ot",
            styles: { justifyContent: "center", maxWidth: "80px" },
            className: "tableHeadMargin",
        },
        {
            content: <Text size="medium" content="First Half" weight="semibold" />,
            key: "fh",
            styles: { justifyContent: "center", maxWidth: "150px" },
            className: "tableHeadMargin",
        },
        {
            content: <Text size="medium" content="Second Half" weight="semibold" />,
            key: "sh",
            styles: { justifyContent: "center", maxWidth: "150px" },
            className: "tableHeadMargin",
        },
    ];

    const rowsPlain =
        state.attendanceData.attendance.isSuccess && state.attendanceData.attendance.status_code === "200" &&
            state.attendanceData.attendance.attendance_enquiry_details.length > 0
            ? state.attendanceData.attendance.attendance_enquiry_details.map(
                (item, index) => {
                    return {
                        key: index,
                        items: [
                            {
                                content: (
                                    <Flex
                                        vAlign="center"
                                        hAlign="center"
                                        className="dateCircle"
                                        styles={state.attendanceData.attendance.public_holidays?.some(sub => sub === item.LDATE)
                                            ? { color: "#fff", background: "#6d339a !important" } :
                                            state.attendanceData.attendance.week_offs?.some(sub => sub === item.LDATE)
                                                ? { color: "#fff", background: "#8b50eb !important" } :
                                                applyLeavesDate?.pending_for_approval?.some(sub => sub === item.LDATE)
                                                    ? { color: "#fff", background: `${leaveColor["Pending for approval"]} !important` } :
                                                    applyLeavesDate?.lwop?.some(sub => sub === item.LDATE)
                                                        ? { color: "#fff", background: "#bd153e !important" } :
                                                        applyLeavesDate?.approved?.some(sub => sub === item.LDATE)
                                                            ? { color: "#fff", background: `${leaveColor["Approved"]} !important` } :
                                                            applyLeavesDate?.approved_and_post_pending?.some(sub => sub === item.LDATE)
                                                                ? { color: "#fff", background: `${leaveColor["Approved and update in progress"]} !important` } :
                                                                applyLeavesDate?.rejected?.some(sub => sub === item.LDATE)
                                                                    ? { color: "#fff", background: `${leaveColor["Rejected"]} !important` } :
                                                                    applyLeavesDate?.deleted_and_update_in_progress?.some(sub => sub === item.LDATE)
                                                                        ? { color: "#fff", background: `${leaveColor["Deleted and update in progress"]} !important` } :
                                                                        applyLeavesDate?.delete_approval_pending?.some(sub => sub === item.LDATE)
                                                                            ? { color: "#fff", background: `${leaveColor["Delete approval pending"]} !important` } :
                                                                            applyLeavesDate?.error_in_posting?.some(sub => sub === item.LDATE)
                                                                                ? { color: "#fff", background: `${leaveColor["Error in posting"]} !important` } :
                                                                                applyLeavesDate?.error_in_deletion?.some(sub => sub === item.LDATE)
                                                                                    ? { color: "#fff", background: `${leaveColor["Error in deletion"]} !important` } :
                                                                                    {}
                                        }
                                    >
                                        <Text
                                            content={new Date(item.LDATE).getDate().toString()}
                                        />
                                    </Flex >
                                ),
                                key: index + "1",
                                styles: { justifyContent: "center", maxWidth: "40px" },
                            },

                            {
                                content: item.SWIPE_IN,
                                key: index + "2",
                                styles: { justifyContent: "center", maxWidth: "70px" },
                            },

                            {
                                content: item.SWIPE_OUT,
                                key: index + "3",
                                styles: { justifyContent: "center", maxWidth: "80px" },
                            },

                            {
                                content: item.FH,
                                key: index + "4",
                                styles: { justifyContent: "center", maxWidth: "150px" },
                            },

                            {
                                key: "index +5",
                                styles: { justifyContent: "center", maxWidth: "150px" },
                                content: item.SH,
                            },
                        ],
                    };
                }
            )
            : [];

    const getMonthStartDay = () => {
        const Month = month < 10 ? "0" + month.toString() : month.toString(); //06
        const year = new Date().getFullYear().toString();
        const days =
            new Date(year + "-" + Month + "-" + "01").getDay() === 0
                ? 6
                : new Date(year + "-" + Month + "-" + "01").getDay() - 1;
        const data: number[] = [];
        for (let i = 0; i < days; i++) {
            data.push(i);
        }
        return setemptyDate(data);
    };

    const getAllLeavesDays = async () => {
        const approved: string[] = [];
        const error_in_deletion: string[] = [];
        const error_in_posting: string[] = [];
        const pending_for_approval: string[] = [];
        const rejected: string[] = [];
        const approved_and_post_pending: string[] = [];
        const delete_approval_pending: string[] = [];
        const deleted_and_update_in_progress: string[] = [];
        const lwop: string[] = [];

        state.leaveData.leaveHistory.data.leave_requests.forEach(item => {
            const start = new Date(item.from_date);
            const end = new Date(item.to_date);
            const day = 1000 * 60 * 60 * 24;
            const daysBetween = (end.getTime() - start.getTime()) / day;
            if (item.leave_status_code === "0") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    pending_for_approval.push(moment(yy).format("YYYY-MM-DD"));
                }
            } else if (item.leave_status_code === "1") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    approved_and_post_pending.push(moment(yy).format("YYYY-MM-DD"));
                }
            } else if (item.leave_status_code === "2") {
                return null;
            } else if (item.leave_status_code === "3") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    rejected.push(moment(yy).format("YYYY-MM-DD"));
                }
            } else if (item.leave_status_code === "4") {
                if (
                    item.leave_category_code === "Z0" ||
                    item.leave_category_code === "Z0P" ||
                    item.leave_category_code === "X0" ||
                    item.leave_category_code === "X0P"
                ) {
                    for (let i = 0; i <= daysBetween; i++) {
                        const xx = start.getTime() + day * i;
                        const yy = new Date(xx);
                        lwop.push(moment(yy).format("YYYY-MM-DD"));
                    }
                } else {
                    for (let i = 0; i <= daysBetween; i++) {
                        const xx = start.getTime() + day * i;
                        const yy = new Date(xx);
                        approved.push(moment(yy).format("YYYY-MM-DD"));
                    }
                }

            } else if (item.leave_status_code === "5") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    delete_approval_pending.push(moment(yy).format("YYYY-MM-DD"));
                }
            } else if (item.leave_status_code === "6") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    deleted_and_update_in_progress.push(moment(yy).format("YYYY-MM-DD"));
                }
            } else if (item.leave_status_code === "7") {
                return null;
            } else if (item.leave_status_code === "8") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    error_in_posting.push(moment(yy).format("YYYY-MM-DD"));
                }
            } else if (item.leave_status_code === "9") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    error_in_deletion.push(moment(yy).format("YYYY-MM-DD"));
                }
            } else if (item.leave_status_code === "Z0" || item.leave_status_code === "Z0P") {
                for (let i = 0; i <= daysBetween; i++) {
                    const xx = start.getTime() + day * i;
                    const yy = new Date(xx);
                    lwop.push(moment(yy).format("YYYY-MM-DD"));
                }
            }
        });
        await setApplyLeavesDate({
            approved: approved,
            error_in_deletion: error_in_deletion,
            error_in_posting: error_in_posting,
            pending_for_approval: pending_for_approval,
            rejected: rejected,
            approved_and_post_pending: approved_and_post_pending,
            delete_approval_pending: delete_approval_pending,
            deleted_and_update_in_progress: deleted_and_update_in_progress,
            lwop: lwop
        });
    };

    React.useEffect(() => {
        if (state.leaveData.leaveHistory.isSuccess && state.leaveData.leaveHistory.status_code === "200") {
            getAllLeavesDays();
        }
    }, [state.leaveData.leaveHistory.isSuccess]);


    React.useEffect(() => {
        if (state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" && state.userInfo.userManagerData.isSuccess) {
            const attendancePayload: AttendanceAction.IAttendancePayload = {
                emp_id: QueryData.employeeId as string,
                month: month < 10 ? "0" + month.toString() : month.toString(), //06
                year: new Date().getFullYear().toString(), //2021
            };
            getMonthStartDay();
            const leavepayLoad: LeaveAppliedAction.ILeaveHistoryPayload = {
                emp_id: QueryData.employeeId as string,
                leave_category_code: "All",
                leave_status_code: "All",
                filter_type: "month",
                month: month < 10 ? "0" + month.toString() : month.toString(),
                pa: state.userInfo.userManagerData.user_details[0].PersArea,
                psa: state.userInfo.userManagerData.user_details[0].PSubarea
            };
            empCode = EmpValueByCode[state.authData.userAuth.comp_code as keyof typeof EmpValueByCode];
            dispatch(LeaveAppliedAction.getLeaveHistoryAction(accessToken, empCode, leavepayLoad));
            dispatch(
                AttendanceAction.postAttendanceAction(accessToken, empCode, attendancePayload)
            );
            setApplyLeavesDate({
                approved: [],
                error_in_deletion: [],
                error_in_posting: [],
                pending_for_approval: [],
                rejected: [],
                approved_and_post_pending: [],
                delete_approval_pending: [],
                deleted_and_update_in_progress: [],
                lwop: []
            });
        }
    }, [month, state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200"]);

    React.useEffect(() => {
        const color: any = {};
        if (state.leaveData.leaveMaster.isSuccess && state.leaveData.leaveMaster.status_code === "200" && state.leaveData.leaveMaster.data.leave_status) {
            setLeaveStatus(state.leaveData.leaveMaster.data.leave_status);
            for (const i in state.leaveData.leaveMaster.data.leave_status) {
                color[state.leaveData.leaveMaster.data.leave_status[i].value] = state.leaveData.leaveMaster.data.leave_status[i].color;
            }
            setLeaveColor(color);
        }
    }, [state.leaveData.leaveMaster.isSuccess]);

    return (
        <React.Fragment>
            <Flex gap="gap.small" padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} className="Attendance" styles={state.appConfig.dimension.deviceWidth < 600 && !QueryData.isTaskModuleActive ? { flexDirection: "column-reverse" } : QueryData.isTaskModuleActive ? { height: "100vh" } : {}}>
                <FlexItem size="size.half" grow>
                    <Flex column gap="gap.small" padding="padding.medium">
                        <RadioGroup
                            defaultCheckedValue={view}
                            onCheckedValueChange={(e: any, val: any) =>
                                val.value === null ? setView("") : setView(val.value)
                            }
                            items={[
                                {
                                    key: "1",
                                    label: "Calendar view",
                                    value: "Calendar",
                                    styles: { fontSize: "16px" },
                                },
                                {
                                    key: "2",
                                    label: "Table view",
                                    value: "Table",
                                    styles: { fontSize: "16px" },
                                },
                            ]}
                        />
                        {state.attendanceData.attendance.isSuccess && state.attendanceData.attendance.status_code === "200" &&
                            state.attendanceData.attendance.attendance_enquiry_details.length > 0 ? (
                                view === "Calendar" ?
                                    <Box>
                                        <Grid columns={7} className="gridMaxWidth">
                                            {WeeksNames.map((item, index) => {
                                                return (
                                                    <Box key={item} className="CalendarBox">
                                                        <Text content={item} weight="semibold" size="medium" />
                                                    </Box>
                                                );
                                            })}
                                        </Grid>
                                        <Grid columns="repeat(7, 1fr)" className="gridMaxWidth">
                                            {emptyDate.map((item, index) => {
                                                return <Box key={item} />;
                                            })}
                                            {state.attendanceData.attendance.attendance_enquiry_details.map(
                                                (item, index) => {
                                                    return (
                                                        <Box key={item.LDATE}
                                                            className="CalendarBox CalendarBoxBorder"
                                                            styles={({ theme: { siteVariables } }) => ({
                                                                background: siteVariables.bodyBackground,
                                                                borderLeftColor: `${siteVariables.bodyBackground} !important`,
                                                            })}
                                                            onClick={() => setIsDateInfo({ isActive: true, data: item })}
                                                        >
                                                            <Box
                                                                key={item.LDATE}
                                                                className="CalendarBox"
                                                                styles={state.attendanceData.attendance.public_holidays?.some(sub => sub === item.LDATE)
                                                                    ? { borderLeftColor: "#6d339a", borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                    state.attendanceData.attendance.week_offs?.some(sub => sub === item.LDATE)
                                                                        ? { borderLeftColor: "#8b50eb", borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                        applyLeavesDate?.pending_for_approval?.some(sub => sub === item.LDATE)
                                                                            ? { borderLeftColor: `${leaveColor["Pending for approval"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                            applyLeavesDate?.lwop?.some(sub => sub === item.LDATE)
                                                                                ? { borderLeftColor: "#bd153e", borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                applyLeavesDate?.approved_and_post_pending?.some(sub => sub === item.LDATE)
                                                                                    ? { borderLeftColor: `${leaveColor["Approved and update in progress"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                    applyLeavesDate?.approved?.some(sub => sub === item.LDATE)
                                                                                        ? { borderLeftColor: `${leaveColor["Approved"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                        applyLeavesDate?.rejected?.some(sub => sub === item.LDATE)
                                                                                            ? { borderLeftColor: `${leaveColor["Rejected"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                            applyLeavesDate?.delete_approval_pending?.some(sub => sub === item.LDATE)
                                                                                                ? { borderLeftColor: `${leaveColor["Delete approval pending"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                                applyLeavesDate?.deleted_and_update_in_progress?.some(sub => sub === item.LDATE)
                                                                                                    ? { borderLeftColor: `${leaveColor["Deleted and update in progress"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                                    applyLeavesDate?.error_in_posting?.some(sub => sub === item.LDATE)
                                                                                                        ? { borderLeftColor: `${leaveColor["Error in posting"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                                        applyLeavesDate?.error_in_deletion?.some(sub => sub === item.LDATE)
                                                                                                            ? { borderLeftColor: `${leaveColor["Error in deletion"]}`, borderLeft: "7px solid", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" } :
                                                                                                            { borderLeftColor: "#ededed", borderLeft: "1px solid" }
                                                                }

                                                            >
                                                                {new Date(item.LDATE).getDate().toString()}
                                                            </Box>
                                                        </Box>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    </Box> :
                                    <Box className="box-table" styles={QueryData.isTaskModuleActive ? { maxHeight: "calc(100vh - 85px)" } : { maxHeight: "calc(100vh - 150px)" }}>
                                        <Table
                                            role="gridcell"
                                            header={{
                                                items: TableHeader,
                                                header: true,
                                                className: "tableHead",
                                                styles: ({ theme: { siteVariables } }) => ({
                                                    background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                                    "&:hover": {
                                                        background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                                                    }
                                                })
                                            }}
                                            rows={rowsPlain}
                                        />
                                    </Box>
                            ) :
                            state.attendanceData.attendance.isSuccess && state.attendanceData.attendance.status_code === "200" && state.attendanceData.attendance.attendance_enquiry_details.length === 0 ?
                                <Flex vAlign="center" hAlign="center"><DataNotFound /></Flex> :
                                state.attendanceData.attendance.isSuccess && state.attendanceData.attendance.status_code !== "200" ?
                                    <Flex vAlign="center" hAlign="center"><ErrorComponent message={state.attendanceData.attendance.message} /></Flex>
                                    : //<Flex vAlign="center" hAlign="center"><Loader /></Flex>
                                    <Skeleton animation="pulse" >
                                        <Flex gap="gap.medium">
                                            <Skeleton.Shape height="400px" width="100%" />
                                        </Flex>
                                    </Skeleton>
                        }
                    </Flex>
                </FlexItem >
                <FlexItem size="size.quarter" grow>
                    <Flex column padding="padding.medium" gap="gap.large">
                        <Flex column gap="gap.small">
                            <Text
                                content="Select Month"
                                weight="semibold"
                                style={{ fontSize: "16px" }}
                            />
                            <Dropdown
                                fluid
                                items={InputMonth}
                                placeholder="Please Select month"
                                value={InputMonth[month - 1]}
                                onChange={(e: any, val: any) => getMonthChangeHandler(val.value)}
                            />
                        </Flex>
                        {state.appConfig.dimension.deviceWidth >= 600 &&
                            <Flex column gap="gap.medium">
                                {StatusList.map(({ color, text, isActive }) => {
                                    return (isActive &&
                                        <Flex key={text} vAlign="center" gap="gap.small">
                                            <Status size="larger" color={color} />
                                            <Text
                                                content={text}
                                                weight="regular"
                                                styles={{ fontSize: "16px", lineHeight: "20px" }}
                                            />
                                        </Flex>
                                    );
                                })}
                            </Flex>
                        }
                    </Flex>
                </FlexItem>
            </Flex>
            <Dialog
                open={isDateInfo.isActive}
                overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none", paddingBlock: "5rem" } : {} }}
                styles={QueryData.isTaskModuleActive ? { width: "550px", boxShadow: "5px 5px 5px 5px #888888" } : {}}
                content={
                    <Box>
                        <Divider fitted />
                        <ServicePermission service_name={[ServiceName.TIME_SCREEN]} showReason={true}>
                            <Flex column padding="padding.medium" gap="gap.large">
                                <Text content={moment(isDateInfo.data.LDATE).format("dddd DD MMMM, YYYY")} size="large" weight="semibold" />
                                <Flex gap="gap.large" space="between" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} >
                                    <Flex column gap="gap.small" >
                                        <Text content={"First Half"} weight="semibold" />
                                        <Box className="dataInfoTime" styles={({ theme: { siteVariables } }) => ({
                                            background: siteVariables.bodyBackground,
                                        })}>
                                            <Flex gap="gap.medium" vAlign="center">
                                                <Image src={inTime} />
                                                <Flex column>
                                                    <Text content="In Time" />
                                                    <Text content={isDateInfo.data.SWIPE_IN} color="grey" weight="semibold" />
                                                </Flex>
                                            </Flex>
                                        </Box>
                                        <Flex gap="gap.smaller">
                                            <Text content="Leave Type :" size="medium" weight="bold" />
                                            <Text content={(isDateInfo.data.FH !== null ? isDateInfo.data.FH : "")} weight="semibold" />
                                        </Flex>
                                    </Flex>
                                    <Flex column gap="gap.small" >
                                        <Text content={"Second Half"} weight="semibold" />
                                        <Box className="dataInfoTime" styles={({ theme: { siteVariables } }) => ({
                                            background: siteVariables.bodyBackground,
                                        })}>
                                            <Flex gap="gap.medium" vAlign="center" >
                                                <Image src={outTime} />
                                                <Flex column>
                                                    <Text content="Out Time" />
                                                    <Text content={isDateInfo.data.SWIPE_OUT} color="grey" weight="semibold" />
                                                </Flex>
                                            </Flex>
                                        </Box>
                                        <Flex gap="gap.smaller">
                                            <Text content="Leave Type:" size="medium" weight="bold" />
                                            <Text content={(isDateInfo.data.SH !== null ? isDateInfo.data.SH : "")} weight="semibold" />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex gap="gap.small" >
                                    <Text content="Status :" size="medium" weight="bold" />
                                    <Text content={
                                        state.attendanceData?.attendance?.public_holidays?.some(sub => sub === isDateInfo.data.LDATE)
                                            ? "Public Holidays" :
                                            state.attendanceData?.attendance?.week_offs?.some(sub => sub === isDateInfo.data.LDATE)
                                                ? "Week Off" :
                                                applyLeavesDate.pending_for_approval?.some(sub => sub === isDateInfo.data.LDATE)
                                                    ? "Pending For Approval" :
                                                    applyLeavesDate.lwop?.some(sub => sub === isDateInfo.data.LDATE)
                                                        ? "LWOP" :
                                                        applyLeavesDate.approved?.some(sub => sub === isDateInfo.data.LDATE)
                                                            ? "Approved" :
                                                            applyLeavesDate.approved_and_post_pending?.some(sub => sub === isDateInfo.data.LDATE)
                                                                ? "Approved and update in progress" :
                                                                applyLeavesDate.rejected?.some(sub => sub === isDateInfo.data.LDATE)
                                                                    ? "Rejected" :
                                                                    applyLeavesDate.deleted_and_update_in_progress?.some(sub => sub === isDateInfo.data.LDATE)
                                                                        ? "Deleted and update in progress" :
                                                                        applyLeavesDate.delete_approval_pending?.some(sub => sub === isDateInfo.data.LDATE)
                                                                            ? "Delete approval pending" :
                                                                            applyLeavesDate.error_in_posting?.some(sub => sub === isDateInfo.data.LDATE)
                                                                                ? "Error in posting" :
                                                                                applyLeavesDate.error_in_deletion?.some(sub => sub === isDateInfo.data.LDATE)
                                                                                    ? "Error in deletion"
                                                                                    : ""
                                    } weight="semibold" />
                                </Flex>
                            </Flex>
                        </ServicePermission>
                    </Box >
                }
                header="Attendance Details"
                headerAction={{
                    content: <CloseIcon />,
                    onClick: () => setIsDateInfo({ ...isDateInfo, isActive: false })
                }}
            />
        </React.Fragment >
    );
};

export default AttendanceEnquiry;
