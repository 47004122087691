import { Actions } from "../actions/appConfigration";

export interface IAppConfig {
    teamMemberSelect: { emp_name: string; emp_id: string; user_id: string, username: string, internal_role: string };
    dimension: { deviceWidth: number; deviceHeight: number };
    isDocumentView?: boolean;
    isDocumentExpand?: boolean,
    viewMTMWish?: boolean,
    path?: string,
    vcType: string
    isVcModify: boolean
}

const appReducer = (state = { teamMemberSelect: {}, dimension: {}, isDocumentView: false, isDocumentExpand: false, viewMTMWish: true, path: "", vcType: "Digital", isVcModify: false }, action: any) => {
    switch (action.type) {
        case Actions.TEAM_MEMBER_SELECT:
            return {
                ...state,
                teamMemberSelect: action.payload,
            };
        case Actions.DEVICE_DIMESIONS:
            return {
                ...state,
                dimension: action.payload,
            };
        case Actions.SET_DOCUMENT_VIEW:
            return {
                ...state,
                isDocumentView: action.payload,
            };
        case Actions.SET_DOCUMENT_EXPAND:
            return {
                ...state,
                isDocumentExpand: action.payload,
            };
        case Actions.VIEW_MTM_WISH:
            return {
                ...state,
                viewMTMWish: action.payload
            };
        case Actions.SET_PATH:
            return {
                ...state,
                path: action.payload
            };
        case Actions.VC_TYPE:
            return {
                ...state,
                vcType: action.payload
            };

        case Actions.IS_VC_MODIFY:
            return {
                ...state,
                isVcModify: action.payload
            };
        default:
            return state;
    }
};

export default appReducer;
