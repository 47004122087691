import React from "react";
import {
    Button,
    Flex
} from "@fluentui/react-northstar";
import { IState } from "../../App";
import CFMTaskModule from "../CFMTaskModule";
import TeamMembers from "../../components/TeamMembers";
import { useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import ServicePermission from "../HOC/ServicePermission";
import { ServiceName } from "../../constant";
interface ICFMFeedbackTaskModule {
    QueryData: IState
}

const CFMFeedbackTaskModule: React.FC<ICFMFeedbackTaskModule> = ({ QueryData }) => {

    const state = useSelector((state: RootSchema) => state);

    const [table, setTable] = React.useState(true);
    const [showBckBtn, setShowBckBtn] = React.useState(true);

    /**
     * Add back functionality in CFM Feedback module
     */
    const onBack = () => {
        setTable(true);
    };

    return (
        <React.Fragment>
            <ServicePermission service_name={[ServiceName.TEAM, ServiceName.TEAM_MEMBER]} showReason={true}>
                {table ?
                    <TeamMembers QueryData={QueryData} isManagerView={true} selectedName={setTable} isTaskModule={true} /> : <>
                        {state.userInfo.teamMemberData.selfMember.isSuccess &&
                            state.appConfig.teamMemberSelect.emp_id !== undefined &&
                            state.userInfo.teamMemberData.selfMember.status_code === "200" &&
                            (state.userInfo.teamMemberData.selfMember.user_details.direct_reportees.length > 0 || state.userInfo.teamMemberData.selfMember.user_details.matrix_reportees.length) && (
                            <CFMTaskModule
                                QueryData={QueryData}
                                byManagerUserId={state.appConfig.teamMemberSelect.user_id}
                                byManagerEmpId={state.appConfig.teamMemberSelect.emp_id}
                                managerView={true}
                                activeBackBtn={setShowBckBtn}
                            />
                        )}
                        {showBckBtn ?
                            <Flex padding="padding.medium" hAlign="end" vAlign="center" styles={{ height: "75px" }} onClick={() => onBack()}>
                                <Button content="Back" />
                            </Flex>
                            : <></>
                        }
                    </>
                }
            </ServicePermission>
        </React.Fragment>
    );
};

export default CFMFeedbackTaskModule;