import { Box, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import error from "../../assets/img/error.png";
import dnf from "../../assets/img/dnf.png";
import "./error.scss";

interface IError {
  message: string;
  size?: string
  height?: string;
  width?: string
}

const ErrorComponent: React.FC<IError> = ({ message, size, width, height }) => {
    return (
        <Flex
            className="Error"
            gap="gap.large"
            column
            vAlign="center"
            hAlign="center"
            fill style={{ minHeight: "100%", padding: "2rem" }}
        >
            <Box className="error-box">
                <Flex fill vAlign="center" hAlign="center">
                    <Image
                        src={error}
                        height={height ? height : "200px"}
                        width={width ? width : "200px"}
                    />
                </Flex>
            </Box>
            <Text
                align="center"
                content={message}
                styles={{ fontSize: size ? size : "20px" }}
                weight="semibold"
            />
        </Flex>
    );
};

export default ErrorComponent;