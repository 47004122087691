import React from "react";
import { Dropdown, Menu, Segment, Flex, FlexItem, Button, AddIcon, Checkbox, Table, Text, Loader, ApprovalsAppbarIcon, Divider, Skeleton, TrashCanIcon, gridCellWithFocusableElementBehavior, Box, Input, SearchIcon, ChevronStartIcon, ChevronEndIcon } from "@fluentui/react-northstar";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import { Constant, getDDMMYYYFormat } from "../../constant";
import DataNotFound from "../../components/DNF";
import ErrorComponent from "../ErrorComponent";
import { IState } from "../../App";
import IncidentDetails from "../IncidentDetails";
import * as ITSMAction from "../../redux/actions/ITSMAction";
import "./IncidentStatus.scss";


interface IIncidentStatusProps {
  QueryData: IState;
}


const statusItem = [
  "ASSIGNED",
  "CLOSED",
  "OPEN",
  "PEND 3RDPARTY",
  "PEND CUSTOMER",
  "PENDING - BRM/BU",
  "PENDING - CLIENT HOLD",
  "PENDING - MONITORING INCIDENT",
  "PENDING - SOFTWARE CHANGE",
  "PENDING DEFERRED",
  "PROGRESSING",
  "RESOLVED",
  "FULFILLED",
  "IN APPROVAL",
  "REJECTED"
];

const IncidentStatus: React.FC<IIncidentStatusProps> = ({ QueryData }) => {
  const [employeeData, setEmployeeData] = React.useState({});
  const [Status, setStatus] = React.useState("All");
  const [searchTicket, setSearchTicket] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [btnClick, setBtnClick] = React.useState(false);
  const [page, setPage] = React.useState({
    listPerPage: 7,
    currentPage: 0
  });
  const dispatch = useDispatch();
  const state = useSelector((state: RootSchema) => state);
  function handleRowClick(record: any, data: any) {
    setEmployeeData(data);
    setOpen(true);
  }

  function onAddNotes(item: any) {
    setOpen(true);
    setBtnClick(true);
    setEmployeeData(item);
  }

  const payload: ITSMAction.IGetTicketsPayload = {
    ticketIdNumber: "",
    ticketStatus: "",
    supportGroup: "",
    assignedTo: "",
    loggedInUsername: QueryData.userId as string,

  };

  const rows: any = state.itsmData.getTicketDetails.isSuccess && Array.isArray(state.itsmData.getTicketDetails.itemData) ?
    state.itsmData.getTicketDetails.itemData.filter((item) => (item.ticketIdNumber.toLowerCase().includes(searchTicket.toLowerCase().trimStart()) || item.description.toLowerCase().includes(searchTicket.toLowerCase().trimStart())) && (Status !== "All" ? item.status === Status : item.status !== "")).map((item, index) => {
      return (
        {
          key: index,
          items: [
            {
              content: <Text content={item.ticketIdNumber} />,
              key: index + "1",
              styles: {
                justifyContent: "start", whiteSpace: "nowrap", maxWidth: "120px", minWidth: "100px"
              }
            },
            {
              content: <Text content={item.category} />,
              key: index + "2",
              truncateContent: true,
              title: item.category,
              styles: {
                justifyContent: "start", maxWidth: "200px", minWidth: "100px"
              }
            },

            {
              content: <Text content={item.description} />,
              key: index + "4",
              truncateContent: true,
              title: item.description,
              styles: {
                justifyContent: "start", maxWidth: "200px", minWidth: "100px"
              }
            },
            {
              content: <Text content={getDDMMYYYFormat(item.creation_time)} />,
              key: index + "3",
              styles: {
                justifyContent: "center", minWidth: "50px"
              }
            },
            {
              content: <Button icon={<AddIcon />} text iconOnly title="Add Notes" onClick={(e) => onAddNotes(item)} />,
              key: index + "6",
              truncateContent: true,
              styles: {
                justifyContent: "center", maxWidth: "100px", minWidth: "60px"
              },
              onClick: (e: any) => e.stopPropagation()
            }
          ],
          onClick: (record: any) => handleRowClick(record, item),
          styles: {
            cursor: "pointer",
            borderLeft: "5px solid",
            borderLeftColor: ["ASSIGNED", "OPEN"].includes(item.status) ? "#8EA9DB" : ["CLOSED", "RESOLVED"].includes(item.status) ? "#92D050" : "#FFCC00",
            borderRadius: "5px 0px 0px 5px",
            "&:hover": {
              borderLeft: "5px solid",
              borderLeftColor: ["ASSIGNED", "OPEN"].includes(item.status) ? "#8EA9DB" : ["CLOSED", "RESOLVED"].includes(item.status) ? "#92D050" : "#FFCC00",
              borderRadius: "5px 0px 0px 5px",
            }
          }
        }
      );
    }) : [];


  const header = [
    {
      content: <Text content="Ticket ID" weight='bold' size='medium' />,
      key: "tid",
      styles: {
        justifyContent: "start", maxWidth: "120px"
      }
    },
    {
      content: <Text content="Category" weight='bold' size='medium' />,
      key: "category",
      styles: {
        justifyContent: "start", maxWidth: "200px", minWidth: "100px"
      }
    },

    {
      content: <Text content="Short Description" weight='bold' size='medium' />,
      key: "description",
      styles: {
        justifyContent: "start", maxWidth: "calc( 200px)", minWidth: "100px"
      }
    },
    {
      content: <Text content="Opening Date" weight='bold' size='medium' />,
      key: "odate",
      styles: {
        justifyContent: "center", minWidth: "50px"
      }
    },
    {
      content: <Text content="Action" weight='bold' size='medium' />,
      key: "action",
      styles: {
        justifyContent: "center", maxWidth: "100px", minWidth: "60px"
      }
    }
  ];

  React.useEffect(() => {
    if (!state.itsmData.getTokenData.isSuccess) {
      dispatch(ITSMAction.getTokenDataAction());
    }
  }, []);


  React.useEffect(() => {
    if (state.itsmData.getTokenData.isSuccess) {
      dispatch(ITSMAction.getTicketsDataAction(state.itsmData.getTokenData.access_token, payload));
    }
  }, [state.itsmData.getTokenData.isSuccess]);

  React.useEffect(() => {
    setPage({
      ...page,
      currentPage: 0
    });
  }, [searchTicket, Status]);


  return (
    <React.Fragment>
      <Flex column className="IncidentStatus">
        <Flex gap={"gap.small"} styles={{ padding: "1.5rem 1rem" }} fill column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
          <FlexItem size="40%">
            <Dropdown
              clearable={Status === "All" ? false : true}
              checkable
              inverted
              className="inputBody"
              fluid
              loading={false}
              loadingMessage={
                <Skeleton animation="pulse">
                  <Flex gap="gap.smaller" column>
                    <Skeleton.Line />
                    <Skeleton.Line />
                  </Flex>
                </Skeleton>
              }
              items={statusItem}
              noResultsMessage={<Flex fill vAlign='center' hAlign='center'><Text content="No Status List Found" /></Flex>}
              placeholder="Select Status"
              onChange={(e: any, v: any) => v.value === null ? setStatus("All") : setStatus(v.value)}
            />
          </FlexItem>
          <FlexItem grow>
            <Input icon={<SearchIcon />} placeholder="Search By Ticket Id and Description..." iconPosition="start" fluid onChange={(e: any) => setSearchTicket(e.target.value)} />
          </FlexItem>
        </Flex>
        <Divider fitted />
        <Box className='box-table' styles={QueryData.isTaskModuleActive ? { height: "calc(100vh - 85px)" } : { height: "calc(100vh - 140px)" }} >
          {state.itsmData.getTicketDetails.isSuccess && rows.length > 0 ? (
            <Flex column>
              <Flex column styles={{ height: QueryData.isTaskModuleActive ? "calc(100vh - 140px)" : "calc(100vh - 200px)", overflow: "auto" }}>
                <Table
                  rows={rows.slice(page.listPerPage * page.currentPage, page.listPerPage * page.currentPage + page.listPerPage)}
                  header={{
                    items: header,
                    header: true,
                    className: "tableHead",
                    styles: ({ theme: { siteVariables } }) => ({
                      background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                      "&:hover": {
                        background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                      }
                    })
                  }}
                  styles={{ maxWidth: "inherit", overflow: "auto" }}
                />
              </Flex>
              <Divider fitted />
              <Flex gap="gap.small" vAlign="center" fill hAlign='end' padding='padding.medium'>
                <Button
                  icon={<ChevronStartIcon />}
                  iconOnly
                  style={{ color: "#6264a7" }}
                  disabled={page.listPerPage * page.currentPage > 0 ? false : true}
                  onClick={() => { setPage({ ...page, currentPage: page.currentPage - 1 }); }}
                />
                <Text>
                  {page.currentPage + 1} of {Math.ceil(rows.length / page.listPerPage)}
                </Text>
                <Button
                  icon={<ChevronEndIcon />}
                  iconOnly
                  style={{ color: "#6265a7" }}
                  disabled={rows.slice(page.listPerPage * page.currentPage, page.listPerPage * page.currentPage + page.listPerPage).includes(rows[rows.length - 1]) ? true : false}
                  onClick={() => { setPage({ ...page, currentPage: page.currentPage + 1 }); }}
                />
              </Flex>
            </Flex>
          ) :
            state.itsmData.getTicketDetails.isSuccess && rows.length === 0 ? (
              <Flex>
                <DataNotFound />
              </Flex>
            )
              : state.itsmData.getTicketDetails.isError ? (
                <Flex vAlign="center" hAlign="center">
                  <ErrorComponent message="Something went wrong" />
                </Flex>
              )
                : (
                  <Skeleton animation="pulse">
                    <Flex gap="gap.medium" column padding='padding.medium'>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                        return <Skeleton.Line key={item} />;
                      })}
                    </Flex>
                  </Skeleton>
                )}
        </Box>
      </Flex>
      {
        open && <IncidentDetails setOpen={setOpen} open={open} QueryData={QueryData} employeeData={employeeData} btnClick={btnClick} setBtnClick={setBtnClick} />
      }
    </React.Fragment >
  );
};

export default IncidentStatus;