import axios from "axios";
import { TraceEvent } from "../constant";
import { getAppInsights, ai } from "./../telemetry/telemetryService";
interface QuerySchema {
  [key: string]: string;
}

export interface HttpServiceSchema {
  httpGet: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpDelete: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPost: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPut: (path: string, query: QuerySchema, payload: any) => Promise<any>;
}

const handleErrorResp = (err: any) => {
    return err.response && err.response.data !== "string"
        ? Promise.reject(err.response.data)
        : Promise.reject(err);
};

// export interface IServerError{
//   respose :{ }
// }
// const handleErrorResp = (err: any) => {
//   return err.response && err.response.data !== "string"
//     ? Promise.reject(err.response.data) : (err.response.data === "string" ? Promise.reject({ response: { data: {error:}}})
//     : Promise.reject(err));
// };

/**
 * This function makes a HTTP GET request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @returns {Promise} promise containing response data or errors
 */

/**
 * This function makes a HTTP POST request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @param {QuerySchema} payload optional body containing the request payload
 * @returns {Promise} promise containing response data or errors
 */
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
ai.initialize();
const { appInsights, trackException, trackTrace } = getAppInsights();
export const httpGet = async (url: string, config = {}) => {
    let res: any;
    return await axios
        .get(url, config)
        .then((response) => {

            res = response;
            return response;
        }).catch((err) => {
            trackTrace(appInsights, TraceEvent.API_ISSUE, {...err,url});
            return handleErrorResp(err);
        }).finally(() => {
            if (appInsights && res.data && (res.data.status_code|| res.data.status) && ["200"].indexOf(res.data.status_code|| res.data.status)==-1) {
                trackTrace(appInsights, TraceEvent.API_ISSUE, {data:res.data, url});
            }
        });
};

export const httpPost = async (
    url: string,
    payload = {} as any,
    config = {} as any
) => {
    let res: any={};
    return await axios
        .post(url, payload, config)
        .then((response) => {
            res = response;
            return response;
        }).catch((err) => {
            trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, ...payload,url });
            return handleErrorResp(err);
        }).finally(() => {
            if (appInsights && res.data && (res.data.status_code|| res.data.status) && ["200"].indexOf(res.data.status_code|| res.data.status)==-1) {
                const data = {
                    response: res.data,
                    body: payload
                };
                trackTrace(appInsights, TraceEvent.API_ISSUE, {data: data, url});
            }
        });
};

export const httpPost2 = async (url: string, payload = {}, config = {}) => {
    let res: any;
    return axios
        .post(url, payload, config)
        .then((response) => {
            res = response;
            return response;
        }).catch((err) => {
            trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, ...payload, url });
            return handleErrorResp(err);
        }).finally(() => {
            if (appInsights && res.data && (res.data.status_code|| res.data.status) && ["200"].indexOf(res.data.status_code|| res.data.status)==-1) {
       
                const data = {
                    response: res.data,
                    body: payload
                };
                trackTrace(appInsights, TraceEvent.API_ISSUE, {data:data, url});
            }
        });
};

export const httpPut = async (url: string, payload = {}, config = {}) => {
    let res: any;
    return axios
        .put(url, payload, config)
        .then((response) => {
            res = response;
            return response;
        }).catch((err) => {
            trackTrace(appInsights, TraceEvent.API_ISSUE, { ...err, ...payload });
            return handleErrorResp(err);
        }).finally(() => {
            if (appInsights && res.data && (res.data.status_code|| res.data.status) && ["200"].indexOf(res.data.status_code|| res.data.status)==-1) {
                const data = {
                    response: res.data,
                    body: payload
                };
                trackTrace(appInsights, TraceEvent.API_ISSUE, {data: data, url});
            }
        });
};
