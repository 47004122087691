import { put, call, take, delay, takeEvery, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/MTMAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, MTM_CONFIG } from "../../config";

function* getMomentFeedData(actions: { type: string, payload: { access_token: string, payload: actionCreators.IGetMomentFeedPayload } }) {
    try {
        yield put(actionCreators.getMomentFeedLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${actions.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + MTM_CONFIG.GET_MOMENT_FEED_DATA,
            actions.payload.payload,
            config
        );
        yield put(actionCreators.getMomentFeedSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getMomentFeedErrorAction(error));
    }
}

function* getUpdateReactionData(payload: { access_token: string, payload: actionCreators.IUpdateUserReactionPayload }) {
    try {
        yield put(actionCreators.updateUserReactionLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + MTM_CONFIG.UPDATE_USER_REACTION_DATA,
            payload.payload,
            config
        );
        yield put(actionCreators.updateUserReactionSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.updateUserReactionErrorAction(error));
    }
}

function* getEmployeeReactionData(actions: { type: string; payload: { access_token: string, payload: actionCreators.IGetEmployeeReactionPayload } }) {
    try {
        yield put(actionCreators.getEmployeeReactionLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${actions.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + MTM_CONFIG.GET_EMPLOYEE_REACTION_DATA,
            actions.payload.payload,
            config
        );
        yield put(actionCreators.getEmployeeReactionSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getEmployeeReactionErrorAction(error));
    }
}

function* getMoomentMatterData(payload: { access_token: string, emp_id: string }) {
    try {
        yield put(actionCreators.getMoomentMatterLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + MTM_CONFIG.GET_MOOMENT_MATTER_DATA,
            {emp_id: payload.emp_id},
            config
        );
        yield put(actionCreators.getMoomentMatterSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getMoomentMatterErrorAction(error));
    }
}

function* getSendWishNotificationData(payload: { access_token: string, payload: actionCreators.IGetSendWishNotificationPayload }) {
    try {
        yield put(actionCreators.getSendWishNotificationLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + MTM_CONFIG.GET_SEND_WISH_NOTIFICATION_DATA,
            payload.payload,
            config
        );
        yield put(actionCreators.getSendWishNotificationSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getSendWishNotificationErrorAction(error));
    }
}

function* getMyMilestonesData(payload: { access_token: string, emp_id: string }) {
    try {
        yield put(actionCreators.getMyMilestonesLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + MTM_CONFIG.GET_MY_MILESTONES_DATA,
            { emp_id: payload.emp_id },
            config
        );
        yield put(actionCreators.getMyMilestonesSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getMyMilestonesErrorAction(error));
    }
}

function* getUpcomingMilestonesData(payload: { access_token: string, emp_id: string }) {
    try {
        yield put(actionCreators.getUpcomingMilestonesLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + MTM_CONFIG.GET_UPCOMING_MILESTONES_DATA,
            { emp_id: payload.emp_id },
            config
        );
        yield put(actionCreators.getUpcomingMilestonesSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getUpcomingMilestonesErrorAction(error));
    }
}



// ==========================================WATCH FUNCTIONS======================================================

export function* watchGetMomentFeedData() {
    yield takeLatest(actionCreators.Actions.GET_MOMENT_FEED_DATA, getMomentFeedData);
}

export function* watchUpdateUserReactionData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.UPDATE_USER_REACTION_DATA
        );
        yield call(getUpdateReactionData, payload);
    }
}
export function* watchGetEmployeeReactionData() {
    yield takeLatest(actionCreators.Actions.GET_EMPLOYEE_REACTION_DATA, getEmployeeReactionData);
}

export function* watchGetMoomentMatterData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_MOOMENT_MATTER_DATA
        );
        yield call(getMoomentMatterData, payload);
    }
}

export function* watchGetSendWishNotificationData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SEND_WISH_NOTIFICATION_DATA
        );
        yield call(getSendWishNotificationData, payload);
    }
}

export function* watchGetMyMilestonesData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_MY_MILESTONES_DATA
        );
        yield call(getMyMilestonesData, payload);
    }
}
export function* watchGetUpcomingMilestonesData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_UPCOMING_MILESTONES_DATA,
        );
        yield call(getUpcomingMilestonesData, payload);
    }
}