import React from "react";
import { Dialog, Button, Flex, Text, FlexItem, CloseIcon } from "@fluentui/react-northstar";
import { getDDMMYYYFormat } from "../../constant";

interface IpopupProps {
    setOpen: (a: boolean) => void,
    open: boolean,
    employeeData: any
}

const ApprovalDetails: React.FC<IpopupProps> = ({ setOpen, open, employeeData }) => {
    return (
        <Dialog
            // cancelButton="Cancel"
            closeOnOutsideClick={true}
            content={
                <Flex column gap='gap.large' space='between' >
                    <FlexItem grow>
                        <Flex space='between' padding="padding.medium">
                            <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} wrap><Text truncated content='Employee Name' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData?.requestor_name} /></Flex>
                            <Flex column styles={{ width: "140px" }} ><Text content='Employee ID' weight='bold' /><Text color='grey' content={employeeData.requestor_emp_id} /></Flex>
                            <Flex column styles={{ width: "140px" }}><Text content='Leave Type' weight='bold' /><Text color='grey' content={employeeData.leave_type} /></Flex>
                        </Flex>
                    </FlexItem>
                    <FlexItem grow>
                        <Flex space='between' padding="padding.medium">
                            <Flex column styles={{ width: "140px" }}><Text content='Leave Category' weight='bold' /><Text color='grey' content={employeeData.leave_category} /></Flex>
                            <Flex column styles={{ width: "140px" }}><Text content='To Date' weight='bold' /><Text color='grey' content={getDDMMYYYFormat(employeeData.to_date)} /></Flex>
                            <Flex column styles={{ width: "140px" }}><Text content='Start Date' weight='bold' /><Text color='grey' content={getDDMMYYYFormat(employeeData.from_date)} /></Flex>
                        </Flex>
                    </FlexItem>
                    <FlexItem grow>
                        <Flex space='between' padding="padding.medium">
                            <Flex column styles={{ width: "140px" }}><Text content='Reason' weight='bold' /><Text styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.reason} /></Flex>
                            <Flex column styles={{ width: "140px" }}><Text content='Total Days' weight='bold' /><Text color='grey' content={employeeData.total_days} /></Flex>
                            <Flex column styles={{ width: "140px" }}><Text content='Status' weight='bold' /><Text color='grey' content={employeeData.leave_status} /></Flex>
                        </Flex>
                    </FlexItem>
                </Flex>
            }
            header={<Flex space='around'>
                <Text
                    style={{
                        marginBottom: "4%",
                        backgroundColor: "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(91, 98, 192, 0.05) 100%)"
                    }}
                    content="Leave Details" size='larger' weight="bold" /></Flex>}
            // headerAction={<CloseIcon />}
            headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => setOpen(false),
            }}
            open={open}
        />
    );
};

export default ApprovalDetails;