import {
    Actions,
    IGetSuccessFactorUserDetails,
    IGetEmployeeGoal,
    IAddGoalComments,
    IGetEmployeeCompetencies,
    IAddCompetenciesComments,
    IGetGoalComment,
    IGetCompetencyComment,
    IGetPerformanceTimeline,
    ISendFeedbackRequest,
    IGetLeadershipBehaviourDescription,
    IUpdateManagerGoalCommentData,
    IUpdateManagerCompetencyCommentData,
    IDeleteManagerGoalCommentData,
    IDeleteManagerCompetencyCommentData
} from "../actions/CFMActions";
import { combineReducers } from "redux";

export const getSuccessFactorUserDetailsDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_SUCCESS_FACTOR_USER_DETAILS_LOADING:
    case Actions.GET_SUCCESS_FACTOR_USER_DETAILS_ERROR:
    case Actions.GET_SUCCESS_FACTOR_USER_DETAILS_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const getEmployeeGoalsDataReducer = (state = { selfGoal: {}, teamGoal: {} }, action: any) => {
    switch (action.type) {
    case Actions.GET_EMPLOYEE_GOAL_LOADING:
    case Actions.GET_EMPLOYEE_GOAL_ERROR:
    case Actions.GET_EMPLOYEE_GOAL_SUCCESS:
        return (action.payload.isTeam ? {
            ...state,
            teamGoal: action.payload
        } : {
            ...state,
            selfGoal: action.payload
        });
    default:
        return state;
    }
};

export const addGoalCommentDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.ADD_GOAL_COMMENTS_LOADING:
    case Actions.ADD_GOAL_COMMENTS_ERROR:
    case Actions.ADD_GOAL_COMMENTS_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const getEmployeeCompetencyDataReducer = (state = { selfCompetency: {}, teamCompetency: {} }, action: any) => {
    switch (action.type) {
    case Actions.GET_EMPLOYEE_COMPETENCY_LOADING:
    case Actions.GET_EMPLOYEE_COMPETENCY_ERROR:
    case Actions.GET_EMPLOYEE_COMPETENCY_SUCCESS:
        return (action.payload.isTeam ? {
            ...state,
            teamCompetency: action.payload
        } : {
            ...state,
            selfCompetency: action.payload
        });
    default:
        return state;
    }
};

export const addCompetencyCommentDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.ADD_COMPETENCY_COMMENT_LOADING:
    case Actions.ADD_COMPETENCY_COMMENT_ERROR:
    case Actions.ADD_COMPETENCY_COMMENT_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const getGoalCommentsDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_GOAL_COMMENTS_LOADING:
    case Actions.GET_GOAL_COMMENTS_ERROR:
    case Actions.GET_GOAL_COMMENTS_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const getCompetencyCommentDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_COMPETENCY_COMMENT_LOADING:
    case Actions.GET_COMPETENCY_COMMENT_ERROR:
    case Actions.GET_COMPETENCY_COMMENT_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const getPerformanceTimelineDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.ADD_PERFORMANCE_TIMELINE_LOADING:
    case Actions.ADD_PERFORMANCE_TIMELINE_ERROR:
    case Actions.ADD_PERFORMANCE_TIMELINE_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const sendFeedbackRequestReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.SEND_FEEDBACK_REQUEST_LOADING:
    case Actions.SEND_FEEDBACK_REQUEST_ERROR:
    case Actions.SEND_FEEDBACK_REQUEST_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const getLeadershipBehaviuorDescriptionReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_LOADING:
    case Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_ERROR:
    case Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_SUCCESS:
        return {
            ...state,
            ...action.payload
        };
    default:
        return state;
    }
};

export const updateManagerGoalCommentDataReducer = (state = {}, action: any) => {
    switch(action.type) {
    case Actions.UPDATE_MANAGER_GOAL_COMMENT_LOADING:
    case Actions.UPDATE_MANAGER_GOAL_COMMENT_ERROR:
    case Actions.UPDATE_MANAGER_GOAL_COMMENT_SUCCESS:
        return {
            ...state,
            ...action.payload
        };
    default:
        return state;
    }
};

export const updateManagerCompetencyCommentDataReducer = (state = {}, action: any) => {
    switch(action.type) {
    case Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_LOADING:
    case Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_ERROR:
    case Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_SUCCESS:
        return {
            ...state,
            ...action.payload
        };
    default:
        return state;
    }
};

export const deleteManagerGoalCommentDataReducer = (state = {}, action: any) => {
    switch(action.type) {
    case Actions.DELETE_MANAGER_GOAL_COMMENT_LOADING:
    case Actions.DELETE_MANAGER_GOAL_COMMENT_ERROR:
    case Actions.DELETE_MANAGER_GOAL_COMMENT_SUCCESS:
        return {
            ...state,
            ...action.payload
        };
    default:
        return state;
    }
};

export const deleteManagerCompetencyDataReducer = (state = {}, action: any) => {
    switch(action.type) {
    case Actions.DELETE_MANAGER_COMPETENCY_COMMENT_LOADING:
    case Actions.DELETE_MANAGER_COMPETENCY_COMMENT_ERROR:
    case Actions.DELETE_MANAGER_COMPETENCY_COMMENT_SUCCESS:
        return {
            ...state,
            ...action.payload
        };
    default:
        return state;
    }
};

interface IEmployeeGoalData {
  selfGoal: IGetEmployeeGoal;
  teamGoal: IGetEmployeeGoal;
}

interface IEmployeeCompetencyData {
  selfCompetency: IGetEmployeeCompetencies;
  teamCompetency: IGetEmployeeCompetencies;
}

export interface ICFMData {
  getSuccessFactorUserDetailsData: IGetSuccessFactorUserDetails,
  getEmployeeGoalsData: IEmployeeGoalData,
  addGoalCommentData: IAddGoalComments,
  getEmployeeCompetencyData: IEmployeeCompetencyData,
  addCompetencyCommentData: IAddCompetenciesComments,
  getGoalCommentsData: IGetGoalComment,
  getCompetencyCommentData: IGetCompetencyComment,
  getPerformanceTimelineData: IGetPerformanceTimeline,
  sendFeedbackRequest: ISendFeedbackRequest,
  getLeadershipBehaviuorDescription: IGetLeadershipBehaviourDescription,
  updateManagerGoalComment: IUpdateManagerGoalCommentData,
  updateManagerCompetencyComment: IUpdateManagerCompetencyCommentData,
  deleteManagerGoalComment: IDeleteManagerGoalCommentData,
  deleteManagerCompetencyComment: IDeleteManagerCompetencyCommentData
}


const CFMData = combineReducers({
    getSuccessFactorUserDetailsData: getSuccessFactorUserDetailsDataReducer,
    getEmployeeGoalsData: getEmployeeGoalsDataReducer,
    addGoalCommentData: addGoalCommentDataReducer,
    getEmployeeCompetencyData: getEmployeeCompetencyDataReducer,
    addCompetencyCommentData: addCompetencyCommentDataReducer,
    getGoalCommentsData: getGoalCommentsDataReducer,
    getCompetencyCommentData: getCompetencyCommentDataReducer,
    getPerformanceTimelineData: getPerformanceTimelineDataReducer,
    sendFeedbackRequest: sendFeedbackRequestReducer,
    getLeadershipBehaviuorDescription: getLeadershipBehaviuorDescriptionReducer,
    updateManagerGoalComment: updateManagerGoalCommentDataReducer,
    updateManagerCompetencyComment: updateManagerCompetencyCommentDataReducer,
    deleteManagerGoalComment: deleteManagerGoalCommentDataReducer,
    deleteManagerCompetencyComment: deleteManagerCompetencyDataReducer
});

export default CFMData;