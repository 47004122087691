
import {
    Actions,
    IGetTokenData,
    IGetTicketsData,
    IAddNotesData,
    ICreateTicketData,
    IUpdateTicketData,
    IAddAttachmentData,
    IGetUserApprovalData,
    IUpdateApprovalData,
    IItsmItems,
    IItsmCategory,
    IItsmSubCategory,
    ITicketApprovalData,
    ICreateTicketCatalogData
} from "../actions/ITSMAction";
import { combineReducers } from "redux";

export const getTokenDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_TOKEN_LOADING:
        case Actions.GET_TOKEN_ERROR:
        case Actions.GET_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getItsmCategoryReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ITSM_CATEGORY_LOADING:
        case Actions.GET_ITSM_CATEGORY_ERROR:
        case Actions.GET_ITSM_CATEGORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getItsmSubCategoryReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ITSM_SUB_CATEGORY_LOADING:
        case Actions.GET_ITSM_SUB_CATEGORY_ERROR:
        case Actions.GET_ITSM_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getItsmItemReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ITSM_ITEM_LIST_LOADING:
        case Actions.GET_ITSM_ITEM_LIST_ERROR:
        case Actions.GET_ITSM_ITEM_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getTicketDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_TICKETS_LOADING:
        case Actions.GET_TICKETS_ERROR:
        case Actions.GET_TICKETS_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const addNotesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.ADD_NOTES_LOADING:
        case Actions.ADD_NOTES_ERROR:
        case Actions.ADD_NOTES_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const createTicketReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.CREATE_TICKET_LOADING:
        case Actions.CREATE_TICKET_ERROR:
        case Actions.CREATE_TICKET_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const updateTicketReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.UPDATE_TICKET_LOADING:
        case Actions.UPDATE_TICKET_ERROR:
        case Actions.UPDATE_TICKET_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const addAttachmentReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.ADD_ATTACHMENT_LOADING:
        case Actions.ADD_ATTACHMENT_ERROR:
        case Actions.ADD_ATTACHMENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getMyApprovalReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_USER_APPROVAL_LIST_LOADING:
        case Actions.GET_USER_APPROVAL_LIST_ERROR:
        case Actions.GET_USER_APPROVAL_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const updateApprovalReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.UPDATE_APPROVAL_LOADING:
        case Actions.UPDATE_APPROVAL_ERROR:
        case Actions.UPDATE_APPROVAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const createTicketCatalogReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.CREATE_TICKET_CATALOG_LOADING:
        case Actions.CREATE_TICKET_CATALOG_ERROR:
        case Actions.CREATE_TICKET_CATALOG_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getTicketApprovalListReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_TICKET_APPROVAL_LIST_LOADING:
        case Actions.GET_TICKET_APPROVAL_LIST_ERROR:
        case Actions.GET_TICKET_APPROVAL_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export interface IITSMData {
    getTokenData: IGetTokenData,
    getItsmItemData: IItsmItems,
    getItsmCategoryData: IItsmCategory,
    getItsmSubCategoryData: IItsmSubCategory,
    getTicketDetails: IGetTicketsData,
    addNotes: IAddNotesData,
    createTicket: ICreateTicketData,
    updateTicket: IUpdateTicketData,
    addAttachment: IAddAttachmentData,
    getMyApproval: IGetUserApprovalData,
    updateApproval: IUpdateApprovalData,
    createTicketCatalog: ICreateTicketCatalogData,
    getTicketApprovalList: ITicketApprovalData

}

const itsmData = combineReducers({
    getTokenData: getTokenDataReducer,
    getItsmItemData: getItsmItemReducer,
    getItsmCategoryData: getItsmCategoryReducer,
    getItsmSubCategoryData: getItsmSubCategoryReducer,
    getTicketDetails: getTicketDetailsReducer,
    addNotes: addNotesReducer,
    createTicket: createTicketReducer,
    updateTicket: updateTicketReducer,
    addAttachment: addAttachmentReducer,
    getMyApproval: getMyApprovalReducer,
    updateApproval: updateApprovalReducer,
    createTicketCatalog: createTicketCatalogReducer,
    getTicketApprovalList: getTicketApprovalListReducer
});
export default itsmData;