import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    // -------------------------Announcement Request---------------------------
    GET_ANNOUNCEMENT_DATA: "GET_ANNOUNCEMENT_DATA",
    GET_ANNOUNCEMENT_DATA_LOADING: "GET_ANNOUNCEMENT_DATA_LOADING",
    GET_ANNOUNCEMENT_DATA_ERROR: "GET_ANNOUNCEMENT_DATA_ERROR",
    GET_ANNOUNCEMENT_DATA_SUCCESS: "GET_ANNOUNCEMENT_DATA_SUCCESS",

    // -------------------------Announcement Feedback Request---------------------------
    GET_ANNOUNCEMENT_FEEDBACK_DATA: "GET_ANNOUNCEMENT_FEEDBACK_DATA",
    GET_ANNOUNCEMENT_FEEDBACK_DATA_LOADING:
    "GET_ANNOUNCEMENT_FEEDBACK_DATA_LOADING",
    GET_ANNOUNCEMENT_FEEDBACK_DATA_ERROR: "GET_ANNOUNCEMENT_FEEDBACK_DATA_ERROR",
    GET_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS:
    "GET_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS",

    // -------------------------Set Announcement Feedback Request---------------------------
    POST_ANNOUNCEMENT_FEEDBACK_DATA: "POST_ANNOUNCEMENT_FEEDBACK_DATA",
    POST_ANNOUNCEMENT_FEEDBACK_DATA_LOADING:
    "POST_ANNOUNCEMENT_FEEDBACK_DATA_LOADING",
    POST_ANNOUNCEMENT_FEEDBACK_DATA_ERROR:
    "POST_ANNOUNCEMENT_FEEDBACK_DATA_ERROR",
    POST_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS:
    "POST_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS",
};

export interface IAnnoucementFeedback {
  employee_response: string;
}
export interface IAnnouncementItem {
  announcement_id: string;
  announcement_title: string;
  announcement_description: string;
  announcement_image: string;
  announcement_type: string;
  is_active: boolean;
  created_datetime: string;
  modified_datetime: string;
  created_by: string;
  total_response: number;
  employee_response: string;
  feedback: IAnnoucementFeedback[]
  image_link: string;
}

export interface IGetAnnouncemnetData extends SideEffectSchema {
  status_code: string;
  message: string;
  total_count: number;
  data: IAnnouncementItem[];
  page_number: number;
  total_page: number;
}
export interface IGetAnnouncemnetPayload {
  announcement_type: string;
  page_number: number;
  employee_id: string;
}

export const getAnnouncementsDataAction = (
    access_token: string,
    emp_comp: string,
    announcementData: IGetAnnouncemnetPayload
) => {
    return {
        type: Actions.GET_ANNOUNCEMENT_DATA,
        payload: { access_token, emp_comp, announcementData },
    };
};
export const getAnnouncementsDataLoadingAction = () =>
    loadingAction(Actions.GET_ANNOUNCEMENT_DATA_LOADING);
export const getAnnouncementsDataErrorAction = (error: string) => {
    return errorAction(Actions.GET_ANNOUNCEMENT_DATA_ERROR, error);
};
export const getAnnouncementsDataSuccessAction = (data: any) => {
    const payload: IGetAnnouncemnetData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_ANNOUNCEMENT_DATA_SUCCESS,
        payload: payload,
    };
};

export interface IGetAnnouncemnetFeedbackData extends SideEffectSchema {
  status_code: string;
  message: string;
  total_count: number;
  data: {
    employee_name: string;
    employee_response: string;
  }[];
  total_page: number;
  feedback_count: {
    like: number;
    applaud: number;
    love: number;
    thoughtful: number;
    innovative: number;
  };
}
export interface IGetAnnouncemnetFeedbackPayload {
  announcement_id: string;
  employee_response: string;
  page_number: number;
}

export const getAnnouncementsFeedbackDataAction = (
    access_token: string,
    emp_comp: string,
    feedbackData: IGetAnnouncemnetFeedbackPayload
) => {
    return {
        type: Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA,
        payload: { access_token, emp_comp, feedbackData },
    };
};
export const getAnnouncementsFeedbackDataLoadingAction = () =>
    loadingAction(Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA_LOADING);
export const getAnnouncementsFeedbackDataErrorAction = (error: string) =>
    errorAction(Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA_ERROR, error);
export const getAnnouncementsFeedbackDataSuccessAction = (data: any) => {
    const payload: IGetAnnouncemnetFeedbackData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS,
        payload: payload,
    };
};

export interface IPostFeedbackPayload {
  announcement_id: string;
  employee_id: string;
  employee_name: string;
  employee_response: string;
}

export interface IPostFeedbackData extends SideEffectSchema {
  status_code: string;
  message: string;
}

export const postAnnouncementsFeedbackDataAction = (
    access_token: string,
    emp_comp: string,
    postPayload: IPostFeedbackPayload
) => {
    return {
        type: Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA,
        payload: { access_token, emp_comp, postPayload },
    };
};
export const postAnnouncementsFeedbackDataLoadingAction = () =>
    loadingAction(Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA_LOADING);
export const postAnnouncementsFeedbackDataErrorAction = (error: string) =>
    errorAction(Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA_ERROR, error);
export const postAnnouncementsFeedbackDataSuccessAction = (data: any) => {
    const payload: IPostFeedbackData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS,
        payload: payload,
    };
};
