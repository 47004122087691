import { combineReducers, Action } from "redux";
import leaveData, { ILeaveData } from "./leaveReducer";
import attendanceData, { IAttendanceData } from "./attendanceReducer";
import vaccineData, { IVaccineData } from "./vaccineReducer";
import authData, { IAuthData } from "./authReducer";
import userInfo, { IUserInfo } from "./userDataReducer";
import { IGetUserToken } from "../actions/AuthActions";
import appReducer, { IAppConfig } from "./appReducer";
import announcementData, { IAnnouncementData } from "./announcementReducer";
import todoData, { ITodoData } from "./todoReducer";
import CFMData, { ICFMData } from "./cfmReducer";
import notificationData, { INotificationData } from "./notificationReducer";
import documentData, { IDocumentData } from "./DocumentReducer";
import itsmData, { IITSMData } from "./ITSMReducer";
import iTtipsData, { IITtipsData } from "./ITipsReducer";
import imacData, { IIMACData } from "./imacReducer";
import serviceData, { IServiceData } from "./serviceReducer";
import visitorData, { IVisitorData } from "./VisitorReducer";
import pinData, { IPinData } from "./pinReducer";
import mtmData, { IMTMData } from "./MTMReducer";
import { IGetChannelData, IGetVideoLibraryData } from "../actions/ChannelAction";
import getChannelReducer from "./channelReducer";
import { IGetSendMailData } from "../actions/SendMailAction";
import getSendMailReducer from "./sendMailReducer";
import sapIdData, { ISapIdData } from "./SapIdReducer";
import acrSapData, { IAcrSapData } from "./acrSapReducer";
import paySpaceData, { IPaySpaceData } from "./PaySpaceReducer";
import getVideoLibraryReducer from "./videoLibrary";
// Default reducer

function defaultReducer(state = {}, _action: any) {
  return state;
}
export interface RootSchema {
  // eslint-disable-next-line @typescript-eslint/ban-types
  default: {};
  appConfig: IAppConfig;
  authData: IAuthData;
  leaveData: ILeaveData;
  attendanceData: IAttendanceData;
  vaccineData: IVaccineData;
  announcementData: IAnnouncementData;
  userInfo: IUserInfo;
  todoData: ITodoData;
  cfmData: ICFMData;
  notificationData: INotificationData;
  documentData: IDocumentData;
  itsmData: IITSMData;
  itTipsData: IITtipsData;
  imacData: IIMACData;
  serviceData: IServiceData;
  visitorData: IVisitorData;
  pinData: IPinData;
  mtmData: IMTMData;
  channelData: IGetChannelData;
  videoLibraryData: IGetVideoLibraryData
  sendMail: IGetSendMailData;
  sapIdData: ISapIdData;
  acrSapData: IAcrSapData;
  paySpaceData: IPaySpaceData;
}

const rootReducer = combineReducers({
  default: defaultReducer,
  appConfig: appReducer,
  authData: authData,
  leaveData: leaveData,
  todoData: todoData,
  attendanceData: attendanceData,
  vaccineData: vaccineData,
  announcementData: announcementData,
  userInfo: userInfo,
  cfmData: CFMData,
  notificationData: notificationData,
  documentData: documentData,
  itsmData: itsmData,
  itTipsData: iTtipsData,
  imacData: imacData,
  serviceData: serviceData,
  visitorData: visitorData,
  pinData: pinData,
  mtmData: mtmData,
  channelData: getChannelReducer,
  videoLibraryData: getVideoLibraryReducer,
  sendMail: getSendMailReducer,
  sapIdData: sapIdData,
  acrSapData: acrSapData,
  paySpaceData: paySpaceData
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
