import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_TOKEN_DATA: "GET_TOKEN_DATA",
    GET_TOKEN_LOADING: "GET_TOKEN_LOADING",
    GET_TOKEN_ERROR: "GET_TOKEN_ERROR",
    GET_TOKEN_SUCCESS: "GET_TOKEN_SUCCESS",

    GET_TICKETS_DATA: "GET_TICKETS_DATA",
    GET_TICKETS_LOADING: "GET_TICKETS_LOADING",
    GET_TICKETS_ERROR: "GET_TICKETS_ERROR",
    GET_TICKETS_SUCCESS: "GET_TICKETS_SUCCESS",

    ADD_NOTES_DATA: "ADD_NOTES_DATA",
    ADD_NOTES_LOADING: "ADD_NOTES_LOADING",
    ADD_NOTES_ERROR: "ADD_NOTES_ERROR",
    ADD_NOTES_SUCCESS: "ADD_NOTES_SUCCESS",

    CREATE_TICKET_DATA: "CREATE_TICKET_DATA",
    CREATE_TICKET_LOADING: "CREATE_TICKET_LOADING",
    CREATE_TICKET_ERROR: "CREATE_TICKET_ERROR",
    CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",

    UPDATE_TICKET_DATA: "UPDATE_TICKET_DATA",
    UPDATE_TICKET_LOADING: "UPDATE_TICKET_LOADING",
    UPDATE_TICKET_ERROR: "UPDATE_TICKET_ERROR",
    UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",

    ADD_ATTACHMENT_DATA: "ADD_ATTACHMENT_DATA",
    ADD_ATTACHMENT_LOADING: "ADD_ATTACHMENT_LOADING",
    ADD_ATTACHMENT_ERROR: "ADD_ATTACHMENT_ERROR",
    ADD_ATTACHMENT_SUCCESS: "ADD_ATTACHMENT_SUCCESS",

    GET_USER_APPROVAL_LIST_DATA: "GET_USER_APPROVAL_LIST_DATA",
    GET_USER_APPROVAL_LIST_LOADING: "GET_USER_APPROVAL_LIST_LOADING",
    GET_USER_APPROVAL_LIST_ERROR: "GET_USER_APPROVAL_LIST_ERROR",
    GET_USER_APPROVAL_LIST_SUCCESS: "GET_USER_APPROVAL_LIST_SUCCESS",

    UPDATE_APPROVAL_DATA: "UPDATE_APPROVAL_DATA",
    UPDATE_APPROVAL_LOADING: "UPDATE_APPROVAL_LOADING",
    UPDATE_APPROVAL_ERROR: "UPDATE_APPROVAL_ERROR",
    UPDATE_APPROVAL_SUCCESS: "UPDATE_APPROVAL_SUCCESS",

    CREATE_TICKET_CATALOG_DATA: "CREATE_TICKET_CATALOG_DATA",
    CREATE_TICKET_CATALOG_LOADING: "CREATE_TICKET_CATALOG_LOADING",
    CREATE_TICKET_CATALOG_ERROR: "CREATE_TICKET_CATALOG_ERROR",
    CREATE_TICKET_CATALOG_SUCCESS: "CREATE_TICKET_CATALOG_SUCCESS",

    GET_TICKET_APPROVAL_LIST_DATA: "GET_TICKET_APPROVAL_LIST_DATA",
    GET_TICKET_APPROVAL_LIST_LOADING: "GET_TICKET_APPROVAL_LIST_LOADING",
    GET_TICKET_APPROVAL_LIST_ERROR: "GET_TICKET_APPROVAL_LIST_ERROR",
    GET_TICKET_APPROVAL_LIST_SUCCESS: "GET_TICKET_APPROVAL_LIST_SUCCESS",


    // --------------------Category ITSM ------------
    GET_ITSM_CATEGORY_DATA: "GET_ITSM_CATEGORY_DATA",
    GET_ITSM_CATEGORY_LOADING: "GET_ITSM_CATEGORY_LOADING",
    GET_ITSM_CATEGORY_ERROR: "GET_ITSM_CATEGORY_ERROR",
    GET_ITSM_CATEGORY_SUCCESS: "GET_ITSM_CATEGORY_SUCCESS",

    // --------------------SubCategory ITSM ------------
    GET_ITSM_SUB_CATEGORY_DATA: "GET_ITSM_SUB_CATEGORY_DATA",
    GET_ITSM_SUB_CATEGORY_LOADING: "GET_ITSM_SUB_CATEGORY_LOADING",
    GET_ITSM_SUB_CATEGORY_ERROR: "GET_ITSM_SUB_CATEGORY_ERROR",
    GET_ITSM_SUB_CATEGORY_SUCCESS: "GET_ITSM_SUB_CATEGORY_SUCCESS",

    // --------------------Category Three ------------
    GET_ITSM_ITEM_LIST_DATA: "GET_ITSM_ITEM_LIST_DATA",
    GET_ITSM_ITEM_LIST_LOADING: "GET_ITSM_ITEM_LIST_LOADING",
    GET_ITSM_ITEM_LIST_ERROR: "GET_ITSM_ITEM_LIST_ERROR",
    GET_ITSM_ITEM_LIST_SUCCESS: "GET_ITSM_ITEM_LIST_SUCCESS",
};

export interface IGetTokenData extends SideEffectSchema {
    access_token: string
}

export const getTokenDataAction = () => {
    return {
        type: Actions.GET_TOKEN_DATA,
    };
};
export const getTokenDataLoadingAction = () =>
    loadingAction(Actions.GET_TOKEN_LOADING);
export const getTokenErrorAction = (error: string) =>
    errorAction(Actions.GET_TOKEN_ERROR, error);
export const getTokenSuccessAction = (data: any) => {
    const payload: IGetTokenData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_TOKEN_SUCCESS,
        payload: payload
    };
};


export interface IGetTicketsPayload {
    ticketIdNumber: string,
    ticketStatus: string,
    supportGroup: string,
    assignedTo: string,
    loggedInUsername: string,
}

export interface IGetTicketsData extends SideEffectSchema {
    itemData: {
        objid: number,
        type: string,
        ticketIdNumber: string,
        vip: string,
        company: string,
        creation_time: string,
        priority: string,
        status: string,
        description: string,
        assigned_user: string,
        contact: string,
        assigned_group: string,
        target_resolve_time: string,
        agreed_contact_date: string,
        location: string,
        customer: string,
        alt_location: string,
        category: string,
        subcategory: string,
        item: string,
        channel: string,
        sla_status: string,
        last_updated: string,
        reopened_date: string,
        reopened_by: string,
        dealer_code: string,
        region: string
    }[]
}

export const getTicketsDataAction = (
    access_token: string,
    get_ticket_data: IGetTicketsPayload
) => {
    return {
        type: Actions.GET_TICKETS_DATA,
        payload: { access_token, get_ticket_data }
    };
};


export const getTicketsLoadingAction = () =>
    loadingAction(Actions.GET_TICKETS_LOADING);

export const getTicketsErrorAction = (error: string) =>
    errorAction(Actions.GET_TICKETS_ERROR, error);

export const getTicketsSuccessAction = (data: any) => {
    const payload = { itemData: data, ...successSideEffectState };

    return {
        type: Actions.GET_TICKETS_SUCCESS,
        payload: payload
    };
};

export interface IAddNotesPayload {
    ticketIdNumber: string,
    notes: string,
    loggedInUsername: string
}

export interface IAddNotesData extends SideEffectSchema {
    TicketId: string,
    message: string
}

export const addNotesDataAction = (
    access_token: string,
    add_notes_data: IAddNotesPayload
) => {
    return {
        type: Actions.ADD_NOTES_DATA,
        payload: { access_token, add_notes_data }
    };
};
export const addNotesLoadingAction = () =>
    loadingAction(Actions.ADD_NOTES_LOADING);
export const addNotesErrorAction = (error: string) =>
    errorAction(Actions.ADD_NOTES_ERROR, error);
export const addNotesSuccessAction = (data: any) => {
    const payload: IAddNotesData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.ADD_NOTES_SUCCESS,
        payload: payload
    };
};

export interface ICreateTicketPayload {
    type: string;
    loggedInUsername: string;
    category: string;
    subcategory: string;
    item: string;
    priority: string;
    severity: string;
    assigned_group: string;
    shortDescription: string;
    additionalComments: string;
    requsterName?: string;
    template?: string;
    location?: string;
    newUserId?: string;
    channel: string;
}

export interface ICreateTicketData extends SideEffectSchema {
    ticketId: string;
    message: string;
    status_code?: string;
}

export const createTicketDataAction = (
    access_token: string,
    create_ticket_data: ICreateTicketPayload
) => {
    return {
        type: Actions.CREATE_TICKET_DATA,
        payload: { access_token, create_ticket_data }
    };
};
export const createTicketLoadingAction = () =>
    loadingAction(Actions.CREATE_TICKET_LOADING);
export const createTicketErrorAction = (error: string) =>
    errorAction(Actions.CREATE_TICKET_ERROR, error);
export const createTicketSuccessAction = (data: any) => {
    const payload: ICreateTicketData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.CREATE_TICKET_SUCCESS,
        payload: payload
    };
};

export interface IUpdateTicketPayload {
    type: string,
    ticketIdNumber: string,
    supportGroup: string,
    status: string,
    resolutionComments: string,
    loggedInUsername: string,
    resolutionMethod: string,
    resolutionCriteria: string,
    resolutionCause: string
}

export interface IUpdateTicketData extends SideEffectSchema {
    ticketId: string,
    message: string
}

export const updateTicketDataAction = (
    access_token: string,
    update_ticket_data: IUpdateTicketPayload
) => {
    return {
        type: Actions.UPDATE_TICKET_DATA,
        payload: { access_token, update_ticket_data }
    };
};
export const updateTicketLoadingAction = () =>
    loadingAction(Actions.UPDATE_TICKET_LOADING);
export const updateTicketErrorAction = (error: string) =>
    errorAction(Actions.UPDATE_TICKET_ERROR, error);
export const updateTicketSuccessAction = (data: any) => {
    const payload: IUpdateTicketData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.UPDATE_TICKET_SUCCESS,
        payload: payload
    };
};

export interface IAddAttachmentPayload {
    ticketIdNumber: string;
    fileName: string;
    fileData: string;
    loggedInUsername: string
}

export interface IAddAttachmentData extends SideEffectSchema {
    ticketId: string;
    Message: string;
    message?: string;
}

export const addAttachmentDataAction = (
    access_token: string,
    payload: any//todo
) => {
    return {
        type: Actions.ADD_ATTACHMENT_DATA,
        payload: { access_token, payload }
    };
};
export const addAttachmentLoadingAction = () =>
    loadingAction(Actions.ADD_ATTACHMENT_LOADING);
export const addAttachmentErrorAction = (error: string) =>
    errorAction(Actions.ADD_ATTACHMENT_ERROR, error);
export const addAttachmentSuccessAction = (data: any) => {
    const payload: IAddAttachmentData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.ADD_ATTACHMENT_SUCCESS,
        payload: payload
    };
};

export interface IGetUserApprovalData extends SideEffectSchema {
    approvals: {
        apprIdNumber: string,
        apprModule: string,
        apprRecordId: string,
        apprStatus: string,
        apprDescription: string,
        apprQueue: string,
        apprApprover: string,
        apprCreatedBy: string,
        apprCreatedOn: string,
        apprRequester: string,
        apprSequence: number
    }[]
}

export const getUserApprovalDataAction = (
    access_token: string,
    loggedInUsername: string
) => {
    return {
        type: Actions.GET_USER_APPROVAL_LIST_DATA,
        payload: { access_token, loggedInUsername }
    };
};

export const getUserApprovalLoadingAction = () =>
    loadingAction(Actions.GET_USER_APPROVAL_LIST_DATA);

export const getUserApprovalErrorAction = (error: string) =>
    errorAction(Actions.GET_USER_APPROVAL_LIST_ERROR, error);

export const getUserApprovalSuccessAction = (data: any) => {
    const payload = { approvals: data, ...successSideEffectState };

    return {
        type: Actions.GET_USER_APPROVAL_LIST_SUCCESS,
        payload: payload
    };
};

export interface IUpdateApprovalPayload {
    ticketIdNumber: string;
    notes: string;
    status: string;
    loggedInUsername: string;
}

export interface IUpdateApprovalData extends SideEffectSchema {
    ticketId: string;
    message: string;
}

export const updateApprovalDataAction = (
    access_token: string,
    payload: IUpdateApprovalPayload
) => {
    return {
        type: Actions.UPDATE_APPROVAL_DATA,
        payload: { access_token, payload }
    };
};
export const updateApprovalLoadingAction = () =>
    loadingAction(Actions.UPDATE_APPROVAL_LOADING);
export const updateApprovalErrorAction = (error: string) =>
    errorAction(Actions.UPDATE_APPROVAL_ERROR, error);
export const updateApprovalSuccessAction = (data: any) => {
    const payload: IUpdateApprovalData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.UPDATE_APPROVAL_SUCCESS,
        payload: payload
    };
};



export interface IItsmCategory extends SideEffectSchema {
    statusCode: string;
    data: { id: number; name: string; is_active: boolean }[]
}

export const getItsmCategoryDataAction = () => {
    return {
        type: Actions.GET_ITSM_CATEGORY_DATA
    };
};
export const getItsmCategoryLoadingAction = () =>
    loadingAction(Actions.GET_ITSM_CATEGORY_LOADING);
export const getItsmCategoryErrorAction = (error: string) =>
    errorAction(Actions.GET_ITSM_CATEGORY_ERROR, error);
export const getItsmCategorySuccessAction = (data: any) => {
    const payload: IItsmCategory = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_ITSM_CATEGORY_SUCCESS,
        payload: payload
    };
};


export interface IItsmSubCategory extends SideEffectSchema {
    statusCode: string;
    data: { id: number; name: string; is_active: boolean, category_id: number }[]
}

export const getItsmSubCategoryDataAction = (id: number) => {
    return {
        type: Actions.GET_ITSM_SUB_CATEGORY_DATA,
        payload: id
    };
};
export const getItsmSubCategoryLoadingAction = () =>
    loadingAction(Actions.GET_ITSM_SUB_CATEGORY_LOADING);
export const getItsmSubCategoryErrorAction = (error: string) =>
    errorAction(Actions.GET_ITSM_SUB_CATEGORY_ERROR, error);
export const getItsmSubCategorySuccessAction = (data: any) => {
    const payload: IItsmSubCategory = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_ITSM_SUB_CATEGORY_SUCCESS,
        payload: payload
    };
};

export interface IItsmItems extends SideEffectSchema {
    statusCode: string;
    data: { id: number; name: string; is_active: boolean, sub_category_id: number }[]
}

export const getItsmItemDataAction = (id: number) => {
    return {
        type: Actions.GET_ITSM_ITEM_LIST_DATA,
        payload: id
    };
};
export const getItsmItemLoadingAction = () =>
    loadingAction(Actions.GET_ITSM_ITEM_LIST_LOADING);
export const getItsmItemErrorAction = (error: string) =>
    errorAction(Actions.GET_ITSM_ITEM_LIST_ERROR, error);
export const getItsmItemSuccessAction = (data: any) => {
    const payload: IItsmItems = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_ITSM_ITEM_LIST_SUCCESS,
        payload: payload
    };
};


export interface ICreateTicketCatalogPayload {
    type: string,
    loggedInUsername: string,
    category: string,
    subcategory: string,
    item: string,
    priority: string,
    severity: string,
    assigned_group: string,
    shortDescription: string,
    additionalComments: string,
    requsterName: string,
    template: string,
    location: string,
    newUserId: string,
    channel: string
}

export interface ICreateTicketCatalogData extends SideEffectSchema {
    ticketId: string;
    message: string;
}

export const createTicketCatalogDataAction = (
    access_token: string,
    payload: ICreateTicketCatalogPayload
) => {
    return {
        type: Actions.CREATE_TICKET_CATALOG_DATA,
        payload: { access_token, payload }
    };
};
export const createTicketCatalogLoadingAction = () =>
    loadingAction(Actions.CREATE_TICKET_CATALOG_LOADING);
export const createTicketCatalogErrorAction = (error: string) =>
    errorAction(Actions.CREATE_TICKET_CATALOG_ERROR, error);
export const createTicketCatalogSuccessAction = (data: any) => {
    const payload: ICreateTicketCatalogData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.CREATE_TICKET_CATALOG_SUCCESS,
        payload: payload
    };
};

///////////////////////////////////////////////////////////////////////////////////////////////////
export interface ITicketApprovalPayload {
    ticketIdNumber: string;
}

export interface ITicketApprovalData extends SideEffectSchema {
    approverList: {
        apprApprover: string,
        apprComment: string,
        apprCreatedBy: string,
        apprCreatedOn: string,
        apprDescription: string,
        apprIdNumber: string,
        apprModule: string,
        apprQueue: string,
        apprRecordId: string,
        apprRequester: string,
        apprSequence: number,
        apprStatus: string,
        approvedBy: string,
        approvedOn: string,
    }[];
}

export const getTicketApprovalDataAction = (
    access_token: string,
    payload: ITicketApprovalPayload
) => {
    return {
        type: Actions.GET_TICKET_APPROVAL_LIST_DATA,
        payload: { access_token, payload }
    };
};
export const getTicketApprovalLoadingAction = () =>
    loadingAction(Actions.GET_TICKET_APPROVAL_LIST_LOADING);
export const getTicketApprovalErrorAction = (error: string) =>
    errorAction(Actions.GET_TICKET_APPROVAL_LIST_ERROR, error);
export const getTicketApprovalSuccessAction = (data: any) => {
    const payload: ITicketApprovalData = {
        ...{ approverList: data },
        ...successSideEffectState
    };
    return {
        type: Actions.GET_TICKET_APPROVAL_LIST_SUCCESS,
        payload: payload
    };
};


