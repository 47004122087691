import { combineReducers } from "redux";
import { Actions, IUser, IUserManager, ITeamMembers } from "../actions/UserDataActions";

const getUserManagerReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_USER_WITH_MANAGER_DATA_LOADING:
    case Actions.GET_USER_WITH_MANAGER_DATA_ERROR:
    case Actions.GET_USER_WITH_MANAGER_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

const getUserReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_USER_DATA_LOADING:
    case Actions.GET_USER_DATA_ERROR:
    case Actions.GET_USER_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

const getTeamMembersReducer = (state = { teamMember: {}, selfMember: {} }, action: any) => {
    switch (action.type) {
    case Actions.GET_TEAM_MEMBERS_LOADING:
    case Actions.GET_TEAM_MEMBERS_ERROR:
    case Actions.GET_TEAM_MEMBERS_SUCCESS:
        return (action.payload.isManager ? {
            ...state,
            teamMember: action.payload
        } : {
            ...state,
            selfMember: action.payload
        });
    default:
        return state;
    }
};

interface ITeamMemberData {
  teamMember: ITeamMembers;
  selfMember: ITeamMembers;
}

export interface IUserInfo {
  userManagerData: IUserManager;
  userData: IUser;
  teamMemberData: ITeamMemberData;
}

const userInfo = combineReducers({
    userManagerData: getUserManagerReducer,
    userData: getUserReducer,
    teamMemberData: getTeamMembersReducer
});

export default userInfo;
