import React from "react";
import { Segment } from "@fluentui/react-northstar";
import { RootSchema } from "../../redux/reducer";
import { useSelector } from "react-redux";
import DataNotFound from "../DNF";
import CFM from "../../assets/img/CFM.svg";

interface IProps {
    children: JSX.Element;
    service_name: string[];
    showReason: boolean;
}

const ServicePermission: React.FC<IProps> = (props) => {
    const state = useSelector((state: RootSchema) => state);
    const serviceData = state.serviceData.getServiceData.services_status;
    for(const i in serviceData) {
        for(const j in props.service_name) {
            if(serviceData[i].service_name === props.service_name[j]) {
                if(!serviceData[i].is_active && props.showReason) {
                    return (
                        <Segment styles={{ borderRadius: "5px", padding: "0", margin: "0", height: "100%", display: "flex", alignItems: "center"}}>
                            <DataNotFound message={serviceData[i].reason} img={CFM} />
                        </Segment>
                    );
                } else if(!serviceData[i].is_active && !props.showReason) {
                    return null;
                }
            }
        }
    }
    return (props.children);
};

export default ServicePermission;