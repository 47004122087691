import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_MASTER_LOCATION_DATA: "GET_MASTER_LOCATION_DATA",
    GET_MASTER_LOCATION_LOADING: " GET_MASTER_LOCATION_LOADING",
    GET_MASTER_LOCATION_ERROR: " GET_MASTER_LOCATION_ERROR",
    GET_MASTER_LOCATION_SUCCESS: " GET_MASTER_LOCATION_SUCCESS",

    GET_MASTER_FIELD_NAMES_DATA: "GET_MASTER_FIELD_NAMES_DATA",
    GET_MASTER_FIELD_NAMES_LOADING: "GET_MASTER_FIELD_NAMES_LOADING",
    GET_MASTER_FIELD_NAMES_ERROR: "GET_MASTER_FIELD_NAMES_ERROR",
    GET_MASTER_FIELD_NAMES_SUCCESS: "GET_MASTER_FIELD_NAMES_SUCCESS",

    GET_OFFICE_LOCATION_DATA: "GET_OFFICE_LCATION_DATA",
    GET_OFFICE_LOCATION_LOADING: "GET_OFFICE_LCATION_LOADING",
    GET_OFFICE_LOCATION_ERROR: "GET_OFFICE_LCATION_ERROR",
    GET_OFFICE_LOCATION_SUCCESS: "GET_OFFICE_LCATION_SUCCESS",
    GET_OFFICE_LOCATION_CLEAR: "GET_OFFICE_LOCATION_CLEAR",

    GET_DISPLAY_FIELD_DATA: "GET_DISPLAY_FIELD_DATA",
    GET_DISPLAY_FIELD_LOADING: "GET_DISPLAY_FIELD_LOADING",
    GET_DISPLAY_FIELD_ERROR: "GET_DISPLAY_FIELD_ERROR",
    GET_DISPLAY_FIELD_SUCCESS: "GET_DISPLAY_FIELD_SUCCESS",
    GET_DISPLAY_FIELD_CLEAR: "GET_DISPLAY_FIELD_CLEAR",

    GET_ALL_STATUS_DATA: "GET_ALL_STATUS_DATA",
    GET_ALL_STATUS_LOADING: "GET_ALL_STATUS_LOADING",
    GET_ALL_STATUS_ERROR: "GET_ALL_STATUS_ERROR",
    GET_ALL_STATUS_SUCCESS: "GET_ALL_STATUS_SUCCESS",

    GET_SAVE_DIGITAL_DATA: "GET_SAVE_DIGITAL_DATA",
    GET_SAVE_DIGITAL_LOADING: "GET_SAVE_DIGITAL_LOADING",
    GET_SAVE_DIGITAL_ERROR: "GET_SAVE_DIGITAL_ERROR",
    GET_SAVE_DIGITAL_SUCCESS: "GET_SAVE_DIGITAL_SUCCESS",

    GET_SAVE_PYSICAL_DATA: "GET_SAVE_PYSICAL_DATA",
    GET_SAVE_PYSICAL_LOADING: "GET_SAVE_PYSICAL_LOADING",
    GET_SAVE_PYSICAL_ERROR: "GET_SAVE_PYSICAL_ERROR",
    GET_SAVE_PYSICAL_SUCCESS: "GET_SAVE_PYSICAL_SUCCESS",

    GET_CARD_QUANTITY_DATA: "GET_CARD_QUANTITY_DATA",
    GET_CARD_QUANTITY_LOADING: "GET_CARD_QUANTITY_LOADING",
    GET_CARD_QUANTITY_ERROR: "GET_CARD_QUANTITY_ERROR",
    GET_CARD_QUANTITY_SUCCESS: "GET_CARD_QUANTITY_SUCCESS",

    GET_SYSTEM_SHUTDOWN_DATA: "GET_SYSTEM_SHUTDOWN_DATA",
    GET_SYSTEM_SHUTDOWN_LOADING: "GET_SYSTEM_SHUTDOWN_LOADING",
    GET_SYSTEM_SHUTDOWN_ERROR: "GET_SYSTEM_SHUTDOWN_ERROR",
    GET_SYSTEM_SHUTDOWN_SUCCESS: "GET_SYSTEM_SHUTDOWN_SUCCESS",

    GET_DIGITAL_VC_DATA: "GET_DIGITAL_VC_DATA",
    GET_DIGITAL_VC_LOADING: "GET_DIGITAL_VC_LOADING",
    GET_DIGITAL_VC_ERROR: "GET_DIGITAL_VC_ERROR",
    GET_DIGITAL_VC_SUCCESS: "GET_DIGITAL_VC_SUCCESS",
    GET_DIGITAL_VC_CLEAR: "GET_DIGITAL_VC_CLEAR",

    GET_PHYSICAL_VC_DATA: "GET_PHYSICAL_VC_DATA",
    GET_PHYSICAL_VC_LOADING: "GET_PHYSICAL_VC_LOADING",
    GET_PHYSICAL_VC_ERROR: "GET_PHYSICAL_VC_ERROR",
    GET_PHYSICAL_VC_SUCCESS: "GET_PHYSICAL_VC_SUCCESS",

    GET_QR_STRING_DATA: "GET_QR_STRING_DATA",
    GET_QR_STRING_LOADING: "GET_QR_STRING_LOADING",
    GET_QR_STRING_ERROR: "GET_QR_STRING_ERROR",
    GET_QR_STRING_SUCCESS: "GET_QR_STRING_SUCCESS",

    UPDATE_PHYSICAL_VC_DATA: "UPDATE_PHYSICAL_VC_DATA",
    UPDATE_PHYSICAL_VC_LOADING: "UPDATE_PHYSICAL_VC_LOADING",
    UPDATE_PHYSICAL_VC_ERROR: "UPDATE_PHYSICAL_VC_ERROR",
    UPDATE_PHYSICAL_VC_SUCCESS: "UPDATE_PHYSICAL_VC_SUCCESS"
};
export interface IGetMasterLocationData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        LocationId: number,
        LocationText: string,
    }[]
}

export const getMasterDataAction = () => {
    return {
        type: Actions.GET_MASTER_LOCATION_DATA,
    };
};
export const getMasterLoadingAction = () =>
    loadingAction(Actions.GET_MASTER_LOCATION_LOADING);

export const getMasterErrorAction = (error: string) =>
    errorAction(Actions.GET_MASTER_LOCATION_ERROR, error);

export const getMasterSuccessAction = (data: any) => {
    const payload: IGetMasterLocationData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_MASTER_LOCATION_SUCCESS,
        payload: payload
    };
};
/////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetMasterFieldData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        Field_Name: string,
        IsActive: boolean,
        Creation_date: string,
        CreatedBy: number,
        Modification_date: never,
        ModifiedBy: never,
        FieldType: string,
        Field_Code: string,
        Field_Sequence: never
    }[]
}

export const getMasterFieldAction = () => {
    return {
        type: Actions.GET_MASTER_FIELD_NAMES_DATA,
    };
};
export const getMasterFieldLoadingAction = () =>
    loadingAction(Actions.GET_MASTER_FIELD_NAMES_LOADING);

export const getMasterFieldErrorAction = (error: string) =>
    errorAction(Actions.GET_MASTER_FIELD_NAMES_ERROR, error);

export const getMasterFieldSuccessAction = (data: any) => {
    const payload: IGetMasterFieldData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_MASTER_FIELD_NAMES_SUCCESS,
        payload: payload
    };
};
/////////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetMasterOfficePayload {
    locationId: number
}
export interface IGetMasterOfficeData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        OfficeAddressId: number,
        OfficeAddress: string
    }[]
}

export const getMasterOfficeAction = (
    get_master_office: IGetMasterOfficePayload
) => {
    return {
        type: Actions.GET_OFFICE_LOCATION_DATA,
        payload: { get_master_office }
    };
};
export const getMasterOfficeLoadingAction = () =>
    loadingAction(Actions.GET_OFFICE_LOCATION_LOADING);

export const getMasterOfficeErrorAction = (error: string) =>
    errorAction(Actions.GET_OFFICE_LOCATION_ERROR, error);

export const getMasterOfficeSuccessAction = (data: any) => {
    const payload: IGetMasterOfficeData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_OFFICE_LOCATION_SUCCESS,
        payload: payload
    };
};

export const getMasterOfficeClearAction = () => {
    return {
        type: Actions.GET_OFFICE_LOCATION_CLEAR,
    };
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetDisplayFieldPayload {
    empId: string,
    fieldCode: string
}
export interface IGetDisplayFieldData extends SideEffectSchema {
    status_code: string,
    message: string,
    position: string
}

export const getDisplayFieldAction = (
    get_display_field: IGetDisplayFieldPayload
) => {
    return {
        type: Actions.GET_DISPLAY_FIELD_DATA,
        payload: { get_display_field }
    };
};
export const getDisplayFieldLoadingAction = () =>
    loadingAction(Actions.GET_DISPLAY_FIELD_LOADING);

export const getDisplayFieldErrorAction = (error: string) =>
    errorAction(Actions.GET_DISPLAY_FIELD_ERROR, error);

export const getDisplayFieldSuccessAction = (data: any) => {
    const payload: IGetDisplayFieldData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_DISPLAY_FIELD_SUCCESS,
        payload: payload
    };
};

export const getDisplayFieldClearAction = () => {
    return {
        type: Actions.GET_DISPLAY_FIELD_CLEAR
    };
};

///////////////////////////////////////////////////////////////////////////////

export interface IGetAllStatusData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        StatusId: number,
        Status: string,
    }[]
}

export const getAllStatusDataAction = () => {
    return {
        type: Actions.GET_ALL_STATUS_DATA,
    };
};
export const getAllStatusLoadingAction = () =>
    loadingAction(Actions.GET_ALL_STATUS_LOADING);

export const getAllStatusErrorAction = (error: string) =>
    errorAction(Actions.GET_ALL_STATUS_ERROR, error);

export const getAllStatusSuccessAction = (data: any) => {
    const payload: IGetAllStatusData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_ALL_STATUS_SUCCESS,
        payload: payload
    };
};

//////////////////////////////////////////////////////////////////////////////////////////

export interface IGetSaveDigitalPayload {
    employee_name: string,
    office_address: string,
    designation: string,
    extenstion1: string,
    extenstion2: string,
    division: string,
    email: string,
    tele_no: string,
    mobile_no: string,
    fax_no: string,
    remarks: string,
    report_to: string,
    user_login: string,
    pers_area: string,
    person_no: string,
    location_id: string,
    ou1_text: string,
    ou2_text: string,
    v_card_path: string,
    v_card_type_id: string,
    company_id: string
}
export interface IGetSaveDigitalData extends SideEffectSchema {
    status_code: string,
    message: string,
    success: string
}

export const getSaveDigitalAction = (
    get_save_digital: IGetSaveDigitalPayload
) => {
    return {
        type: Actions.GET_SAVE_DIGITAL_DATA,
        payload: { get_save_digital }
    };
};
export const getSaveDigitalLoadingAction = () =>
    loadingAction(Actions.GET_SAVE_DIGITAL_LOADING);

export const getSaveDigitalErrorAction = (error: string) =>
    errorAction(Actions.GET_SAVE_DIGITAL_ERROR, error);

export const getSaveDigitalSuccessAction = (data: any) => {
    const payload: IGetSaveDigitalData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_SAVE_DIGITAL_SUCCESS,
        payload: payload
    };
};
/////////////////////////////////////////////////////////////////////////////////////////
export interface IGetSavePysicalPayload {
    employee_name: string,
    office_address: string,
    designation: string,
    extenstion1: string,
    extenstion2: string,
    division: string,
    email: string,
    tele_no: string,
    mobile_no: string,
    fax_no: string,
    no_of_card: string,
    remarks: string,
    report_to: string,
    user_login: string,
    pers_area: string,
    person_no: string,
    location_id: string,
    ou1_text: string,
    ou2_text: string,
    company_id: string,
    delivery_location_name: string
}
export interface IGetSavePysicalData extends SideEffectSchema {
    status_code: string,
    message: string,
    success: string
}

export const getSavePysicalAction = (
    get_save_pysical: IGetSavePysicalPayload
) => {
    return {
        type: Actions.GET_SAVE_PYSICAL_DATA,
        payload: { get_save_pysical }
    };
};
export const getSavePysicalLoadingAction = () =>
    loadingAction(Actions.GET_SAVE_PYSICAL_LOADING);

export const getSavePysicalErrorAction = (error: string) =>
    errorAction(Actions.GET_SAVE_PYSICAL_ERROR, error);

export const getSavePysicalSuccessAction = (data: any) => {
    const payload: IGetSavePysicalData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_SAVE_PYSICAL_SUCCESS,
        payload: payload
    };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetCardQuantityPayload {
    personArea: string
}
export interface IGetCardQuantityData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        CQuantityID: number,
        CQuantity: string
    }[]
}

export const getCardQuantityAction = (
    get_card_quantity: IGetCardQuantityPayload
) => {
    return {
        type: Actions.GET_CARD_QUANTITY_DATA,
        payload: { get_card_quantity }
    };
};
export const getCardQuantityLoadingAction = () =>
    loadingAction(Actions.GET_CARD_QUANTITY_LOADING);

export const getCardQuantityErrorAction = (error: string) =>
    errorAction(Actions.GET_CARD_QUANTITY_ERROR, error);

export const getCardQuantitySuccessAction = (data: any) => {
    const payload: IGetCardQuantityData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_CARD_QUANTITY_SUCCESS,
        payload: payload
    };
};
//////////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetSystemShutdownPayload {
    personArea: string
}
export interface IGetSystemShutdownData extends SideEffectSchema {
    status_code: string,
    message: string,
    ShutdownDate: string
}

export const getSystemShutdownAction = (
    get_system_shutdown: IGetSystemShutdownPayload
) => {
    return {
        type: Actions.GET_SYSTEM_SHUTDOWN_DATA,
        payload: { get_system_shutdown }
    };
};
export const getSystemShutdownLoadingAction = () =>
    loadingAction(Actions.GET_SYSTEM_SHUTDOWN_LOADING);

export const getSystemShutdownErrorAction = (error: string) =>
    errorAction(Actions.GET_SYSTEM_SHUTDOWN_ERROR, error);

export const getSystemShutdownSuccessAction = (data: any) => {
    const payload: IGetSystemShutdownData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_SYSTEM_SHUTDOWN_SUCCESS,
        payload: payload
    };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetDigitalVcPayload {
    start_index: string,
    page_size: string,
    person_area: string,
    emp_id: string
}

export interface IGetDigitalVcDataItem {
    RowNumber: string,
    ID: number,
    EmployeeName: string,
    PersonNo: string,
    Designation: string,
    Status: string,
    RequestDate: string,
    VCardPath: string,
    OfficeAddress: string,
    Division: string,
    MobileNo: string,
    OU1_TEXT: string,
    OU2_TEXT: string,
    TeleNo: string,
    ReportTo: string
}
export interface IGetDigitalVcData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: [
        [
            { RowCount: number }
        ],
        IGetDigitalVcDataItem[]
    ]
}

export const getDigitalVcAction = (
    get_digital_vc: IGetDigitalVcPayload
) => {
    return {
        type: Actions.GET_DIGITAL_VC_DATA,
        payload: { get_digital_vc }
    };
};
export const getDigitalVcLoadingAction = () =>
    loadingAction(Actions.GET_DIGITAL_VC_LOADING);

export const getDigitalVcErrorAction = (error: string) =>
    errorAction(Actions.GET_DIGITAL_VC_ERROR, error);

export const getDigitalVcSuccessAction = (data: any) => {
    const payload: IGetDigitalVcData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_DIGITAL_VC_SUCCESS,
        payload: payload
    };
};


export const getDigitalVcClearAction = () => {
    return {
        type: Actions.GET_DIGITAL_VC_CLEAR,
        payload: { isSuccess: false }
    };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetPhysicalVcPayload {
    start_index: string,
    page_size: string,
    person_area: string,
    emp_id: string,
}

export interface IGetPhysicalVcDataitem {
    Designation: string;
    EmployeeName: string;
    ID: string;
    PersonNo: string;
    ReportTo: string;
    RequestDate: string;
    RowNumber: string;
    Status: string;
    OfficeAddress: string;
    OU1_Text: string;
    OU2_Text: string;
    Division: string;
    Teleno: string;
    MobileNo: string;
    Extension1: string;
}

export interface IGetPhysicalVcData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: [
        [
            { RowCount: number }
        ],
        IGetPhysicalVcDataitem[]
    ]
}

export const getPhysicalVcAction = (
    get_physical_vc: IGetPhysicalVcPayload
) => {
    return {
        type: Actions.GET_PHYSICAL_VC_DATA,
        payload: { get_physical_vc }
    };
};
export const getPhysicalVcLoadingAction = () =>
    loadingAction(Actions.GET_PHYSICAL_VC_LOADING);

export const getPhysicalVcErrorAction = (error: string) =>
    errorAction(Actions.GET_PHYSICAL_VC_ERROR, error);

export const getPhysicalVcSuccessAction = (data: any) => {
    const payload: IGetPhysicalVcData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_PHYSICAL_VC_SUCCESS,
        payload: payload
    };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetQRStringPayload {
    firstName: string,
    middleName: string,
    lastName: string,
    organization: string,
    workPhone: string,
    workEmail: string,
    workUrl: string,
    cellPhone: string,
    role: string,
    workAddress: {
        label: string,
        street: string
    }
}

export interface IGetQRStringData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: string
}

export const getQRStringAction = (
    qr_string: IGetQRStringPayload
) => {
    return {
        type: Actions.GET_QR_STRING_DATA,
        payload: { qr_string }
    };
};
export const getQRStringLoadingAction = () =>
    loadingAction(Actions.GET_QR_STRING_LOADING);

export const getQRStringErrorAction = (error: string) =>
    errorAction(Actions.GET_QR_STRING_ERROR, error);

export const getQRStringSuccessAction = (data: any) => {
    const payload: IGetQRStringData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_QR_STRING_SUCCESS,
        payload: payload
    };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////
// export interface IUpdatePhysicalVCpayload {
//     ID: string
// }

export interface IUpdatePhysicalVCData extends SideEffectSchema {
    status_code: string,
    message: string
}

export const updatePhysicalVCAction = (
    id: string,
    remark: string,
    ModifiedBy: string
) => {
    return {
        type: Actions.UPDATE_PHYSICAL_VC_DATA,
        payload: { id, remark, ModifiedBy }
    };
};

export const updatePhysicalVCLoadingAction = () =>
    loadingAction(Actions.UPDATE_PHYSICAL_VC_LOADING);

export const updatePhysicalVCErrorAction = (error: string) =>
    errorAction(Actions.UPDATE_PHYSICAL_VC_ERROR, error);

export const updatePhysicalVCSuccessAction = (data: any) => {
    const payload: IUpdatePhysicalVCData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.UPDATE_PHYSICAL_VC_SUCCESS,
        payload: payload
    };
};