export const Actions = {
    TEAM_MEMBER_SELECT: "TEAM_MEMBER_SELECT",
    DEVICE_DIMESIONS: "DEVICE_DIMESIONS",
    SET_DOCUMENT_VIEW: "SET_DOCUMENT_VIEW",
    SET_DOCUMENT_EXPAND: "SET_DOCUMENT_EXPAND",
    VIEW_MTM_WISH: "VIEW_MTM_WISH",
    SET_PATH: "SET_PATH",
    VC_TYPE: "VC_TYPE",
    IS_VC_MODIFY: "IS_VC_MODIFY"
};

export const setTeamMemberSelect = (value: {
    emp_name: string;
    emp_id: string;
    user_id: string;
    username: string;
    internal_role: string;
    level: string;
}) => {
    return {
        type: Actions.TEAM_MEMBER_SELECT,
        payload: value,
    };
};

export const setDimensionByDevice = (value: {
    deviceWidth: number;
    deviceHeight: number
}) => {
    return {
        type: Actions.DEVICE_DIMESIONS,
        payload: value,
    };
};

export const setDocumentView = (value: boolean) => {
    return {
        type: Actions.SET_DOCUMENT_VIEW,
        payload: value,
    };
};

export const setDocumentExpand = (value: boolean) => {
    return {
        type: Actions.SET_DOCUMENT_EXPAND,
        payload: value,
    };
};

export const viewMTMWish = (value: boolean) => {
    return {
        type: Actions.VIEW_MTM_WISH,
        payload: value
    };
};

export const setPath = (value: string) => {
    return {
        type: Actions.SET_PATH,
        payload: value
    };
};

export const setvcType = (value: string) => {
    return {
        type: Actions.VC_TYPE,
        payload: value
    };
};

export const setIsVcModifyAction = (value: boolean) => {
    return {
        type: Actions.IS_VC_MODIFY,
        payload: value
    };
};