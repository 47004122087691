import { put, call, take, delay, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/UserDataActions";
import { httpPost } from "./../../service";
import { API_BASE_URL, USER_INFO_API_CONFIG } from "../../config";

function* getUserManagerData(payload: { empId: string; access_token: string }) {
    try {
        yield put(actionCreators.getUserManagerLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + USER_INFO_API_CONFIG.GET_USER_MANAGER_INFO,
            { empId: payload.empId },
            config
        );
        yield put(actionCreators.getUserManagerSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getUserManagerErrorAction(error.response.data.error)
        );
    }
}

function* getUserData(payload: { empId: string; access_token: string }) {
    try {
        yield put(actionCreators.getUserLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + USER_INFO_API_CONFIG.GET_USER_INFO,
            { empId: payload.empId },
            config
        );
        yield put(actionCreators.getUserSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getUserErrorAction(error.response.data.error));
    }
}
function* getTeamMemberData(action: { type: string; payload: { emp_id: string; access_token: string; isManager: boolean } }) {
    // function* getTeamMemberData(payload: { emp_id: string; access_token: string }) {
    try {
        yield put(actionCreators.getTeamMembersLoadingAction(action.payload.isManager));
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + USER_INFO_API_CONFIG.GET_TEAM_MEMBERS,
            { emp_id: action.payload.emp_id },
            config
        );
        yield put(actionCreators.getTeamMembersSuccessAction(data, action.payload.isManager));
    } catch (error: any) {
        yield put(
            actionCreators.getTeamMembersErrorAction(error.response.data.error, action.payload.isManager)
        );
    }
}

export function* watchGetUserManagerData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_USER_WITH_MANAGER_DATA
        );
        yield call(getUserManagerData, payload);
    }
}

export function* watchGetUserData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_USER_DATA);
        yield call(getUserData, payload);
    }
}

export function* watchGetTeamMemberData() {
    // while (true) {
    //   const { payload } = yield take(actionCreators.Actions.GET_TEAM_MEMBERS);
    //   yield call(getTeamMemberData, payload);
    // }
    yield takeLatest(actionCreators.Actions.GET_TEAM_MEMBERS, getTeamMemberData);

}
