import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_MOMENT_FEED_DATA: "GET_MOMENT_FEED_DATA",
    GET_MOMENT_FEED_LOADING: "GET_MOMENT_FEED_LOADING",
    GET_MOMENT_FEED_ERROR: "GET_MOMENT_FEED_ERROR",
    GET_MOMENT_FEED_SUCCESS: "GET_MOMENT_FEED_SUCCESS",

    UPDATE_USER_REACTION_DATA: "UPDATE_USER_REACTION_DATA",
    UPDATE_USER_REACTION_LOADING: "UPDATE_USER_REACTION_LOADING",
    UPDATE_USER_REACTION_ERROR: "UPDATE_USER_REACTION_ERROR",
    UPDATE_USER_REACTION_SUCCESS: "UPDATE_USER_REACTION_SUCCESS",
    UPDATE_USER_REACTION_DATA_UPDATE: "UPDATE_USER_REACTION_DATA_UPDATE",

    GET_EMPLOYEE_REACTION_DATA: "GET_EMPLOYEE_REACTION_DATA",
    GET_EMPLOYEE_REACTION_LOADING: "GET_EMPLOYEE_REACTION_LOADING",
    GET_EMPLOYEE_REACTION_ERROR: "GET_EMPLOYEE_REACTION_ERROR",
    GET_EMPLOYEE_REACTION_SUCCESS: "GET_EMPLOYEE_REACTION_SUCCESS",

    GET_MOOMENT_MATTER_DATA: "GET_MOOMENT_MATTER_DATA",
    GET_MOOMENT_MATTER_LOADING: "GET_MOOMENT_MATTER_LOADING",
    GET_MOOMENT_MATTER_ERROR: "GET_MOOMENT_MATTER_ERROR",
    GET_MOOMENT_MATTER_SUCCESS: "GET_MOOMENT_MATTER_SUCCESS",

    GET_SEND_WISH_NOTIFICATION_DATA: "GET_SEND_WISH_NOTIFICATION_DATA",
    GET_SEND_WISH_NOTIFICATION_LOADING: "GET_SEND_WISH_NOTIFICATION_LOADING",
    GET_SEND_WISH_NOTIFICATION_ERROR: "GET_SEND_WISH_NOTIFICATION_ERROR",
    GET_SEND_WISH_NOTIFICATION_SUCCESS: "GET_SEND_WISH_NOTIFICATION_SUCCESS",

    GET_MY_MILESTONES_DATA: "GET_MY_MILESTONES_DATA",
    GET_MY_MILESTONES_LOADING: "GET_MY_MILESTONES_LOADING",
    GET_MY_MILESTONES_ERROR: "GET_MY_MILESTONES_ERROR",
    GET_MY_MILESTONES_SUCCESS: "GET_MY_MILESTONES_SUCCESS",

    GET_UPCOMING_MILESTONES_DATA: "GET_UPCOMING_MILESTONES_DATA",
    GET_UPCOMING_MILESTONES_LOADING: "GET_UPCOMING_MILESTONES_LOADING",
    GET_UPCOMING_MILESTONES_ERROR: "GET_UPCOMING_MILESTONES_ERROR",
    GET_UPCOMING_MILESTONES_SUCCESS: "GET_UPCOMING_MILESTONES_SUCCESS",

};



export interface IGetMomentFeedPayload {
    emp_id: string;
    filter: string;
    page_number: number;
}

export interface IGetMomentFeedItemData {
    is_event_owner: boolean;
    moments_id: string;
    notification_type: string;
    greeting_title: string;
    greeting_message: string;
    greeting_for: string;
    moment_date: string;
    employee_response: string;
    feedback_data: {
        employee_id: string;
        employee_name: string;
        employee_response: string;
    }[];
    image_url: string;
    employee_name: string;
    profile_image: string;
    years_completed: number;
}

export interface IGetMomentFeedData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        total_page: number,
        page_number: number,
        total_count: number,
        moment_feed_details_json_list: IGetMomentFeedItemData[];
    }
}

export const getMomentFeedDataAction = (access_token: string, payload: IGetMomentFeedPayload) => {
    return {
        type: Actions.GET_MOMENT_FEED_DATA,
        payload: { access_token, payload }
    };
};
export const getMomentFeedLoadingAction = () =>
    loadingAction(Actions.GET_MOMENT_FEED_LOADING);

export const getMomentFeedErrorAction = (error: string) =>
    errorAction(Actions.GET_MOMENT_FEED_ERROR, error);

export const getMomentFeedSuccessAction = (data: any) => {
    const payload: IGetMomentFeedData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_MOMENT_FEED_SUCCESS,
        payload: payload
    };
};

/////////////////////////////////////////////////////////////////////////////////////////////

export interface IUpdateUserReactionPayload {
    moments_id: string;
    employee_name: string;
    employee_response: string;
    employee_id: string;
}
export interface IUpdateUserReactionData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
    }[];
}

export const updateUserReactionAction = (access_token: string, payload: IUpdateUserReactionPayload) => {
    return {
        type: Actions.UPDATE_USER_REACTION_DATA,
        payload: { access_token, payload }
    };
};
export const updateUserReactionLoadingAction = () =>
    loadingAction(Actions.UPDATE_USER_REACTION_LOADING);

export const updateUserReactionErrorAction = (error: string) =>
    errorAction(Actions.UPDATE_USER_REACTION_ERROR, error);

export const updateUserReactionSuccessAction = (data: any) => {
    const payload: IUpdateUserReactionData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.UPDATE_USER_REACTION_SUCCESS,
        payload: payload
    };
};

export const updateUserReactionDataUpdateAction = (payload: any) => {
    return {
        type: Actions.UPDATE_USER_REACTION_SUCCESS,
        payload: payload
    };
};

///////////////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetEmployeeReactionPayload {
    moments_id: string;
    page_number: number;
    employee_response: string
}
export interface IGetEmployeeReactionData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        total_count: number,
        data: {
            employee_name: string,
            employee_response: string
        }[];
        total_page: number,
        feedback_count: {
            like: number,
            applaud: number,
            love: number
        };
    };
}

export const getEmployeeReactionAction = (access_token: string, payload: IGetEmployeeReactionPayload) => {
    return {
        type: Actions.GET_EMPLOYEE_REACTION_DATA,
        payload: { access_token, payload }
    };
};
export const getEmployeeReactionLoadingAction = () =>
    loadingAction(Actions.GET_EMPLOYEE_REACTION_LOADING);

export const getEmployeeReactionErrorAction = (error: string) =>
    errorAction(Actions.GET_EMPLOYEE_REACTION_ERROR, error);

export const getEmployeeReactionSuccessAction = (data: any) => {
    const payload: IGetEmployeeReactionData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_EMPLOYEE_REACTION_SUCCESS,
        payload: payload
    };
};

////////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetMoomentMatterPayload {
    emp_id: string;
}
export interface IGetMoomentMatterData extends SideEffectSchema {
    status_code: string,
    message: string,
    data: {
        is_event: boolean,
        is_popup_notification: boolean,
        notification_type: {
            notification_type: string;
            title: string;
            message: string;
            image_link: string;
        }[];
        employee_first_name: string;
        number_of_years: number;
    }
}

export const getMoomentMatterAction = (access_token: string, emp_id: string) => {
    return {
        type: Actions.GET_MOOMENT_MATTER_DATA,
        payload: { access_token, emp_id }
    };
};
export const getMoomentMatterLoadingAction = () =>
    loadingAction(Actions.GET_MOOMENT_MATTER_LOADING);

export const getMoomentMatterErrorAction = (error: string) =>
    errorAction(Actions.GET_MOOMENT_MATTER_ERROR, error);

export const getMoomentMatterSuccessAction = (data: any) => {
    const payload: IGetMoomentMatterData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_MOOMENT_MATTER_SUCCESS,
        payload: payload
    };
};

///////////////////////////////////////////////////////////////////////////////////////////////////////



export interface IGetSendWishNotificationPayload {

}
export interface IGetSendWishNotificationData extends SideEffectSchema {

    status_code: string;
    message: string;
    data: {}

}
export const getSendWishNotificationAction = (access_token: string, payload: IGetSendWishNotificationPayload) => {
    return {
        type: Actions.GET_SEND_WISH_NOTIFICATION_DATA,
    };
};
export const getSendWishNotificationLoadingAction = () =>
    loadingAction(Actions.GET_SEND_WISH_NOTIFICATION_LOADING);

export const getSendWishNotificationErrorAction = (error: string) =>
    errorAction(Actions.GET_SEND_WISH_NOTIFICATION_ERROR, error);

export const getSendWishNotificationSuccessAction = (data: any) => {
    const payload: IGetSendWishNotificationData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_SEND_WISH_NOTIFICATION_SUCCESS,
        payload: payload
    };
};

///////////////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetMyMilestonesPayload {
    emp_id: string;
}
export interface IGetMyMilestonesData extends SideEffectSchema {

    status_code: string,
    message: string,
    data: {
        joining_year: number;
        experience: string;
        current_year: string;
    };

}
export const getMyMilestonesAction = (access_token: string, emp_id: string) => {
    return {
        type: Actions.GET_MY_MILESTONES_DATA,
        payload: { access_token, emp_id }
    };
};
export const getMyMilestonesLoadingAction = () =>
    loadingAction(Actions.GET_MY_MILESTONES_LOADING);

export const getMyMilestonesErrorAction = (error: string) =>
    errorAction(Actions.GET_MY_MILESTONES_ERROR, error);

export const getMyMilestonesSuccessAction = (data: any) => {
    const payload: IGetMyMilestonesData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_MY_MILESTONES_SUCCESS,
        payload: payload
    };
};

///////////////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetUpcomingMilestonesPayload {
    emp_id: string;
}
export interface IGetUpcomingMilestonesData extends SideEffectSchema {

    status_code: string,
    message: string,
    data: {
        joining_year: number;
        upcoming_long_service: number;
    };

}

export const getUpcomingMilestonesAction = (access_token: string, emp_id: string) => {
    return {
        type: Actions.GET_UPCOMING_MILESTONES_DATA,
        payload: { access_token, emp_id }
    };
};
export const getUpcomingMilestonesLoadingAction = () =>
    loadingAction(Actions.GET_UPCOMING_MILESTONES_LOADING);

export const getUpcomingMilestonesErrorAction = (error: string) =>
    errorAction(Actions.GET_UPCOMING_MILESTONES_ERROR, error);

export const getUpcomingMilestonesSuccessAction = (data: any) => {
    const payload: IGetUpcomingMilestonesData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_UPCOMING_MILESTONES_SUCCESS,
        payload: payload,
    };
};

///////////////////////////////////////////////////////////////////////////////////////////////////////