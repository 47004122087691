import { put, call, take, delay, takeLatest } from "redux-saga/effects";
import axios from "axios";
import * as actionCreators from "../actions/DocumentAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, DOCUMENT_API_CONFIG } from "../../config";
import { ErrorToast, SuccessToast } from "../../components/ToastifyComponent";
import { Constant, downloadFileByS3BucketLink, downloadFileHtmlToBonafidePdf, downloadFileHtmlToPdf, EmpTrushDocument } from "../../constant";
import moment from "moment";

function* getCurrentMasterData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.ICurrentMasterDataPayLoad;
}) {
    try {
        yield put(actionCreators.getCurrentMasterDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_CURRENT_MASTER_LIST}`,
            payload.payload,
            config
        );

        yield put(actionCreators.getCurrentMasterDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getCurrentMasterDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetCurrentMasterData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_CURRENT_MASTER_DATA
        );
        yield call(getCurrentMasterData, payload);
    }
}
//------------------------------------------------------------------------------------------------

function* getCurrentPayslipData(action: { type: string; payload: { access_token: string; emp_Code: string; payload: actionCreators.ICurrentPayslipWorksheetPayLaod; } }) {
    try {
        yield put(actionCreators.getCurrentPayslipWorksheetDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${action.payload.emp_Code}${DOCUMENT_API_CONFIG.GET_CURRENT_PAYSLIP_WORKSHEET_LIST}`,
            action.payload.payload,
            config
        );
        yield put(actionCreators.getCurrentPayslipWorksheetDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getCurrentPayslipWorksheetDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetCurrentPayslipData() {
    // while (true) {
    //   const { payload } = yield take(
    //     actionCreators.Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA
    //   );
    //   yield call(getCurrentPayslipData, payload);
    // }
    yield takeLatest(actionCreators.Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA, getCurrentPayslipData);
}

//---------------------------------------------------------------------------------------------

function* getFormSixteenData(action: {
    type: string; payload: {
        access_token: string;
        emp_Code: string;
        payload: actionCreators.IFormSixteenDataPayLoad;
    }
}) {
    try {
        yield put(actionCreators.getFormSixteenDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${action.payload.emp_Code}${DOCUMENT_API_CONFIG.GET_FORM_SIXTEEN}`,
            action.payload.payload,
            config
        );
        yield put(actionCreators.getFormSixteenDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getFormSixteenDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetFormSixteenData() {
    // while (true) {
    //   const { payload } = yield take(
    //     actionCreators.Actions.GET_FORM16_DATA
    //   );
    //   yield call(getFormSixteenData, payload);
    // }
    yield takeLatest(actionCreators.Actions.GET_FORM16_DATA, getFormSixteenData);
}
//-------------------------------------------------------------------------------------------

function* getCurrentDownloadDocumentData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.ICurrentDownloadDocumentDataPayLoad;
}) {
    try {
        yield put(actionCreators.getCurrentDownloadDocumentDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_D0WNLOAD_DOCUMENT_LIST}`,
            payload.payload,
            config
        );
        if (data.status_code === "200") {
            downloadFileByS3BucketLink(data.data.url);
        } else {
            ErrorToast(data.message);
        }
        yield put(actionCreators.getCurrentDownloadDocumentDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getCurrentDownloadDocumentDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetCurrentDownloadDocumentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_CURRENT_DOWNLOAD_DATA
        );
        yield call(getCurrentDownloadDocumentData, payload);
    }
}

//---------------------------------------------------------------------------------------------

function* getPreviousMasterData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IPreviousMasterDataPayLoad;
}) {
    try {
        yield put(actionCreators.getPreviousMasterDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_PREVIOUS_MASTER_LIST}`,
            payload.payload,
            config
        );
        yield put(actionCreators.getPreviousMasterDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getPreviousMasterDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetPreviousMasterDataData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_PREVIOUS_MASTER_DATA
        );
        yield call(getPreviousMasterData, payload);
    }
}
//--------------------------------------------------------------------------------------------

function* getPreviousPayslipData(action: {
    type: string; payload: {
        access_token: string;
        emp_Code: string;
        payload: actionCreators.IPreviousPayslipDataPayLoad;
    }
}) {
    try {
        yield put(actionCreators.getPreviousPayslipDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${action.payload.emp_Code}${DOCUMENT_API_CONFIG.GET_PREVIOUS_PAYSLIP_WORKSHEET_LIST}`,
            action.payload.payload,
            config
        );
        yield put(actionCreators.getPreviousPayslipDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getPreviousPayslipDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetPreviousPayslipData() {
    // while (true) {
    //   const { payload } = yield take(
    //     actionCreators.Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA
    //   );
    //   yield call(getPreviousPayslipData, payload);
    // }
    yield takeLatest(actionCreators.Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA, getPreviousPayslipData);
}

//-----------------------------------------------------------------------------------------------



function* getOldDocumentData(action: {
    type: string; payload: {
        access_token: string;
        emp_Code: string;
        payload: actionCreators.IOldDocumentDataPayLoad;
    }
}) {
    try {
        yield put(actionCreators.getOldDocumentDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${action.payload.emp_Code}${DOCUMENT_API_CONFIG.GET_ALL_OLD_DOC_LIST}`,
            action.payload.payload,
            config
        );
        // let modifiedData = { ...data, data: { form16_list: data.data } }
        yield put(actionCreators.getOldDocumentDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getOldDocumentDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetOldDocumentData() {
    // while (true) {
    //   const { payload } = yield take(
    //     actionCreators.Actions.GET_ALL_OLD_DOC_DATA
    //   );
    //   yield call(getOldDocumentData, payload);
    // }
    yield takeLatest(actionCreators.Actions.GET_ALL_OLD_DOC_DATA, getOldDocumentData);
}
//------------------------------------------------------------------------------------------------


function* getPreviousDownloadDocumentData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IPreviousDownloadDocumentDataPayLoad;
}) {
    try {
        yield put(actionCreators.getPreviousDownloadDocumentDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_PREVIOUS_DOWNLOAD_LIST}`,
            payload.payload,
            config
        );
        if (data.status_code === "200") {
            downloadFileByS3BucketLink(data.data.url);
        } else {
            ErrorToast(data.message);
        }
        yield put(actionCreators.getPreviousDownloadDocumentDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getPreviousDownloadDocumentDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetPreviousDownloadDocumentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_PREVIOUS_DOWNLOAD_DATA
        );
        yield call(getPreviousDownloadDocumentData, payload);
    }
}

//-----------------------------------------------------------------------------------------------------------

function* getEmployeeLatestData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IEmployeeLatestDataPayLoad;
}) {
    try {
        yield put(actionCreators.getEmployeeLatestDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_EMPLOYEE_LATEST_LIST}`,
            payload.payload,
            config
        );
        yield put(actionCreators.getEmployeeLatestDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getEmployeeLatestDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetEmployeeLatestData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_EMPLOYEE_LATEST_DATA
        );
        yield call(getEmployeeLatestData, payload);
    }
}
//-----------------------------------------------------------------------------------------------------------

function* getPassbookDetailData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IPassbookDetailDataPayLoad;
}) {
    try {
        yield put(actionCreators.getPassbookDetailDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_PASSBOOK_DETAILS_LIST}`,
            payload.payload,
            config
        );
        if (data.status_code === "200") {
            const fileName = payload.payload.emp_id + "_" + "PF_Passbook";
            downloadFileHtmlToPdf(fileName, `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_PASSBOOK_DETAILS_LIST}`, payload.payload.emp_id, payload.access_token);
            SuccessToast(Constant.DOWNLOAD_STATEMENT_FILE_SUCCESS);
        } else {
            ErrorToast(Constant.DOWNLOAD_ERROR_MESSAGE);
        }
        yield put(actionCreators.getPassbookDetailDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getPassbookDetailDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetPassbookDetailData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_PASSBOOK_DETAILS_DATA
        );
        yield call(getPassbookDetailData, payload);
    }
}

//---------------------------------------------------------------------------------------------------------

function* getDownloadTrustFundData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IDownloadTrustFundDataPayLoad;
}) {
    try {
        yield put(actionCreators.getDownloadTrustFundDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_TRUSTFUND_LIST}`,
            payload.payload,
            config
        );
        if (data.status_code === "200") {
            const fileName = payload.payload.emp_id + "_" + (payload.payload.annual_type === EmpTrushDocument["PFS"] ? "Provident_Fund" : payload.payload.annual_type === EmpTrushDocument["PS"] ? "Pension" : "Super_Annuation") + "_" + payload.payload.fin_year;
            downloadFileHtmlToPdf(fileName, `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_TRUSTFUND_LIST}`, payload.payload.emp_id, payload.access_token, payload.payload.fin_year, payload.payload.annual_type);
            SuccessToast(Constant.DOWNLOAD_STATEMENT_FILE_SUCCESS);
        } else {
            ErrorToast(Constant.DOWNLOAD_ERROR_MESSAGE);
        }
        yield put(actionCreators.getDownloadTrustFundDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getDownloadTrustFundDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetDownloadTrustFundData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_TRUSTFUND_DATA
        );
        yield call(getDownloadTrustFundData, payload);
    }
}

//-----------------------------------------------------------------------------------------------------------------


function* getEmployeeApplicableData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IEmployeeApplicableDataPayLoad;
}) {
    try {
        yield put(actionCreators.getEmployeeApplicableDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_EMPLOYEE_APPLICABLE_LIST}`,
            payload.payload,
            config
        );
        yield put(actionCreators.getEmployeeApplicableDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getEmployeeApplicableDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetEmployeeApplicableData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_EMPLOYEE_APPLICABLE_DATA
        );
        yield call(getEmployeeApplicableData, payload);
    }
}

//-----------------------------------------------------------------------------------------------------------------


function* getEmployeeAdditionalData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IEmployeeAdditionalDataPayLoad;
}) {
    try {
        yield put(actionCreators.getEmployeeAdditionalDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_EMPLOYEE_ADDITIONAL_LIST}`,
            payload.payload,
            config
        );
        yield put(actionCreators.getEmployeeAdditionalDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getEmployeeAdditionalDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetEmployeeAdditionalData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_EMPLOYEE_ADDITIONAL_DATA
        );
        yield call(getEmployeeAdditionalData, payload);
    }
}

//-------------------------------------------------------------------------------------------

function* getAdditionalDownloadDocumentData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.ICurrentDownloadDocumentDataPayLoad;
}) {
    try {
        yield put(actionCreators.getAdditionalDownloadDocumentDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_DOWNLOAD_ADDITIONAL}`,
            payload.payload,
            config
        );
        if (data.status_code === "200") {
            downloadFileByS3BucketLink(data.data.url);
        } else {
            ErrorToast(data.message);
        }
        yield put(actionCreators.getAdditionalDownloadDocumentDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getAdditionalDownloadDocumentDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetAdditionalDownloadDocumentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ADDITIONAL_DOWNLOAD_DATA
        );
        yield call(getAdditionalDownloadDocumentData, payload);
    }
}

//---------------------------------------------------------------------------------------------------------

function* getGenerateBonafideData(payload: {
    access_token: string;
    emp_Code: string;
    payload: actionCreators.IGenerateBonafideDataPayLoad;
}) {
    try {
        yield put(actionCreators.getGenerateBonafideDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_GENERATE_BONAFIDE_API}`,
            payload.payload,
            config
        );
        if (data.status_code === "200") {

            const fileName = "Bonafide_" + moment().format("DDMMYYYYHHmmss");
            downloadFileHtmlToBonafidePdf(fileName, `${API_BASE_URL}/${payload.emp_Code}${DOCUMENT_API_CONFIG.GET_GENERATE_BONAFIDE_API}`, payload.payload.emp_id, payload.payload.joining_date.toString(), payload.payload.current_address.toString(), payload.payload.permanent_address.toString(), payload.payload.concern_authorities, payload.payload.reason, payload.access_token);
            SuccessToast(data.message);
        } else {
            ErrorToast(Constant.DOWNLOAD_ERROR_MESSAGE);
        }
        yield put(actionCreators.getGenerateBonafideDataSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getGenerateBonafideDataErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetGenerateBonafideData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_GENERATE_BONAFIDE_DATA
        );
        yield call(getGenerateBonafideData, payload);
    }
}