import {
    Actions,
    IGetChannelData,
} from "../actions/ChannelAction";
import { combineReducers } from "redux";

const getChannelReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_CHANNEL_LOADING:
        case Actions.GET_CHANNEL_ERROR:
        case Actions.GET_CHANNEL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};


export default getChannelReducer;