import { Actions, IDose, IDosePut, IDosePost } from "../actions/VacinneAction";
import { combineReducers } from "redux";

export const getDose1Reducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_DOSE1_DATA_LOADING:
    case Actions.GET_DOSE1_DATA_ERROR:
    case Actions.GET_DOSE1_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const putDoseReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.UPDATE_DOSE_DATA_LOADING:
    case Actions.UPDATE_DOSE_DATA_ERROR:
    case Actions.UPDATE_DOSE_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    case Actions.POST_PUT_CLEAR_REDUCER_DATA:
        return {
            ...action.payload,
        };
    default:
        return state;
    }
};

export const postdoseReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.POST_DOSE_DATA_LOADING:
    case Actions.POST_DOSE_DATA_ERROR:
    case Actions.POST_DOSE_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    case Actions.POST_PUT_CLEAR_REDUCER_DATA:
        return {
            ...action.payload,
        };
    default:
        return state;
    }
};

// dose2
export const getDose2Reducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_DOSE2_DATA_LOADING:
    case Actions.GET_DOSE2_DATA_ERROR:
    case Actions.GET_DOSE2_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export interface IVaccineData {
  dose1: IDose;
  dose2: IDose;
  putdosedata: IDosePut;
  postdosedata: IDosePost;
}

const vaccineData = combineReducers({
    dose1: getDose1Reducer,
    dose2: getDose2Reducer,
    putdosedata: putDoseReducer,
    postdosedata: postdoseReducer,
});

export default vaccineData;
