import {
    Actions,
    IGetCompensationStructureInstruction,
    IGetCompensationStructure,
    IGetCompensationStructurePDF,

} from "../actions/PaySpaceAction";
import { combineReducers } from "redux";

export const getCompensationStructureInstructionReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_LOADING:
        case Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_ERROR:
        case Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getCompensationStructureReducer = (state = { standard: {}, current: {} }, action: any) => {
    switch (action.type) {
        case Actions.GET_COMPENSATION_STRUCTURE_LOADING:
        case Actions.GET_COMPENSATION_STRUCTURE_ERROR:
        case Actions.GET_COMPENSATION_STRUCTURE_SUCCESS:
            return (action.payload.isStandard ? {
                ...state,
                standard: action.payload
            } : {
                ...state,
                current: action.payload
            });
        default:
            return state;
    }
};

export const getCompensationStructurePDFReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_COMPENSATION_STRUCTURE_PDF_LOADING:
        case Actions.GET_COMPENSATION_STRUCTURE_PDF_ERROR:
        case Actions.GET_COMPENSATION_STRUCTURE_PDF_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

interface IGetCompensationStructureData {
    standard: IGetCompensationStructure;
    current: IGetCompensationStructure;
}

export interface IPaySpaceData {
    getCompensationStructureInstruction: IGetCompensationStructureInstruction;
    getCompensationStructure: IGetCompensationStructureData;
    getCompensationStructurePDF: IGetCompensationStructurePDF
}

const paySpaceData = combineReducers({
    getCompensationStructureInstruction: getCompensationStructureInstructionReducer,
    getCompensationStructure: getCompensationStructureReducer,
    getCompensationStructurePDF: getCompensationStructurePDFReducer
});

export default paySpaceData;