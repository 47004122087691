import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_IT_TIPS_DATA: "GET_IT_TIPS_DATA",
    GET_IT_TIPS_DATA_LOADING: "GET_IT_TIPS_DATA_LOADING",
    GET_IT_TIPS_DATA_ERROR: "GET_IT_TIPS_DATA_ERROR",
    GET_IT_TIPS_DATA_SUCCESS: "GET_IT_TIPS_DATA_SUCCESS",
};

export interface IIttipsData extends SideEffectSchema {
    tipsData: {
        id: number,
        title: string,
        description: string,
        image_url: string,
        action_button_label: string,
        action_url: string,
        is_active: boolean,
        heading: string,
        image_number: number
    }[]
}

export const getIttipsDataAction = () => {
    return {
        type: Actions.GET_IT_TIPS_DATA  
    };
};

export const getIttipsDataLoadingAction = () =>
    loadingAction(Actions.GET_IT_TIPS_DATA_LOADING);

export const getIttipsDataErrorAction = (error: string) =>
    errorAction(Actions.GET_IT_TIPS_DATA_ERROR, error);

export const getIttipsDataSuccessAction = (data: any) => {
    const payload = { tipsData:data, ...successSideEffectState };
    
    return {
        type: Actions.GET_IT_TIPS_DATA_SUCCESS,
        payload: payload
    };
};