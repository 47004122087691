import { put, call, take, delay, takeLatest, takeEvery } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/NotificationAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, NOTIFICATION_API_CONIG, ITSM_NOTIFICATION_CONFIG, API_SECRET_TOKEN } from "../../config";

function* getNotificationData(payload: {
    access_token: string;
    emp_comp: string;
    notificationData: actionCreators.IGetNotificationPayload
}) {
    try {
        yield put(actionCreators.getNotificationDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };

        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            NOTIFICATION_API_CONIG.GET_NOTIFICATION,

            payload.notificationData,
            config
        );
        yield put(actionCreators.getNotificationSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getNotificationDataErrorAction(error));
    }
}

function* postITSMNotificationData(payload: {
    access_token: string,
    payload: actionCreators.INotificationPayload
}) {
    try {
        yield put(actionCreators.postNotificationLoadingAction());
        const config = {
            headers: {
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            }
        };
        const { data } = yield httpPost(
            ITSM_NOTIFICATION_CONFIG.POST_ITSM_NOTIFICATION,
            payload.payload,
            config
        );
        yield put(actionCreators.postNotificationSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postNotificationErrorAction(error));
    }
}


export function* watchGetNotificationData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_NOTIFICATION_DATA);
        yield call(getNotificationData, payload);
    }
}

export function* watchPostNotificationData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.POST_NOTIFICATION_DATA
        );
        yield call(postITSMNotificationData, payload);
    }
}
