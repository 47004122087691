import React from "react";
import { Flex, Button, ChatIcon } from "@fluentui/react-northstar";
import ErrorComponent from "../ErrorComponent";
interface IProps {
    children: JSX.Element;
    permission: string;
    message: string
}
class CFMButtonPermission extends React.Component<IProps>{
    constructor(props: IProps) {
        super(props);
    }
    render(): React.ReactNode {
        if (this.props.permission === "write") {
            return this.props.children;
        } else {
            return <></>;
        }
    }
}


export default CFMButtonPermission;