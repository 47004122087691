import {
    Actions,
    IGetMomentFeedData,
    IUpdateUserReactionData,
    IGetEmployeeReactionData,
    IGetMoomentMatterData,
    IGetSendWishNotificationData,
    IGetMyMilestonesData,
    IGetUpcomingMilestonesData,
} from "../actions/MTMAction";
import { combineReducers } from "redux";



//////////////////////////////////////////////////////////////////////////////////////////
export const getMomentFeedReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_MOMENT_FEED_LOADING:
        case Actions.GET_MOMENT_FEED_ERROR:
        case Actions.GET_MOMENT_FEED_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};


export const updateUserReactionReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.UPDATE_USER_REACTION_LOADING:
        case Actions.UPDATE_USER_REACTION_ERROR:
        case Actions.UPDATE_USER_REACTION_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.UPDATE_USER_REACTION_DATA_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getEmployeeReactionReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_EMPLOYEE_REACTION_LOADING:
        case Actions.GET_EMPLOYEE_REACTION_ERROR:
        case Actions.GET_EMPLOYEE_REACTION_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getMoomentMatterReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_MOOMENT_MATTER_LOADING:
        case Actions.GET_MOOMENT_MATTER_ERROR:
        case Actions.GET_MOOMENT_MATTER_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getSendWishNotificationReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_SEND_WISH_NOTIFICATION_LOADING:
        case Actions.GET_SEND_WISH_NOTIFICATION_ERROR:
        case Actions.GET_SEND_WISH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export const getMyMilestonesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_MY_MILESTONES_LOADING:
        case Actions.GET_MY_MILESTONES_ERROR:
        case Actions.GET_MY_MILESTONES_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getUpcomingMilestonesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_UPCOMING_MILESTONES_LOADING:
        case Actions.GET_UPCOMING_MILESTONES_ERROR:
        case Actions.GET_UPCOMING_MILESTONES_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
///////////////////////////////////////////////////////////////////////////////////////////



export interface IMTMData {
    getMomentFeed: IGetMomentFeedData,
    updateUserReaction: IUpdateUserReactionData,
    getEmployeeReaction: IGetEmployeeReactionData,
    getMoomentMatter: IGetMoomentMatterData,
    getSendWishNotification: IGetSendWishNotificationData,
    getMyMilestones: IGetMyMilestonesData,
    getUpcomingMilestones: IGetUpcomingMilestonesData,

}

const mtmData = combineReducers({
    getMomentFeed: getMomentFeedReducer,
    updateUserReaction: updateUserReactionReducer,
    getEmployeeReaction: getEmployeeReactionReducer,
    getMoomentMatter: getMoomentMatterReducer,
    getSendWishNotification: getSendWishNotificationReducer,
    getMyMilestones: getMyMilestonesReducer,
    getUpcomingMilestones: getUpcomingMilestonesReducer,
});
export default mtmData;