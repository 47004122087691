import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema
} from ".";

export const Actions = {
    // --------------------Floor Data---------------
    GET_FLOOR_DATA: "GET_FLOOR_DATA",
    GET_FLOOR_LOADING: "GET_FLOOR_LOADING",
    GET_FLOOR_ERROR: "GET_FLOOR_ERROR",
    GET_FLOOR_SUCCESS: "GET_FLOOR_SUCCESS",

    // -------------------Location Data ------------
    GET_LOCATION_DATA: "GET_LOCATION_DATA",
    GET_LOCATION_LOADING: "GET_LOCATION_LOADING",
    GET_LOCATION_ERROR: "GET_LOCATION_ERROR",
    GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",

    // ---------------------Site -------------------
    GET_SITE_DATA: "GET_SITE_DATA",
    GET_SITE_LOADING: "GET_SITE_LOADING",
    GET_SITE_ERROR: "GET_SITE_ERROR",
    GET_SITE_SUCCESS: "GET_SITE_SUCCESS",

    // --------------------Category One ------------
    GET_IMAC_CATEGORY_ONE_DATA: "GET_IMAC_CATEGORY_ONE_DATA",
    GET_IMAC_CATEGORY_ONE_LOADING: "GET_IMAC_CATEGORY_ONE_LOADING",
    GET_IMAC_CATEGORY_ONE_ERROR: "GET_IMAC_CATEGORY_ONE_ERROR",
    GET_IMAC_CATEGORY_ONE_SUCCESS: "GET_IMAC_CATEGORY_ONE_SUCCESS",

    // --------------------Category Two ------------
    GET_IMAC_CATEGORY_TWO_DATA: "GET_IMAC_CATEGORY_TWO_DATA",
    GET_IMAC_CATEGORY_TWO_LOADING: "GET_IMAC_CATEGORY_TWO_LOADING",
    GET_IMAC_CATEGORY_TWO_ERROR: "GET_IMAC_CATEGORY_TWO_ERROR",
    GET_IMAC_CATEGORY_TWO_SUCCESS: "GET_IMAC_CATEGORY_TWO_SUCCESS",


    // --------------------Category Three ------------
    GET_IMAC_CATEGORY_THREE_DATA: "GET_IMAC_CATEGORY_THREE_DATA",
    GET_IMAC_CATEGORY_THREE_LOADING: "GET_IMAC_CATEGORY_THREE_LOADING",
    GET_IMAC_CATEGORY_THREE_ERROR: "GET_IMAC_CATEGORY_THREE_ERROR",
    GET_IMAC_CATEGORY_THREE_SUCCESS: "GET_IMAC_CATEGORY_THREE_SUCCESS",

    // -------------------- Asset Details ------------
    GET_ASSETS_DATA: "GET_ASSETS_DATA",
    GET_ASSETS_LOADING: "GET_ASSETS_LOADING",
    GET_ASSETS_ERROR: "GET_ASSETS_ERROR",
    GET_ASSETS_SUCCESS: "GET_ASSETS_SUCCESS",

    // --------------------Domain Id ------------
    POST_DOMAIN_ID_DATA: "POST_DOMAIN_ID_DATA",
    POST_DOMAIN_ID_LOADING: "POST_DOMAIN_ID_LOADING",
    POST_DOMAIN_ID_ERROR: "POST_DOMAIN_ID_ERROR",
    POST_DOMAIN_ID_SUCCESS: "POST_DOMAIN_ID_SUCCESS",

    // --------------------Domain Id Manager ------------
    POST_DOMAIN_ID_MANAGER_DATA: "POST_DOMAIN_ID_MANAGER_DATA",
    POST_DOMAIN_ID_MANAGER_LOADING: "POST_DOMAIN_ID_MANAGER_LOADING",
    POST_DOMAIN_ID_MANAGER_ERROR: "POST_DOMAIN_ID_MANAGER_ERROR",
    POST_DOMAIN_ID_MANAGER_SUCCESS: "POST_DOMAIN_ID_MANAGER_SUCCESS",


};

export interface IIMACCommon extends SideEffectSchema {
    statusCode: string,
    data: {
        id: number,
        name: string,
        is_active: boolean
    }[]
}

export interface IIMACCategoryTwo extends SideEffectSchema {
    statusCode: string,
    data: {
        id: number,
        name: string,
        category_one_id: number,
        is_active: boolean
    }[]
}

export interface IIMACCategoryThree extends SideEffectSchema {
    statusCode: string,
    data: {
        id: number,
        name: string,
        category_two_id: number,
        is_active: boolean
    }[]
}

export const getFloorDataAction = () => {
    return {
        type: Actions.GET_FLOOR_DATA
    };
};
export const getFloorLoadinngAction = () =>
    loadingAction(Actions.GET_FLOOR_LOADING);
export const getFloorErrorAction = (error: string) =>
    errorAction(Actions.GET_FLOOR_ERROR, error);
export const getFloorSuccessAction = (data: any) => {
    const payload: IIMACCommon = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_FLOOR_SUCCESS,
        payload: payload
    };
};

export const getLocationDataAction = () => {
    return {
        type: Actions.GET_LOCATION_DATA
    };
};
export const getLocationLoadinngAction = () =>
    loadingAction(Actions.GET_LOCATION_LOADING);
export const getLocationErrorAction = (error: string) =>
    errorAction(Actions.GET_LOCATION_ERROR, error);
export const getLocationSuccessAction = (data: any) => {
    const payload: IIMACCommon = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_LOCATION_SUCCESS,
        payload: payload
    };
};

export const getSiteDataAction = () => {
    return {
        type: Actions.GET_SITE_DATA
    };
};
export const getSiteLoadinngAction = () =>
    loadingAction(Actions.GET_SITE_LOADING);
export const getSiteErrorAction = (error: string) =>
    errorAction(Actions.GET_SITE_ERROR, error);
export const getSiteSuccessAction = (data: any) => {
    const payload: IIMACCommon = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_SITE_SUCCESS,
        payload: payload
    };
};

export const getIMACCategoryOneDataAction = () => {
    return {
        type: Actions.GET_IMAC_CATEGORY_ONE_DATA
    };
};
export const getIMACCategoryOneLoadinngAction = () =>
    loadingAction(Actions.GET_IMAC_CATEGORY_ONE_LOADING);
export const getIMACCategoryOneErrorAction = (error: string) =>
    errorAction(Actions.GET_IMAC_CATEGORY_ONE_ERROR, error);
export const getIMACCategoryOneSuccessAction = (data: any) => {
    const payload: IIMACCommon = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_IMAC_CATEGORY_ONE_SUCCESS,
        payload: payload
    };
};

export const getIMACCategoryTwoDataAction = (id: number) => {
    return {
        type: Actions.GET_IMAC_CATEGORY_TWO_DATA,
        payload: id
    };
};
export const getIMACCategoryTwoLoadinngAction = () =>
    loadingAction(Actions.GET_IMAC_CATEGORY_TWO_LOADING);
export const getIMACCategoryTwoErrorAction = (error: string) =>
    errorAction(Actions.GET_IMAC_CATEGORY_TWO_ERROR, error);
export const getIMACCategoryTwoSuccessAction = (data: any) => {
    const payload: IIMACCategoryTwo = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_IMAC_CATEGORY_TWO_SUCCESS,
        payload: payload
    };
};

export const getIMACCategoryThreeDataAction = (id: number) => {
    return {
        type: Actions.GET_IMAC_CATEGORY_THREE_DATA,
        payload: id
    };
};
export const getIMACCategoryThreeLoadinngAction = () =>
    loadingAction(Actions.GET_IMAC_CATEGORY_THREE_LOADING);
export const getIMACCategoryThreeErrorAction = (error: string) =>
    errorAction(Actions.GET_IMAC_CATEGORY_THREE_ERROR, error);
export const getIMACCategoryThreeSuccessAction = (data: any) => {
    const payload: IIMACCategoryThree = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_IMAC_CATEGORY_THREE_SUCCESS,
        payload: payload
    };
};

export interface IDomainIdData extends SideEffectSchema {
    value: {
        hitsContainers: {
            hits: {
                hitId: string;
                rank: number;
                summary: string;
                resource: {
                    "@odata.type": string;
                    displayName: string;
                    givenName: string;
                    surname: string;
                    emailAddresses: {
                        address: string;
                        rank: number;
                    }[];
                    phones: {
                        type: string;
                        number: string;
                    }[];
                    jobTitle: string;
                    department: string;
                    personType: string;
                    userPrincipalName: string;
                    imAddress: string;
                }
            }[];
            total: number;
            moreResultsAvailable: boolean
        }[]
    }[];
}

export interface IDomainIdPayload {
    access_token: string;
    name: string
}

export const postDomainIdDataAction = (access_token: string, name: string) => {
    return {
        type: Actions.POST_DOMAIN_ID_DATA,
        payload: { access_token, name }
    };
};
export const postDomainIdLoadinngAction = () =>
    loadingAction(Actions.POST_DOMAIN_ID_LOADING);
export const postDomainIdErrorAction = (error: string) =>
    errorAction(Actions.POST_DOMAIN_ID_ERROR, error);
export const postDomainIdSuccessAction = (data: any) => {
    const payload: IDomainIdData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.POST_DOMAIN_ID_SUCCESS,
        payload: payload
    };
};

export interface IDomainIdManagerData extends SideEffectSchema {
    employeeId: string;
    id: string;
    mailNickname: string;
    manager: {
        id: string;
        city: string;
        companyName: string;
        consentProvidedForMinor: null;
        country: string;
        createdDateTime: string;
        creationType: null;
        deletedDateTime: null;
        department: string;
        displayName: string;
        employeeHireDate: null;
        employeeId: string;
    };
    userPrincipalName: string;
}

export interface IDomainIdManagerPayload {
    access_token: string;
    id: string
}

export const postDomainIdManagerDataAction = (access_token: string, id: string) => {
    return {
        type: Actions.POST_DOMAIN_ID_MANAGER_DATA,
        payload: { access_token, id }
    };
};
export const postDomainIdManagerLoadinngAction = () =>
    loadingAction(Actions.POST_DOMAIN_ID_MANAGER_LOADING);
export const postDomainIdManagerErrorAction = (error: string) =>
    errorAction(Actions.POST_DOMAIN_ID_MANAGER_ERROR, error);
export const postDomainIdManagerSuccessAction = (data: any) => {
    const payload: IDomainIdManagerData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.POST_DOMAIN_ID_MANAGER_SUCCESS,
        payload: payload
    };
};

export interface IGetAssetsPayload {
    employeeId: string;
    username: string;
    fullName: string;
    assetName: string;
    serialNumber: string;
    assetType: string;
    assetTag: string;
    location: string;
    buildingName: string;
    bay: string;
    floor: string;
    assetPhysicalLocation: string;
    assignedTo: string;
    assigneeOrganisation: string;
}

export interface IGetAssetsResponse {
    employeeId: string;
    username: string;
    fullName: string;
    assetName: string;
    serialNumber: string;
    assetType: string;
    assetTag: string;
    location: string;
    buildingName: string;
    bay: string;
    floor: string;
    assetPhysicalLocation: string;
    assignedTo: string;
    assigneeOrganisation: string;
    auditRemark: string;
    auditDate: string;
}

export interface IGetAssetData extends SideEffectSchema {
    data: IGetAssetsResponse[]
}

export const getAssetDataAction = (
    access_token: string,
    payload: IGetAssetsPayload
) => {
    return {
        type: Actions.GET_ASSETS_DATA,
        payload: { access_token, payload }
    };
};
export const getAssetLoadingAction = () =>
    loadingAction(Actions.GET_ASSETS_LOADING);
export const getAssetErrorAction = (error: string) =>
    errorAction(Actions.GET_ASSETS_ERROR, error);
export const getAssetSuccessAction = (data: any) => {
    const payload: IGetAssetData = {
        data: data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_ASSETS_SUCCESS,
        payload: payload
    };
};
