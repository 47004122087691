import React from "react";
import {
    Dropdown,
    Menu,
    Segment,
    Flex,
    FlexItem,
    Button,
    Checkbox,
    Table,
    Loader,
    Skeleton,
    Text,
    ApprovalsAppbarIcon,
    Divider,
    TrashCanIcon,
    gridCellWithFocusableElementBehavior,
    Box,
    ChevronDownIcon,
    TableRow,
    TableCell,
    ChevronEndIcon,
} from "@fluentui/react-northstar";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../App";
import * as LeaveAppliedAction from "../../redux/actions/LeaveActions";
import { Constant, EmpValueByCode, getDDMMYYYFormat,TraceEvent, TraceTelemtry } from "../../constant";
import { RootSchema } from "../../redux/reducer";
import DataNotFound from "../DNF";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import "./leaveApprove.scss";
import ErrorComponent from "../ErrorComponent";
import NoTasks from "../../assets/img/NoTasks.svg";
import { getAppInsights } from "./../../telemetry/telemetryService";
interface IDeleteApproveLeave {
  QueryData: IState;
}

interface ICheckDataProps {
  leave_request_id: string;
  requestor_emp_id: string;
}

const DeleteApproveLeave: React.FC<IDeleteApproveLeave> = ({ QueryData }) => {
    const {appInsights, trackEvent, trackMetrix} = getAppInsights();
    const [checkedData, setCheckedData] = React.useState<ICheckDataProps[]>([]);
    const [refresh, setRefresh] = React.useState<number>(0);
    const [isExpand, setIsExpand] = React.useState<string>("");
    const [activeBtn, setActiveBtn] = React.useState<string>("");
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const [appInsightData,setAppInsightData] = React.useState<any>({});
    const accessToken = state.authData.userAuth.token;
    const empCode =
    EmpValueByCode[
    state.authData.userAuth.comp_code as keyof typeof EmpValueByCode
    ];
    const get_leave_request = state.leaveData.leaveApprovalHistory;

    const onCheckSelectAllHandler = () => {
        const checkAllList = get_leave_request.data.leave_requests_approval.map(
            (item) => {
                return {
                    leave_request_id: item.leave_request_id,
                    requestor_emp_id: item.requestor_emp_id,
                };
            }
        );
        if (
            checkedData.length ===
      get_leave_request.data.leave_requests_approval.length
        ) {
            setCheckedData([]);
        } else {
            setCheckedData(checkAllList);
        }
    };
    const onCheckedHandler = (item: LeaveAppliedAction.ILeaveHistory) => {
        const value = {
            leave_request_id: item.leave_request_id,
            requestor_emp_id: item.requestor_emp_id,
        };
        checkedData.some(
            (sub: ICheckDataProps) =>
                sub["leave_request_id"] === item.leave_request_id
        )
            ? setCheckedData(
                checkedData.filter(
                    (dept: ICheckDataProps) =>
                        dept.leave_request_id !== value.leave_request_id
                )
            )
            : setCheckedData([...checkedData, value]);
    };

    const rows: any[] =
    get_leave_request.isSuccess &&
      get_leave_request.data.leave_requests_approval.length > 0
        ? get_leave_request.data.leave_requests_approval.map((item, index) => {
            return {
                key: index,
                items: [
                    {
                        content: (
                            <Checkbox
                                checked={checkedData.some(
                                    (sub: ICheckDataProps) =>
                                        sub["leave_request_id"] === item.leave_request_id
                                )}
                                onChange={() => onCheckedHandler(item)}
                            />
                        ),
                        key: index + "-1",
                        isActive: true,
                        styles: { maxWidth: "50px", justifyContent: "center" },
                        accessibility: gridCellWithFocusableElementBehavior,
                        onClick: (e: any) => e.stopPropagation(),
                    },
                    {
                        content: <Text content={index + 1} />,
                        key: index + "-2",
                        isActive: false,
                        styles: { maxWidth: "100px", justifyContent: "center" },
                    },
                    {
                        content: <Text content={item.requestor_email} />,
                        key: index + "-3",
                        truncateContent: true,
                        isActive: true,
                        styles: { justifyContent: "center" },
                    },
                    {
                        content: <Text content={item.leave_category} />,
                        key: index + "-4",
                        isActive: true,
                        styles: { justifyContent: "center" },
                    },
                    {
                        content: <Text content={item.leave_type} />,
                        key: index + "-5",
                        isActive: false,
                        styles: { justifyContent: "center" },
                    },
                    {
                        content: <Text content={getDDMMYYYFormat(item.from_date)} />,
                        key: index + "-6",
                        isActive: true,
                        styles: { justifyContent: "center" },
                    },
                    {
                        content: <Text content={getDDMMYYYFormat(item.to_date)} />,
                        key: index + "-7",
                        isActive: true,
                        styles: { justifyContent: "center" },
                    },
                    {
                        content: <Text content={item.leave_status} />,
                        key: index + "-8",
                        isActive: true,
                        styles: { justifyContent: "center" },
                    },
                    {
                        content: <Text content={item.reason} />,
                        key: index + "-9",
                        isActive: false,
                        styles: { justifyContent: "center" },
                    },
                    {
                        content:
                isExpand === String(index) ? (
                    <ChevronEndIcon rotate={-90} />
                ) : (
                    <ChevronDownIcon />
                ),
                        key: index + "-10",
                        isActive: true,
                        styles: { justifyContent: "center", cursor: "pointer" },
                        onclick: () =>
                            isExpand === String(index)
                                ? setIsExpand("")
                                : setIsExpand(String(index)),
                    },
                ],
            };
        })
        : [];
    const header = [
        {
            content: (
                <Checkbox
                    checked={checkedData.length === rows.length}
                    onClick={() => onCheckSelectAllHandler()}
                />
            ),
            key: "checkbox",
            isActive: true,
            styles: {
                margin: "0px",
                maxWidth: "50px",
                justifyContent: "center",
            },
        },
        {
            content: <Text content="Sr No." weight="bold" size="medium" />,
            key: "sr",
            isActive: false,
            styles: {
                margin: "0px",
                maxWidth: "100px",
                justifyContent: "center",
            },
        },
        {
            content: <Text content="Emp Name" weight="bold" size="medium" />,
            key: "empName",
            isActive: true,
            styles: {
                margin: "0px",
                justifyContent: "center",
            },
        },
        {
            content: <Text content="Leave Category" weight="bold" size="medium" />,
            key: "leaveCategory",
            isActive: true,
            styles: { margin: "0px", justifyContent: "center" },
        },
        {
            content: <Text content="Leave Type" weight="bold" size="medium" />,
            key: "leaveType",
            isActive: false,
            styles: { margin: "0px", justifyContent: "center" },
        },
        {
            content: <Text content="From Date" weight="bold" size="medium" />,
            key: "fromDate",
            isActive: true,
            styles: { margin: "0px", justifyContent: "center" },
        },
        {
            content: <Text content="To Date" weight="bold" size="medium" />,
            key: "toDate",
            isActive: true,
            styles: { margin: "0px", justifyContent: "center" },
        },
        {
            content: <Text content="Status" weight="bold" size="medium" />,
            key: "status",
            isActive: true,
            styles: { margin: "0px", justifyContent: "center" },
        },
        {
            content: <Text content="Reason" weight="bold" size="medium" />,
            key: "reason",
            isActive: false,
            styles: { margin: "0px", justifyContent: "center" },
        },
        {
            content: "",
            key: "expand",
            isActive: true,
            styles: { margin: "0px", justifyContent: "center" },
        },
    ];

    const onDeleteApproveRejectHandler = (Action: string) => {
        setActiveBtn(Action);
        const payload: LeaveAppliedAction.IDeleteLeaveRequestApprovalPayLaod = {
            emp_id: QueryData.employeeId as string,
            data: checkedData,
            action: Action === "approve" ? "0" : "1",
            approval_type: "Leave Request",
        };
        dispatch(
            LeaveAppliedAction.postDeleteLeaveRequestApprovalAction(
                accessToken,
                empCode,
                payload
            )
        );
        setAppInsightData({...payload, empCode});
    };

    React.useEffect(() => {
        if (
            state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" &&
      state.userInfo.userManagerData.isSuccess
        ) {
            const payload: LeaveAppliedAction.ILeaveRequestApprovalHistoryPayLaod = {
                emp_id: QueryData.employeeId as string,
                approval_type: "Delete Leave Request",
                leave_category_code: "All", //WFH
                leave_status_code: "5",
                emp_id_value: "All",
                filter_type: "leave_status",
                approver_id: state.userInfo.userManagerData.manager_details[0]?.Perno,
                pa: state.userInfo.userManagerData.user_details[0].PersArea,
                psa: state.userInfo.userManagerData.user_details[0].PSubarea,
            };
            dispatch(
                LeaveAppliedAction.getLeaveRequestApprovalHistoryAction(
                    accessToken,
                    empCode,
                    payload
                )
            );
            dispatch(LeaveAppliedAction.postPutClearReducerData());
        }
    }, [
        state.userInfo.userManagerData.isSuccess &&
    state.userInfo.userManagerData.status_code === "200",
        refresh,
    ]);

    React.useEffect(() => {
        if (
            state.leaveData.deleteLeaveApproval.isSuccess &&
      state.leaveData.deleteLeaveApproval.status_code === "200"
        ) {
            SuccessToast(state.leaveData.deleteLeaveApproval.message);
            setRefresh(refresh + 1);
            setCheckedData([]);
            setIsExpand("");
            appInsightData && appInsightData.action === "0" ?trackEvent(appInsights, TraceEvent.LEAVE_DELETE_APPROVE, appInsightData):trackEvent(appInsights, TraceEvent.LEAVE_DELETE_REJECT, appInsightData);
        } else if (
            state.leaveData.deleteLeaveApproval.isSuccess &&
      state.leaveData.deleteLeaveApproval.status_code !== "200"
        ) {
            ErrorToast(state.leaveData.deleteLeaveApproval.message);
            trackEvent(appInsights, TraceEvent.LEAVE_DELETE_ERROR, {...state.leaveData.deleteLeaveApproval,...appInsightData});
        }
    }, [state.leaveData.deleteLeaveApproval.isSuccess]);

    return (
        <React.Fragment>
            <Flex
                column
                gap="gap.medium"
                padding="padding.medium"
                className="LeaveApprove"
                styles={({ theme: { siteVariables } }) => ({
                    background: siteVariables.bodyBackground,
                })}
            >
                {state.leaveData.leaveApprovalHistory.isSuccess &&
          state.leaveData.leaveApprovalHistory.status_code === "200" &&
          state.leaveData.leaveApprovalHistory.data.leave_requests_approval
              .length > 0 ? (
                        <Box className="box-table">
                            {/* <Table
                                rows={rows}
                                header={{
                                    items: header,
                                    header: true,
                                    className: "tableHead",
                                }}
                            /> */}
                            <TableRow className="tableHead"
                                styles={({ theme: { siteVariables } }) => ({
                                    background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                    "&:hover": {
                                        background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                                    }
                                })}
                            >
                                {header.map((item, index) => {
                                    return (
                                        item.isActive && (
                                            <TableCell
                                                content={item.content}
                                                key={item.key}
                                                style={item.styles}
                                            />
                                        )
                                    );
                                })}
                            </TableRow>
                            {rows.map((item, index) => {
                                return (
                                    <Box key={item.key}>
                                        <TableRow key={item.key}>
                                            {item.items.map((ele: any, index: any) => {
                                                return (
                                                    ele.isActive && (
                                                        <TableCell
                                                            content={ele.content}
                                                            truncateContent={ele.truncateContent}
                                                            key={ele.key}
                                                            style={ele.styles}
                                                            onClick={ele.onclick}
                                                        />
                                                    )
                                                );
                                            })}
                                        </TableRow>
                                        {isExpand === String(index) && (
                                            <Box>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                          Name
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {item.items[2].content}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                          Leave Category
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {item.items[3].content}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                          Leave Type
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {item.items[4].content}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                          From Date
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {item.items[5].content}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                          To Date
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {item.items[6].content}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                          Reason
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            justifyContent: "start",
                                                            padding: "0 4rem",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {item.items[8].content}
                                                    </TableCell>
                                                </TableRow>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : state.leaveData.leaveApprovalHistory.isSuccess &&
          state.leaveData.leaveApprovalHistory.status_code === "200" &&
          state.leaveData.leaveApprovalHistory.data.leave_requests_approval
              .length === 0 ? (
                            <Flex
                                vAlign="center"
                                hAlign="center"
                                style={{ height: "calc(100vh - 70px)" }}
                            >
                                <DataNotFound img={NoTasks} />
                            </Flex>
                        ) : state.leaveData.leaveApprovalHistory.isSuccess &&
          state.leaveData.leaveApprovalHistory.status_code !== "200" ? (
                                <Flex
                                    vAlign="center"
                                    hAlign="center"
                                    style={{ height: "calc(100vh - 70px)" }}
                                >
                                    <ErrorComponent
                                        message={state.leaveData.leaveApprovalHistory.message}
                                    />
                                </Flex>
                            ) : (
                                <Flex style={{ height: "calc(100vh - 70px)" }}>
                                    <Skeleton
                                        animation="pulse"
                                        //styles={{ margin: '10px' }}
                                    >
                                        <Flex gap="gap.small">
                                            <Skeleton styles={{ margin: "10px" }}>
                                                <Flex gap="gap.medium" column>
                                                    <Skeleton.Line />
                                                    <Skeleton.Line />
                                                    <Skeleton.Line />
                                                </Flex>
                                            </Skeleton>
                                        </Flex>
                                    </Skeleton>
                                </Flex>
                            )}

                <Flex fill gap="gap.small" hAlign="end">
                    <Button
                        content="Reject"
                        disabled={checkedData.length === 0}
                        loading={state.leaveData.deleteLeaveApproval.isLoading && activeBtn === "reject"}
                        onClick={() => onDeleteApproveRejectHandler("reject")}
                    />
                    <Button
                        content="Approve"
                        disabled={checkedData.length === 0}
                        loading={state.leaveData.deleteLeaveApproval.isLoading && activeBtn === "approve"}
                        primary
                        onClick={() => onDeleteApproveRejectHandler("approve")}
                    />
                </Flex>
            </Flex>
        </React.Fragment>
    );
};

export default DeleteApproveLeave;
