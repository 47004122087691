import { put, call, take, delay } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/serviceAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, SERVICE_CONFIG } from "../../config";

function* getServiceStatusData(payload: {
    access_token: string;
    emp_comp: string;
}) {
    try {
        yield put(actionCreators.getServiceAvailabilityDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpGet(
            `${API_BASE_URL}/${payload.emp_comp}${SERVICE_CONFIG.GET_SERVICE_AVAILABILITY}`,
            config
        );
        yield put(actionCreators.getServiceAvailabilityDataSuccessgAction (data));
    } catch (error: any) {
        yield put(actionCreators.getServiceAvailabilityErrorAction(error));
    }
}

export function* watchGetServiceStatusData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SERVICE_AVAILABILITY
        );
        yield call(getServiceStatusData, payload);
    }
}