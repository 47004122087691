import React from "react";
import { Grid, Flex, Text, CloseIcon, Dropdown, RadioGroup, Datepicker, FlexItem, Segment, Button, SearchIcon, Loader, Table, Divider, TextArea, Pill, Skeleton, Box, Input, RedbangIcon, Popup } from "@fluentui/react-northstar";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import Attachments from "../Attachment";
import { IMACForm } from "../../apiConstant/imac_form";
import * as IMACActions from "../../redux/actions/IMACAction";
import * as ITSMAction from "../../redux/actions/ITSMAction";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import axios from "axios";
import { ITSM_API_CONFIG, ITSM_BASE_URL } from "../../config";
import { IUserManager } from "../../redux/actions/UserDataActions";
import * as NotificationAction from "../../redux/actions/NotificationAction";
import { Constant } from "../../constant";
import * as microsoftTeams from "@microsoft/teams-js";
import { getAppInsights } from "./../../telemetry/telemetryService";
import { TraceEvent } from "../../constant";

interface IRaiseImacProps {
    QueryData: IState
}

interface IDropdownObj {
    key?: string;
    id: number | string;
    header: string;
    value: string;
    isActive?: boolean;
    category_one_id?: number;
    category_two_id?: number;
    title?: string;
    department?: string;
    employeeId?: string;
    userName?: string;
    fullName?: string;
    assetName?: string;
    serialNumber?: string;
    assetType?: string;
    assetTag?: string;
    location?: string;
    buildingName?: string;
    bay?: string;
    floor?: string;
    assetPhysicalLocation?: string;
    assignedTo?: string;
    assigneeOrganisation?: string;
    auditRemark?: string;
    auditDate?: string | null;
    contactNo?: string;
}

interface IDropdownValues {
    domainId: IDropdownObj[];
    newDomainId: IDropdownObj[];
    location: IDropdownObj[];
    categoryOne: IDropdownObj[];
    categoryTwo: IDropdownObj[];
    categoryThree: IDropdownObj[];
    assetTag: IDropdownObj[];
    site: IDropdownObj[];
    floor: IDropdownObj[];
    assetCategory: IDropdownObj[];
    newLocation: IDropdownObj[];
    newSite: IDropdownObj[];
    newFloor: IDropdownObj[];
}

interface IRaiseImacForm {
    domainId: IDropdownObj;
    managerName: string;
    department: string;
    location: IDropdownObj;
    costCenter: string;
    categoryOne: IDropdownObj;
    categoryTwo: IDropdownObj;
    categoryThree: IDropdownObj;
    assetTag: IDropdownObj;
    assetType: string;
    assignedUserName: string;
    assignedUserOrganization: string;
    newDomainId: IDropdownObj;
    newManagerName: string;
    newUserDeparment: string;
    newUserCostCenter: string;
    newLocation: IDropdownObj;
    newSite: IDropdownObj;
    newBlockBuilding: string;
    newBay: string;
    newFloor: IDropdownObj;
    newAssetPhysicalLocation: string;
    newAssetCategory: IDropdownObj;
    site: IDropdownObj;
    blockBuilding: string;
    bay: string;
    floor: IDropdownObj;
    assetPhysicalLocation: string;
    assetCategory: IDropdownObj;
    resolutionCatOne: string;
    resolutionCatTwo: string;
    resolutionCatThree: string;
    description: string;
    softwareName: string;
    contactDetails: string;
}

interface IRules {
    N1: boolean;
    CAT: boolean;
    N2: boolean;
    ASG: boolean;
    INF: boolean;
    N3: boolean;
    N4: boolean;
    N5: boolean;
    N6: boolean;
}

const RaiseImacRequest: React.FC<IRaiseImacProps> = ({ QueryData }) => {
    const { appInsights, trackEvent } = getAppInsights();
    const [file, setFile] = React.useState<any>();
    const [refresh, setRefresh] = React.useState(0);
    const [dropDownValues, setDropdownValues] = React.useState<IDropdownValues>({
        domainId: [],
        newDomainId: [],
        location: [],
        categoryOne: [],
        categoryTwo: [],
        categoryThree: [],
        assetTag: [],
        assetCategory: [],
        site: [],
        floor: [],
        newLocation: [],
        newSite: [],
        newFloor: [],
    });
    const [rules, setRules] = React.useState<IRules>({
        N1: true,
        CAT: true,
        N2: true,
        ASG: true,
        INF: true,
        N3: false,
        N4: false,
        N5: false,
        N6: false
    });
    const [imacFormData, setImacFormData] = React.useState<IRaiseImacForm>({
        domainId: { id: 0, header: "", value: "" },
        managerName: "",
        department: "",
        location: { id: 0, header: "", value: "" },
        costCenter: "",
        categoryOne: { id: 0, header: "", value: "" },
        categoryTwo: { id: 0, header: "", value: "" },
        categoryThree: { id: 0, header: "", value: "" },
        softwareName: "",
        assetTag: { id: 0, header: "", value: "" },
        assetType: "",
        assignedUserName: "",
        assignedUserOrganization: "",
        newDomainId: { id: 0, header: "", value: "" },
        newManagerName: "",
        newUserDeparment: "",
        newUserCostCenter: "",
        newLocation: { id: 0, header: "", value: "" },
        newSite: { id: 0, header: "", value: "" },
        newBlockBuilding: "",
        newBay: "",
        newFloor: { id: 0, header: "", value: "" },
        newAssetPhysicalLocation: "",
        newAssetCategory: { id: 0, header: "", value: "" },
        contactDetails: "",
        site: { id: 0, header: "", value: "" },
        blockBuilding: "",
        bay: "",
        floor: { id: 0, header: "", value: "" },
        assetPhysicalLocation: "",
        assetCategory: { id: 0, header: "", value: "" },
        resolutionCatOne: "",
        resolutionCatTwo: "",
        resolutionCatThree: "",
        description: "",
    });
    const [search, setSearch] = React.useState<string>("");
    const [activeInput, setActiveInput] = React.useState<string>("");
    const dispatch = useDispatch();
    const state: RootSchema = useSelector((state: RootSchema) => state);
    const [vldBtn, setVldBtn] = React.useState<boolean>(false);
    const [validateInputField, setValidateInputField] = React.useState<{ name: string, isError: boolean }[]>([]);

    const validateButtonDataUpdate = () => {
        const data = IMACForm.every((category_type: any) => {
            if (rules[category_type.cate_type as keyof typeof rules]) {
                return category_type.fields.every((item: any) => {
                    if (item.input_type === "Dropdown" && item.required) {
                        const dropValue: IDropdownObj = imacFormData[item.id as keyof typeof imacFormData] as IDropdownObj;
                        return dropValue?.value !== "";
                    } else if (item.required) {
                        return imacFormData[item.id as keyof typeof imacFormData] as string !== "";
                    } else return true;
                });
            } else {
                return true;
            }
        });
        return setVldBtn(data && imacFormData.description !== "");
    };

    const validateInputFieldData = async () => {
        const data: { name: string, isError: boolean }[] = [];
        IMACForm.map((category_type: any) => {
            if (rules[category_type.cate_type as keyof typeof rules]) {
                return category_type.fields.map((item: any) => {
                    if (item.input_type === "Dropdown" && item.required) {
                        const dropValue: IDropdownObj = imacFormData[item.id as keyof typeof imacFormData] as IDropdownObj;
                        return data.push({ name: item.id, isError: dropValue?.value === "" });
                    } else if (item.required) {
                        return data.push({ name: item.id, isError: imacFormData[item.id as keyof typeof imacFormData] as string === "" });
                    } else return true;
                });
            }
        });
        console.log("data ====>", [...data, { name: "description", isError: imacFormData.description === "" }]);
        return await setValidateInputField([...data, { name: "description", isError: imacFormData.description === "" }]);
    };

    const CapitalizeAttribute = (input: string) => {
        const words = input.replace(/[A-Z]/g, " $&").split(" ");
        const CapitalizedWords: string[] = [];
        words.forEach((element) => {
            if (element && String(element[0]).trim() !== "")
                CapitalizedWords.push(
                    element[0].toUpperCase() +
                    element.slice(1, element.length).toLowerCase()
                );
        });
        return CapitalizedWords.join(" ");
    };

    const onChangeDomainIdGet = (name: string) => {
        if (search.length >= 1) dispatch(IMACActions.postDomainIdDataAction(QueryData.azureADToken as string, name));
    };

    const getDomianIdManagerData = (id: string) => {
        dispatch(IMACActions.postDomainIdManagerDataAction(QueryData.azureADToken as string, id));
    };

    const onSearchLoadingErrorInDropdown = (id: string, status: string) => {
        switch (id) {
            case "location": return status === "loading" ? state.imacData.getLocationData.isLoading : state.imacData.getLocationData.isError;
            case "newLocation": return status === "loading" ? state.imacData.getLocationData.isLoading : state.imacData.getLocationData.isError;
            case "categoryOne": return status === "loading" ? state.imacData.getIMACcategoryOneData.isLoading : state.imacData.getIMACcategoryOneData.isError;
            case "categoryTwo": return status === "loading" ? state.imacData.getIAMCCategoryTwoData.isLoading : state.imacData.getIAMCCategoryTwoData.isError;
            case "categoryThree": return status === "loading" ? state.imacData.getIMACcategoryThreeData.isLoading : state.imacData.getIMACcategoryThreeData.isError;
            case "assetTag": return status === "loading" ? state.imacData.getAssetData.isLoading : state.imacData.getAssetData.isError;
            case "site": return status === "loading" ? state.imacData.getSiteData.isLoading : state.imacData.getSiteData.isError;
            case "floor": return status === "loading" ? state.imacData.getFloorData.isLoading : state.imacData.getFloorData.isError;
            case "newSite": return status === "loading" ? state.imacData.getSiteData.isLoading : state.imacData.getSiteData.isError;
            case "newFloor": return status === "loading" ? state.imacData.getFloorData.isLoading : state.imacData.getFloorData.isError;
            case "assetCategory": return false;
            case "newDomainId": return status === "loading" ? state.imacData.postDomainIdData.isLoading : state.imacData.postDomainIdData.isError;
            case "domainId": return status === "loading" ? state.imacData.postDomainIdData.isLoading : state.imacData.postDomainIdData.isError;
            default: return false;
        }
    };

    const getUserAssetsDetails = (id: string) => {
        const getAssetsPayload: IMACActions.IGetAssetsPayload = {
            employeeId: id,
            username: "",
            assigneeOrganisation: "",
            assetName: "",
            fullName: "",
            serialNumber: "",
            assetType: "",
            assetTag: "",
            location: "",
            buildingName: "",
            bay: "",
            floor: "",
            assetPhysicalLocation: "",
            assignedTo: ""
        };
        dispatch(IMACActions.getAssetDataAction(state.itsmData.getTokenData.access_token, getAssetsPayload));
    };

    const onOpenDropdownDataGet = (id: string) => {
        setActiveInput(id);
        switch (id) {
            case "location" || "newLocation": !state.imacData.getLocationData.isSuccess && dispatch(IMACActions.getLocationDataAction());
                break;
            case "newLocation": !state.imacData.getLocationData.isSuccess && dispatch(IMACActions.getLocationDataAction());
                break;
            case "categoryOne": !state.imacData.getIMACcategoryOneData.isSuccess && dispatch(IMACActions.getIMACCategoryOneDataAction());
                break;
            case "categoryTwo": {
                setDropdownValues({ ...dropDownValues, categoryTwo: [] });
                dispatch(IMACActions.getIMACCategoryTwoDataAction(imacFormData.categoryOne.id as number));
            }
                break;
            case "categoryThree": {
                setDropdownValues({ ...dropDownValues, categoryThree: [] });
                dispatch(IMACActions.getIMACCategoryThreeDataAction(imacFormData.categoryTwo.id as number));
            }
                break;
            case "assetTag": null;
                break;
            case "site": !state.imacData.getSiteData.isSuccess && dispatch(IMACActions.getSiteDataAction());
                break;
            case "floor": !state.imacData.getFloorData.isSuccess && dispatch(IMACActions.getFloorDataAction());
                break;
            case "newSite": !state.imacData.getSiteData.isSuccess && dispatch(IMACActions.getSiteDataAction());
                break;
            case "newFloor": !state.imacData.getFloorData.isSuccess && dispatch(IMACActions.getFloorDataAction());
                break;
            case "assetCategory": null;
                break;
            default:
                break;
        }
    };

    const resetFormHandler = () => {
        setImacFormData({
            domainId: { id: 0, header: "", value: "" },
            managerName: "",
            department: "",
            location: { id: 0, header: "", value: "" },
            costCenter: "",
            categoryOne: { id: 0, header: "", value: "" },
            categoryTwo: { id: 0, header: "", value: "" },
            categoryThree: { id: 0, header: "", value: "" },
            assetTag: { id: 0, header: "", value: "" },
            assetType: "",
            assignedUserName: "",
            assignedUserOrganization: "",
            newDomainId: { id: 0, header: "", value: "" },
            newManagerName: "",
            newUserDeparment: "",
            newUserCostCenter: "",
            newLocation: { id: 0, header: "", value: "" },
            newSite: { id: 0, header: "", value: "" },
            newBlockBuilding: "",
            newBay: "",
            newFloor: { id: 0, header: "", value: "" },
            newAssetPhysicalLocation: "",
            newAssetCategory: { id: 0, header: "", value: "" },
            site: { id: 0, header: "", value: "" },
            blockBuilding: "",
            bay: "",
            floor: { id: 0, header: "", value: "" },
            assetPhysicalLocation: "",
            assetCategory: { id: 0, header: "", value: "" },
            resolutionCatOne: "",
            resolutionCatTwo: "",
            resolutionCatThree: "",
            description: "",
            softwareName: "",
            contactDetails: ""
        });
    };

    function createAdditionalComment() {
        const data = Object.entries(imacFormData);
        let stringData = "";
        let existAssets = "";
        state.imacData.getAssetData.isSuccess && state.imacData.getAssetData?.data && state.imacData.getAssetData.data.map((item) => existAssets = existAssets + `${item.assetType}-${item.assetName} <br />`);
        data.map((item: any) => {
            if (typeof (item[1]) === "string" && item[1] !== "" && item[1] !== undefined) return stringData = stringData + `${CapitalizeAttribute(item[0])} :- ${item[1]} <br />`;
            else if (typeof (item[1]) === "object" && item[1].value !== "" && item[1].value !== undefined) return stringData = stringData + `${CapitalizeAttribute(item[0])} :- ${item[1].value} <br />`;
        });
        // console.log("Addinational Comments", "<p>" + stringData + "Existing Assets :- <br />" + existAssets + "</p>");
        return "<p>" + stringData + "Existing Assets :- <br />" + existAssets + "</p>";
    }

    const onRaiseImacSubmitHandler = () => {
        const payload: ITSMAction.ICreateTicketCatalogPayload = {
            type: "Request",
            loggedInUsername: QueryData.userId as string,
            category: imacFormData.categoryOne.value,
            subcategory: imacFormData.categoryTwo.value,
            item: imacFormData.categoryThree.value,
            priority: "P3",
            severity: "LOW",
            assigned_group: "TCS_SERVICE_DESK",
            shortDescription: imacFormData.description,
            // shortDescription: "IMAC FORM",
            additionalComments: createAdditionalComment(),
            requsterName: String(imacFormData.domainId.userName || "").split("@")[0],
            template: "IMAC FORM",
            location: imacFormData.location.value,
            newUserId: String(imacFormData.newDomainId.userName || "").split("@")[0],
            channel: Constant.SUPPORT_CHANNEL_NAME
        };
        dispatch(ITSMAction.createTicketCatalogDataAction(state.itsmData.getTokenData.access_token, payload));
    };

    const onChangeDropdownHandler = (id: string, value: any) => {
        const intialState = { id: 0, header: "", value: "" };
        if (id === "categoryOne") value === null ? setImacFormData({ ...imacFormData, [id]: intialState, categoryTwo: intialState, categoryThree: intialState }) : setImacFormData({ ...imacFormData, [id]: value, categoryTwo: intialState, categoryThree: intialState });
        else if (id === "categoryTwo") value === null ? setImacFormData({ ...imacFormData, [id]: intialState, categoryThree: intialState }) : setImacFormData({ ...imacFormData, [id]: value, categoryThree: intialState });
        else if (id === "assetTag") value === null ? setImacFormData({ ...imacFormData, [id]: intialState, assetType: "", assignedUserName: "", assignedUserOrganization: "" }) : setImacFormData({ ...imacFormData, [id]: value, assetType: value.assetType, assignedUserName: value.assignedTo, assignedUserOrganization: value.assigneeOrganisation });
        else if (id === "newDomainId") {
            value === null ? setImacFormData({ ...imacFormData, [id]: intialState, newManagerName: "", newUserDeparment: "", newUserCostCenter: "" }) : setImacFormData({ ...imacFormData, [id]: value, newManagerName: "", newUserDeparment: value.department, newUserCostCenter: "" });
            value !== null && getDomianIdManagerData(value.userName);
        }
        else if (id === "domainId") {
            value === null ?
                setImacFormData({
                    ...imacFormData, [id]: intialState,
                    managerName: "",
                    department: "",
                    location: intialState,
                    costCenter: "",
                    categoryOne: intialState,
                    categoryTwo: intialState,
                    categoryThree: intialState,
                    softwareName: "",
                    assetTag: intialState,
                    assetType: "",
                    assignedUserName: "",
                    assignedUserOrganization: "",
                    contactDetails: ""
                })
                : setImacFormData({ ...imacFormData, [id]: value, managerName: "", department: value.department, costCenter: "", contactDetails: value.contactNo });
            value !== null && getDomianIdManagerData(value.userName);
            value === null && setDropdownValues({ ...dropDownValues, assetTag: [] });
        }
        else value === null ? setImacFormData({ ...imacFormData, [id]: intialState }) : setImacFormData({ ...imacFormData, [id]: value });
    };

    function addAttachment() {
        const attachmentRequest = file.map(async (data: any) => {
            const fd = new FormData();
            fd.append("file", data);
            fd.append("loggedInUsername", QueryData.userId as string);
            fd.append("ticketIdNumber", state.itsmData.createTicketCatalog.ticketId);

            const config: any = {
                headers: {
                    Authorization: `Bearer ${state.itsmData.getTokenData.access_token}`,
                    env: process.env.REACT_APP_ENV
                },
            };
            return axios.post(ITSM_BASE_URL + ITSM_API_CONFIG.ADD_ATTACHMENT, fd, config);

        });

        Promise.allSettled(attachmentRequest).then((results: any) => {
            let successCount = 0;
            for (const i in results) {
                if (results[i].status === "fulfilled" && results[i].value.status == 200) {
                    successCount++;
                } else {
                    ErrorToast(file[i].name + "file failed to attached with ticket id " + state.itsmData.addAttachment.ticketId);
                }
            }
            const attachFile = [];
            for (const i in file) {
                attachFile.push(file[i].name);
            }
            const payload = {
                type: "create",
                ticketType: "request",
                empId: QueryData.employeeId as string,
                userObjId: QueryData.userObjectId as string,
                cardDetails: {
                    ticketId: state.itsmData.createTicketCatalog.ticketId,
                    shortDescription: imacFormData.description,
                    attachment: attachFile
                },
                env: process.env.REACT_APP_ENV === "production" ? null : process.env.REACT_APP_ENV === "staging" ? QueryData.app_env : "dev"
            };
            dispatch(NotificationAction.postNotificationDataAction(payload));
            const successTxt = successCount === 1 ? "File is attached with ticket id :- " + state.itsmData.createTicketCatalog.ticketId : successCount + " files are attached with ticket id :- " + state.itsmData.createTicketCatalog.ticketId;
            SuccessToast(successTxt);
            setRefresh(1);
            setFile([]);
        })
            .catch((error) => {
                console.log(error);
                setFile([]);
            });
    }

    React.useEffect(() => {
        if (!state.itsmData.getTokenData.isSuccess) {
            dispatch(ITSMAction.getTokenDataAction());
        }
    }, []);

    React.useEffect(() => {
        if (state.itsmData.createTicketCatalog.isSuccess && state.itsmData.createTicketCatalog.ticketId !== "" && vldBtn) {
            if (file) {
                addAttachment();
            } else {
                const payload = {
                    type: "create",
                    ticketType: "request",
                    empId: QueryData.employeeId as string,
                    userObjId: QueryData.userObjectId as string,
                    cardDetails: {
                        ticketId: state.itsmData.createTicketCatalog.ticketId,
                        shortDescription: imacFormData.description,
                        attachment: []
                    },
                    env: process.env.REACT_APP_ENV === "production" ? null : process.env.REACT_APP_ENV === "staging" ? QueryData.app_env : "dev"
                };
                dispatch(NotificationAction.postNotificationDataAction(payload));
                trackEvent(appInsights, TraceEvent.ITSM_REQUEST_CREATE, payload);
            }
            SuccessToast("Ticket id: " + state.itsmData.createTicketCatalog.ticketId);
            resetFormHandler();
            setValidateInputField([]);
            setTimeout(() => {
                microsoftTeams.tasks.submitTask({}, process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_ID : process.env.REACT_APP_ENV === "staging" ? process.env.REACT_APP_STAG_ID : process.env.REACT_APP_ID);
            }, 1000);
        } else if ((state.itsmData.createTicketCatalog.isError || (state.itsmData.createTicketCatalog.isSuccess && state.itsmData.createTicketCatalog.ticketId === "")) && vldBtn) {
            ErrorToast(state.itsmData.createTicketCatalog.message);
        }
    }, [state.itsmData.createTicketCatalog.isSuccess]);

    React.useEffect(() => {
        const timer = setTimeout(() => { onChangeDomainIdGet(search); }, 750);
        return () => clearTimeout(timer);
    }, [search]);

    React.useEffect(() => {
        if (state.imacData.postDomainIdManagerData.isSuccess) {
            activeInput === "domainId" && setDropdownValues({ ...dropDownValues, assetTag: [] });
            if (activeInput === "domainId" && state.imacData.postDomainIdManagerData.employeeId !== null) {
                getUserAssetsDetails(state.imacData.postDomainIdManagerData.employeeId);
                setImacFormData({ ...imacFormData, managerName: state.imacData.postDomainIdManagerData.manager.displayName });
            }
            if (activeInput === "newDomainId") {
                setImacFormData({ ...imacFormData, newManagerName: state.imacData.postDomainIdManagerData.manager.displayName });
            }
        }
    }, [state.imacData.postDomainIdManagerData.isSuccess]);

    React.useEffect(() => {
        if (state.imacData.postDomainIdData.isSuccess && (activeInput === "newDomainId" || activeInput === "domainId")) {
            if (state.imacData.postDomainIdData.value[0].hitsContainers[0].total > 0 && search.length > 0) {
                const data = state.imacData.postDomainIdData.value[0].hitsContainers[0].hits.map((item) => {
                    return {
                        key: item.hitId + Date.now().toString(),
                        id: item.hitId,
                        header: item.resource?.displayName,
                        value: item.resource?.displayName,
                        userName: item.resource?.userPrincipalName,
                        contactNo: item.resource?.phones ? item.resource?.phones[0]?.number : "",
                        department: item.resource?.department
                    };
                });
                (activeInput === "newDomainId" ? setDropdownValues({ ...dropDownValues, newDomainId: data }) : setDropdownValues({ ...dropDownValues, domainId: data }));
            } else (activeInput === "newDomainId" ? setDropdownValues({ ...dropDownValues, newDomainId: [] }) : setDropdownValues({ ...dropDownValues, domainId: [] }));
        }
        else if (state.imacData.getIMACcategoryOneData.isSuccess && activeInput === "categoryOne") {
            const data = state.imacData.getIMACcategoryOneData.data.map((item) => {
                return {
                    key: item.id + Date.now().toString(),
                    id: item.id,
                    header: item.name,
                    value: item.name,
                    isActive: item.is_active
                };
            });
            setDropdownValues({ ...dropDownValues, categoryOne: data });
        }
        else if (state.imacData.getIAMCCategoryTwoData.isSuccess && activeInput === "categoryTwo") {
            const data = state.imacData.getIAMCCategoryTwoData.data.map((item) => {
                return {
                    key: item.id + Date.now().toString(),
                    id: item.id,
                    header: item.name,
                    value: item.name,
                    isActive: item.is_active
                };
            });
            setDropdownValues({ ...dropDownValues, categoryTwo: data });
        }
        else if (state.imacData.getIMACcategoryThreeData.isSuccess && activeInput === "categoryThree") {
            const data = state.imacData.getIMACcategoryThreeData.data.map((item) => {
                return {
                    key: item.id + Date.now().toString(),
                    id: item.id,
                    header: item.name,
                    value: item.name,
                    isActive: item.is_active
                };
            });
            setDropdownValues({ ...dropDownValues, categoryThree: data });
        }
        else if (state.imacData.getLocationData.isSuccess && (activeInput === "location" || activeInput === "newLocation")) {
            const data = state.imacData.getLocationData.data.map((item) => {
                return {
                    key: item.id + Date.now().toString(),
                    id: item.id,
                    header: item.name,
                    value: item.name,
                    isActive: item.is_active
                };
            });
            setDropdownValues({ ...dropDownValues, location: data, newLocation: data });
        }
        else if (state.imacData.getFloorData.isSuccess && (activeInput === "floor" || activeInput === "newFloor")) {
            const data = state.imacData.getFloorData.data.map((item) => {
                return {
                    key: item.id + Date.now().toString(),
                    id: item.id,
                    header: item.name,
                    value: item.name,
                    isActive: item.is_active
                };
            });
            setDropdownValues({ ...dropDownValues, floor: data, newFloor: data });
        }
        else if (state.imacData.getSiteData.isSuccess && (activeInput === "site" || activeInput === "newSite")) {
            const data = state.imacData.getSiteData.data.map((item) => {
                return {
                    key: item.id + Date.now().toString(),
                    id: item.id,
                    header: item.name,
                    value: item.name,
                    isActive: item.is_active
                };
            });
            setDropdownValues({ ...dropDownValues, site: data, newSite: data });
        }
        else if (state.imacData.getAssetData.isSuccess && activeInput === "assetTag" && imacFormData.domainId.value !== "") {
            const data = state.imacData.getAssetData?.data && state.imacData.getAssetData?.data.length > 0 ?
                state.imacData.getAssetData.data.map((item) => {
                    return {
                        key: item.assetName + Date.now().toString(),
                        id: item.assetName,
                        header: item.assetName,
                        value: item.assetName,
                        serialNumber: item.serialNumber,
                        assetType: item.assetType,
                        assetTag: item.assetTag,
                        location: item.location,
                        buildingName: item.buildingName,
                        bay: item.bay,
                        floor: item.floor,
                        assetPhysicalLocation: item.assetPhysicalLocation,
                        assignedTo: item.assignedTo,
                        assigneeOrganisation: item.assigneeOrganisation,
                        auditRemark: item.auditRemark,
                        auditDate: item.auditDate,
                    };
                }) : [];
            setDropdownValues({ ...dropDownValues, assetTag: data });
        }
    }, [
        state.imacData.postDomainIdData.isSuccess,
        state.imacData.getIMACcategoryOneData.isSuccess,
        state.imacData.getIAMCCategoryTwoData.isSuccess,
        state.imacData.getIMACcategoryThreeData.isSuccess,
        state.imacData.getLocationData.isSuccess,
        state.imacData.getFloorData.isSuccess,
        state.imacData.getSiteData.isSuccess,
        state.imacData.getAssetData.isSuccess,
        activeInput
    ]);

    React.useEffect(() => {
        if (imacFormData.categoryThree.value === "New device required") {
            setRules({ ...rules, N2: false, CAT: true, N3: false, N4: false, N5: false, N6: false });
        } else if (imacFormData.categoryThree.value === "Change ownership") {
            setRules({ ...rules, N2: true, CAT: true, N3: true, N4: true, N5: false, N6: false });
        } else if (imacFormData.categoryThree.value === "Shift to a different location" || imacFormData.categoryThree.value === "Surrender to store") {
            setRules({ ...rules, N2: true, CAT: true, N3: false, N4: true, N5: false, N6: false });
        } else if (imacFormData.categoryThree.value === "OTHER SOFTWARE") {
            setRules({ ...rules, N2: true, CAT: true, N3: false, N4: false, N5: false, N6: true });
        } else {
            setRules({ ...rules, N2: true, CAT: true, N3: false, N4: false, N5: false, N6: false });
        }
    }, [imacFormData.categoryThree]);

    React.useEffect(() => {
        validateButtonDataUpdate();
    }, [imacFormData, rules]);

    const handleFiles = (files: any) => {
        if (files.length > 0) {
            setFile(files);
        } else {
            setFile([]);
        }
    };

    return (
        <React.Fragment>
            {state.itsmData.getTokenData.isSuccess ?
                <Flex column gap="gap.small" padding="padding.medium" styles={QueryData.isTaskModuleActive ? { height: "calc(100vh - 55px)", overflow: "auto" } : { height: "calc(100vh - 110px)", overflow: "auto" }}>
                    <Grid columns={state.appConfig.dimension.deviceWidth >= 600 ? "repeat(3,1fr)" : "repeat(1,1fr)"} styles={{ gridGap: "12px", padding: "0.5rem" }}>
                        {IMACForm.sort((a: any, b: any) => a.index - b.index).map((category_type: any) => {
                            return rules[category_type.cate_type as keyof typeof rules] && category_type.fields.map((item: any) => {
                                if (item.input_type === "Dropdown") {
                                    const dropValue: IDropdownObj = imacFormData[item.id as keyof typeof imacFormData] as IDropdownObj;
                                    return (<Flex key={item.id} column gap="gap.small" fill>
                                        <Text weight="semibold" >
                                            {item.content} {item.required && <Text content="*" color="red" />}
                                        </Text>
                                        <Dropdown
                                            key={item.id}
                                            search={(item.id === "newDomainId" || item.id === "domainId") ? () => {
                                                const result = dropDownValues[item.dropItems as keyof typeof dropDownValues];
                                                return result;
                                            } : true}
                                            inverted
                                            className="inputBody"
                                            fluid
                                            error={item.required && dropValue.value === "" ? validateInputField.filter(obj => obj.name === item.id)[0]?.isError : false}
                                            loading={onSearchLoadingErrorInDropdown(item.id, "loading")}
                                            loadingMessage={
                                                <Flex fill vAlign='center' hAlign='center'>
                                                    <Text content="Loading..." />
                                                </Flex>
                                            }
                                            noResultsMessage={
                                                onSearchLoadingErrorInDropdown(item.id, "error") ?
                                                    <Flex fill vAlign='center' hAlign='center'>
                                                        <Text content="Something Went Wrong" />
                                                    </Flex>
                                                    :
                                                    <Flex fill vAlign='center' hAlign='center'>
                                                        <Text content="No Items Found" />
                                                    </Flex>
                                            }
                                            clearable={dropValue.value === "" ? false : true}
                                            placeholder={item.placeholder}
                                            value={dropValue.value}
                                            defaultValue={dropValue.value}
                                            items={dropDownValues[item.dropItems as keyof typeof dropDownValues]}
                                            onOpenChange={(e, v) => {
                                                const checkOpen = v.open !== undefined ? v.open : true;
                                                checkOpen && onOpenDropdownDataGet(item.id);
                                                setActiveInput(item.id);
                                            }}
                                            disabled={item.isDisabled || (item.id === "categoryTwo" && imacFormData.categoryOne.value === "") || (item.id === "categoryThree" && imacFormData.categoryTwo.value === "")}
                                            onChange={(e, val: any) => onChangeDropdownHandler(item.id, val.value)}
                                            onSearchQueryChange={(e, atr) => {
                                                const checkOpen = atr?.open !== undefined ? atr.open : true;
                                                if ((item.id === "newDomainId" || item.id === "domainId") && checkOpen && atr.searchQuery && atr.searchQuery.length > 0) {
                                                    setSearch(atr.searchQuery as string);
                                                    if (item.id === "domainId" && dropValue.value !== "") {
                                                        const intialState = { id: 0, header: "", value: "" };
                                                        setImacFormData({
                                                            ...imacFormData, [item.id]: intialState,
                                                            managerName: "",
                                                            department: "",
                                                            location: intialState,
                                                            costCenter: "",
                                                            categoryOne: intialState,
                                                            categoryTwo: intialState,
                                                            categoryThree: intialState,
                                                            softwareName: "",
                                                            assetTag: intialState,
                                                            assetType: "",
                                                            assignedUserName: "",
                                                            assignedUserOrganization: "",
                                                            contactDetails: ""
                                                        });
                                                        setDropdownValues({ ...dropDownValues, assetTag: [] });
                                                    }
                                                } else setSearch("");
                                            }}
                                        />
                                    </Flex>
                                    );
                                } else if (item.input_type === "Text") {
                                    return (
                                        <Flex key={item.id} column gap="gap.small" fill>
                                            <Text weight="semibold" >
                                                {item.content} {item.required && <Text content="*" color="red" />}
                                            </Text>
                                            <Input
                                                inverted
                                                className="inputBody"
                                                fluid
                                                error={item.required && imacFormData[item.id as keyof typeof imacFormData] as string === "" ? validateInputField.filter(obj => obj.name === item.id)[0]?.isError : false}
                                                clearable={imacFormData[item.id as keyof typeof imacFormData] === "" ? false : true}
                                                placeholder={item.placeholder}
                                                value={imacFormData[item.id as keyof typeof imacFormData] as string}
                                                required={item.required}
                                                disabled={item.isDisabled}
                                                onChange={(e, val: any) => onChangeDropdownHandler(item.id, val.value)}
                                            />
                                        </Flex>
                                    );
                                }
                            });
                        })}
                    </Grid>
                    <Flex column gap="gap.small" padding="padding.medium" >
                        <Text weight="semibold" >
                            {"Description of word requested"} <Text content="*" color="red" />
                        </Text>
                        <Box className="inputBody">
                            <TextArea
                                inverted
                                placeholder="Type Description"
                                required
                                error={imacFormData.description === "" ? validateInputField.filter(obj => obj.name === "description")[0]?.isError : false}
                                value={imacFormData.description}
                                fluid
                                rows={5}
                                onChange={(e: any, v: any): any => setImacFormData({ ...imacFormData, description: v.value.trimStart() })}
                            />
                        </Box>
                    </Flex>
                    <Flex column gap="gap.small" padding="padding.medium">
                        <Flex gap="gap.medium" vAlign="center">
                            <Text content="Attachments (Optional)" weight='semibold' styles={{ marginBottom: "0px !important" }} />
                            {/* <Popup trigger={<Button icon={<RedbangIcon size="small" rotate={180} />} flat iconOnly />} position="after" align="center" pointing content={{ content: ToolTipContentAttachment(), styles: { maxWidth: "300px" } }} /> */}
                        </Flex>
                        <Attachments handleFiles={handleFiles} maxFiles={5} refresh={refresh} />
                    </Flex>
                </Flex> :
                <Skeleton animation='pulse'>
                    <Flex column gap="gap.small" padding="padding.medium" styles={{ position: "relative", height: QueryData.isTaskModuleActive ? "calc(100vh - 55px)" : "calc(100vh - 110px)", overflow: "auto" }}>
                        {[0, 1, 2, 3, 4].map((item, index) => {
                            return (
                                <Flex key={item} gap="gap.small" padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                    <Flex column gap="gap.small" fill>
                                        <Skeleton.Text style={{ width: "30%" }} />
                                        <Skeleton.Input fluid />
                                    </Flex>
                                    <Flex column gap="gap.small" fill>
                                        <Skeleton.Text style={{ width: "30%" }} />
                                        <Skeleton.Input fluid />
                                    </Flex>
                                    <Flex column gap="gap.small" fill>
                                        <Skeleton.Text style={{ width: "30%" }} />
                                        <Skeleton.Input fluid />
                                    </Flex>
                                </Flex>
                            );
                        })}
                        <Flex column gap="gap.small" padding="padding.medium" >
                            <Skeleton.Text style={{ width: "15%" }} />
                            <Skeleton.Input fluid styles={{ height: "6rem" }} />
                        </Flex>
                    </Flex>
                </Skeleton>
            }
            <Divider fitted />
            <Flex gap="gap.medium" hAlign='end' padding='padding.medium'  >
                {/* <Button content="Reset" onClick={() => createAdditionalComment()} /> */}
                <Button content="Submit" primary loading={state.itsmData.createTicketCatalog.isLoading} onClick={() => vldBtn ? onRaiseImacSubmitHandler() : validateInputFieldData()} />
            </Flex>
        </React.Fragment>
    );
};

export default RaiseImacRequest;