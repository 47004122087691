import {
    CloseIcon,
    Dialog,
    Flex,
    Text,
    TextArea,
    Button,
    Box,
    Skeleton,
    Avatar,
    EditIcon,
    Input,
    AcceptIcon,
    FlexItem
} from "@fluentui/react-northstar";
import React from "react";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import {
    addGoalCommentDataAction,
    getGoalCommentDataAction,
    getCompetencyCommentDataAction,
    addCompetencyCommentDataAction,
    updateManagerGoalCommentDataAction,
    updateManagerCompetencyCommentDataAction,
} from "../../redux/actions/CFMActions";
import DataNotFound from "../DNF";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import "./teamFeedback.scss";
import { Capitalize, Constant, TraceEvent, TraceTelemtry } from "../../constant";
import CFMMenuComponent from "../Common/CFMMenuComponent";
import { getAppInsights } from "./../../telemetry/telemetryService";

interface IFeedback {
  handler: [isActive: boolean, setIsActive: (value: boolean) => void];
  QueryData: IState;
  feedbackProps: any;
  itemId: string;
  managerView: boolean
}

const Feedback: React.FC<IFeedback> = ({
    handler,
    QueryData,
    itemId,
    feedbackProps,
    managerView
}) => {
    const { appInsights, trackEvent, trackMetrix } = getAppInsights();
    const [recentFeedback, setRecentFeedback] = React.useState<any[]>([]);
    const [comment, setComment] = React.useState<{ key: string, value: string }>({ key: "", value: "" });
    const [isPreview, setIsPreview] = React.useState<boolean>(false);
    const [preDefinedText, setPreDefinedText] = React.useState<string>("");
    const [editableId, setEditableId] = React.useState<{ isActive?: number; comment?: string }>({});
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);

    React.useEffect(() => {
        if (feedbackProps.item_id != "") {
            if (feedbackProps.type === "Goal") {
                dispatch(
                    getGoalCommentDataAction(
                        state.authData.userAuth.token,
                        feedbackProps.item_id,
                        managerView ? false : true
                    )
                );
            } else if (feedbackProps.type === "Leadership") {
                dispatch(
                    getCompetencyCommentDataAction(
                        state.authData.userAuth.token,
                        feedbackProps.item_id,
                        feedbackProps.user_id,
                        managerView ? false : true
                    )
                );
            }
        }
        if (
            comment.key !== "" &&
      (state.cfmData.addCompetencyCommentData.isSuccess ||
        state.cfmData.addGoalCommentData.isSuccess)
        ) {
            setComment({ key: "", value: "" });
            setIsPreview(false);
            if (
                state.cfmData.addCompetencyCommentData.status_code === "200" ||
        state.cfmData.addGoalCommentData.status_code === "200"
            ) {
                SuccessToast(
                    state.cfmData.addCompetencyCommentData.message ||
          state.cfmData.addGoalCommentData.message
                );
            } else {
                ErrorToast(
                    state.cfmData.addCompetencyCommentData.message ||
          state.cfmData.addGoalCommentData.message
                );
            }
        }
    }, [
        feedbackProps.item_id,
        state.cfmData.addCompetencyCommentData.isSuccess,
        state.cfmData.addGoalCommentData.isSuccess,
    ]);

    React.useEffect(() => {
        if (feedbackProps.type === "Goal") {
            setRecentFeedback(state.cfmData.getGoalCommentsData.goal_comments);
        } else if (feedbackProps.type === "Leadership") {
            setRecentFeedback(
                state.cfmData.getCompetencyCommentData.competency_comments
            );
        }
    }, [
        state.cfmData.getCompetencyCommentData.isSuccess,
        state.cfmData.getGoalCommentsData.isSuccess,
    ]);

    const addComment = () => {
        if (feedbackProps.type === "Goal") {
            const data = {
                emp_id: managerView ? state.appConfig.teamMemberSelect.emp_id : QueryData.employeeId as string,
                item_id: feedbackProps.item_id,
                comment: comment.key,
                commented_by: state.userInfo.userManagerData.user_details[0].Perno,
                commented_by_name:
          state.userInfo.userManagerData.user_details[0].CompName,
                goal_name: feedbackProps.name,
                role: managerView ? state.appConfig.teamMemberSelect.internal_role === "direct_reportees" ? "DEM" : state.appConfig.teamMemberSelect.internal_role === "matrix_reportees" ? "FEM" : "Employee" : "Employee",
                //role: state.appConfig.teamMemberSelect.internal_role === "direct_reportees" ? "DEM" : state.appConfig.teamMemberSelect.internal_role === "matrix_reportees" ? "FEM" : "Employee",
                form_data_id: feedbackProps.form_data_id,
                form_template_id: feedbackProps.form_template_id,
                prev_sf_goal_comments_id: feedbackProps.sf_goal_comments_id,
                user_id: feedbackProps.user_id,
                dem_email: managerView ? state.appConfig.teamMemberSelect.internal_role === "direct_reportees" ? state.userInfo.userManagerData.user_details[0].Imailid : "" : "",
                fem_email: managerView ? state.appConfig.teamMemberSelect.internal_role === "matrix_reportees" ? state.userInfo.userManagerData.user_details[0].Imailid : "" : "",
                emp_email: managerView ? state.appConfig.teamMemberSelect.username : state.userInfo.userManagerData.user_details[0].Imailid
            };
            dispatch(addGoalCommentDataAction(state.authData.userAuth.token, data));
            trackEvent(appInsights, TraceEvent.TML_CFM_GOALS_SELF_ADD_COMMENT, data);
        } else if (feedbackProps.type === "Leadership") {
            const data = {
                emp_id: managerView ? state.appConfig.teamMemberSelect.emp_id : QueryData.employeeId as string,
                item_id: feedbackProps.item_id,
                comment: comment.key,
                commented_by: state.userInfo.userManagerData.user_details[0].Perno,
                commented_by_name:
          state.userInfo.userManagerData.user_details[0].CompName,
                role: managerView ? state.appConfig.teamMemberSelect.internal_role === "direct_reportees" ? "DEM" : state.appConfig.teamMemberSelect.internal_role === "matrix_reportees" ? "FEM" : "Employee" : "Employee",
                //role: state.appConfig.teamMemberSelect.internal_role === "direct_reportees" ? "DEM" : state.appConfig.teamMemberSelect.internal_role === "matrix_reportees" ? "FEM" : "Employee",
                competency_name: feedbackProps.name,
                form_data_id: feedbackProps.form_data_id,
                form_template_id: feedbackProps.form_template_id,
                prev_sf_competency_comments_id:
          feedbackProps.sf_competency_comments_id,
                user_id: feedbackProps.user_id,
                dem_email: managerView ? state.appConfig.teamMemberSelect.internal_role === "direct_reportees" ? state.userInfo.userManagerData.user_details[0].Imailid : "" : "",
                fem_email: managerView ? state.appConfig.teamMemberSelect.internal_role === "matrix_reportees" ? state.userInfo.userManagerData.user_details[0].Imailid : "" : "",
                emp_email: managerView ? state.appConfig.teamMemberSelect.username : state.userInfo.userManagerData.user_details[0].Imailid
            };
            dispatch(
                addCompetencyCommentDataAction(state.authData.userAuth.token, data)
            );
            trackEvent(appInsights, TraceEvent.TML_CFM_LEADERSHIP_SELF_ADD_COMMENT, data);
        }
        setPreDefinedText("");
    // handler[1](false);
    };

    const submitHandler = () => {
        if (comment.key.length === 0 || comment.key.length > 300) {
            return true;
        }
        return false;
    };

    const onClickPreDefine = (value: string) => {
        setPreDefinedText(value);
        setComment({ key: value + " ", value: value + " " });
    };

    const feedbackEditValue = (btnValue: string, item: any) => {
        if (btnValue === "accept") {
            if (item?.sf_competency_comments_id) {
                const data = {
                    item_id: item.item_id,
                    sf_competency_comments_id: item.sf_competency_comments_id,
                    comment: editableId.comment as string
                };
                dispatch(
                    updateManagerCompetencyCommentDataAction(state.authData.userAuth.token, data)
                );
                trackEvent(appInsights, TraceEvent.TML_CFM_LEADERSHIP_MANAGER_UPDATE_COMMENT, data);
            } else if (item?.sf_goal_comments_id) {
                const data = {
                    item_id: item.item_id_pk_id,
                    sf_goal_comments_id: item.sf_goal_comments_id,
                    comment: editableId.comment as string
                };
                dispatch(
                    updateManagerGoalCommentDataAction(state.authData.userAuth.token, data)
                );
                trackEvent(appInsights, TraceEvent.TML_CFM_GOALS_MANAGER_UPDATE_COMMENT, data);
            }
        } else {
            setEditableId({});
        }
    };

    return (
        <React.Fragment>
            <Flex column gap="gap.large" padding="padding.medium">
                <Text content={feedbackProps.name} size="large" />

                <Flex column gap="gap.medium" className={QueryData.isTaskModuleActive ? "taskModule" : ""}>
                    {!isPreview && <Text weight="bold" content="Recent Feedback" size="large" />}
                    {!isPreview && <Flex
                        column
                        gap="gap.small"
                        styles={{ maxHeight: "180px", overflow: "auto" }}
                    >
                        {recentFeedback && recentFeedback.length > 0 ? (
                            recentFeedback.filter((item: any) => item.status !== 3).slice(0, 5).map((item: any, index) => {
                                return (
                                    <Flex gap="gap.medium" key={index} vAlign="center">
                                        <Flex gap="gap.small" vAlign="center" fill>
                                            <Avatar name={Capitalize(`${item.commented_by_name}`)} size="smaller"
                                                label={{
                                                    styles: item.role === "DEM" ? { backgroundColor: "#3A83F8", color: "#FFFFFF" } : item.role === "FEM" ? { backgroundColor: "#3D1D76", color: "#FFFFFF" } : { backgroundColor: "#65D283", color: "#FFFFFF" }
                                                }}
                                            />
                                            <Text weight="semibold" content={item.commented_by_name === state.userInfo.userManagerData.user_details[0].CompName ? "Self" : item.commented_by_name} />
                                            {item.id === editableId.isActive ?
                                                <Flex gap="gap.medium" fill>
                                                    <Input
                                                        value={editableId.comment}
                                                        fluid
                                                        onChange={(e: any) => setEditableId({ ...editableId, comment: e.target.value.trimStart() })}
                                                    />
                                                    <FlexItem>
                                                        <Flex gap="gap.small" vAlign="center">
                                                            <AcceptIcon size="small" className="pointer" disabled={editableId?.comment?.length === 0} onClick={() => editableId?.comment?.length !== 0 && feedbackEditValue("accept", item)} />
                                                            <CloseIcon size="small" className="pointer" onClick={() => feedbackEditValue("reject", item)} />
                                                        </Flex>
                                                    </FlexItem>
                                                </Flex>
                                                : <Text content={item.comment} />
                                            }
                                        </Flex>
                                        <Flex>
                                            {
                                                item.status === "4" ?
                                                    <AcceptIcon size="small" styles={{ color: "#407855" }} />
                                                    : item.status === "5" ?
                                                        <Flex>
                                                            <AcceptIcon size="small" styles={{ color: "#407855", marginRight: "0px !important", position: "relative", left: "12px", bottom: "4px" }} />
                                                            <AcceptIcon size="small" styles={{ color: "#407855" }} />
                                                        </Flex>
                                                        :
                                                        <></>
                                                // <CFMMenuComponent item={item} editableId={editableId} setEditableId={setEditableId} managerView={managerView} QueryData={QueryData}/>
                                            }
                                        </Flex>
                                    </Flex>
                                );
                            })
                        ) : recentFeedback && recentFeedback.length === 0 ? (
                            <Flex
                                fill
                                vAlign="center"
                                hAlign="center"
                                padding="padding.medium"
                            >
                                <Text
                                    content="No Recent Feedback"
                                    size="large"
                                    weight="semibold"
                                />
                            </Flex>
                        ) : (
                            <Box>
                                {[1, 2].map((item) => {
                                    return (
                                        <Skeleton animation="pulse" key={item}>
                                            <Flex
                                                gap="gap.medium"
                                                styles={{ background: "#fefefe" }}
                                            >
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                            </Flex>
                                        </Skeleton>
                                    );
                                })}
                            </Box>
                        )}
                    </Flex>}
                    <Flex column gap="gap.medium">
                        <Text weight="semibold" content="Add Feedback" />
                        <TextArea
                            placeholder="Add your feedback..."
                            value={comment.value.trimStart()}
                            rows={6}
                            // error={submitHandler()}
                            error={comment.value.length >= 300}
                            maxLength={300}
                            onChange={(e: any) => {
                                setComment({ key: e.target && (e.target["value"].trim() as string), value: e.target && (e.target["value"]) });
                            }}
                            fluid
                            disabled={isPreview}
                        />
                        {
                            comment.value.length >= 300 && <Text error content="Feedback should not be greater than 300 characters" />
                        }
                        {
                            isPreview && <Flex fill vAlign="center" hAlign="center">
                                <Text weight="semibold" color="brand" content={managerView ? Constant.FEEDBACK_CONFRIM : Constant.FEEDBACK_CONFRIM_EMP} />
                            </Flex>
                        }
                    </Flex>
                    {!isPreview && managerView && <Flex hAlign="end" vAlign="start">
                        <Button text size="small" content="Consider" className="textStyle" primary={preDefinedText === "Consider"} onClick={() => onClickPreDefine("Consider")} styles={preDefinedText === "Consider" ? { textDecoration: "underline", minWidth: "1rem" } : { minWidth: "1rem" }} />
                        <Button text size="small" content="Continue" className="textStyle" primary={preDefinedText === "Continue"} onClick={() => onClickPreDefine("Continue")} styles={preDefinedText === "Continue" ? { textDecoration: "underline", minWidth: "1rem" } : { minWidth: "1rem" }} />
                    </Flex>}
                </Flex>
                <Flex hAlign="end" vAlign="end" gap="gap.small">
                    <Button content="Back" onClick={(e) => isPreview ? setIsPreview(false) : handler[1](false)} />
                    <Button content={isPreview ? "Submit" : "Confirm"} loading=
                        {state.cfmData.addCompetencyCommentData.isLoading ||
              state.cfmData.addGoalCommentData.isLoading} primary disabled={submitHandler()} onClick={(e) => isPreview ? addComment() : setIsPreview(true)} />
                </Flex>
            </Flex>
        </React.Fragment>
    );
};

export default Feedback;
