import {
  errorAction,
  loadingAction,
  successSideEffectState,
  SideEffectSchema,
} from ".";

export const Actions = {
  GET_USER_DETAILS_DATA: "GET_USER_DETAILS_DATA",
  GET_USER_DETAILS_LOADING: " GET_USER_DETAILS_LOADING",
  GET_USER_DETAILS_ERROR: " GET_USER_DETAILS_ERROR",
  GET_USER_DETAILS_SUCCESS: " GET_USER_DETAILS_SUCCESS",

  GET_USER_ROLES_ASSIGN_DATA: "GET_USER_ROLES_ASSIGN_DATA",
  GET_USER_ROLES_ASSIGN_LOADING: " GET_USER_ROLES_ASSIGN_LOADING",
  GET_USER_ROLES_ASSIGN_ERROR: " GET_USER_ROLES_ASSIGN_ERROR",
  GET_USER_ROLES_ASSIGN_SUCCESS: " GET_USER_ROLES_ASSIGN_SUCCESS",
  GET_USER_ROLES_ASSIGN_CLEAR: " GET_USER_ROLES_ASSIGN_CLEAR",

  GET_USER_SYSTEM_DETAILS_DATA: "GET_USER_SYSTEM_DETAILS_DATA",
  GET_USER_SYSTEM_DETAILS_LOADING: " GET_USER_SYSTEM_DETAILS_LOADING",
  GET_USER_SYSTEM_DETAILS_ERROR: " GET_USER_SYSTEM_DETAILS_ERROR",
  GET_USER_SYSTEM_DETAILS_SUCCESS: " GET_USER_SYSTEM_DETAILS_SUCCESS",

  GET_SYSTEM_DESCRIPTION_DATA: "GET_SYSTEM_DESCRIPTION_DATA",
  GET_SYSTEM_DESCRIPTION_LOADING: " GET_SYSTEM_DESCRIPTION_LOADING",
  GET_SYSTEM_DESCRIPTION_ERROR: " GET_SYSTEM_DESCRIPTION_ERROR",
  GET_SYSTEM_DESCRIPTION_SUCCESS: " GET_SYSTEM_DESCRIPTION_SUCCESS",

  GET_REQ_USER_DETAILS_DATA: "GET_REQ_USER_DETAILS_DATA",
  GET_REQ_USER_DETAILS_LOADING: " GET_REQ_USER_DETAILS_LOADING",
  GET_REQ_USER_DETAILS_ERROR: " GET_REQ_USER_DETAILS_ERROR",
  GET_REQ_USER_DETAILS_SUCCESS: " GET_REQ_USER_DETAILS_SUCCESS",

  GET_NO_SYSTEM_ASSIGNED_USER_DATA: "GET_NO_SYSTEM_ASSIGNED_USER_DATA",
  GET_NO_SYSTEM_ASSIGNED_USER_LOADING: " GET_NO_SYSTEM_ASSIGNED_USER_LOADING",
  GET_NO_SYSTEM_ASSIGNED_USER_ERROR: " GET_NO_SYSTEM_ASSIGNED_USER_ERROR",
  GET_NO_SYSTEM_ASSIGNED_USER_SUCCESS: " GET_NO_SYSTEM_ASSIGNED_USER_SUCCESS",

  POST_USER_CREATE_DATA: "POST_USER_CREATE_DATA",
  POST_USER_CREATE_LOADING: " POST_USER_CREATE_LOADING",
  POST_USER_CREATE_ERROR: " POST_USER_CREATE_ERROR",
  POST_USER_CREATE_SUCCESS: " POST_USER_CREATE_SUCCESS",
  POST_USER_CREATE_CLEAR: " POST_USER_CREATE_CLEAR",

  GET_APPROVER_INBOX_DATA: "GET_APPROVER_INBOX_DATA",
  GET_APPROVER_INBOX_LOADING: " GET_APPROVER_INBOX_LOADING",
  GET_APPROVER_INBOX_ERROR: " GET_APPROVER_INBOX_ERROR",
  GET_APPROVER_INBOX_SUCCESS: " GET_APPROVER_INBOX_SUCCESS",

  GET_FINDING_T_CODES_ROLE_DATA: "GET_FINDING_T_CODES_ROLE_DATA",
  GET_FINDING_T_CODES_ROLE_LOADING: " GET_FINDING_T_CODES_ROLE_LOADING",
  GET_FINDING_T_CODES_ROLE_ERROR: " GET_FINDING_T_CODES_ROLE_ERROR",
  GET_FINDING_T_CODES_ROLE_SUCCESS: " GET_FINDING_T_CODES_ROLE_SUCCESS",
  GET_FINDING_T_CODES_ROLE_CLEAR: " GET_FINDING_T_CODES_ROLE_CLEAR",

  GET_FINDING_ORG_VALUES_ROLE_DATA: "GET_FINDING_ORG_VALUES_ROLE_DATA",
  GET_FINDING_ORG_VALUES_ROLE_LOADING: " GET_FINDING_ORG_VALUES_ROLE_LOADING",
  GET_FINDING_ORG_VALUES_ROLE_ERROR: " GET_FINDING_ORG_VALUES_ROLE_ERROR",
  GET_FINDING_ORG_VALUES_ROLE_SUCCESS: " GET_FINDING_ORG_VALUES_ROLE_SUCCESS",
  GET_FINDING_ORG_VALUES_ROLE_CLEAR: " GET_FINDING_ORG_VALUES_ROLE_CLEAR",

  GET_ROLE_OWNER_ROLE_NAME_DATA: "GET_ROLE_OWNER_ROLE_NAME_DATA",
  GET_ROLE_OWNER_ROLE_NAME_LOADING: " GET_ROLE_OWNER_ROLE_NAME_LOADING",
  GET_ROLE_OWNER_ROLE_NAME_ERROR: " GET_ROLE_OWNER_ROLE_NAME_ERROR",
  GET_ROLE_OWNER_ROLE_NAME_SUCCESS: " GET_ROLE_OWNER_ROLE_NAME_SUCCESS",

  GET_LIST_BUSINESS_PROCESS_DATA: "GET_LIST_BUSINESS_PROCESS_DATA",
  GET_LIST_BUSINESS_PROCESS_LOADING: " GET_LIST_BUSINESS_PROCESS_LOADING",
  GET_LIST_BUSINESS_PROCESS_ERROR: " GET_LIST_BUSINESS_PROCESS_ERROR",
  GET_LIST_BUSINESS_PROCESS_SUCCESS: " GET_LIST_BUSINESS_PROCESS_SUCCESS",

  GET_LIST_ROLE_ASSIGNED_TO_OWNER_DATA: "GET_LIST_ROLE_ASSIGNED_TO_OWNER_DATA",
  GET_LIST_ROLE_ASSIGNED_TO_OWNER_LOADING:
    " GET_LIST_ROLE_ASSIGNED_TO_OWNER_LOADING",
  GET_LIST_ROLE_ASSIGNED_TO_OWNER_ERROR:
    " GET_LIST_ROLE_ASSIGNED_TO_OWNER_ERROR",
  GET_LIST_ROLE_ASSIGNED_TO_OWNER_SUCCESS:
    " GET_LIST_ROLE_ASSIGNED_TO_OWNER_SUCCESS",
  GET_LIST_ROLE_ASSIGNED_TO_OWNER_CLEAR:
    " GET_LIST_ROLE_ASSIGNED_TO_OWNER_CLEAR",

  POST_API_FOR_APPROVE_REQ_DATA: "POST_API_FOR_APPROVE_REQ_DATA",
  POST_API_FOR_APPROVE_REQ_LOADING: " POST_API_FOR_APPROVE_REQ_LOADING",
  POST_API_FOR_APPROVE_REQ_ERROR: " POST_API_FOR_APPROVE_REQ_ERROR",
  POST_API_FOR_APPROVE_REQ_SUCCESS: " POST_API_FOR_APPROVE_REQ_SUCCESS",

  GET_SYSTEM_TYPE_DATA: "GET_SYSTEM_TYPE_DATA",
  GET_SYSTEM_TYPE_LOADING: " GET_SYSTEM_TYPE_LOADING",
  GET_SYSTEM_TYPE_ERROR: " GET_SYSTEM_TYPE_ERROR",
  GET_SYSTEM_TYPE_SUCCESS: " GET_SYSTEM_TYPE_SUCCESS",

  GET_FUNCTION_DATA: "GET_FUNCTION_DATA",
  GET_FUNCTION_LOADING: " GET_FUNCTION_LOADING",
  GET_FUNCTION_ERROR: " GET_FUNCTION_ERROR",
  GET_FUNCTION_SUCCESS: " GET_FUNCTION_SUCCESS",

  GET_SUB_FUNCTION_DATA: "GET_SUB_FUNCTION_DATA",
  GET_SUB_FUNCTION_LOADING: " GET_SUB_FUNCTION_LOADING",
  GET_SUB_FUNCTION_ERROR: " GET_SUB_FUNCTION_ERROR",
  GET_SUB_FUNCTION_SUCCESS: " GET_SUB_FUNCTION_SUCCESS",

  GET_DEPARTMENT_DATA: "GET_DEPARTMENT_DATA",
  GET_DEPARTMENT_LOADING: " GET_DEPARTMENT_LOADING",
  GET_DEPARTMENT_ERROR: " GET_DEPARTMENT_ERROR",
  GET_DEPARTMENT_SUCCESS: " GET_DEPARTMENT_SUCCESS",

  GET_SUB_DEPARTMENT_DATA: "GET_SUB_DEPARTMENT_DATA",
  GET_SUB_DEPARTMENT_LOADING: " GET_SUB_DEPARTMENT_LOADING",
  GET_SUB_DEPARTMENT_ERROR: " GET_SUB_DEPARTMENT_ERROR",
  GET_SUB_DEPARTMENT_SUCCESS: " GET_SUB_DEPARTMENT_SUCCESS",

  GET_ROLE_OWNER_DATA: "GET_ROLE_OWNER_DATA",
  GET_ROLE_OWNER_LOADING: " GET_ROLE_OWNER_LOADING",
  GET_ROLE_OWNER_ERROR: " GET_ROLE_OWNER_ERROR",
  GET_ROLE_OWNER_SUCCESS: " GET_ROLE_OWNER_SUCCESS",

  GET_ROLE_DATA: "GET_ROLE_DATA",
  GET_ROLE_LOADING: " GET_ROLE_LOADING",
  GET_ROLE_ERROR: " GET_ROLE_ERROR",
  GET_ROLE_SUCCESS: " GET_ROLE_SUCCESS",

  POST_SOD_CONFLICT_DATA: "POST_SOD_CONFLICT_DATA",
  POST_SOD_CONFLICT_LOADING: " POST_SOD_CONFLICT_LOADING",
  POST_SOD_CONFLICT_ERROR: " POST_SOD_CONFLICT_ERROR",
  POST_SOD_CONFLICT_SUCCESS: " POST_SOD_CONFLICT_SUCCESS",
  POST_SOD_CONFLICT_CLEAR: " POST_SOD_CONFLICT_CLEAR",
};

// =======================================================================================================
export interface IGetUserDetailsItem {
  SAP_ID: string;
  SYSTEM: string;
  SYSTEM_DESCRIPTION: string;
  VALIDITY: string;
  USER_NAME: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  DEPARTMENT: "";
  STATUS: string;
}
[];

export interface IGetUserDetailspayload {
  sap_client: string;
  lv_email: string;
}

export interface IGetUserDetailsData extends SideEffectSchema {
  data: IGetUserDetailsItem[];
}

export const getUserDetailsAction = (query: IGetUserDetailspayload) => {
  return {
    type: Actions.GET_USER_DETAILS_DATA,
    payload: query,
  };
};
export const getUserDetailsLoadingAction = () =>
  loadingAction(Actions.GET_USER_DETAILS_LOADING);

export const getUserDetailsErrorAction = (error: string) =>
  errorAction(Actions.GET_USER_DETAILS_ERROR, error);

export const getUserDetailsSuccessAction = (data: any) => {
  const payload: IGetUserDetailsData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetUserRolesAssignItem {
  USER_ID: string;
  SYSTEM_ID: string;
  ROLE_NAME: string;
  ROLE_DESC: "";
  VALID_FROM: number;
  VALID_TO: number;
  ROLE_OWNER_ID: "";
  ROLE_OWNER_NAME: "";
}
[];

export interface IGetUserRolesAssignpayload {
  sap_client: string;
  User_ID: string;
  // CLIENTID: string,
  System_ID: string;
}

export interface IGetUserRolesAssignData extends SideEffectSchema {
  data: IGetUserRolesAssignItem[];
}

export const getUserRolesAssignAction = (query: IGetUserRolesAssignpayload) => {
  return {
    type: Actions.GET_USER_ROLES_ASSIGN_DATA,
    payload: query,
  };
};
export const getUserRolesAssignLoadingAction = () =>
  loadingAction(Actions.GET_USER_ROLES_ASSIGN_LOADING);

export const getUserRolesAssignErrorAction = (error: string) =>
  errorAction(Actions.GET_USER_ROLES_ASSIGN_ERROR, error);

export const getUserRolesAssignSuccessAction = (data: any) => {
  const payload: IGetUserRolesAssignData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_USER_ROLES_ASSIGN_SUCCESS,
    payload: payload,
  };
};

export const getUserRolesAssignClearAction = () => {
  return {
    type: Actions.GET_USER_ROLES_ASSIGN_CLEAR,
    payload: { isSuccess: false, data: [] },
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetUserSystemDetailsItem {
  USER_ID: string;
  SYSTEM_ID: string;
  SYSTEM_DESCRIPTION: string;
  LOCK_STATUS: "";
  VALIDITY: string;
}

export interface IGetUserSystemDetailspayload {
  sap_client: string;
  EmailID: string;
}

export interface IGetUserSystemDetailsData extends SideEffectSchema {
  data: IGetUserSystemDetailsItem[];
}

export const getUserSystemDetailsAction = (
  query: IGetUserSystemDetailspayload
) => {
  return {
    type: Actions.GET_USER_SYSTEM_DETAILS_DATA,
    payload: query,
  };
};
export const getUserSystemDetailsLoadingAction = () =>
  loadingAction(Actions.GET_USER_SYSTEM_DETAILS_LOADING);

export const getUserSystemDetailsErrorAction = (error: string) =>
  errorAction(Actions.GET_USER_SYSTEM_DETAILS_ERROR, error);

export const getUserSystemDetailsSuccessAction = (data: any) => {
  const payload: IGetUserSystemDetailsData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_USER_SYSTEM_DETAILS_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetSystemDescriptionItem {
  CONNECTOR: string;
  RFCDOC1: string;
  SYS_ID: "";
  CLIENT_ID: "";
}
[];

export interface IGetSystemDescriptionpayload {
  sap_client: string;
}

export interface IGetSystemDescriptionData extends SideEffectSchema {
  data: IGetSystemDescriptionItem[];
}

export const getSystemDescriptionAction = (
  query: IGetSystemDescriptionpayload
) => {
  return {
    type: Actions.GET_SYSTEM_DESCRIPTION_DATA,
    payload: query,
  };
};
export const getSystemDescriptionLoadingAction = () =>
  loadingAction(Actions.GET_SYSTEM_DESCRIPTION_LOADING);

export const getSystemDescriptionErrorAction = (error: string) =>
  errorAction(Actions.GET_SYSTEM_DESCRIPTION_ERROR, error);

export const getSystemDescriptionSuccessAction = (data: any) => {
  const payload: IGetSystemDescriptionData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_SYSTEM_DESCRIPTION_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetReqUserDetailsItem {
  REQ_NO: string;
  REQ_STATUS: "";
  REQ_TYPE: number;
  APPROVER_USER: "";
}
[];

export interface IGetReqUserDetailspayload {
  sap_client: string;
  USERID: string;
}
export interface IGetReqUserDetailsData extends SideEffectSchema {
  data: IGetReqUserDetailsItem[];
}

export const getReqUserDetailsAction = (query: IGetReqUserDetailspayload) => {
  return {
    type: Actions.GET_REQ_USER_DETAILS_DATA,
    payload: query,
  };
};
export const getReqUserDetailsLoadingAction = () =>
  loadingAction(Actions.GET_REQ_USER_DETAILS_LOADING);

export const getReqUserDetailsErrorAction = (error: string) =>
  errorAction(Actions.GET_REQ_USER_DETAILS_ERROR, error);

export const getReqUserDetailsSuccessAction = (data: any) => {
  const payload: IGetReqUserDetailsData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_REQ_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetNoSystemAssignedUserItem {
  USER_ID: string;
  NO_OF_SYSTEMS: number;
}
export interface IGetNoSystemAssignedUserpayload {
  sap_client: string;
  USERID: string;
}

export interface IGetNoSystemAssignedUserData extends SideEffectSchema {
  data: IGetNoSystemAssignedUserItem;
}

export const getNoSystemAssignedUserAction = (
  query: IGetNoSystemAssignedUserpayload
) => {
  return {
    type: Actions.GET_NO_SYSTEM_ASSIGNED_USER_DATA,
    payload: query,
  };
};
export const getNoSystemAssignedUserLoadingAction = () =>
  loadingAction(Actions.GET_NO_SYSTEM_ASSIGNED_USER_LOADING);

export const getNoSystemAssignedUserErrorAction = (error: string) =>
  errorAction(Actions.GET_NO_SYSTEM_ASSIGNED_USER_ERROR, error);

export const getNoSystemAssignedUserSuccessAction = (data: any) => {
  const payload: IGetNoSystemAssignedUserData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_NO_SYSTEM_ASSIGNED_USER_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
//todo
export interface CustomData {
  FIELDNAME: string;
  VALUE: string;
}

export interface ItemData {
  ROLE_NAME: string;
  CONNECTOR: string;
}
export interface IPostUserCreatepayload {
  REQTYPE: string;
  REQUESTORID: string;
  USERID: string;
  BPROC: string;
  FNAME: string;
  LNAME: string;
  MANAGER: string;
  PERSONNELNO: string;
  EMPTYPE: string;
  COMPANY: string;
  DEPARTMENT: string;
  EMAIL: string;
  LOCATION: string;
  USER_TYPE: string;
  REQUEST_REASON: string;
  CUSTOM_DATA: CustomData[];
  ITEM_DATA: ItemData[];
}

export interface IPostUserCreateData extends SideEffectSchema {
  data: {
    REQUEST_NO: string;
    REQUEST_ID: string;
    MSG_RETURN: {
      MSG_NO: string;
      MSG_TYPE: string;
      MSG_STATEMENT: string;
    };
  };
}

export const postUserCreateAction = (
  userPayload: IPostUserCreatepayload,
  sap_client: string
) => {
  return {
    type: Actions.POST_USER_CREATE_DATA,
    payload: { userPayload, sap_client },
  };
};
export const postUserCreateLoadingAction = () =>
  loadingAction(Actions.POST_USER_CREATE_LOADING);

export const postUserCreateErrorAction = (error: string) =>
  errorAction(Actions.POST_USER_CREATE_ERROR, error);

export const postUserCreateSuccessAction = (data: any) => {
  const payload: IPostUserCreateData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.POST_USER_CREATE_SUCCESS,
    payload: payload,
  };
};
export const postUserCreateClearAction = () => {
  return {
    type: Actions.POST_USER_CREATE_CLEAR,
    payload: { isSuccess: false, data: [] },
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetApproverInboxItem {
  REQ_NO: string;
  REQ_TYPE: number;
  REQ_TYPE_TEXT: string;
  REQ_RAISED_BY: string;
  REQ_AGE: number;
}
[];
export interface IGetApproverInboxPayload {
  sap_client: string;
  userid: string;
}
export interface IGetApproverInboxData extends SideEffectSchema {
  data: IGetApproverInboxItem[];
}

export const getApproverInboxAction = (query: IGetApproverInboxPayload) => {
  return {
    type: Actions.GET_APPROVER_INBOX_DATA,
    payload: query,
  };
};
export const getApproverInboxLoadingAction = () =>
  loadingAction(Actions.GET_APPROVER_INBOX_LOADING);

export const getApproverInboxErrorAction = (error: string) =>
  errorAction(Actions.GET_APPROVER_INBOX_ERROR, error);

export const getApproverInboxSuccessAction = (data: any) => {
  const payload: IGetApproverInboxData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_APPROVER_INBOX_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetFindingTCodesRoleItem {
  CONNECTOR: string;
  ROLE_NAME: string;
  ROLE_DESC: string;
  TCODE: string;
}
export interface IGetFindingTCodesRolepayload {
  sap_client: string;
  TCODE: string;
  CONNECTOR: string;
}
export interface IGetFindingTCodesRoleData extends SideEffectSchema {
  data: IGetFindingTCodesRoleItem[];
}

export const getFindingTCodesRoleAction = (
  query: IGetFindingTCodesRolepayload
) => {
  return {
    type: Actions.GET_FINDING_T_CODES_ROLE_DATA,
    payload: query,
  };
};
export const getFindingTCodesRoleLoadingAction = () =>
  loadingAction(Actions.GET_FINDING_T_CODES_ROLE_LOADING);

export const getFindingTCodesRoleErrorAction = (error: string) =>
  errorAction(Actions.GET_FINDING_T_CODES_ROLE_ERROR, error);

export const getFindingTCodesRoleSuccessAction = (data: any) => {
  const payload: IGetFindingTCodesRoleData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_FINDING_T_CODES_ROLE_SUCCESS,
    payload: payload,
  };
};

export const getFindingTCodesRoleClearAction = () => {
  return {
    type: Actions.GET_FINDING_T_CODES_ROLE_CLEAR,
    payload: { isSuccess: false, data: [] },
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////
//todo
export interface IGetFindingOrgValuesRoleItem {
  CONNECTOR: "";
  ROLE_NAME: string;
  ROLE_DESC: string;
  OWNER_NAME: string;
}
[];

export interface IGetFindingOrgValuesRolepayload {
  sap_client: string;
  Function: string;
  Subfunction: string;
  Department: string;
  Subdepartment: string;
  RoleName: string;
  RoleOwner: string;
}

export interface IGetFindingOrgValuesRoleData extends SideEffectSchema {
  data: IGetFindingOrgValuesRoleItem[];
}

export const getFindingOrgValuesRoleAction = (
  query: IGetFindingOrgValuesRolepayload
) => {
  return {
    type: Actions.GET_FINDING_ORG_VALUES_ROLE_DATA,
    payload: query,
  };
};
export const getFindingOrgValuesRoleLoadingAction = () =>
  loadingAction(Actions.GET_FINDING_ORG_VALUES_ROLE_LOADING);

export const getFindingOrgValuesRoleErrorAction = (error: string) =>
  errorAction(Actions.GET_FINDING_ORG_VALUES_ROLE_ERROR, error);

export const getFindingOrgValuesRoleSuccessAction = (data: any) => {
  const payload: IGetFindingOrgValuesRoleData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_FINDING_ORG_VALUES_ROLE_SUCCESS,
    payload: payload,
  };
};

export const getFindingOrgValuesRoleClearAction = () => {
  return {
    type: Actions.GET_FINDING_ORG_VALUES_ROLE_CLEAR,
    payload: { isSuccess: false, data: [] },
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetRoleOwnerRoleNameItem {
  CONNECTOR: string;
  ROLE_NAME: string;
  ROLE_DESC: string;
  OWNER_ID: string;
  OWNER_NAME: "";
}
export interface IGetRoleOwnerRoleNamepayload {
  sap_client: string;
  Connector: string;
  Rolename: string;
}
export interface IGetRoleOwnerRoleNameData extends SideEffectSchema {
  data: IGetRoleOwnerRoleNameItem[];
}

export const getRoleOwnerRoleNameAction = (
  query: IGetRoleOwnerRoleNamepayload
) => {
  return {
    type: Actions.GET_ROLE_OWNER_ROLE_NAME_DATA,
    payload: query,
  };
};
export const getRoleOwnerRoleNameLoadingAction = () =>
  loadingAction(Actions.GET_ROLE_OWNER_ROLE_NAME_LOADING);

export const getRoleOwnerRoleNameErrorAction = (error: string) =>
  errorAction(Actions.GET_ROLE_OWNER_ROLE_NAME_ERROR, error);

export const getRoleOwnerRoleNameSuccessAction = (data: any) => {
  const payload: IGetRoleOwnerRoleNameData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_ROLE_OWNER_ROLE_NAME_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
//todo

export interface IGetListBusinessProcessItem {
  BPROCESS: string;
  BPDESC: string;
  SBPROCESS: string;
  SBDESC: string;
}
[];

export interface IGetListBusinessProcesspayload {
  sap_client: string;
}
export interface IGetListBusinessProcessData extends SideEffectSchema {
  data: IGetListBusinessProcessItem[];
}

export const getListBusinessProcessAction = (
  query: IGetListBusinessProcesspayload
) => {
  return {
    type: Actions.GET_LIST_BUSINESS_PROCESS_DATA,
    payload: query,
  };
};
export const getListBusinessProcessLoadingAction = () =>
  loadingAction(Actions.GET_LIST_BUSINESS_PROCESS_LOADING);

export const getListBusinessProcessErrorAction = (error: string) =>
  errorAction(Actions.GET_LIST_BUSINESS_PROCESS_ERROR, error);

export const getListBusinessProcessSuccessAction = (data: any) => {
  const payload: IGetListBusinessProcessData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_LIST_BUSINESS_PROCESS_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetListRoleAssignedToOwnerItem {
  CONNECTOR: "";
  OWNER_ID: string;
  OWNER_NAME: string;
  ROLE_NAME: "";
  ROLE_DESC: "";
}

export interface IGetListRoleAssignedToOwnerpayload {
  sap_client: string;
  Roleowner: string;
}

export interface IGetListRoleAssignedToOwnerData extends SideEffectSchema {
  data: IGetListRoleAssignedToOwnerItem[];
}

export const getListRoleAssignedToOwnerAction = (
  query: IGetListRoleAssignedToOwnerpayload
) => {
  return {
    type: Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_DATA,
    payload: query,
  };
};
export const getListRoleAssignedToOwnerLoadingAction = () =>
  loadingAction(Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_LOADING);

export const getListRoleAssignedToOwnerErrorAction = (error: string) =>
  errorAction(Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_ERROR, error);

export const getListRoleAssignedToOwnerSuccessAction = (data: any) => {
  const payload: IGetListRoleAssignedToOwnerData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_SUCCESS,
    payload: payload,
  };
};

export const getListRoleAssignedToOwnerClearAction = () => {
  return {
    type: Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_CLEAR,
    payload: { isSuccess: false, data: [] },
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////
export interface ILogForAppreqItem {
  REQ_NO: "";
  APPROVER: "";
  APPROVE_REJECT: "";
  NOTES: "";
}

export interface ILogForAppreqpayload {
  sap_client: string;
  Reqno: string;
  Approver: string;
}

export interface ILogForAppreq {
  data: ILogForAppreqItem[];
}
export interface IPostApiForApproveReqData extends SideEffectSchema {
  ERROR: string;
  Log: ILogForAppreq[];
}
[];

export const postApiForApproveReqAction = (query: ILogForAppreqpayload) => {
  return {
    type: Actions.POST_API_FOR_APPROVE_REQ_DATA,
    payload: query,
  };
};
export const postApiForApproveReqLoadingAction = () =>
  loadingAction(Actions.POST_API_FOR_APPROVE_REQ_LOADING);

export const postApiForApproveReqErrorAction = (error: string) =>
  errorAction(Actions.POST_API_FOR_APPROVE_REQ_ERROR, error);

export const postApiForApproveReqSuccessAction = (data: any) => {
  const payload: IPostApiForApproveReqData = {
    ...data,
    ...successSideEffectState,
  };

  return {
    type: Actions.POST_API_FOR_APPROVE_REQ_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetSystemTypeItem {
  id: number;
  name: string;
}

export interface IGetSystemTypepayload {
  sap_client: string;
}

export interface IGetSystemTypeData extends SideEffectSchema {
  data: IGetSystemTypeItem[];
}

export const getSystemTypeAction = (query: IGetSystemTypepayload) => {
  return {
    type: Actions.GET_SYSTEM_TYPE_DATA,
    payload: query,
  };
};
export const getSystemTypeLoadingAction = () =>
  loadingAction(Actions.GET_SYSTEM_TYPE_LOADING);

export const getSystemTypeErrorAction = (error: string) =>
  errorAction(Actions.GET_SYSTEM_TYPE_ERROR, error);

export const getSystemTypeSuccessAction = (data: any) => {
  const payload: IGetSystemTypeData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_SYSTEM_TYPE_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetFunctionItem {
  id: number;
  name: string;
  active: boolean;
}

export interface IGetFunctionpayload {
  sap_client: string;
}

export interface IGetFunctionData extends SideEffectSchema {
  data: IGetFunctionItem[];
}

export const getFunctionAction = (query: IGetFunctionpayload) => {
  return {
    type: Actions.GET_FUNCTION_DATA,
    payload: query,
  };
};
export const getFunctionLoadingAction = () =>
  loadingAction(Actions.GET_FUNCTION_LOADING);

export const getFunctionErrorAction = (error: string) =>
  errorAction(Actions.GET_FUNCTION_ERROR, error);

export const getFunctionSuccessAction = (data: any) => {
  const payload: IGetFunctionData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_FUNCTION_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetSubFunctionItem {
  id: number;
  function_id: number;
  name: string;
  active: boolean;
}
export interface IGetSubFunctionpayload {
  sap_client: string;
  functionId: string;
}

export interface IGetSubFunctionData extends SideEffectSchema {
  data: IGetSubFunctionItem[];
}

export const getSubFunctionAction = (query: IGetSubFunctionpayload) => {
  return {
    type: Actions.GET_SUB_FUNCTION_DATA,
    payload: query,
  };
};
export const getSubFunctionLoadingAction = () =>
  loadingAction(Actions.GET_SUB_FUNCTION_LOADING);

export const getSubFunctionErrorAction = (error: string) =>
  errorAction(Actions.GET_SUB_FUNCTION_ERROR, error);

export const getSubFunctionSuccessAction = (data: any) => {
  const payload: IGetSubFunctionData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_SUB_FUNCTION_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetDepartmentItem {
  id: number;
  sub_function_id: number;
  name: string;
  active: boolean;
}
export interface IGetDepartmentpayload {
  sap_client: string;
  subFunctionId: string;
}

export interface IGetDepartmentData extends SideEffectSchema {
  data: IGetDepartmentItem[];
}

export const getDepartmentAction = (query: IGetDepartmentpayload) => {
  return {
    type: Actions.GET_DEPARTMENT_DATA,
    payload: query,
  };
};
export const getDepartmentLoadingAction = () =>
  loadingAction(Actions.GET_DEPARTMENT_LOADING);

export const getDepartmentErrorAction = (error: string) =>
  errorAction(Actions.GET_DEPARTMENT_ERROR, error);

export const getDepartmentSuccessAction = (data: any) => {
  const payload: IGetDepartmentData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetSubDepartmentItem {
  id: number;
  department_id: number;
  name: string;
  active: boolean;
}
export interface IGetSubDepartmentpayload {
  sap_client: string;
  departmentId: string;
}

export interface IGetSubDepartmentData extends SideEffectSchema {
  data: IGetSubDepartmentItem[];
}
export const getSubDepartmentAction = (query: IGetSubDepartmentpayload) => {
  return {
    type: Actions.GET_SUB_DEPARTMENT_DATA,
    payload: query,
  };
};
export const getSubDepartmentLoadingAction = () =>
  loadingAction(Actions.GET_SUB_DEPARTMENT_LOADING);

export const getSubDepartmentErrorAction = (error: string) =>
  errorAction(Actions.GET_SUB_DEPARTMENT_ERROR, error);

export const getSubDepartmentSuccessAction = (data: any) => {
  const payload: IGetSubDepartmentData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_SUB_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetRoleOwnerItem {
  id: number;
  name: string;
}
export interface IGetRoleOwnerpayload {
  sap_client: string;
}

export interface IGetRoleOwnerData extends SideEffectSchema {
  data: IGetRoleOwnerItem[];
}

export const getRoleOwnerAction = (query: IGetRoleOwnerpayload) => {
  return {
    type: Actions.GET_ROLE_OWNER_DATA,
    payload: query,
  };
};
export const getRoleOwnerLoadingAction = () =>
  loadingAction(Actions.GET_ROLE_OWNER_LOADING);

export const getRoleOwnerErrorAction = (error: string) =>
  errorAction(Actions.GET_ROLE_OWNER_ERROR, error);

export const getRoleOwnerSuccessAction = (data: any) => {
  const payload: IGetRoleOwnerData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_ROLE_OWNER_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IGetRoleItem {
  id: number;
  name: string;
}
export interface IGetRolepayload {
  sap_client: string;
}

export interface IGetRoleData extends SideEffectSchema {
  data: IGetRoleItem[];
}
export const getRoleAction = (query: IGetRolepayload) => {
  return {
    type: Actions.GET_ROLE_DATA,
    payload: query,
  };
};
export const getRoleLoadingAction = () =>
  loadingAction(Actions.GET_ROLE_LOADING);

export const getRoleErrorAction = (error: string) =>
  errorAction(Actions.GET_ROLE_ERROR, error);

export const getRoleSuccessAction = (data: any) => {
  const payload: IGetRoleData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.GET_ROLE_SUCCESS,
    payload: payload,
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
export interface IPostSodConflictItemMSG {
  TYPE: string;
  ID: string;
  NUMBER: number;
  MESSAGE: string;
  LOG_NO: string;
  LOG_MSG_NO: number;
  MESSAGE_V1: string;
  MESSAGE_V2: string;
  MESSAGE_V3: string;
  MESSAGE_V4: string;
  PARAMETER: string;
  ROW: number;
  FIELD: string;
  SYSTEM: string;
}
export interface IPostSodConflictItem {
  ET_ACT_VIOL_SUM: [];
  ET_ACT_VIOL_DET: [];
  ET_ACT_MGMT_SUM: [];
  ET_ACT_EXEC_SUM: [];
  EV_RETURN_MSG: IPostSodConflictItemMSG;
}
export interface IPostSodConflictPayload {
  sap_client: string;
  sod_payload: {
    IT_SYS_RANGE: {
      SIGN: string;
      OPTION: string;
      LOW: string;
      HIGH: string;
    };
    IT_OBJECT_RANGE: {
      SIGN: string;
      OPTION: string;
      LOW: string;
      HIGH: string;
    }[];
    IV_OBJECT_TYPE: number;
    IT_REPORT_TYPE: {
      REPTYPE: number;
    }[];
    IV_SIMU_RISK_ONLY: string;
    IV_ANALYSIS_MODE: number;
  };
}

export interface IPostSodConflictData extends SideEffectSchema {
  data: IPostSodConflictItem[];
}
export const postSodConflictAction = (query: IPostSodConflictPayload) => {
  return {
    type: Actions.POST_SOD_CONFLICT_DATA,
    payload: query,
  };
};
export const postSodConflictLoadingAction = () =>
  loadingAction(Actions.POST_SOD_CONFLICT_LOADING);

export const postSodConflictErrorAction = (error: string) =>
  errorAction(Actions.POST_SOD_CONFLICT_ERROR, error);

export const postSodConflictSuccessAction = (data: any) => {
  const payload: IPostSodConflictData = {
    ...{ data: data },
    ...successSideEffectState,
  };

  return {
    type: Actions.POST_SOD_CONFLICT_SUCCESS,
    payload: payload,
  };
};
export const postSodConflictClearAction = () => {
  return {
    type: Actions.POST_SOD_CONFLICT_CLEAR,
    payload: { isSuccess: false, data: [] },
  };
};
/////////////////////////////////////////////////////////////////////////////////////////////
