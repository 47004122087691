import { Flex, Text, Image, Input, Segment, Dropdown, Divider, TextArea, Button, Menu, Loader } from "@fluentui/react-northstar";
import React from "react";
import "./genericDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import { IGenericForm } from "../SAPReqIdCreation";
import * as SapIdAction from "../../redux/actions/SapIdAction";
import { SapIdUserTypeItems, RequestType, SapIdUserType, SapIdPrimaryModule, Constant } from "../../constant";
import { Value } from "sass";
interface IGenericDetails {
    genericForm: IGenericForm,
    setGenericForm: any
}

interface IDropdownValues {
    businessProcess: { key: string; header: string; value: string; }[];
    primaryMethod: { id: string; header: string; value: string; }[];
}

const GenericDetails: React.FC<IGenericDetails> = ({ genericForm, setGenericForm }) => {

    const [dropdownValues, setDropdownValues] = React.useState<IDropdownValues>({
        businessProcess: [],
        primaryMethod: [],
    });

    const state = useSelector((state: RootSchema) => state);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (state.sapIdData.getListBusinessProcess.isSuccess && Array.isArray(state.sapIdData.getListBusinessProcess.data)) {
            const form_values = state.sapIdData.getListBusinessProcess.data.map(
                (item, index) => { return { key: item.BPDESC + index, header: item.BPDESC, value: item.BPDESC }; }
            );
            setDropdownValues(prev => ({ ...prev, businessProcess: form_values }));
        }
    }, [state.sapIdData.getListBusinessProcess.isSuccess]);

    React.useEffect(() => {
        setGenericForm((prev: IGenericForm) => ({ ...prev, requestType: RequestType["New Account"], userType: SapIdUserType["Dialog"] }));
    }, []);

    return (
        <React.Fragment>
            <Flex gap="gap.medium" >
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >First Name {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter First Name" className="inputBody" readOnly disabled inverted required fluid value={genericForm.firstName} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, firstName: "" }) : setGenericForm({ ...genericForm, firstName: v.value })} />
                </Flex>
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >Last Name {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Last Name" className="inputBody" inverted required readOnly disabled fluid value={genericForm.lastName} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, lastName: "" }) : setGenericForm({ ...genericForm, lastName: v.value })} />
                </Flex>
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >Email {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Email Address" className="inputBody" readOnly disabled inverted required fluid value={genericForm.email} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, email: "" }) : setGenericForm({ ...genericForm, email: v.value })} />
                </Flex>
            </Flex>
            <Flex gap="gap.medium" >
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold">Location {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Location" className="inputBody" inverted required fluid value={genericForm.location} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, location: "" }) : setGenericForm({ ...genericForm, location: v.value })} />
                </Flex>
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" > Manager {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Manager Name" className="inputBody" inverted readOnly disabled required fluid value={genericForm.manager} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, manager: "" }) : setGenericForm({ ...genericForm, manager: v.value })} />
                </Flex>
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >Employee Personal No. {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Employee Personal No" className="inputBody" readOnly disabled inverted required fluid value={genericForm.empPersNo} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, empPersNo: "" }) : setGenericForm({ ...genericForm, empPersNo: v.value })} />
                </Flex>
            </Flex>
            <Flex gap="gap.medium"  >
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >Company {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Company Name" className="inputBody" readOnly disabled inverted required fluid value={genericForm.company} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, company: "" }) : setGenericForm({ ...genericForm, company: v.value })} />
                </Flex>
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >Department {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Department Name" className="inputBody" inverted required fluid value={genericForm.department} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, department: "" }) : setGenericForm({ ...genericForm, department: v.value })} />
                </Flex>
            </Flex>
            <Divider />
            <Flex gap="gap.medium" >
                <Text content="Reason for Request" weight="bold" styles={{ fontSize: "15px" }} />
            </Flex>
            <Flex gap="gap.medium"  >
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >Request Type {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter Request Type" readOnly disabled className="inputBody" inverted required fluid
                        value={Object.keys(RequestType)[Object.values(RequestType).indexOf(genericForm.requestType as RequestType)]}
                        onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, requestType: "" }) : setGenericForm({ ...genericForm, requestType: v.value })} />
                </Flex>
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >User Type {<Text content="*" color="red" />}</Text>
                    <Input placeholder="Enter User Type" readOnly disabled className="inputBody" inverted required fluid
                        value={Object.keys(SapIdUserType)[Object.values(SapIdUserType).indexOf(genericForm.userType as SapIdUserType)]} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, userType: "" }) : setGenericForm({ ...genericForm, userType: v.value })} />
                </Flex>
                <Flex column gap="gap.small" fill>
                    <Text weight="semibold" >Business Process {<Text content="*" color="red" />}</Text>
                    <Dropdown
                        className="inputBody"
                        search
                        inverted
                        fluid
                        clearable={genericForm.businessProcess.value !== ""}
                        value={genericForm.businessProcess.header}
                        placeholder="Select Business Process"
                        noResultsMessage="We couldn't find any matches."
                        loading={state.sapIdData.getListBusinessProcess.isLoading}
                        loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                        onChange={(e, v: any) => v.value === null ? setGenericForm({ ...genericForm, businessProcess: { id: "", header: "", value: "" } }) : setGenericForm({ ...genericForm, businessProcess: v.value })}
                        items={dropdownValues.businessProcess}
                        onOpenChange={(e, v) => {
                            const checkOpen = v.open !== undefined ? v.open : true;
                            checkOpen && !state.sapIdData.getListBusinessProcess.isSuccess && dispatch(SapIdAction.getListBusinessProcessAction({ sap_client: Constant.SAP_CLIENT }));
                        }}
                    />
                </Flex>
            </Flex>
            <Flex gap="gap.small" column >
                <Text weight="semibold" > Description {<Text content="*" color="red" />}</Text>
                <TextArea placeholder="Enter Description..." rows={5} value={genericForm.description} onChange={(e: any, v: any) => v.value === null ? setGenericForm({ ...genericForm, description: "" }) : setGenericForm({ ...genericForm, description: v.value })} />
            </Flex>
            <Divider />
            <Flex gap="gap.medium" >
                <Text content="Custom Data" weight="bold" styles={{ fontSize: "15px" }} />
            </Flex>
            <Flex gap="gap.small" column>
                <Text weight="semibold" >Primary module of user {<Text content="*" color="red" />}</Text>
                <Dropdown className="inputBody" styles={{ width: "fit-content" }} placeholder="Please Select Primary Module" inverted items={SapIdPrimaryModule}
                    value={genericForm.primaryModule.header}
                    clearable={genericForm.primaryModule.value !== ""}
                    onChange={(e, v: any) => v.value === null ? setGenericForm({ ...genericForm, primaryModule: { id: "", header: "", value: "" } }) : setGenericForm({ ...genericForm, primaryModule: v.value })}
                />
            </Flex >
        </React.Fragment >
    );
};

export default GenericDetails;