import {
    Actions,
    IIttipsData 
} from "../actions/ITtipsAction";
import { combineReducers } from "redux";


export const getIttipsDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_IT_TIPS_DATA_LOADING:
    case Actions.GET_IT_TIPS_DATA_ERROR:
    case Actions.GET_IT_TIPS_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export interface IITtipsData {
    getIttipsData: IIttipsData,
    
}
const iTtipsData = combineReducers({
    getIttipsData: getIttipsDataReducer,
   
});
export default iTtipsData;