import {
    Actions,
    IToDoData,
    ICreateListData,
    ICreateTaskData,
    IUpdatetaskData,
    IDeleteTask,
    IToDoListData,
    ITodoMaster
} from "../actions/TodoAction";
import { combineReducers } from "redux";
  
export const postToDoOrgDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.POST_TODO_ORG_LIST_DATA_LOADING:
    case Actions.POST_TODO_ORG_LIST_DATA_ERROR:
    case Actions.POST_TODO_ORG_LIST_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const postToDoPersonalDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.POST_TODO_PERSONAL_LIST_DATA_LOADING:
    case Actions.POST_TODO_PERSONAL_LIST_DATA_ERROR:
    case Actions.POST_TODO_PERSONAL_LIST_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const postCreateListDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.POST_CREATE_LIST_DATA_LOADING:
    case Actions.POST_CREATE_LIST_DATA_ERROR:
    case Actions.POST_CREATE_LIST_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};


export const getCreateTaskDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_CREATE_TASK_DATA_LOADING:
    case Actions.GET_CREATE_TASK_DATA_ERROR:
    case Actions.GET_CREATE_TASK_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};


export const postUpdateTaskDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.POST_UPDATE_TASK_DATA_LOADING:
    case Actions.POST_UPDATE_TASK_DATA_ERROR:
    case Actions.POST_UPDATE_TASK_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};


export const getDeleteTaskReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_DELETE_TASK_LOADING:
    case Actions.GET_DELETE_TASK_ERROR:
    case Actions.GET_DELETE_TASK_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};


export const getToDoListDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_TODO_LIST_DATA_LOADING:
    case Actions.GET_TODO_LIST_DATA_ERROR:
    case Actions.GET_TODO_LIST_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export const getToDoMasterDataReducer = (state = {}, action: any) => {
    switch(action.type) {
    case Actions.GET_TODO_MASTER_LOADING:
    case Actions.GET_TODO_MASTER_ERROR:
    case Actions.GET_TODO_MASTER_SUCCESS:
        return {
            ...state,
            ...action.payload
        };
    default:
        return state;
    }
};

  
export interface ITodoData {
    todoOrgData:  IToDoData;
    todoPersonalData:  IToDoData;
    createList: ICreateListData;
    createTask: ICreateTaskData,
    updateTask: IUpdatetaskData,
    deleteTask:IDeleteTask,
    todoList: IToDoListData,
    todoMaster: ITodoMaster
  }
const todoData = combineReducers({
    todoOrgData:  postToDoOrgDataReducer,
    todoPersonalData:  postToDoPersonalDataReducer,
    createList:  postCreateListDataReducer,
    createTask: getCreateTaskDataReducer,
    updateTask: postUpdateTaskDataReducer,
    deleteTask:getDeleteTaskReducer,
    todoList:  getToDoListDataReducer,
    todoMaster: getToDoMasterDataReducer
});
export default todoData;
  