import { put, call, take, delay } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/AttendanceAction";
import { httpGet, httpPost } from "./../../service";
import { API_BASE_URL, ATTENDANCE_API_CONFIG } from "../../config";
function* postAttendanceData(payload: {
  access_token: string;
  emp_Code: string;
  payload: actionCreators.IAttendancePayload;
}) {
    try {
        yield put(actionCreators.postAttendanceLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_Code}${ATTENDANCE_API_CONFIG.GET_ATTENDANCE_ENQUIRY}`,
            payload.payload,
            config
        );
        yield put(actionCreators.postAttendanceSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.postAttendanceErrorAction(error.response.data.error)
        );
    }
}

export function* watchAttendanceData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.POST_ATTENDANCE_DETAILS
        );
        yield call(postAttendanceData, payload);
    }
}
