import {
    Box,
    Button,
    ChevronEndIcon,
    ChevronEndMediumIcon,
    ChevronStartIcon,
    Divider,
    Dropdown,
    Flex,
    Segment,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import "./ViewStatusPagination.scss";

interface IPaginationProps{
    pagination: { page_size: number, start_index: number };
    setPagination: any,
    totalRowCount: number
}

const ViewStatusPagination: React.FC<IPaginationProps> = ({ pagination, setPagination, totalRowCount }) => {
    const rows = [5, 10, 15, 20];
    return (
        <Flex vAlign="center" className="pagination-flex" space="between" gap="gap.medium">
            <Flex gap="gap.medium" vAlign="center">
                <Text content="Rows Per Page: " />
                <Dropdown
                    items={rows}
                    fluid
                    defaultValue={rows[0]} styles={{ width: "60px" }}
                    value={ pagination.page_size }
                    onChange={(e: any, val: any) => setPagination({ start_index: 0 , page_size: val.value })}
                />
            </Flex>
            <Flex gap="gap.medium" vAlign="center">
                <Button
                    icon={<ChevronStartIcon />}
                    iconOnly
                    style={{ color: "#6264a7" }}
                    disabled={pagination.start_index === 0 }
                    onClick={() => setPagination({ ...pagination, start_index: (pagination.start_index-1) })}
                />
                <Text content={ (pagination.start_index + 1 ) + " of " + Math.ceil(totalRowCount/pagination.page_size)} />
                <Button
                    icon={<ChevronEndIcon />}
                    iconOnly
                    style={{ color: "#6265a7" }}
                    disabled={ (pagination.start_index + 1) >= Math.ceil(totalRowCount/pagination.page_size) }
                    onClick={() => setPagination({ ...pagination, start_index: (pagination.start_index+1) })}
                />
            </Flex>
        </Flex>
    );
};

export default ViewStatusPagination;
