import { put, call, take, delay, takeLatest } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/TodoAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, TODO_API_CONFIG} from "../../config";

function* postToDoOrgData(action: any) {
    try {
        yield put(actionCreators.postToDoOrgDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.GET_TODO_TASK,
            action.payload.payload,
            config
        );
        yield put(actionCreators.postToDoOrgDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postToDOOrgDataErrorAction(error));
    }
}

function* postToDoPersonalData(action: any) {
    try {
        yield put(actionCreators.postToDoPersonalDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.GET_TODO_TASK,
            action.payload.payload,
            config
        );
        yield put(actionCreators.postToDoPersonalDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postToDOPersonalDataErrorAction(error));
    }
}


function*  postCreateListData(payload: {
    access_token: string;
    payload: actionCreators.ICreateListDataPayLaod;
  }) {
    try {
        yield put(actionCreators.postCreateListDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
  
        const { data } = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.CREATE_LIST,
            payload.payload,
            config
        );
        yield put(actionCreators.postCreateListDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postCreateListDataErrorAction(error));
    }
}



function* getCreateTaskData(payload: {
    access_token: string;
    payload: actionCreators.ICreateTaskDataPayLaod;
  }) {
    try {
        yield put(actionCreators.getCreateTaskDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
  
        const { data } = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.CREATE_TASK,
            payload.payload,
            config
        );
        yield put(actionCreators.getCreatetaskDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getCreateTaskDataErrorAction(error));
    }
}



function* postUpdateTaskData(payload: {
    access_token: string;
    payload: actionCreators.IUpdateTaskDataPayLaod;
  }) {
    try {
        yield put(actionCreators. postUpdateTaskDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
  
        const { data } = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.UPDATE_TASK,
            payload.payload,
            config 
        );
        yield put(actionCreators.postUpdateTaskDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postUpdateTaskDataErrorAction(error));
    }
}



function* getDeleteTask(payload: {
    access_token: string;
    payload: actionCreators.IDeleteTaskPayLaod;
  }) {
    try {
        yield put(actionCreators.getDeleteTaskLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
  
        const { data } = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.DELETE_TASK,
            payload.payload,
            config
        );
        yield put(actionCreators.getDeleteTaskSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getDeleteTaskErrorAction(error));
    }
}


function* getToDoListData(payload: {
    access_token: string;
    payload: actionCreators.ITodoListDataPayLaod;
  }) {
    try {
        yield put(actionCreators.getToDoListDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.GET_TODO_LIST,
            payload.payload,
            config
        );
        yield put(actionCreators.getTodoListDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getToDoListDataErrorAction(error));
    }
}

function* getToDoMasterData(payload: {
    access_token: string;
    emp_id: string
  }) {
    try {
        yield put(actionCreators.getToDoMasterDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const {data} = yield httpPost(
            API_BASE_URL + TODO_API_CONFIG.GET_TODO_MASTER_DATA,
            {
                emp_id: payload.emp_id
            },
            config
        );
        yield put(actionCreators.getToDOMasterDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getToDOMasterDataErrorAction(error));
    }
}

export function* watchToDoOrgData() {
    // while (true) {
    //   const { payload } = yield take(actionCreators.Actions.POST_TODO_ORG_LIST_DATA);
    //   yield call(postToDoOrgData, payload);
    // }
    yield takeLatest(actionCreators.Actions.POST_TODO_ORG_LIST_DATA, postToDoOrgData);
}

export function* watchToDoPersonalData() {
    // while (true) {
    //   const { payload } = yield take(actionCreators.Actions.POST_TODO_PERSONAL_LIST_DATA);
    //   yield call(postToDoPersonalData, payload);
    // }
    yield takeLatest(actionCreators.Actions.POST_TODO_PERSONAL_LIST_DATA, postToDoPersonalData);
}

export function* watchCreateListData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.POST_CREATE_LIST_DATA);
        yield call(postCreateListData, payload);
    }
}


export function* watchCreateTaskData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_CREATE_TASK_DATA);
        yield call(getCreateTaskData, payload);
    }
}


export function* watchUpdateTaskData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.POST_UPDATE_TASK_DATA);
        yield call(postUpdateTaskData, payload);
    }
}

export function* watchDeleteTask() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_DELETE_TASK);
        yield call(getDeleteTask, payload);
    }
}

export function* watchToDoListData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_TODO_LIST_DATA);
        yield call(getToDoListData, payload);
    }
}

export function* watchToDoMasterData() {
    while(true) {
        const { payload } = yield take(actionCreators.Actions.GET_TODO_MASTER_DATA);
        yield call(getToDoMasterData, payload);
    }
}