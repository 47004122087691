import React from "react";
import DataNotFound from "../DNF";
import CFM from "../../assets/img/CFM.svg";

interface IProps{
    children: JSX.Element;
    permission: string;
    message: string
}
class CFMPermission extends React.Component<IProps>{
    constructor(props: IProps){
        super(props);
    }
    render(): React.ReactNode {
        if(this.props.permission === "close") {
            return <DataNotFound message={this.props.message} img={CFM} />;
        } else if(this.props.permission === "view" || this.props.permission === "write") {
            return this.props.children;
        }
    }
}


export default CFMPermission;