import { Flex, Text, Box, Grid, Divider, Popup, ApprovalsAppbarIcon, Image, Skeleton, MoreIcon } from "@fluentui/react-northstar";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./sidebar.scss";
import { CFMIcon, InjectionIcon, ManageLeavesIcon, ManagerViewIcon, OverviewIcon, TeamFeedbackIcon, SupportIcon, VisitingCardIcon, SapAcrIcon, NFAIcon, } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import { createOrUpdateDataAction, createOrUpdateUpdateDataAction, getPinDataSuccessAction } from "../../redux/actions/PinActions";
import { IState } from "../../App";
import { setPath, setvcType } from "../../redux/actions/appConfigration";
import { ITSMIcon, SAPIDIcon, PaySpaceIcon } from "../../assets/icons";

interface ISidebarProps {
  QueryData: IState;
  app_env?: string;
  children?: JSX.Element;
}

interface IMore {
  isActive: boolean;
  startIndex?: number;
  endIndex?: number;
}

interface IDrag {
  isActive: boolean;
  dragFrom?: number;
  dragTo?: number;
}

const Sidebar: React.FC<ISidebarProps> = (props: ISidebarProps) => {
  const [isMore, setIsMore] = React.useState<IMore>({ isActive: false, startIndex: 0, endIndex: 5 });
  const [isDrag, setIsDrag] = React.useState<IDrag>({ isActive: false });

  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const dispatch = useDispatch();
  const state = useSelector((state: RootSchema) => state);

  // eslint-disable-next-line prefer-const
  let SidebarData = [
    {
      sequence: 0,
      icon: <OverviewIcon />,
      isActive: state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" ? true : false,
      path: "/?app_env=" + process.env.REACT_APP_ENV,
      text: "Overview",
      subMenu: {
        isMenu: false,
        subItems: []
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 1,
      icon: <ManageLeavesIcon />,
      isActive: state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" ? true : false,
      path: "/manage-leaves",
      text: "Leaves",
      subMenu: {
        isMenu: false,
        subItems: []
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 2,
      icon: <InjectionIcon />,
      isActive: false,
      path: "/vaccination",
      text: "Vaccination",
      subMenu: {
        isMenu: false,
        subItems: []
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 3,
      icon: <CFMIcon />,
      isActive: state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" ? true : false,
      path: "/cfm",
      text: "CFM",
      subMenu: {
        isMenu: false,
        subItems: []
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 4,
      icon: <ManagerViewIcon />,
      isActive: state.userInfo.userManagerData.is_employee_reporting ? true : false,
      path: "/feedback-management",
      text: "Team",
      subMenu: {
        isMenu: false,
        subItems: [],
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 5,
      icon: <SupportIcon />,
      isActive: true,
      path: "/support-desk",
      originaltext: "IT Support Desk",
      text: state.appConfig.dimension.deviceWidth >= 600 ? "IT Support Desk" : "ITSM",
      subMenu: {
        isMenu: true,
        subItems: [
          {
            isActive: true,
            path: "/raise-itsm-ticket",
            text: "ITSM",
            icon: <ITSMIcon />,
          },
          {
            isActive: true,
            path: "/support-desk",
            text: "SAP ID",
            icon: <SAPIDIcon />,
          },
          {
            path: "/req-id-creation",
            text: "New Request ID Creation",
            isActive: false,
          },
          {
            path: "/role-creation",
            text: "Role Creation",
            isActive: false,
          },
          {
            path: "/role-modification",
            text: "Role Modification",
            isActive: false,
          },
        ]
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 6,
      icon: <VisitingCardIcon />,
      isActive: state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" ? true : false,
      path: "/visiting-cards",
      text: "Visiting Card",
      subMenu: {
        isMenu: false,
        subItems: [],
      },
      publishDate: "05/09/2023" //MM/DD/YYYY
    },
    {
      sequence: 7,
      icon: <ApprovalsAppbarIcon outline styles={({ theme: { siteVariables } }) => ({
        color: siteVariables.colorScheme.brand.foreground,
      })} size="larger" />,
      isActive: state.userInfo.userManagerData.is_employee_reporting ? true : false,
      path: "/manager-view",
      text: "Approvals",
      subMenu: {
        isMenu: false,
        subItems: [
          {
            isActive: false,
            path: "/approval",
            text: "Leave Approvals",
          },
          {
            isActive: false,
            path: "/imac-approval",
            text: "ITSM Approvals",
          }
        ],
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 8,
      icon: <SapAcrIcon />,
      isActive: state.acrSapData.getAcrSapEmpRowCountData.isSuccess && state.acrSapData.getAcrSapEmpRowCountData.status_code === "200" ? (state.acrSapData.getAcrSapEmpRowCountData.data.approver || state.acrSapData.getAcrSapEmpRowCountData.data.initiator ? true : false) : false,
      path: state.acrSapData.getAcrSapEmpRowCountData.isSuccess && state.acrSapData.getAcrSapEmpRowCountData.status_code === "200" ? (state.acrSapData.getAcrSapEmpRowCountData.data.approver && state.acrSapData.getAcrSapEmpRowCountData.data.initiator ? "/acr" : state.acrSapData.getAcrSapEmpRowCountData.data.approver ? "/acr-approvals" : state.acrSapData.getAcrSapEmpRowCountData.data.initiator ? "/acr-initiator" : "/acr") : "/acr",
      text: "ACR",
      subMenu: {
        isMenu: state.acrSapData.getAcrSapEmpRowCountData.isSuccess && state.acrSapData.getAcrSapEmpRowCountData.status_code === "200" && state.acrSapData.getAcrSapEmpRowCountData.data.approver && state.acrSapData.getAcrSapEmpRowCountData.data.initiator ? true : false,
        subItems: [
          {
            isActive: true,
            path: "/acr-initiator",
            text: "ACR Requests",
            icon: <SAPIDIcon />,
          },
          {
            isActive: true,
            path: "/acr-approvals",
            text: "ACR Approvals",
            icon: <ITSMIcon />,
          },
          {
            isActive: true,
            path: "/acr-dashboard",
            text: "ACR Dashboard",
            icon: <ITSMIcon />,
          }
        ]
      },
      publishDate: "" //MM/DD/YYYY
    },
    {
      sequence: 9,
      icon: <PaySpaceIcon />,
      isActive: true,
      path: "/pay-space",
      text: "Pay Space",
      subMenu: {
        isMenu: false,
        subItems: [],
      },
      publishDate: "12/30/2023" //MM/DD/YYYY
    },
    {
      sequence: 10,
      icon: <NFAIcon />,
      isActive: state.authData.nfaUserAuth.isSuccess && state.authData.nfaUserAuth.data.some((item) => item.access_token && item.requested_subject) ? true : false,
      path: "/nfa",
      text: "NFA",
      subMenu: {
        isMenu: false,
        subItems: [],
      },
      publishDate: "" //MM/DD/YYYY
    },
  ];
  const [sideData, setSideData] = React.useState<any[]>(SidebarData);

  function swapArrayElement(input: any[], from: number, to: number) {
    let numberOfDeletedElm = 1;
    const elm = input.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;
    input.splice(to, numberOfDeletedElm, elm);
  }

  const handleMenuClick = (path: string) => {
    const baseUrl = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_ENV === "development" ? "c19d5f03-ea6e-4509-b1a3-c421b8e85b32" : "71825527-a187-4739-9f56-2d48f5648776"}/personalTab`;
    const appWebUrl = process.env.REACT_APP_ENV === "development" ? "https://finpowerdev.tatamotors.com" : "https://finpower.tatamotors.com"; // The base web URL for your app
    const label = "Menu";
    const context = `{"subEntityId":"${path}"}`;

    const deepLink = `${baseUrl}?webUrl=${encodeURIComponent(appWebUrl)}&label=${encodeURIComponent(label)}&context=${encodeURIComponent(context)}`;
    // Navigate to the deep link
    //  window.location.href = deepLink;
    window.open(deepLink, "_blank");
  };

  const handleRoute = (path: string, position?: number) => {
    if (path === "/acr-approvals" || path === "/acr-initiator") {
      handleMenuClick(path);
    } else {
      state.appConfig.path !== "" && dispatch(setPath(""));
      state.appConfig.vcType !== "" && dispatch(setvcType("Digital"));
      navigate(path);
      position && swapArrayElement(sideData, position, 4);
    }

  };

  const onDragStart = (id: number, module: string) => {
    isDrag?.dragFrom !== id && setIsDrag({ ...isDrag, isActive: true, dragFrom: id });
  };

  const onDragEnd = (id: number, module: string) => {
    setIsDrag({ isActive: false });
  };

  const onDragDrop = async (id: number, module: string) => {
    if ((isDrag.dragFrom as number) !== (isDrag.dragTo as number)) {
      isDrag.dragTo !== undefined &&
        swapArrayElement(
          sideData,
          isDrag.dragFrom as number,
          isDrag.dragTo !== 0 ? (isDrag.dragTo as number) : 1
        );
      const pinData = sideData.map((item: any) => {
        return { seq: item.sequence, name: item.text };
      });
      setIsDrag({ isActive: false });
      await dispatch(
        createOrUpdateDataAction(props.QueryData.employeeId as string, pinData)
      );
    }
  };

  const onDragOver = (id: number, module: string) => {
    isDrag?.dragTo !== id && setIsDrag({ ...isDrag, dragTo: id });
  };

  const onDateComaparison = (publishDate: string) => {
    const today = new Date();
    const afterFifteenDays = new Date(publishDate);
    afterFifteenDays.setDate(afterFifteenDays.getDate() + 15); //Is 15 Days Please Change for how many days show new tag
    if (afterFifteenDays > today) return true;
    else return false;
  };


  React.useEffect(() => {
    if (state.pinData.createOrUpdatePin.isSuccess) {
      const updateData = { ...state.pinData.getPin, pin: sideData.filter((obj) => obj?.isActive).map((item) => { return ({ seq: item.sequence, name: item.text }); }) };
      dispatch(getPinDataSuccessAction(updateData));
      dispatch(createOrUpdateUpdateDataAction({ ...state.pinData.createOrUpdatePin, isSuccess: false }));
    }
  }, [state.pinData.createOrUpdatePin.isSuccess]);

  React.useEffect(() => {
    if (state.pinData.getPin.isSuccess) {
      // const data = state.pinData.getPin.pin.map((item) => SidebarData.find((obj) => { return obj.text == item.name || obj.originaltext == item.name; }));
      // const data = SidebarData.map((item) => state.pinData.getPin.pin.find((obj) => { return obj.name == item.text || obj.name == item.originaltext; }));

      // setSideData(data.filter(x => x !== undefined));

      const pinData = Array.isArray(state.pinData.getPin.pin) ? state.pinData.getPin.pin.map((item) => SidebarData.find((obj) => { return obj.text == item.name || obj.originaltext == item.name; })) : [];
      const newItems = SidebarData.filter((item) => !pinData.find((obj) => obj === item) && item);
      const data = [...pinData, ...newItems].filter(x => x !== undefined).sort((a, b) => { if (a?.isActive === b?.isActive) return 0; return a?.isActive ? -1 : 1; });
      setSideData(data);
    }
  }, [state.pinData.getPin.isSuccess, state.userInfo.userManagerData.isSuccess, state.acrSapData.getAcrSapEmpRowCountData.isSuccess, state.authData.nfaUserAuth.isSuccess]);
  const isRedirect = state.appConfig.path !== "" ? true : false;
  React.useEffect(() => {
    if (isRedirect) {
      navigate("/" + ((state.appConfig.path as string).split("-")[0] === "mtm" ? (state.appConfig.path as string).split("-")[0] : (state.appConfig.path as string)));
    }
  }, [isRedirect]);

  return (
    <React.Fragment>
      {
        state.appConfig.dimension.deviceWidth >= 600 ?
          (pathName === "/" ?
            (state.authData.userAuth.isSuccess ?
              <Grid columns="repeat(3,1fr)"  >
                {sideData.filter((item) => item?.isActive).slice(isMore.startIndex, isMore.endIndex).map(({ sequence, icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                  return (isActive && <Flex key={indexNo}
                    className="padding-in-grid"
                    draggable={indexNo !== 0}
                    onDragStart={(e: any) => {
                      e.dataTransfer.effectAllowed;
                      e.dataTransfer.effectAllowed = "move";
                      onDragStart(indexNo, text);
                    }}
                    onDrop={(e: any) => {
                      e.preventDefault();
                      e.dataTransfer.effectAllowed === "move" && onDragDrop(indexNo, text);
                    }}
                    onDragOver={(e: any) => {
                      e.preventDefault();
                      onDragOver(indexNo, text);
                    }}
                    onDragEnd={() => onDragEnd(indexNo, text)}
                  >
                    {isDrag.isActive && isDrag.dragTo === indexNo && indexNo !== 0 && <Divider vertical color="brand" size={2} />}
                    <Popup
                      key={indexNo}
                      on={subMenu.isMenu ? "click" : "context"}
                      content={<Flex column>
                        {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                          return (item.isActive &&
                            <Box key={item.text + index} className="sidebar-subMenu" onClick={() => pathName !== item.path.split("?")?.[0] && handleRoute(item.path)}>
                              <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                {item.icon}
                                <Text content={item.text} color="white" size="medium" />
                              </Flex>
                            </Box>
                          );
                        })}
                      </Flex>}
                      align='top'
                      position='after'
                      trigger={
                        <Box key={text} className={(pathName === path.split("?")[0] || pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                          styles={({ theme: { siteVariables } }) => ({
                            background: siteVariables.bodyBackground
                          })}
                          onClick={() => subMenu.isMenu ? null : pathName !== path.split("?")?.[0] && handleRoute(path)}>
                          <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                            {icon}
                            <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                          </Flex>
                          {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                            <Text weight="bold" content={"New"} />
                          </Box>}
                        </Box>
                      }
                    />
                  </Flex>
                  );
                })}
                {sideData.filter((item) => item?.isActive).length > 5 && <Box key="moreBtn" className='sidebar_box padding-in-grid'
                  styles={({ theme: { siteVariables } }) => ({
                    background: siteVariables.bodyBackground,
                  })}
                  onClick={() => isMore.isActive ? setIsMore({ ...isMore, isActive: false, endIndex: 5 }) : setIsMore({ ...isMore, isActive: true, endIndex: SidebarData.length - 1 })}>
                  <Flex column fill hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                    <MoreIcon styles={({ theme: { siteVariables } }) => ({
                      color: siteVariables.colorScheme.brand.foreground,
                    })} size='largest' />
                    <Text align='center' style={{ whiteSpace: "break-spaces" }} content={isMore.isActive ? "Less" : "More"} size="medium" />
                  </Flex>
                  {sideData.filter((item) => item?.isActive).slice(5, sideData.filter((item) => item?.isActive).length).some(obj => obj.publishDate && obj.publishDate !== "" ? onDateComaparison(obj.publishDate) : false) && !isMore.isActive && <Box className="new-tag">
                    <Text weight="bold" content={"New"} />
                  </Box>}
                </Box>}
              </Grid> :
              <Skeleton animation='pulse'>
                <Grid columns="repeat(3,1fr)" >
                  {[1, 2, 3, 4, 5, 6].map((item, index) => {
                    return (<Box key={item} className='sidebar_box padding-in-grid'
                      styles={({ theme: { siteVariables } }) => ({
                        background: siteVariables.bodyBackground,
                      })}>
                      <Flex column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                        <Skeleton.Line height='37px' width='37px' />
                        <Skeleton.Text />
                      </Flex>
                    </Box>);
                  })}
                </Grid>
              </Skeleton>)
            :
            (state.authData.userAuth.isSuccess ?
              <Box className='sidebar-root'>
                <Flex column className='sidebar-main'>
                  {sideData.filter((item) => item?.isActive).slice(0, 5).map(({ sequence, icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                    return (isActive && <Box key={indexNo}
                      draggable={indexNo !== 0}
                      onDragStart={(e: any) => {
                        e.dataTransfer.effectAllowed;
                        e.dataTransfer.effectAllowed = "move";
                        onDragStart(indexNo, text);
                      }}
                      onDrop={(e: any) => {
                        e.preventDefault();
                        onDragDrop(indexNo, text);
                      }}
                      onDragOver={(e: any) => {
                        e.preventDefault();
                        onDragOver(indexNo, text);
                      }}
                      onDragEnd={() => onDragEnd(indexNo, text)}
                    >
                      {isDrag.isActive && isDrag.dragTo === indexNo && indexNo !== 0 && <Divider color="brand" size={2} />}
                      <Popup
                        key={indexNo}
                        on={subMenu.isMenu ? "hover" : "context"}
                        content={<Flex column>
                          {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                            return (item.isActive &&
                              <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => pathName !== item.path.split("?")?.[0] && handleRoute(item.path)}>
                                <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                  {item.icon}
                                  <Text content={item.text} color="white" size="medium" />
                                </Flex>
                              </Box>
                            );
                          })}
                        </Flex>}
                        align='top'
                        position='after'
                        trigger={
                          <Box key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                            styles={({ theme: { siteVariables } }) => ({
                              background: siteVariables.bodyBackground,
                            })}
                            onClick={() => subMenu.isMenu ? null : pathName !== path.split("?")?.[0] && handleRoute(path)}>
                            <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                              {icon}
                              <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                            </Flex>
                            {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                              <Text weight="bold" content={"New"} />
                            </Box>}
                          </Box>
                        }
                      />
                      {!(isDrag.isActive && isDrag.dragTo === indexNo && indexNo !== 0) && <Divider fitted />}
                    </Box>
                    );
                  })}
                  {sideData.filter((item) => item?.isActive).length > 5 && <Popup
                    key="ismore"
                    on="hover"
                    content={
                      <Flex gap="gap.small" padding='padding.medium' wrap styles={{ maxWidth: "335px" }}>
                        {sideData.filter((item) => item?.isActive).slice(5, SidebarData.length).map(({ sequence, icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                          return (isActive && <Box key={indexNo + 5}
                            draggable
                            onDragStart={(e: any) => {
                              e.dataTransfer.effectAllowed;
                              e.dataTransfer.effectAllowed = "move";
                              onDragStart(indexNo + 5, text);
                            }}
                            onDrop={(e: any) => {
                              e.preventDefault();
                              onDragDrop(indexNo + 5, text);
                            }}
                            onDragOver={(e: any) => {
                              e.preventDefault();
                              onDragOver(indexNo + 5, text);
                            }}
                            onDragEnd={() => onDragEnd(indexNo, text)}
                          >
                            <Popup
                              key={indexNo}
                              on={subMenu.isMenu ? "hover" : "context"}
                              content={<Flex column>
                                {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                                  return (item.isActive &&
                                    <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => pathName !== item.path.split("?")?.[0] && handleRoute(item.path)}>
                                      <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                        {item.icon}
                                        <Text content={item.text} color="white" size="medium" />
                                      </Flex>
                                    </Box>
                                  );
                                })}
                              </Flex>}
                              align='top'
                              position='after'
                              trigger={
                                <Box
                                  key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                  styles={({ theme: { siteVariables } }) => ({
                                    background: siteVariables.bodyBackground,
                                  })}
                                  onClick={() => subMenu.isMenu ? null : pathName !== path.split("?")?.[0] && handleRoute(path, indexNo + 5)}>
                                  <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                                    {icon}
                                    <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                                  </Flex>
                                  {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                                    <Text weight="bold" content={"New"} />
                                  </Box>}
                                </Box>
                              }
                            />
                          </Box>
                          );
                        })}
                      </Flex>}
                    align='top'
                    position='after'
                    pointing
                    trigger={
                      <Box key="moreBtn" className={isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                        styles={({ theme: { siteVariables } }) => ({
                          background: siteVariables.bodyBackground,
                        })}
                      // onClick={() => setIsMore({ ...isMore, isActive: !isMore.isActive })}
                      >
                        <Flex column fill hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                          <MoreIcon styles={({ theme: { siteVariables } }) => ({
                            color: siteVariables.colorScheme.brand.foreground,
                          })} size='largest' />
                          <Text align='center' style={{ whiteSpace: "break-spaces" }} content={isMore.isActive ? "Less" : "More"} size="medium" />
                        </Flex>
                        {sideData.filter((item) => item?.isActive).slice(5, SidebarData.length).some(obj => obj.publishDate && obj.publishDate !== "" ? onDateComaparison(obj.publishDate) : false) && !isMore.isActive && <Box className="new-tag">
                          <Text weight="bold" content={"New"} />
                        </Box>}
                      </Box>
                    }
                  />}

                </Flex>
                <Box className='content' styles={({ theme: { siteVariables } }) => ({
                  background: siteVariables.colorScheme.grey.backgroundActive,
                })} >
                  {props.children}
                </Box>
              </Box> :
              <Skeleton animation='pulse'>
                <Box className='sidebar-root'>
                  <Flex column className='sidebar-main'>
                    {[1, 2, 3, 4, 5, 6].map((item, index) => {
                      return (<Box key={item} className='sidebar_box padding-in-grid'
                        styles={({ theme: { siteVariables } }) => ({
                          background: siteVariables.bodyBackground,
                        })}>
                        <Flex column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                          <Skeleton.Line height='37px' width='37px' />
                          <Skeleton.Text />
                        </Flex>
                      </Box>);
                    })}
                  </Flex>
                  <Box className='content' styles={({ theme: { siteVariables } }) => ({
                    background: siteVariables.colorScheme.grey.backgroundActive,
                  })} >
                    {props.children}
                  </Box>
                </Box>
              </Skeleton>
            )) :
          (<Box className='sidebar-mobile'>
            {pathName !== "/" && <Box className='content' styles={({ theme: { siteVariables } }) => ({
              background: siteVariables.colorScheme.grey.backgroundActive,
            })}>
              {props.children}
            </Box>}
            {state.authData.userAuth.isSuccess ?
              <Box
                styles={({ theme: { siteVariables } }) => ({
                  background: siteVariables.bodyBackground,
                  borderRadius: "20px"
                })}
              >
                {isMore.isActive && <Grid columns="repeat(5,1fr)"
                  styles={({ theme: { siteVariables } }) => ({
                    maxHeight: "200px",
                    borderTop: "2px solid #bdb9b7",
                    borderRadius: "20px",
                    padding: "0.5rem 1rem",
                    overflow: "auto",
                    gridGap: "8px"
                  })} >
                  {sideData.filter((item) => item?.isActive).slice(4, SidebarData.length).map(({ sequence, icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                    return (isActive && <Box styles={{ width: "inherit" }}
                      key={indexNo + 5}
                      draggable
                      onDragStart={(e: any) => {
                        e.dataTransfer.effectAllowed;
                        e.dataTransfer.effectAllowed = "move";
                        onDragStart(indexNo + 5, text);
                      }}
                      onDrop={(e: any) => {
                        e.preventDefault();
                        onDragDrop(indexNo + 5, text);
                      }}
                      onDragOver={(e: any) => {
                        e.preventDefault();
                        onDragOver(indexNo + 5, text);
                      }}
                      onDragEnd={() => onDragEnd(indexNo, text)}
                    >
                      <Popup
                        key={indexNo}
                        on={subMenu.isMenu ? "hover" : "context"}
                        content={<Flex column>
                          {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                            return (item.isActive &&
                              <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => handleRoute(item.path)}>
                                <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                  {item.icon}
                                  <Text content={item.text} color="white" size="medium" />
                                </Flex>
                              </Box>
                            );
                          })}
                        </Flex>}
                        align='top'
                        position='after'
                        trigger={
                          <Box key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                            styles={({ theme: { siteVariables } }) => ({
                              background: siteVariables.bodyBackground,
                            })}
                            onClick={() => subMenu.isMenu ? null : handleRoute(path)}>
                            <Flex fill className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                              {icon}
                              <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                            </Flex>
                            {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                              <Text weight="bold" content={"New"} />
                            </Box>}
                          </Box>
                        }
                      />
                    </Box>
                    );
                  })}
                </Grid>}
                <Divider fitted />
                <Flex className='sidebar-main' fill>
                  {sideData.filter((item) => item?.isActive).slice(0, 4).map(({ sequence, icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                    return (isActive && <Box key={indexNo}
                      draggable
                      onDragStart={(e: any) => {
                        e.dataTransfer.effectAllowed;
                        e.dataTransfer.effectAllowed = "move";
                        onDragStart(indexNo, text);
                      }}
                      onDrop={(e: any) => {
                        e.preventDefault();
                        onDragDrop(indexNo, text);
                      }}
                      onDragOver={(e: any) => {
                        e.preventDefault();
                        onDragOver(indexNo, text);
                      }}
                      onDragEnd={() => onDragEnd(indexNo, text)}
                      style={{ width: "100%" }}
                    >
                      <Popup
                        key={indexNo}
                        on={subMenu.isMenu ? "hover" : "context"}
                        content={<Flex column>
                          {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                            return (item.isActive &&
                              <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => handleRoute(item.path)}>
                                <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                  {item.icon}
                                  <Text content={item.text} color="white" size="medium" />
                                </Flex>
                              </Box>
                            );
                          })}
                        </Flex>}
                        align='top'
                        position='after'
                        trigger={
                          <Box draggable key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                            styles={({ theme: { siteVariables } }) => ({
                              background: siteVariables.bodyBackground,
                            })}
                            onClick={() => subMenu.isMenu ? null : handleRoute(path)}>
                            <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} fill column hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                              {icon}
                              <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                            </Flex>
                            {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                              <Text weight="bold" content={"New"} />
                            </Box>}
                          </Box>
                        }
                      />
                    </Box>);
                  })}
                  {SidebarData.filter((item) => item.isActive).length > 4 && <Box key="moreBtn" className={isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                    styles={({ theme: { siteVariables } }) => ({
                      background: siteVariables.bodyBackground,
                    })}
                    onClick={() => setIsMore({ ...isMore, isActive: !isMore.isActive })}>
                    <Flex column hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                      <MoreIcon styles={({ theme: { siteVariables } }) => ({
                        color: siteVariables.colorScheme.brand.foreground,
                      })} />
                      <Text align='center' style={{ whiteSpace: "break-spaces" }} content="More" size="medium" />
                    </Flex>
                    {sideData.filter((item) => item?.isActive).slice(4, SidebarData.length).some(obj => obj.publishDate && obj.publishDate !== "" ? onDateComaparison(obj.publishDate) : false) && !isMore.isActive && <Box className="new-tag">
                      <Text weight="bold" content={"New"} />
                    </Box>}
                  </Box>}
                </Flex>
              </Box>
              : <Skeleton animation='pulse'>
                <Flex className='sidebar-main' fill>
                  {[1, 2, 3, 4, 5].map((item, index) => {
                    return (<Box key={item} className='sidebar_box'
                      styles={({ theme: { siteVariables } }) => ({
                        background: siteVariables.bodyBackground,
                      })} >
                      <Flex column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                        <Skeleton.Line height='25px' width='25px' />
                        <Skeleton.Text />
                      </Flex>
                    </Box>);
                  })}
                </Flex>
              </Skeleton>}
          </Box>)
      }
    </React.Fragment >

  );
};

export default Sidebar;