import { put, call, take, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions/AcrSapActions";
import { httpGet, httpPost } from "../../service";
import { ACR_SAP_CONFIG, API_SECRET_TOKEN, VC_BASE_URL } from "../../config";

function* getAcrSapApprovalListData(actions: { type: string, payload: Actions.IGetAcrSapApprovalListpayload }) {
    try {
        yield put(Actions.getAcrSapApprovalListLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_APPROVAL_LIST,
            actions.payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(Actions.getAcrSapApprovalListSuccessAction(data));
    } catch (error: any) {
        yield put(Actions.getAcrSapApprovalListErrorAction(error));
    }
}

function* getAcrSapRequestInitiatorData(actions: { type: string; payload: Actions.IGetAcrSapRequestInitiatorpayload }) {
    try {
        yield put(Actions.getAcrSapRequestInitiatorLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_REQUEST_INITIATOR,
            actions.payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(Actions.getAcrSapRequestInitiatorSuccessAction(data));
    } catch (error: any) {
        yield put(Actions.getAcrSapRequestInitiatorErrorAction(error));
    }
}

function* getAcrSapEmpRowCountData(payload: Actions.IGetAcrSapEmpRowCountpayload) {
    try {
        yield put(Actions.getAcrSapEmpRowCountLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_EMP_ROW_COUNT,
            payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(Actions.getAcrSapEmpRowCountSuccessAction(data));
    } catch (error: any) {
        yield put(Actions.getAcrSapEmpRowCountErrorAction(error));
    }
}

function* getAcrSapRequestDetailsData(action: { type: string; payload: { payload: Actions.IGetAcrSapRequestDetailspayload; } }) {
    try {
        yield put(Actions.getAcrSapRequestDetailsLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_REQUEST_DETAILS,
            action.payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(Actions.getAcrSapRequestDetailsSuccessAction(data));
    } catch (error: any) {
        yield put(Actions.getAcrSapRequestDetailsErrorAction(error));
    }
}

function* postAcrSapRequestUpdateData(payload: Actions.IPostAcrSapRequestUpdatepayload) {
    try {
        yield put(Actions.postAcrSapRequestUpdateLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + ACR_SAP_CONFIG.POST_ACR_SAP_REQUEST_UPDATE,
            payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(Actions.postAcrSapRequestUpdateSuccessAction(data));
    } catch (error: any) {
        yield put(Actions.postAcrSapRequestUpdateErrorAction(error));
    }
}
// =================================================WATCH FUNCTIONS=======================================

export function* watchGetAcrSapApprovalListData() {
    // while (true) {
    //     const { payload } = yield take(
    //         Actions.Actions.GET_ACR_SAP_APPROVAL_LIST_DATA
    //     );
    //     yield call(getAcrSapApprovalListData, payload);
    // }
    yield takeLatest(Actions.Actions.GET_ACR_SAP_APPROVAL_LIST_DATA, getAcrSapApprovalListData);
}

export function* watchGetAcrSapRequestInitiatorData() {
    // while (true) {
    //     const { payload } = yield take(
    //         Actions.Actions.GET_ACR_SAP_REQUEST_INITIATOR_DATA
    //     );
    //     yield call(getAcrSapRequestInitiatorData, payload);
    // }
    yield takeLatest(Actions.Actions.GET_ACR_SAP_REQUEST_INITIATOR_DATA, getAcrSapRequestInitiatorData);
}

export function* watchGetAcrSapEmpRowCountData() {
    while (true) {
        const { payload } = yield take(
            Actions.Actions.GET_ACR_SAP_EMP_ROW_COUNT_DATA
        );
        yield call(getAcrSapEmpRowCountData, payload);
    }
}


export function* watchGetAcrSapRequestDetailsData() {
    // while (true) {
    //     const { payload } = yield take(
    //         Actions.Actions.GET_ACR_SAP_REQUEST_DETAILS_DATA
    //     );
    //     yield call(getAcrSapRequestDetailsData, payload);
    // }
    yield takeLatest(Actions.Actions.GET_ACR_SAP_REQUEST_DETAILS_DATA, getAcrSapRequestDetailsData);
}

export function* watchPostAcrSapRequestUpdateData() {
    while (true) {
        const { payload } = yield take(
            Actions.Actions.POST_ACR_SAP_REQUEST_UPDATE_DATA
        );
        yield call(postAcrSapRequestUpdateData, payload);
    }
}
