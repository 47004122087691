import { put, call, take, delay, takeEvery } from "redux-saga/effects";
import * as actionCreators from "../actions/SendMailAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_SECRET_TOKEN, CHANNEL_CONFIG, SENDMAIL_CONFIG, VC_BASE_URL } from "../../config";

function* getSendMailData(payload: actionCreators.ISendMailPayload) {
    try {
        yield put(actionCreators.getSendMailLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + SENDMAIL_CONFIG.GET_SEND_MAIL_DATA,
            payload, {
            headers: {
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            }
        }
        );
        yield put(actionCreators.getSendMailSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getSendMailErrorAction(error));
    }
}


export function* watchGetSendMailData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SEND_MAIL_DATA
        );
        yield call(getSendMailData, payload);
    }
}