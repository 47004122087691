import { put, call, take, delay } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/AnnouncementAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, ANNOUNCEMENT_API_CONFIG } from "../../config";

function* getAnnouncementsData(payload: {
  access_token: string;
  emp_comp: string;
  announcementData: {
    announcement_type: string;
    page_number: number;
    employee_id: string;
  };
}) {
    try {
        yield put(actionCreators.getAnnouncementsDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_comp}${ANNOUNCEMENT_API_CONFIG.GET_ANNOUNCEMENT_LIST}`,
            payload.announcementData,
            config
        );
        yield put(actionCreators.getAnnouncementsDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getAnnouncementsDataErrorAction(error));
    }
}
function* getAnnouncementsFeedbackData(payload: {
  access_token: string;
  emp_comp: string;
  feedbackData: actionCreators.IGetAnnouncemnetFeedbackPayload;
}) {
    try {
        yield put(actionCreators.getAnnouncementsFeedbackDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_comp}${ANNOUNCEMENT_API_CONFIG.GET_ANNOUNCEMENT_FEEDBACK_LIST}`,
            payload.feedbackData,
            config
        );
        yield put(actionCreators.getAnnouncementsFeedbackDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getAnnouncementsFeedbackDataErrorAction(error));
    }
}
function* postAnnouncementsFeedbackData(payload: {
  access_token: string;
  emp_comp: string;
  postPayload: actionCreators.IPostFeedbackPayload;
}) {
    try {
        yield put(actionCreators.postAnnouncementsFeedbackDataLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}/${payload.emp_comp}${ANNOUNCEMENT_API_CONFIG.POST_ANNOUNCEMENT_FEEDBACK_LIST}`,
            payload.postPayload,
            config
        );
        yield put(actionCreators.postAnnouncementsFeedbackDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postAnnouncementsFeedbackDataErrorAction(error));
    }
}

export function* watchGetAnnouncementsData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ANNOUNCEMENT_DATA
        );
        yield call(getAnnouncementsData, payload);
    }
}
export function* watchGetAnnouncementsFeedbackData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA
        );
        yield call(getAnnouncementsFeedbackData, payload);
    }
}
export function* watchPostAnnouncementsFeedbackData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA
        );
        yield call(postAnnouncementsFeedbackData, payload);
    }
}
