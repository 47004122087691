import React from "react";
import { Flex, Segment, Image, Text } from "@fluentui/react-northstar";
import { Constant, EmpCompanyByCode } from "../../constant";
import tatamotorlogo from "../../assets/icons/Tata_Motors_Logo.png";
import { IGetPhysicalVcDataitem } from "../../redux/actions/VisitorAction";
import { useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import "./viewPhysicalPreview.scss";
interface IViewPhysicalPreview {
    item: IGetPhysicalVcDataitem
}
const ViewPhysicalPreview: React.FC<IViewPhysicalPreview> = ({ item }) => {
    const state = useSelector((state: RootSchema) => state);
    return (<Flex column>
        <Segment className="card_design">
            <Flex className="card_design_content" column gap="gap.smaller" vAlign="center" hAlign="center">
                <Image src={tatamotorlogo} height="80px" width="80px" color="brand" />
                <Text className="card_design_content_1" content={item.EmployeeName} color="brand" weight="semibold" />
                <Text className="card_design_content" content={item.Designation.concat(item.Division && ", " + item.Division).concat(item.OU1_Text && ", " + item.OU1_Text).concat(item.OU2_Text && ", " + item.OU2_Text)} color="brand" align="center" />
                <Text className="card_design_content_1" content={EmpCompanyByCode[state.authData.userAuth.comp_code as keyof typeof EmpCompanyByCode]} color="brand" weight="semibold" />
                <Text className="card_design_content" content={item.OfficeAddress} color="brand" align="center" />
                {(item.Teleno !== "" || item.Extension1 !== "") && <Flex gap="gap.small" wrap hAlign="center">
                    {item.Teleno !== "" && <Text className="card_design_content" content={"Tel Direct: " + item.Teleno} color="brand" />}
                    {item.Extension1 !== "" && <Text className="card_design_content" content={"Extn No: " + item.Extension1} color="brand" />}
                </Flex>}
                <Text className="card_design_content" content={"Mobile: " + item.MobileNo} color="brand" />
                <Text className="card_design_content" content={"E-mail: " + (state.leaveData.employeeData.mail ?? "")} color="brand" />
                <Text className="card_design_content" content={"Website: " + Constant.TML_URL} color="brand" />
            </Flex>
        </Segment>
    </Flex>
    );
};
export default ViewPhysicalPreview;