import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    // -------------------Get Service Availability-------------------------
    GET_SERVICE_AVAILABILITY: "GET_SERVICE_AVAILABILITY",
    GET_SERVICE_AVAILABILITY_LOADING: "GET_SERVICE_AVAILABILITY_LOADING",
    GET_SERVICE_AVAILABILITY_ERROR: "GET_SERVICE_AVAILABILITY_ERROR",
    GET_SERVICE_AVAILABILITY_SUCCESS: "GET_SERVICE_AVAILABILITY_SUCCESS"
};
export interface IServicesStatusData {
    service_name: string;
    service_type: string;
    service_id: string;
    reason: string;
    is_active: string;
}

export interface IGetServiceAvailabilityData extends SideEffectSchema {
    status_code: string;
    services_status: IServicesStatusData[]
}

export const getServiceAvailabilityDataAction = (
    access_token: string,
    emp_comp: string
) => {
    return {
        type: Actions.GET_SERVICE_AVAILABILITY,
        payload: { access_token, emp_comp }
    };
};
export const getServiceAvailabilityDataLoadingAction = () =>
    loadingAction(Actions.GET_SERVICE_AVAILABILITY_LOADING);
export const getServiceAvailabilityErrorAction = (error: string) =>
    errorAction(Actions.GET_SERVICE_AVAILABILITY_ERROR, error);
export const getServiceAvailabilityDataSuccessgAction = (data: any) => {
    const payload: IGetServiceAvailabilityData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_SERVICE_AVAILABILITY_SUCCESS,
        payload: payload
    };
};