import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_CHANNEL_DATA: "GET_CHANNEL_DATA",
    GET_CHANNEL_LOADING: " GET_CHANNEL_LOADING",
    GET_CHANNEL_ERROR: " GET_CHANNEL_ERROR",
    GET_CHANNEL_SUCCESS: " GET_CHANNEL_SUCCESS",

    GET_VIDEO_LIBRARY_DATA: "GET_VIDEO_LIBRARY_DATA",
    GET_VIDEO_LIBRARY_LOADING: " GET_VIDEO_LIBRARY_LOADING",
    GET_VIDEO_LIBRARY_ERROR: " GET_VIDEO_LIBRARY_ERROR",
    GET_VIDEO_LIBRARY_SUCCESS: " GET_VIDEO_LIBRARY_SUCCESS",
};

export interface IGetChannelData extends SideEffectSchema {
    statusCode: string,
    data:
    {
        id: number,
        title: string,
        description: string,
        channelLink: string,
        isActive: boolean,
    }[],

}

export const getChannelDataAction = () => {
    return {
        type: Actions.GET_CHANNEL_DATA,
    };
};
export const getChannelLoadingAction = () =>
    loadingAction(Actions.GET_CHANNEL_LOADING);

export const getChannelErrorAction = (error: string) =>
    errorAction(Actions.GET_CHANNEL_ERROR, error);

export const getChannelSuccessAction = (data: any) => {
    const payload: IGetChannelData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_CHANNEL_SUCCESS,
        payload: payload
    };
};
/////////////////////////////////////////////////////////////////////////////////////////////

export interface IGetVideoLibraryObjectFile { Name: string; ServerRelativeUrl: string; }
export interface IGetVideoLibraryObjectThumbnail { Description: string; Url: string; }
export interface IGetVideoLibraryObject {
    Category: string;
    Date: string;
    Id: number;
    ID: number;
    Title: string;
    VideoDescription: string;
    File: IGetVideoLibraryObjectFile;
    Thumbnail: IGetVideoLibraryObjectThumbnail;
}
export interface IGetVideoLibraryData extends SideEffectSchema {
    value: IGetVideoLibraryObject[];
}

export const getVideoLibraryDataAction = (access_token: string) => {
    return {
        type: Actions.GET_VIDEO_LIBRARY_DATA,
        payload: { access_token },
    };
};
export const getVideoLibraryLoadingAction = () =>
    loadingAction(Actions.GET_VIDEO_LIBRARY_LOADING);

export const getVideoLibraryErrorAction = (error: string) =>
    errorAction(Actions.GET_VIDEO_LIBRARY_ERROR, error);

export const getVideoLibrarySuccessAction = (data: any) => {
    const payload: IGetVideoLibraryData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_VIDEO_LIBRARY_SUCCESS,
        payload: payload
    };
};