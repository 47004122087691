import { put, call, take, delay, takeEvery } from "redux-saga/effects";
import * as actionCreators from "../actions/ITSMAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { ITSM_BASE_URL, ITSM_API_CONFIG, API_SECRET_TOKEN } from "../../config";

function* getTokenData(payload: {
    tokenData: actionCreators.IGetTokenData
}) {
    try {
        yield put(actionCreators.getTokenDataLoadingAction());
        const { data } = yield httpGet(
            ITSM_API_CONFIG.GET_TOKEN,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getTokenSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getTokenErrorAction(error));
    }
}

function* getItsmCategoryData(payload: any) {
    try {
        yield put(actionCreators.getItsmCategoryLoadingAction());
        const { data } = yield httpGet(
            ITSM_API_CONFIG.GET_CATEGORY_LIST,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getItsmCategorySuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getItsmCategoryErrorAction(error));
    }
}

function* getItsmSubCategoryData(payload: number) {
    try {
        yield put(actionCreators.getItsmSubCategoryLoadingAction());
        const { data } = yield httpGet(
            ITSM_API_CONFIG.GET_SUB_CATEGORY_LIST + "/" + payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getItsmSubCategorySuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getItsmSubCategoryErrorAction(error));
    }
}

function* getItsmItemData(payload: number) {
    try {
        yield put(actionCreators.getItsmItemLoadingAction());
        const { data } = yield httpGet(
            ITSM_API_CONFIG.GET_ITEMS_LIST + "/" + payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getItsmItemSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getItsmItemErrorAction(error));
    }
}

function* getTicketsData(payload: {
    access_token: string;
    get_ticket_data: actionCreators.IGetTicketsPayload
}) {
    try {
        yield put(actionCreators.getTicketsLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                env: process.env.REACT_APP_ENV,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.GET_TICKETS,
            payload.get_ticket_data,
            config
        );
        yield put(actionCreators.getTicketsSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getTicketsErrorAction(error));
    }
}

function* addNotesData(payload: {
    access_token: string;
    add_notes_data: actionCreators.IAddNotesPayload
}) {
    try {
        yield put(actionCreators.addNotesLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                env: process.env.REACT_APP_ENV,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.ADD_NOTES,
            payload.add_notes_data,
            config
        );
        yield put(actionCreators.addNotesSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.addNotesErrorAction(error));
    }
}

function* createTicketData(payload: {
    access_token: string;
    create_ticket_data: actionCreators.ICreateTicketPayload
}) {
    try {
        yield put(actionCreators.createTicketLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.CREATE_TICKET,
            payload.create_ticket_data,
            config
        );
        yield put(actionCreators.createTicketSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.createTicketErrorAction(error));
    }
}

function* updateTicketData(payload: {
    access_token: string;
    tokenData: actionCreators.IUpdateTicketData
}) {
    try {
        yield put(actionCreators.updateTicketLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.UPDATE_TICKET,
            payload.tokenData,
            config
        );
        yield put(actionCreators.updateTicketSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.updateTicketErrorAction(error));
    }
}

function* addAttachmentData(payload: any) {
    const fd = new FormData();
    fd.append("file", payload.payload.file);
    fd.append("loggedInUsername", payload.payload.loggedInUsername);
    fd.append("ticketIdNumber", payload.payload.ticketIdNumber);
    try {
        yield put(actionCreators.addAttachmentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                "Content-Type": "multipart/form-data",
                env: process.env.REACT_APP_ENV,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.ADD_ATTACHMENT,
            fd,
            config
        );
        yield put(actionCreators.addAttachmentSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.addAttachmentSuccessAction(error));
    }
}

function* getUserApprovalList(payload: {
    access_token: string;
    loggedInUsername: string
}) {
    try {
        yield put(actionCreators.getUserApprovalLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                env: process.env.REACT_APP_ENV,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.GET_USER_APPROVAL_LIST,
            { "loggedInUsername": payload.loggedInUsername },
            config
        );
        yield put(actionCreators.getUserApprovalSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getUserApprovalErrorAction(error));
    }
}

function* updateApproval(payload: {
    access_token: string;
    payload: actionCreators.IUpdateApprovalData
}) {
    try {
        yield put(actionCreators.updateApprovalLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                env: process.env.REACT_APP_ENV,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.UPDATE_APPROVAL,
            payload.payload,
            config
        );
        yield put(actionCreators.updateApprovalSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.updateApprovalErrorAction(error));
    }
}
////////////////////////////////////////////////////////
function* createTicketCatalogData(payload: {
    access_token: string;
    payload: actionCreators.ICreateTicketCatalogPayload
}) {
    try {
        yield put(actionCreators.createTicketCatalogLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                env: process.env.REACT_APP_ENV,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.GET_TICKET_CATALOG,
            payload.payload,
            config
        );
        yield put(actionCreators.createTicketCatalogSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.createTicketCatalogErrorAction(error));
    }
}
/////////////////////////////////////////////////////////////////////////////////

function* getTicketApprovalListData(payload: {
    access_token: string;
    payload: actionCreators.ITicketApprovalPayload
}) {
    try {
        yield put(actionCreators.getTicketApprovalLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                env: process.env.REACT_APP_ENV,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + ITSM_API_CONFIG.GET_TICKET_APPROVAL,
            payload.payload,
            config
        );
        yield put(actionCreators.getTicketApprovalSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getTicketApprovalErrorAction(error));
    }
}

export function* watchGetTokenData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_TOKEN_DATA
        );
        yield call(getTokenData, payload);
    }
}

export function* watchGetTicketsData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_TICKETS_DATA
        );
        yield call(getTicketsData, payload);
    }
}

export function* watchAddNotesData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.ADD_NOTES_DATA
        );
        yield call(addNotesData, payload);
    }
}

export function* watchCreateTicketData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.CREATE_TICKET_DATA
        );
        yield call(createTicketData, payload);
    }
}

export function* watchUpdateTicketData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.UPDATE_TICKET_DATA
        );
        yield call(updateTicketData, payload);
    }
}

export function* watchAddAttachmentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.ADD_ATTACHMENT_DATA
        );
        yield call(addAttachmentData, payload);
    }
}

export function* watchGetUserApprovalList() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_USER_APPROVAL_LIST_DATA
        );
        yield call(getUserApprovalList, payload);
    }
}

export function* watchUpdateApproval() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.UPDATE_APPROVAL_DATA
        );
        yield call(updateApproval, payload);
    }
}

export function* watchGetItsmCategoryData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ITSM_CATEGORY_DATA
        );
        yield call(getItsmCategoryData, payload);
    }
}

export function* watchGetItsmSubCategoryData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ITSM_SUB_CATEGORY_DATA
        );
        yield call(getItsmSubCategoryData, payload);
    }
}

export function* watchGetItsmItemData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ITSM_ITEM_LIST_DATA
        );
        yield call(getItsmItemData, payload);
    }
}

export function* watchCreateTicketCatalog() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.CREATE_TICKET_CATALOG_DATA
        );
        yield call(createTicketCatalogData, payload);
    }
}

export function* watchGetTciketApproval() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_TICKET_APPROVAL_LIST_DATA
        );
        yield call(getTicketApprovalListData, payload);
    }
}
