import moment from "moment";
import axios from "axios";
import { ErrorToast, SuccessToast } from "../components/ToastifyComponent";
export const LeaveTypes = [
    "Work From Home",
    "Sick Leave",
    "Casual Leave",
    "Privilege Leave",
    "Privilege Leave Reservoir",
    "Paternity Leave",
    "Maternity Leave",
    "Relocation Leave",
    "Compensatory Off",
    "Additional Comp Off",
    "Annual Leave",
    "Early Going",
    "Late Coming",
    "Bus Late",
];

export const LeaveMode = [
    {
        key: "1",
        label: "Full Day",
        value: "FD",
    },
    {
        key: "2",
        label: "First Half",
        value: "FH",
    },
    {
        key: "3",
        label: "Second Half",
        value: "SH",
    },
];

export const FeedbackConstant = [
    {
        key: "goals",
        content: "Goals",
    },
    {
        key: "leadership",
        content: "Leadership behaviour",
    },
];

const ErrorMsg = "Oops! Something went wrong...";

interface IConstant {
    VC_PHYSICAL_IN_PROGRESS: string;
    VC_TIME_ZONE: string;
    SAP_CLIENT: string;
    TML_URL: string;
    TML_FF: string;
    DOWNLOAD_PAYSLIP_WORKSHEET_FILE_SUCCESS: string;
    DOWNLOAD_STATEMENT_FILE_SUCCESS: string;
    CAUGHT_UP: string;
    LEAVE_REMOVE: string;
    EMPLOYEE_TYPE: string;
    DATA_NOT_FOUND: string;
    LEAVE_REMOVE_SUCCESS: string;
    ERROR_DATE_ALREADY_SELECTED: string;
    ERROR_DATE_SELECTED_WITH_DIFFERENCE: string;
    VACCINATION_ADD_DOSE_MESSAGE: string;
    VACCINATION_DOSE_UPDATE_MESSAGE: string;
    APPLY_LEAVE_VALIDATE_NOTE: string;
    APPLY_LEAVE_RESET: string;
    LEAVE_VALIDATE_SUCCESS: string;
    ANNOUNCEMENT_CAUGHT_UP: string;
    TODO_CAUGHT_UP: string;
    APPROVAL_CAUGHT_UP: string;
    GOALS_CAUGHT_UP: string;
    LEADERSHIP_CAUGHT_UP: string;
    NO_FEEDBACK: string;
    NO_NOTIFICATION: string;
    FEEDBACK_CONFRIM: string;
    FEEDBACK_CONFRIM_EMP: string;
    SUPPORT_CHANNEL_NAME: string;
    REACTION_SUCCESS_MESSAGE_FOR_MTM: string;
    VC_PHYSICAL_BUTTON_VALIDATE: string[];
    BIRTHDAY_FEED_CONTENT: string;
    WORK_ANNIVERSARY_CONTENT: string;
    LONG_WORK_ANNIVERSARY_CONTENT: string;
    DOWNLOAD_ERROR_MESSAGE: string;
    ERROR_502: string;
    ERROR_403: string;
    ERROR_404: string;
    ERROR_400: string;
    VC_PHYSICAL_PREVIEW_NOTE: string;
    VC_PHYSICAL_WARNING_NOTE: string;
    VC_QR_SCAN_CLICK_NOTE: string;
    VC_QR_MAIL_SUBJECT: string;
    VC_QR_SCAN_NOTE: string;
    PAY_SPACE_ERROR_IN_API: string;
    VC_DOWNLOAD_SUCCESS_MESSAGE: string;
    IMAC_APPROVALS_DATA_NOT_FOUND: string;
    TEAM_MEMBER_DATA_NOT_FOUND: string;
}

export const Constant: IConstant = {
    VC_PHYSICAL_BUTTON_VALIDATE: ["Pending for Approval", "Pending For Verification", "Approved By Admin", "Send for Printing"],
    VC_PHYSICAL_IN_PROGRESS: "New Printed Visiting Card request cannot be generated as an existing request is in progress. Please check the status of your request in the 'VC Status' tab.",
    VC_PHYSICAL_PREVIEW_NOTE: "Note: The Visiting Card will be printed as per the Visiting Card Guidelines and will be ready in 7-8 working days.",
    VC_PHYSICAL_WARNING_NOTE: "GO GREEN & contribute to Paperless invitation ---Please choose Digital Option & get QR Code for your Visiting Card generated instantly. Do you still want to print your Visiting Card?",
    VC_QR_SCAN_NOTE: "Scan QR to view contact details",
    VC_QR_SCAN_CLICK_NOTE: "Scan or Click the QR code to view the details.",
    VC_QR_MAIL_SUBJECT: "QR Code for Digital Visiting Card",
    VC_TIME_ZONE: "Africa/Monrovia",
    SAP_CLIENT: "800",
    TML_URL: "www.tatamotors.com",
    TML_FF: "Tata Motors Limited",
    SUPPORT_CHANNEL_NAME: "teams",
    REACTION_SUCCESS_MESSAGE_FOR_MTM: "Thank you for the feedback",
    DOWNLOAD_PAYSLIP_WORKSHEET_FILE_SUCCESS: "Your document has been downloaded. Use your PAN number to open the file.",
    DOWNLOAD_STATEMENT_FILE_SUCCESS: "Annual statements retrieved successfully",
    FEEDBACK_CONFRIM: "Please note, once feedback is submitted it can be modified or deleted for a limited duration (Upto 5 days from feedback creation). Kindly confirm if you want to proceed?",
    FEEDBACK_CONFRIM_EMP: "Please note, once feedback is submitted it cannot be modified or deleted. Kindly confirm if you want to proceed?",
    NO_NOTIFICATION: "No notification shared yet!",
    APPROVAL_CAUGHT_UP: " You have taken care of all the approvals",
    ANNOUNCEMENT_CAUGHT_UP: "No announcement have been shared yet",
    GOALS_CAUGHT_UP: "Your goal form is not yet completed !",
    LEADERSHIP_CAUGHT_UP: "Leadership form is not yet completed !",
    NO_FEEDBACK: "No feedbacks shared yet!",
    TODO_CAUGHT_UP: "No tasks yet",
    CAUGHT_UP: "You're all caught up..!",
    LEAVE_REMOVE: "Leave remove",
    EMPLOYEE_TYPE: "White Collor",
    DATA_NOT_FOUND: "You're all caught up!",
    LEAVE_REMOVE_SUCCESS: "Leave Remove",
    ERROR_DATE_ALREADY_SELECTED: "date is already selected",
    ERROR_DATE_SELECTED_WITH_DIFFERENCE:
        "date is already selected with different leave category",
    APPLY_LEAVE_RESET: "Leave Form Reset successfully",
    VACCINATION_ADD_DOSE_MESSAGE: "Dose details successfully submitted",
    VACCINATION_DOSE_UPDATE_MESSAGE: "Dose details successfully updated",
    APPLY_LEAVE_VALIDATE_NOTE:
        "Note: Number of days can change in case of weekly off/holiday calendar changes",
    LEAVE_VALIDATE_SUCCESS:
        "Leave/Regularization request validated successfully. Kindly click on \"Preview\" button to complete submission.",
    DOWNLOAD_ERROR_MESSAGE: "Error while downloading Document/Statement file",
    ERROR_502: ErrorMsg,
    ERROR_403: ErrorMsg,
    ERROR_404: ErrorMsg,
    ERROR_400: ErrorMsg,
    LONG_WORK_ANNIVERSARY_CONTENT: "<p>Thank you for being a valuable member of the Team Tata Motors. <br>Congratulations on your 5 years work anniversary <br>and best wishes for coming years.</p><p>We look Forward to your continued success at Tata Motors!<br>#MoreWhenOne</p>",
    WORK_ANNIVERSARY_CONTENT: "<p>Here’s wishing the happiest of work anniversary to someone who’s more than just a committed employee, but also a cherished member of our team.</p><p>Congratulations on your work anniversary this year. Thank you for your hard work and dedication as a valued person at the organization.<br>HAPPY ANNIVERSARY!</p>",
    BIRTHDAY_FEED_CONTENT: "<p>With each passing year, you are achieving greater heights. We are so grateful to have a family member like you, dedicated, hard-working, innovative, hustler, winner.</p><p>We want you to grow and be a better version of yourself than yesterday. <br>Happy Birthday!</p>",
    PAY_SPACE_ERROR_IN_API: "Error in api..",
    VC_DOWNLOAD_SUCCESS_MESSAGE: "Digital visiting card download successfully",
    IMAC_APPROVALS_DATA_NOT_FOUND: "You don't have any pending approvals in your bucket",
    TEAM_MEMBER_DATA_NOT_FOUND: "You don’t have any team members mapped"
};
export interface IProps {
    appId: string;
    authKey: string;
    sapToken: string;
    domain: string;
    notificationAction?: string;
}
export interface ILoader {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorCode?: number;
}
export const dateConverstion = (data: string): string => {
    try {
        if (String(data).trim() !== "") {
            const year = parseInt(data.substr(0, 4));
            const month = parseInt(data.substr(4, 2));
            const day = parseInt(data.substr(6, 2));
            const date = new Date();
            date.setFullYear(year);
            date.setMonth(month);
            date.setDate(day);
            return `${day}/${month}/${year}`;
        } else return "";
    } catch (err) {
        return "";
    }
};

export const ThemaValue = {
    0: "light",
    1: "dark",
    2: "highContrast",
};

interface ILeaveTypeCatogary {
    [key: string]: string;
}

export enum LeaveTypeCatogary {
    "All" = "All",
    "Work From Home" = "WFH",
    "Sick Leave" = "M0",
    "Casual Leave" = "L0",
    "Privilege Leave" = "P0",
    "Privilege Leave Reservoir" = "P1",
    "Paternity Leave" = "D3",
    "Maternity Leave" = "D0",
    "Relocation Leave" = "RL",
    "Compensatory Off" = "COFF",
    "Additional Comp Off" = "ACOF",
    "Annual Leave" = "AL",
    "Early Going" = "E0",
    "Late Coming" = "V0",
    "Bus Late" = "F0",
}
export enum LeaveTypeCode {
    "fullday" = "FD",
    "secondhalf" = "SH",
    "firsthalf" = "FH",
}

export const InputLeaveType = [
    "Annual Leave",
    "CL (Casual Leave)",
    "SL (Sick Leave)",
    "PL (Privilege Leave)",
    "PL (RESERVOIR)",
    "PL for Contractual Emp",
    "Prev. Leave for Trainess",
    "CERTIFIED NO SWIPE",
    "CERT ATTD (AWAY ON DUTY)",
    "Work From Home",
    "EARLY GOING",
    "LATE COMING",
    "BUS LATE",
    "Flexibletime -PUNE",
    "COMPENSATORY OFF",
    "ADDITIONAL COMP OFF",
    "RELOCATION LEAVE",
    "Trainee leave",
    "PL (To Accrue)",
    "Maternity Leave",
    "Flexibletime -ERCU",
];

export const InputStatus = [
    "Approved",
    "Pending For Approval",
    "Approved & Post Pending",
    "Approved & Posted",
    "Rejected",
    "Delete approval pending",
    "Deleted and update in progress",
    "Deleted",
    "Error in posting",
    "Error in deletion",
];

export enum InputStatusCode {
    "All" = "All",
    "Approved" = "4",
    "Pending For Approval" = "0",
    "Approved & Post Pending" = "1",
    "Approved & Posted" = "2",
    "Rejected" = "3",
    "Delete approval pending" = "5",
    "Deleted and update in progress" = "6",
    "Deleted" = "7",
    "Error in posting" = "8",
    "Error in deletion" = "9",
}
export const InputMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export function getDateFormat(date: any) {
    return moment(date).format("YYYY-MM-DD");
}

export function getDDMMYYYFormat(date: any) {
    return moment(date).format("DD-MM-YYYY");
}

export function getCommaSeparatorNumber(value: string) {
    return Number.isNaN(parseInt(value)) ? "" : Number(parseFloat(value).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2
    });
}

export function getAddTwoDecimalNumber(value: string) {
    return parseFloat(value).toFixed(2);
}

export function getIntegerNoToString(value: string) {
    return parseInt(value).toString();
}

export const DosageBrand = ["Covaxin", "Covishield", "Sputnik"];

export const StatusList = [
    {
        color: "rgb(106, 51, 153)",
        isActive: true,
        text: "Public Holiday",
    },
    {
        color: "rgb(141, 77, 241)",
        isActive: true,
        text: "Weekly off",
    },
    {
        color: "rgb(4, 120, 87)",
        isActive: true,
        text: "Approved",
    },
    {
        color: "rgb(245, 158, 11)",
        isActive: false,
        text: "Pending for Approval",
    },
    {
        color: "rgb(245, 158, 11)",
        isActive: true,
        text: "In Progress",
    },
    {
        color: "rgb(4, 120, 87)",
        isActive: false,
        text: "Approved and Post Pending",
    },
    {
        color: "rgb(71, 85, 105)",
        isActive: true,
        text: "Rejected",
    },
    {
        color: "rgb(36, 138, 248)",
        isActive: true,
        text: "Cancel",
    },
    {
        color: "rgb(36, 138, 248)",
        isActive: false,
        text: "Delete Approval pending",
    },
    {
        color: "rgb(48, 187, 195)",
        isActive: true,
        text: "Error",
    },
    {
        color: "rgb(48, 187, 195)",
        isActive: false,
        text: "Error in deletion",
    },
    {
        color: "rgb(190, 18, 60)",
        isActive: true,
        text: "LWOP",
    },
];

export const WeeksNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export enum EmpValueByCode {
    "0100" = "cv",
    "0550" = "pv",
    "0650" = "ev",
    "0300" = "tmlbsl"
}

export enum EmpCompanyByCode {
    "0100" = "Tata Motors Limited",
    "0550" = "Tata Motors Passenger Vehicles Limited",
    "0650" = "Tata Passenger Electric Mobility Limited",
    "0300" = "TML Business Services Limited",
}

export const Capitalize = (input: string) => {
    // var words = String(input || "")
    //   .trim()
    //   .toLowerCase()
    //   .replace(/_/i, " ")
    //   .split(" ");
    // var CapitalizedWords: string[] = [];
    // words.forEach((element) => {
    //   if (element && String(element[0]).trim() !== "")
    //     CapitalizedWords.push(
    //       element[0].toUpperCase() +
    //       element.slice(1, element.length).toLowerCase()
    //     );
    // });
    // return CapitalizedWords.join(" ");
    return input;
};


export const TodoDueDateFillter = [
    {
        key: "upcoming",
        title: "Upcoming",
        value: "upcoming",
    },
    {
        key: "today",
        title: "Today",
        value: "today",
    },
    {
        key: "yesterday",
        title: "Yesterday",
        value: "yesterday",
    },
    {
        key: "earlier",
        title: "Earlier",
        value: "earlier",
    },
];

export const TodoPriorityFillter = [
    {
        key: "high",
        value: "high",
        title: "High",
    },
    {
        key: "normal",
        value: "normal",
        title: "Normal",
    },
    {
        key: "low",
        value: "low",
        title: "Low",
    },
];

export const TodoStatusFillter = [
    {
        key: "notStarted",
        value: "notStarted",
        title: "Not Started",
    },
    {
        key: "inProgress",
        value: "inProgress",
        title: "In Progress",
    },
    {
        key: "completed",
        value: "completed",
        title: "Completed",
    },
    {
        key: "waitingForOthers",
        value: "waitingForOthers",
        title: "Waiting for others",
    },
    {
        key: "deferred",
        value: "deferred",
        title: "Deferred",
    },
];

export enum TraceTelemtry {
    THEME = "Theme Change",
}

export enum TraceEvent {
    SSO_SUCCESS = "sso login",
    SSO_SUCCESS_ISSUE = "sso issue",
    TML_KEY_CLOCK = "tml key-clock get",
    TML_KEY_CLOCK_ISSUE = "tml key-clock issue",
    TML_GRAPH_DATA = "token exchange",
    TML_GRAPH_DATA_ISSUE = "token exchange issue",
    TML_TASK_MODULE = "task module",
    TML_CFM_GOALS_SELF_ADD_COMMENT = "cfm goals comment add",
    //TML_CFM_GOALS_SELF_UPDATE_COMMENT = "cfm goals comment update",
    //TML_CFM_GOALS_SELF_DELETE_COMMENT = "cfm goals comment delete",
    TML_CFM_LEADERSHIP_SELF_ADD_COMMENT = "cfm leadership comment add",
    //TML_CFM_LEADERSHIP_SELF_UPDATE_COMMENT = "cfm leadership comment update",
    //TML_CFM_LEADERSHIP_SELF_DELETE_COMMENT = "cfm leadership comment delete",
    //TML_CFM_GOALS_MANAGER_COMMENT = "cfm goals comment manager  ",
    TML_CFM_GOALS_MANAGER_UPDATE_COMMENT = "cfm goals comment update",
    TML_CFM_GOALS_MANAGER_DELETE_COMMENT = "cfm goals comment delete",
    //TML_CFM_LEADERSHIP_MANAGER_COMMENT = "cfm leadership comment manager  ",
    TML_CFM_LEADERSHIP_MANAGER_UPDATE_COMMENT = "cfm leadership comment update",
    TML_CFM_LEADERSHIP_MANAGER_DELETE_COMMENT = "cfm leadership comment delete",
    TML_VACCINE_ADD = "vaccine add",
    TML_VACCINE_UPDATE = "vaccine update",
    LEAVE_APPLY = "leave apply",
    LEAVE_APPROVE = "leave approve",
    LEAVE_REJECT = "leave reject",
    LEAVE_APPROVE_ERROR = "leave approve error",
    LEAVE_APPLY_ERROR = "leave apply error",
    LEAVE_DELETE_APPROVE = "leave delete approve",
    LEAVE_DELETE_REJECT = "leave delete reject",
    LEAVE_DELETE_ERROR = "leave delete approve error",
    API_ISSUE = "API response error",
    ITSM_INCIDENT_CREATE = "Raise incident ticket",
    ITSM_REQUEST_CREATE = "Raise imac ticket",
    ITSM_IMAC_APPROVE = "Imac approve",
    ITSM_IMAC_REJECT = "Imac reject",
    ITSM_ADD_NOTE = "Add note",
    ITSM_ATTACH_FILE = "Attach file",
    MTM_SEND_WISH = "Send wish",
    MTM_SNED_WISH_FAIL = "Send wish fail",
    VC_DOWNLOAD_QR = "VC Download QR",
    VC_DOWNLOAD_QR_ERROR = "VC Download QR",
    VC_SEND_MAIL_QR = "VC Send Mail QR",
    VC_SEND_MAIL_QR_ERROR = "VC Send Mail Error",
    VC_SAVE_DIGITAL_CARD = "Save D_VC",
    VC_SAVE_DIGITAL_CARD_ERROR = "Save D_VC Error",
    VC_SAVE_PHYSICAL_CARD = "Save P_VC",
    VC_SAVE_PHYSICAL_CARD_ERROR = "Save P_VC Error",

    ACR_SAP_REQ_APPROVE = "ACR Approved",
    ACR_SAP_REQ_REJECT = "ACR Rejected",
    ACR_SAP_REQ_APPROVE_ERROR = "ACR Approved Error",
    ACR_SAP_REQ_REJECT_ERROR = "ACR Rejected Error",
}

export enum EmpTrushDocument {
    PS = "TELCOEPS",
    PFS = "TELCOPF",
    SAS = "TELCOSAF"
}

export enum ServiceName {
    ADD_ANOTHER_LEAVE = "Add Another Leave",
    LEAVE_APPROVAL = "Manager leave approval",
    LEAVE_HISTORY = "Leave history",
    EMP_DETAILS = "Employment details",
    BASIC_DETAILS = "Basic details",
    PROFILE = "Profile Main",
    CONTACT = "Contact details",
    DEPENDENT_DETAILS = "Dependent details",
    DOCUMENT_DETAILS = "Document details",
    EDU_DETAILS = "Education details",
    PROFILE_SUMMARY = "Profile summary",
    TEAM_MEMBER = "Team Member",
    TIME_SCREEN = "Time screen",
    BANK_DETAILS = "Bank details",
    ADDITIONAL_DOCUMENTS = "Additional documents",
    DOCUMENT_CENTER = "Document center",
    LEAVE_SCREEN = "Leave Screen",
    TEAM = "Team Screen",
    FORM_16 = "Form 16",
    MONTHLY_PAYSLIP = "Monthly payslip",
    TRUST_FUND_DOCUMENTS = "Trust fund documents",
    APPLY_LEAVE = "Apply Leave",

}

export const ChannelData = [
    {
        key: "Drishti 2.0",
        value: "Drishti 2.0",
    },
    {
        key: "Demo",
        value: "Demo",
    },

];

export const downloadFileByS3BucketLink = (url: string, successMsg?: string, ErrorMsg?: string) => {
    const filename = url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("."));
    axios.get(url, { responseType: "blob" }).then((res: any) => {
        const a = document.createElement("a");
        a.href = `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}` + "/api/file-download?url=" + btoa(url) + "&filename=" + btoa(filename + ".pdf") + "&contenttype=application/pdf";
        a.click();
        SuccessToast(Constant.DOWNLOAD_PAYSLIP_WORKSHEET_FILE_SUCCESS);
    }).catch((err) => {
        ErrorToast(Constant.DOWNLOAD_ERROR_MESSAGE);
    });
};

export const downloadFileHtmlToPdf = async (fileName: string, url: string, emp_id: string, token: string, fin_year?: string, annual_type?: string) => {
    const customQuery = annual_type ? "&fin_year=" + fin_year + "&annual_type=" + annual_type : "";
    const a = document.createElement("a");
    a.href = `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}` + "/api/html-to-pdf?emp_id=" + emp_id + "&token=" + token + "&url=" + btoa(url) + "&filename=" + fileName + ".pdf" + "&contenttype=application/pdf" + customQuery;
    a.click();
};

export const downloadFileHtmlToBonafidePdf = async (fileName: string, url: string, emp_id: string, dateOfJoining: string, currentAdd: string, permanentAdd: string, concern: string, reason: string, token: string,) => {
    const a = document.createElement("a");
    a.href = `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}` + "/api/html-to-bonafide-pdf?emp_id=" + emp_id + "&dateOfJoining=" + dateOfJoining + "&currentAdd=" + currentAdd + "&permanentAdd=" + permanentAdd + "&concern=" + concern + "&reason=" + reason + "&token=" + token + "&url=" + btoa(url) + "&filename=" + fileName + ".pdf" + "&contenttype=application/pdf";
    // a.href = "http://localhost:9000" + "/api/html-to-bonafide-pdf?emp_id=" + emp_id + "&dateOfJoining=" + dateOfJoining + "&currentAdd=" + currentAdd + "&permanentAdd=" + permanentAdd + "&concern=" + concern + "&reason=" + reason + "&token=" + token + "&url=" + btoa(url) + "&filename=" + fileName + ".pdf" + "&contenttype=application/pdf";
    a.click();
};

export const createChatOneToOne = async (token: string, sender: string, reciver: string) => {

    const body = {
        "chatType": "oneOnOne",
        "members": [
            {
                "@odata.type": "#microsoft.graph.aadUserConversationMember",
                "roles": ["owner"],
                "user@odata.bind": "https://graph.microsoft.com/v1.0/users('" + sender + "')"
            },
            {
                "@odata.type": "#microsoft.graph.aadUserConversationMember",
                "roles": ["owner"],
                "user@odata.bind": "https://graph.microsoft.com/v1.0/users('" + reciver + "')"
            }
        ]
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    };
    return await axios.post("https://graph.microsoft.com/v1.0/chats", body, config);
};

export const sendMsgOneToOne = async (token: string, base64String: string, conversationId: string, template: string) => {
    // const body = {
    //     "body": {
    //         "contentType": "html",
    //         "content": `<img src="${imageUrl}" />`
    //     }
    // };
    const datetime = Date.now();
    const body = {
        "body": {
            "contentType": "html",
            // eslint-disable-next-line no-useless-escape
            "content": String(template).toLowerCase() === "birthday" ? `<div><div>\n<div><span><img height=\"550\" src=\"../hostedContents/${datetime}/$value\" width=\"500\" style=\"vertical-align:bottom; width:500px; height:550px\"></span>\n\n</div>\n\n\n</div>\n</div>` : `<div><div>\n<div><span><img height=\"545\" src=\"../hostedContents/${datetime}/$value\" width=\"500\" style=\"vertical-align:bottom; width:500px; height:545px\"></span>\n\n</div>\n\n\n</div>\n</div>`
        },
        "hostedContents": [
            {
                "@microsoft.graph.temporaryId": `${datetime}`,
                "contentBytes": base64String || "",
                "contentType": "image/png"
            }
        ]
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    };
    return await axios.post(`https://graph.microsoft.com/v1.0/chats/${conversationId}/messages`, body, config);
};

export const getCardImage = async (url: string) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };
    return await axios.get(url, config);
};

export const getADUserByEmpId = async (token: string, empid?: number | null, userPrincipalName?: string | null) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    };
    const query = empid ? "employeeID" : "userPrincipalName";
    return await axios.get(`https://graph.microsoft.com/v1.0/users?$select=userPrincipalName,displayName,mail,id,givenName,surname,employeeID&$filter=${query} eq '${empid || userPrincipalName}'`, config);

};

export enum RequestType {
    "New Account" = "001",
    "Change Account / SAP Access Request" = "002",
    "Delete Account" = "003",
    "Lock Account" = "004",
    "Unlock User" = "005",
    "Emergency User Access" = "006",
    "Role Approval" = "021",
    "Role creation/change request" = "022",
    "Firefighter Log review report" = "023",
    "UAR Review" = "024",
    "Risk Approval" = "025",
    "SOD Review" = "026",
    "Function Workflow" = "027",
    "Funtion Request" = "028"
}

export enum SapIdUserType {
    "Dialog" = "A",
    "System" = "B",
    "Communications Data" = "C",
    "Reference (Logon not possible)" = "L",
    "Service" = "S"
}

export const SapIdUserTypeItems: { id: string, header: string, value: string; }[] = [
    { id: "dialog", header: "Dialog", value: "A" },
    { id: "system", header: "System", value: "B" },
    { id: "communications", header: "Communications Data", value: "C" },
    { id: "reference", header: "Reference (Logon not possible)", value: "L" },
    { id: "service", header: "Service", value: "S" }
];

export const SapIdPrimaryModule: { id: string, header: string, value: string; }[] = [
    { id: "SAP - FICO", header: "SAP Finance & Control", value: "SAP - FICO" },
    { id: "SAP - IA", header: "SAP Internal Auditor", value: "SAP - IA" },
    { id: "SAP - IT", header: "SAP Technical", value: "SAP - IT" },
    { id: "SAP - MM", header: "Material Management", value: "SAP - MM" },
    { id: "SAP - PP", header: "Production Planning", value: "SAP - PP" },
    { id: "SAP - SD", header: "Sales & Distribution", value: "SAP - SD" }
];

export const CountryCode: { id: number, header: string, value: string; }[] = [
    { id: 1, header: "AF +93", value: "+93" },
    { id: 2, header: "AX +358", value: "+358" },
    { id: 3, header: "AL +355", value: "+355" },
    { id: 4, header: "DZ +213", value: "+213" },
    { id: 5, header: "AS +1684", value: "+1684" },
    { id: 6, header: "AD +376", value: "+376" },
    { id: 7, header: "AO +244", value: "+244" },
    { id: 8, header: "AI +1264", value: "+1264" },
    { id: 9, header: "AQ +672", value: "+672" },
    { id: 10, header: "AG +1268", value: "+1268" },
    { id: 11, header: "AR +54", value: "+54" },
    { id: 12, header: "AM +374", value: "+374" },
    { id: 13, header: "AW +297", value: "+297" },
    { id: 14, header: "AU +61", value: "+61" },
    { id: 15, header: "AT +43", value: "+43" },
    { id: 16, header: "AZ +994", value: "+994" },
    { id: 17, header: "BS +1242", value: "+1242" },
    { id: 18, header: "BH +973", value: "+973" },
    { id: 19, header: "BD +880", value: "+880" },
    { id: 20, header: "BB +1246", value: "+1246" },
    { id: 21, header: "BY +375", value: "+375" },
    { id: 22, header: "BE +32", value: "+32" },
    { id: 23, header: "BZ +501", value: "+501" },
    { id: 24, header: "BJ +229", value: "+229" },
    { id: 25, header: "BM +1441", value: "+1441" },
    { id: 26, header: "BT +975", value: "+975" },
    { id: 27, header: "BO +591", value: "+591" },
    { id: 28, header: "BQ +599", value: "+599" },
    { id: 29, header: "BA +387", value: "+387" },
    { id: 30, header: "BW +267", value: "+267" },
    { id: 31, header: "BV +55", value: "+55" },
    { id: 32, header: "BR +55", value: "+55" },
    { id: 33, header: "IO +246", value: "+246" },
    { id: 34, header: "BN +673", value: "+673" },
    { id: 35, header: "BG +359", value: "+359" },
    { id: 36, header: "BF +226", value: "+226" },
    { id: 37, header: "BI +257", value: "+257" },
    { id: 38, header: "KH +855", value: "+855" },
    { id: 39, header: "CM +237", value: "+237" },
    { id: 40, header: "CA +1", value: "+1" },
    { id: 41, header: "CV +238", value: "+238" },
    { id: 42, header: "KY +1345", value: "+1345" },
    { id: 43, header: "CF +236", value: "+236" },
    { id: 44, header: "TD +235", value: "+235" },
    { id: 45, header: "CL +56", value: "+56" },
    { id: 46, header: "CN +86", value: "+86" },
    { id: 47, header: "CX +61", value: "+61" },
    { id: 48, header: "CC +672", value: "+672" },
    { id: 49, header: "CO +57", value: "+57" },
    { id: 50, header: "KM +269", value: "+269" },
    { id: 51, header: "CG +242", value: "+242" },
    { id: 52, header: "CD +242", value: "+242" },
    { id: 53, header: "CK +682", value: "+682" },
    { id: 54, header: "CR +506", value: "+506" },
    { id: 55, header: "CI +225", value: "+225" },
    { id: 56, header: "HR +385", value: "+385" },
    { id: 57, header: "CU +53", value: "+53" },
    { id: 58, header: "CW +599", value: "+599" },
    { id: 59, header: "CY +357", value: "+357" },
    { id: 60, header: "CZ +420", value: "+420" },
    { id: 61, header: "DK +45", value: "+45" },
    { id: 62, header: "DJ +253", value: "+253" },
    { id: 63, header: "DM +1767", value: "+1767" },
    { id: 64, header: "DO +1809", value: "+1809" },
    { id: 65, header: "EC +593", value: "+593" },
    { id: 66, header: "EG +20", value: "+20" },
    { id: 67, header: "SV +503", value: "+503" },
    { id: 68, header: "GQ +240", value: "+240" },
    { id: 69, header: "ER +291", value: "+291" },
    { id: 70, header: "EE +372", value: "+372" },
    { id: 71, header: "ET +251", value: "+251" },
    { id: 72, header: "FK +500", value: "+500" },
    { id: 73, header: "FO +298", value: "+298" },
    { id: 74, header: "FJ +679", value: "+679" },
    { id: 75, header: "FI +358", value: "+358" },
    { id: 76, header: "FR +33", value: "+33" },
    { id: 77, header: "GF +594", value: "+594" },
    { id: 78, header: "PF +689", value: "+689" },
    { id: 79, header: "TF +262", value: "+262" },
    { id: 80, header: "GA +241", value: "+241" },
    { id: 81, header: "GM +220", value: "+220" },
    { id: 82, header: "GE +995", value: "+995" },
    { id: 83, header: "DE +49", value: "+49" },
    { id: 84, header: "GH +233", value: "+233" },
    { id: 85, header: "GI +350", value: "+350" },
    { id: 86, header: "GR +30", value: "+30" },
    { id: 87, header: "GL +299", value: "+299" },
    { id: 88, header: "GD +1473", value: "+1473" },
    { id: 89, header: "GP +590", value: "+590" },
    { id: 90, header: "GU +1671", value: "+1671" },
    { id: 91, header: "GT +502", value: "+502" },
    { id: 92, header: "GG +44", value: "+44" },
    { id: 93, header: "GN +224", value: "+224" },
    { id: 94, header: "GW +245", value: "+245" },
    { id: 95, header: "GY +592", value: "+592" },
    { id: 96, header: "HT +509", value: "+509" },
    { id: 97, header: "HM +0", value: "+0" },
    { id: 98, header: "VA +39", value: "+39" },
    { id: 99, header: "HN +504", value: "+504" },
    { id: 100, header: "HK +852", value: "+852" },
    { id: 101, header: "HU +36", value: "+36" },
    { id: 102, header: "IS +354", value: "+354" },
    { id: 103, header: "IN +91", value: "+91" },
    { id: 104, header: "ID +62", value: "+62" },
    { id: 105, header: "IR +98", value: "+98" },
    { id: 106, header: "IQ +964", value: "+964" },
    { id: 107, header: "IE +353", value: "+353" },
    { id: 108, header: "IM +44", value: "+44" },
    { id: 109, header: "IL +972", value: "+972" },
    { id: 110, header: "IT +39", value: "+39" },
    { id: 111, header: "JM +1876", value: "+1876" },
    { id: 112, header: "JP +81", value: "+81" },
    { id: 113, header: "JE +44", value: "+44" },
    { id: 114, header: "JO +962", value: "+962" },
    { id: 115, header: "KZ +7", value: "+7" },
    { id: 116, header: "KE +254", value: "+254" },
    { id: 117, header: "KI +686", value: "+686" },
    { id: 118, header: "KP +850", value: "+850" },
    { id: 119, header: "KR +82", value: "+82" },
    { id: 120, header: "XK +383", value: "+383" },
    { id: 121, header: "KW +965", value: "+965" },
    { id: 122, header: "KG +996", value: "+996" },
    { id: 123, header: "LA +856", value: "+856" },
    { id: 124, header: "LV +371", value: "+371" },
    { id: 125, header: "LB +961", value: "+961" },
    { id: 126, header: "LS +266", value: "+266" },
    { id: 127, header: "LR +231", value: "+231" },
    { id: 128, header: "LY +218", value: "+218" },
    { id: 129, header: "LI +423", value: "+423" },
    { id: 130, header: "LT +370", value: "+370" },
    { id: 131, header: "LU +352", value: "+352" },
    { id: 132, header: "MO +853", value: "+853" },
    { id: 133, header: "MK +389", value: "+389" },
    { id: 134, header: "MG +261", value: "+261" },
    { id: 135, header: "MW +265", value: "+265" },
    { id: 136, header: "MY +60", value: "+60" },
    { id: 137, header: "MV +960", value: "+960" },
    { id: 138, header: "ML +223", value: "+223" },
    { id: 139, header: "MT +356", value: "+356" },
    { id: 140, header: "MH +692", value: "+692" },
    { id: 141, header: "MQ +596", value: "+596" },
    { id: 142, header: "MR +222", value: "+222" },
    { id: 143, header: "MU +230", value: "+230" },
    { id: 144, header: "YT +262", value: "+262" },
    { id: 145, header: "MX +52", value: "+52" },
    { id: 146, header: "FM +691", value: "+691" },
    { id: 147, header: "MD +373", value: "+373" },
    { id: 148, header: "MC +377", value: "+377" },
    { id: 149, header: "MN +976", value: "+976" },
    { id: 150, header: "ME +382", value: "+382" },
    { id: 151, header: "MS +1664", value: "+1664" },
    { id: 152, header: "MA +212", value: "+212" },
    { id: 153, header: "MZ +258", value: "+258" },
    { id: 154, header: "MM +95", value: "+95" },
    { id: 155, header: "NA +264", value: "+264" },
    { id: 156, header: "NR +674", value: "+674" },
    { id: 157, header: "NP +977", value: "+977" },
    { id: 158, header: "NL +31", value: "+31" },
    { id: 159, header: "AN +599", value: "+599" },
    { id: 160, header: "NC +687", value: "+687" },
    { id: 161, header: "NZ +64", value: "+64" },
    { id: 162, header: "NI +505", value: "+505" },
    { id: 163, header: "NE +227", value: "+227" },
    { id: 164, header: "NG +234", value: "+234" },
    { id: 165, header: "NU +683", value: "+683" },
    { id: 166, header: "NF +672", value: "+672" },
    { id: 167, header: "MP +1670", value: "+1670" },
    { id: 168, header: "NO +47", value: "+47" },
    { id: 169, header: "OM +968", value: "+968" },
    { id: 170, header: "PK +92", value: "+92" },
    { id: 171, header: "PW +680", value: "+680" },
    { id: 172, header: "PS +970", value: "+970" },
    { id: 173, header: "PA +507", value: "+507" },
    { id: 174, header: "PG +675", value: "+675" },
    { id: 175, header: "PY +595", value: "+595" },
    { id: 176, header: "PE +51", value: "+51" },
    { id: 177, header: "PH +63", value: "+63" },
    { id: 178, header: "PN +64", value: "+64" },
    { id: 179, header: "PL +48", value: "+48" },
    { id: 180, header: "PT +351", value: "+351" },
    { id: 181, header: "PR +1787", value: "+1787" },
    { id: 182, header: "QA +974", value: "+974" },
    { id: 183, header: "RE +262", value: "+262" },
    { id: 184, header: "RO +40", value: "+40" },
    { id: 185, header: "RU +7", value: "+7" },
    { id: 186, header: "RW +250", value: "+250" },
    { id: 187, header: "BL +590", value: "+590" },
    { id: 188, header: "SH +290", value: "+290" },
    { id: 189, header: "KN +1869", value: "+1869" },
    { id: 190, header: "LC +1758", value: "+1758" },
    { id: 191, header: "MF +590", value: "+590" },
    { id: 192, header: "PM +508", value: "+508" },
    { id: 193, header: "VC +1784", value: "+1784" },
    { id: 194, header: "WS +684", value: "+684" },
    { id: 195, header: "SM +378", value: "+378" },
    { id: 196, header: "ST +239", value: "+239" },
    { id: 197, header: "SA +966", value: "+966" },
    { id: 198, header: "SN +221", value: "+221" },
    { id: 199, header: "RS +381", value: "+381" },
    { id: 200, header: "CS +381", value: "+381" },
    { id: 201, header: "SC +248", value: "+248" },
    { id: 202, header: "SL +232", value: "+232" },
    { id: 203, header: "SG +65", value: "+65" },
    { id: 204, header: "SX +721", value: "+721" },
    { id: 205, header: "SK +421", value: "+421" },
    { id: 206, header: "SI +386", value: "+386" },
    { id: 207, header: "SB +677", value: "+677" },
    { id: 208, header: "SO +252", value: "+252" },
    { id: 209, header: "ZA +27", value: "+27" },
    { id: 210, header: "GS +500", value: "+500" },
    { id: 211, header: "SS +211", value: "+211" },
    { id: 212, header: "ES +34", value: "+34" },
    { id: 213, header: "LK +94", value: "+94" },
    { id: 214, header: "SD +249", value: "+249" },
    { id: 215, header: "SR +597", value: "+597" },
    { id: 216, header: "SJ +47", value: "+47" },
    { id: 217, header: "SZ +268", value: "+268" },
    { id: 218, header: "SE +46", value: "+46" },
    { id: 219, header: "CH +41", value: "+41" },
    { id: 220, header: "SY +963", value: "+963" },
    { id: 221, header: "TW +886", value: "+886" },
    { id: 222, header: "TJ +992", value: "+992" },
    { id: 223, header: "TZ +255", value: "+255" },
    { id: 224, header: "TH +66", value: "+66" },
    { id: 225, header: "TL +670", value: "+670" },
    { id: 226, header: "TG +228", value: "+228" },
    { id: 227, header: "TK +690", value: "+690" },
    { id: 228, header: "TO +676", value: "+676" },
    { id: 229, header: "TT +1868", value: "+1868" },
    { id: 230, header: "TN +216", value: "+216" },
    { id: 231, header: "TR +90", value: "+90" },
    { id: 232, header: "TM +7370", value: "+7370" },
    { id: 233, header: "TC +1649", value: "+1649" },
    { id: 234, header: "TV +688", value: "+688" },
    { id: 235, header: "UG +256", value: "+256" },
    { id: 236, header: "UA +380", value: "+380" },
    { id: 237, header: "AE +971", value: "+971" },
    { id: 238, header: "GB +44", value: "+44" },
    { id: 239, header: "US +1", value: "+1" },
    { id: 240, header: "UM +1", value: "+1" },
    { id: 241, header: "UY +598", value: "+598" },
    { id: 242, header: "UZ +998", value: "+998" },
    { id: 243, header: "VU +678", value: "+678" },
    { id: 244, header: "VE +58", value: "+58" },
    { id: 245, header: "VN +84", value: "+84" },
    { id: 246, header: "VG +1284", value: "+1284" },
    { id: 247, header: "VI +1340", value: "+1340" },
    { id: 248, header: "WF +681", value: "+681" },
    { id: 249, header: "EH +212", value: "+212" },
    { id: 250, header: "YE +967", value: "+967" },
    { id: 251, header: "ZM +260", value: "+260" },
    { id: 252, header: "ZW +263", value: "+263" }
];

export function getUserTitle(title: string) {
    const value = title.split("(")[0].trimEnd();
    const lastElement = value[value.length - 1];
    if (title !== "" && title !== null) return lastElement === "." ? value + " " : value + ". ";
    else return "";
}

export function getValueZeroToConversion(initialValue: string) {
    if (/([a-zA-Z/\\*])\w/g.test(initialValue)) {
        return initialValue;
    }
    return Number.isNaN(parseFloat(initialValue)) && !Number.isSafeInteger(initialValue) ? initialValue : parseFloat(initialValue).toString();
}


