import React from "react";
import {
    Box,
    CallIcon,
    ChatIcon,
    Divider,
    Flex,
    Header,
    Input,
    Loader,
    SearchIcon,
    Segment,
    Text,
    Skeleton,
    Avatar,
    Button,
} from "@fluentui/react-northstar";

import "./teamMembers.scss";
import * as UserData from "../../redux/actions/UserDataActions";
import * as appData from "../../redux/actions/appConfigration";
import { RootSchema } from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import * as microsoftTeams from "@microsoft/teams-js";
import { IState } from "../../App";
import ServicePermission from "../HOC/ServicePermission";
import { Constant, ServiceName } from "../../constant";

interface IProps {
    isManagerView?: boolean;
    selectedName?: (item: any) => void;
    isTaskModule?: boolean;
    QueryData?: IState
}

const TeamMembers: React.FC<IProps> = (props) => {
    const [searchInput, setSearchInput] = React.useState<string>("");
    const [refresh, setRefresh] = React.useState<number>(0);
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);

    const teamMemberData = props.isManagerView ? state.userInfo.teamMemberData.selfMember : state.userInfo.teamMemberData.teamMember;

    const allReporteesData = teamMemberData.isSuccess &&
        teamMemberData.status_code === "200"
        ? teamMemberData.user_details.all_reportees.filter(
            (item: any) =>
                (item.empId === state.userInfo.userManagerData.user_details[0].Perno ? item.empId !== state.userInfo.userManagerData.user_details[0].Perno : "00" + item.empId !== state.userInfo.userManagerData.user_details[0].Perno)
        )
        : [];

    const membersData = allReporteesData.filter(
        (item: any) =>
            item.defaultFullName
                .toLowerCase().includes(searchInput.toLowerCase())
    );

    const onClickChatHanlder = (emailId: any) => {
        const chatLink = `msteams://teams.microsoft.com/l/chat/0/0?users=${emailId} `;
        microsoftTeams.executeDeepLink(chatLink);
    };
    const onClickCallHanlder = (emailId: any) => {
        microsoftTeams.executeDeepLink(
            `msteams://teams.microsoft.com/l/call/0/0?users=${emailId}`
        );
    };

    React.useEffect(() => {
        if (
            state.userInfo.userManagerData.isSuccess &&
            state.authData.managerAuth.isSuccess &&
            !props.isManagerView && !(state.userInfo.teamMemberData.teamMember.status_code === "200")
        ) {
            dispatch(
                UserData.getTeamMembersAction(
                    state.authData.managerAuth.token,
                    state.userInfo.userManagerData.manager_details[0]?.Perno,
                    true
                )
            );
        } else if (
            state.userInfo.userManagerData.isSuccess &&
            state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" &&
            props.isManagerView && !(state.userInfo.teamMemberData.selfMember.status_code === "200")
        ) {
            dispatch(
                UserData.getTeamMembersAction(
                    state.authData.userAuth.token,
                    state.userInfo.userManagerData.user_details[0].Perno
                )
            );
        }
    }, [
        state.authData.managerAuth.isSuccess,
        state.authData.userAuth.isSuccess,
        state.userInfo.userManagerData.isSuccess,
        props.isManagerView,
    ]);

    React.useEffect(() => {
        if (
            teamMemberData.isSuccess &&
            teamMemberData.status_code === "200" &&
            teamMemberData.user_details.all_reportees.length > 0 &&
            props.isManagerView
        ) {
            const reporteesFilter = teamMemberData.user_details.all_reportees.filter((user) => user.empId === state.userInfo.userManagerData.user_details[0].Perno ? user.empId !== state.userInfo.userManagerData.user_details[0].Perno : "00" + user.empId !== state.userInfo.userManagerData.user_details[0].Perno);
            if (reporteesFilter.length) {
                const value = {
                    emp_name:
                        reporteesFilter[0]
                            .defaultFullName,
                    emp_id:
                        reporteesFilter[0].empId,
                    user_id:
                        reporteesFilter[0].userId,
                    username: reporteesFilter[0].username,
                    internal_role:
                        reporteesFilter[0].internal_role,
                    level: reporteesFilter[0].custom06
                };
                dispatch(appData.setTeamMemberSelect(value));
            }
        }
        setRefresh(refresh + 1);
    }, [
        teamMemberData.isSuccess &&
        teamMemberData.status_code === "200",
    ]);



    function getNames(string: any) {
        if (string) {
            // eslint-disable-next-line prefer-const
            let names = string.split(" "),
                initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        }
    }

    const onTeamMemberSelected = (item: any) => {
        const value = {
            emp_name: item.defaultFullName,
            emp_id: item.empId,
            user_id: item.userId,
            username: item.username,
            internal_role: item.internal_role,
            level: item.custom06
        };
        props.selectedName?.(false);
        dispatch(appData.setTeamMemberSelect(value));
    };
    return (
        <React.Fragment>
            <Segment
                styles={{
                    borderRadius: "5px",
                    padding: "0",
                    margin: "0",
                    width: "100%",
                }}
            >
                <Header className="segmentHeader" content={props.isManagerView ? "Team Members" : membersData.length ? "Reporting Manager & Team" : "Reporting Manager"} as="h3" />
                <Divider fitted />
                <ServicePermission service_name={[ServiceName.TEAM_MEMBER]} showReason={true}>
                    <>
                        {!props.isManagerView &&
                            (state.userInfo.userManagerData.isSuccess &&
                                state.userInfo.userManagerData.status_code === "200" ? (
                                <Flex gap="gap.small" padding="padding.medium" vAlign='end' hAlign='end' space='between'>
                                    <Flex gap="gap.small">
                                        <Box className="box_reactangle" title={state.userInfo.userManagerData.manager_details[0]?.CompName}>
                                            <Text content={getNames(state.userInfo.userManagerData.manager_details[0]?.CompName)} color="brand" />
                                        </Box>
                                        <Flex column vAlign="center">
                                            <Text title={state.userInfo.userManagerData.manager_details[0]?.CompName} content={state.userInfo.userManagerData.manager_details[0]?.CompName} className="manager_name" />
                                            <Text content={state.userInfo.userManagerData.manager_details[0]?.Jobtxt} styles={{ color: "#B3B3B3" }} />
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        gap="gap.smaller"
                                        hAlign="end"
                                    >
                                        <Box
                                            className="box_reactangle"
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => onClickCallHanlder(state.userInfo.userManagerData.manager_details[0]?.Imailid)}
                                        >
                                            <CallIcon
                                                styles={({ theme: { siteVariables } }) => ({
                                                    color: siteVariables.colorScheme.brand.foreground,
                                                })}
                                                size="small"
                                            />
                                        </Box>
                                        <Box
                                            className="box_reactangle"
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => onClickChatHanlder(state.userInfo.userManagerData.manager_details[0]?.Imailid)}
                                        >
                                            <ChatIcon
                                                styles={({ theme: { siteVariables } }) => ({
                                                    color: siteVariables.colorScheme.brand.foreground,
                                                })}
                                                size="small"
                                            />
                                        </Box>
                                    </Flex>
                                </Flex>
                            ) : (
                                <Skeleton animation="pulse">
                                    <Flex gap="gap.small" padding="padding.medium">
                                        <Box className="box_reactangle">
                                            <Skeleton.Avatar round />
                                        </Box>
                                        <Flex column vAlign="center">
                                            <Skeleton.Line />
                                            <Skeleton.Text />
                                        </Flex>
                                    </Flex>
                                </Skeleton>
                            ))}
                        <Divider fitted />

                        {!props.isTaskModule && ((allReporteesData.length > 0) ?
                            <Box>
                                <Flex padding="padding.medium">
                                    <Input
                                        fluid
                                        clearable
                                        placeholder="Search"
                                        iconPosition="end"
                                        value={searchInput.trimStart()}
                                        icon={<SearchIcon />}
                                        onChange={(e: any, v: any) =>
                                            v.value === null ? setSearchInput("") : setSearchInput(v.value.trimStart())
                                        }
                                    />
                                </Flex>
                                <Divider fitted />
                            </Box> : teamMemberData.isSuccess && allReporteesData.length === 0 ?
                                <Box></Box> :
                                <Skeleton animation="pulse" styles={{ height: "54px", width: "100%", padding: "0 0.5rem" }}>
                                    <Flex fill vAlign='center' hAlign='center' >
                                        <Skeleton.Input fluid width='100%' />
                                    </Flex>
                                </Skeleton>
                        )}
                        <Flex column>
                            {teamMemberData.isSuccess &&
                                teamMemberData.status_code === "200" &&
                                membersData.length > 0 ? (
                                <Flex column className="teamList" styles={props.isTaskModule ? { height: "calc(100vh - 50px)" } : props.isManagerView ? { height: "calc(100vh - 124px)" } : { height: "calc(100vh - 486px)" }}>
                                    {membersData.map((item, index) => {
                                        return (
                                            <Box
                                                key={index}
                                                onClick={() =>
                                                    props.isManagerView && !props.isTaskModule ? onTeamMemberSelected(item) : null
                                                }
                                            // className={props.isManagerView ? "on-hover" : ""}
                                            >
                                                <Flex vAlign="center">
                                                    <Flex
                                                        gap="gap.smaller"
                                                        fill
                                                        hAlign="start"
                                                        vAlign="center"
                                                        space="between"
                                                        className={
                                                            props.isManagerView &&
                                                                state.appConfig.teamMemberSelect.emp_id === item.empId && !props.isTaskModule
                                                                ? "selectTeamMwmber"
                                                                : "unSelectTeamMwmber"
                                                        }
                                                    >
                                                        <Flex vAlign='center' gap="gap.smaller">
                                                            <Avatar
                                                                name={item.defaultFullName}
                                                                label={{
                                                                    styles:
                                                                        props.isManagerView &&
                                                                            state.appConfig.teamMemberSelect.emp_id ===
                                                                            item.empId && !props.isTaskModule
                                                                            ? {
                                                                                backgroundColor: "#FCFCFC",
                                                                                color: "#5B62C0",
                                                                                fontWeight: "600",
                                                                            }
                                                                            : {
                                                                                backgroundColor: "#5B62C029",
                                                                                color: "#5B62C0",
                                                                                fontWeight: "600",
                                                                            },
                                                                }}
                                                            />
                                                            <Text content={item.defaultFullName} />
                                                        </Flex>
                                                        {props.isTaskModule && <Button primary text flat icon={<ChatIcon />} content={"Give Feedback"} onClick={() => props.isTaskModule ? onTeamMemberSelected(item) : null} />}
                                                    </Flex>
                                                    {!props.isManagerView && (
                                                        <Flex
                                                            padding="padding.medium"
                                                            gap="gap.smaller"
                                                            hAlign="end"
                                                        >
                                                            <Box
                                                                className="box_reactangle"
                                                                style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => onClickCallHanlder(item.username)}
                                                            >
                                                                <CallIcon
                                                                    styles={({ theme: { siteVariables } }) => ({
                                                                        color: siteVariables.colorScheme.brand.foreground,
                                                                    })}
                                                                    size="small"
                                                                />
                                                            </Box>
                                                            <Box
                                                                className="box_reactangle"
                                                                style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => onClickChatHanlder(item.username)}
                                                            >
                                                                <ChatIcon
                                                                    styles={({ theme: { siteVariables } }) => ({
                                                                        color: siteVariables.colorScheme.brand.foreground,
                                                                    })}
                                                                    size="small"
                                                                />
                                                            </Box>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                                <Divider fitted />
                                            </Box>
                                        );
                                    })}
                                </Flex>
                            ) : teamMemberData.isSuccess &&
                                teamMemberData.status_code === "200" &&
                                membersData.length === 0 ? (
                                (allReporteesData.length > 0 || props.isTaskModule) && <Flex fill vAlign='center' hAlign='center' >
                                    <DataNotFound message={Constant.TEAM_MEMBER_DATA_NOT_FOUND} />
                                </Flex>
                            ) : teamMemberData.isSuccess &&
                                teamMemberData.status_code !== "200" ? (
                                <Flex padding="padding.medium" fill vAlign='center' hAlign='center' className="teamList" styles={props.isTaskModule ? { height: "calc(100vh - 50px)" } : props.isManagerView ? { height: "calc(100vh - 124px)" } : { height: "calc(100vh - 486px)" }}>
                                    {/* <DataNotFound /> */}
                                    <ErrorComponent message={teamMemberData.message} />
                                </Flex>
                            ) : (
                                //  <Loader />
                                // <Skeleton animation="pulse">
                                //   <Flex gap="gap.medium" column hAlign='center' padding='padding.medium'>
                                //     <Skeleton.Avatar size="largest" />
                                //     <Skeleton.Text size="medium" />
                                //   </Flex>
                                // </Skeleton>

                                <Skeleton animation="pulse" className="teamList" styles={props.isTaskModule ? { height: "calc(100vh - 50px)" } : props.isManagerView ? { height: "calc(100vh - 124px)" } : { height: "calc(100vh - 486px)" }}>
                                    {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {
                                        return (
                                            <Flex
                                                key={item}
                                                space="between"
                                                vAlign="center"
                                                gap="gap.medium"
                                                styles={({ theme: { siteVariables } }) => ({
                                                    padding: "0.5rem 1rem",
                                                    background: index % 2 === 0 ? siteVariables.colorScheme.grey.backgroundActive : siteVariables.bodyBackground,
                                                })}
                                            >
                                                <Flex wrap fill>
                                                    <Skeleton.Text />
                                                </Flex>
                                            </Flex>
                                        );
                                    })}
                                </Skeleton>
                            )}
                        </Flex>

                    </>
                </ServicePermission>
            </Segment>
        </React.Fragment>
    );
};

export default TeamMembers;
