import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_SEND_MAIL_DATA: "GET_SEND_MAIL_DATA",
    GET_SEND_MAIL_LOADING: " GET_SEND_MAIL_LOADING",
    GET_SEND_MAIL_ERROR: " GET_SEND_MAIL_ERROR",
    GET_SEND_MAIL_SUCCESS: " GET_SEND_MAIL_SUCCESS",
};

export interface IGetSendMailData extends SideEffectSchema {
    status_code: number,
    message: string,
}

export interface ISendMailPayload {
    to: string;
    subject: string;
    userDetails: {
        name: string;
        designation: string;
        email: string;
        company: string;
        field_1: string;
        field_2: string;
        field_3: string;
        office_address: string;
        contact_number: string;
        tel_number: string;
    },
    fileName: string;
    base64: string;
}

export const getSendMailDataAction = (payload: ISendMailPayload) => {
    return {
        type: Actions.GET_SEND_MAIL_DATA,
        payload: payload
    };
};
export const getSendMailLoadingAction = () =>
    loadingAction(Actions.GET_SEND_MAIL_LOADING);

export const getSendMailErrorAction = (error: string) =>
    errorAction(Actions.GET_SEND_MAIL_ERROR, error);

export const getSendMailSuccessAction = (data: any) => {
    const payload: IGetSendMailData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_SEND_MAIL_SUCCESS,
        payload: payload
    };
};
/////////////////////////////////////////////////////////////////////////////////////////////
