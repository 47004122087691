import {
    Actions,
    IGetSendMailData,
} from "../actions/SendMailAction";
import { combineReducers } from "redux";

const getSendMailReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_SEND_MAIL_LOADING:
        case Actions.GET_SEND_MAIL_ERROR:
        case Actions.GET_SEND_MAIL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};


export default getSendMailReducer;