import React from "react";
import {
    Segment,
    Avatar,
    Divider,
    Flex,
    Text,
    Header,
    FlexItem,
    Box,
    Button,
    AddIcon,
    ExclamationTriangleIcon,
    RedbangIcon,
    ChatIcon,
    Skeleton,
    Popup,
    AcceptIcon,
    CloseIcon,
    EditIcon,
    Input,
    TextArea,
    TrashCanIcon,
    Dialog
} from "@fluentui/react-northstar";
import { Capitalize, Constant, TraceEvent, TraceTelemtry } from "../../constant";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import {
    getSuccessFactorUserDetailsDataAction,
    getEmployeeCompetencyDataAction,
    addCompetencyCommentDataAction,
    getCompetencyCommentDataAction,
    getPerformanceTimelineDataAction,
    sendFeedbackRequestDataAction,
    getLeadershipBehaviourDewscriptionDataAction,
    updateManagerCompetencyCommentDataAction,
} from "../../redux/actions/CFMActions";
import FeedbackDialog from "../FeedbackDialog";
import Feedback from "../TeamFeedback";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import "./leadership.scss";
import CFM from "../../assets/img/CFM.svg";
import NoLeave from "../../assets/img/NoLeave.svg";
import CFMPermission from "../HOC/CFMPermission";
import CFMButtonPermission from "../HOC/CFMButtonPermission";
import CFMMenuComponent from "../Common/CFMMenuComponent";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import { getAppInsights } from "./../../telemetry/telemetryService";
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-IN");
interface ILeadershipBehaviour {
    QueryData: IState;
    byManagerUserId?: string;
    byManagerEmpId?: string;
    byEmpLevel?: string;
    managerView: boolean;
    activeBackButton?: any;
}
const LeadershipBehaviour: React.FC<ILeadershipBehaviour> = ({ QueryData, managerView, byManagerUserId, byManagerEmpId, byEmpLevel, activeBackButton }) => {
    const { appInsights, trackEvent, trackMetrix } = getAppInsights();
    const queryData = QueryData;
    const [feedback, setfeedback] = React.useState<{
        isActive: boolean;
        index: number;
        add: boolean;
        id?: string;
        user_id?: string;
    }>({
        isActive: false,
        index: 0,
        add: false,
    });
    const [feedbackProps, setFeedbackProps] = React.useState<{
        type: string;
        name: string;
        item_id: string;
        form_data_id: string;
        form_template_id: string;
        sf_competency_comments_id: string;
        user_id: string;
    }>({
        type: "Leadership",
        name: "",
        item_id: "",
        form_data_id: "",
        form_template_id: "",
        sf_competency_comments_id: "",
        user_id: ""
    });
    const [addDialog, setAddDialog] = React.useState<boolean>(false);
    const [editableId, setEditableId] = React.useState<{ isActive?: number; comment?: string, isDelete?: boolean }>({ isActive: 0, comment: "" });
    const [preDefinedText, setPreDefinedText] = React.useState<string>("");
    const [open, setOpen] = React.useState<boolean>(false);
    const [commentItem, setCommentItem] = React.useState<any>();
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const leadershipData = managerView ? state.cfmData.getEmployeeCompetencyData.teamCompetency : state.cfmData.getEmployeeCompetencyData.selfCompetency;
    React.useEffect(() => {
        if (queryData.employeeId && state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" && !managerView && !(state.cfmData.getSuccessFactorUserDetailsData.status_code === "200")) {
            dispatch(
                getSuccessFactorUserDetailsDataAction(
                    state.authData.userAuth.token,
                    queryData.employeeId
                )
            );
        }
    }, [queryData.employeeId, state.authData.userAuth.isSuccess]);
    React.useEffect(() => {
        setfeedback({
            isActive: false,
            index: 0,
            add: false,
        });
        if (
            state.cfmData.getSuccessFactorUserDetailsData.isSuccess &&
            state.cfmData.getSuccessFactorUserDetailsData.user_details?.user_id && !managerView &&
            state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" && !(state.cfmData.getEmployeeCompetencyData.selfCompetency.status_code === "200")
        ) {
            dispatch(
                getEmployeeCompetencyDataAction(state.authData.userAuth.token, {
                    emp_id: queryData.employeeId as string,
                    user_id: state.cfmData.getSuccessFactorUserDetailsData.user_details.user_id,
                    is_employee: managerView ? false : true
                })
            );
        }
    }, [
        state.cfmData.getSuccessFactorUserDetailsData.isSuccess,
        state.authData.userAuth.isSuccess,
    ]);
    React.useEffect(() => {
        if (managerView && state.authData.userAuth.isSuccess) {
            setfeedback({
                isActive: false,
                index: 0,
                add: false,
            });
            dispatch(
                getEmployeeCompetencyDataAction(state.authData.userAuth.token, {
                    emp_id: byManagerEmpId as string,
                    user_id: byManagerUserId as string,
                    is_employee: managerView ? false : true
                }, true)
            );
        }
    }, [
        state.authData.userAuth.isSuccess,
        byManagerEmpId, byManagerUserId
    ]);
    React.useEffect(() => {
        if (addDialog) {
            activeBackButton?.(false);
        } else {
            activeBackButton?.(true);
        }
    }, [addDialog]);

    /**
     * This function will return the feedbacks of competency,
     * after hitting the API for particular competency.
     * @param index index position of competency
     * @param item data of selected competency
     */
    const viewLeadershipFeedback = (index: number, item: any) => {
        if (feedback.isActive) {
            activeBackButton?.(true);
            setfeedback({ isActive: false, index: 0, add: false });
        } else {
            activeBackButton?.(false);
            setfeedback({ isActive: true, index: index, add: false, id: item.item_id, user_id: item.user_id });
            dispatch(
                getCompetencyCommentDataAction(
                    state.authData.userAuth.token,
                    item.item_id,
                    item.user_id,
                    managerView ? false : true
                )
            );
        }
        setEditableId({});
    };

    /**
     * This function if add the feedback for the particular competency
     * @param item data of selected competency
     */
    const addGoalComment = (item: any) => {
        setAddDialog(true);
        setFeedbackProps({
            type: "Leadership",
            name: item.name,
            item_id: item.item_id,
            form_data_id: item.form_data_id,
            form_template_id: item.form_template_id,
            sf_competency_comments_id: item.sf_competency_details_id,
            user_id: item.user_id
        });
        setfeedback({
            isActive: feedback.isActive,
            index: feedback.index,
            add: true,
        });
    };

    /**
     * This function will open the competency detailed view
     * @param item data of competency
     */
    const onClickLeadershipBehaviourDescription = (item: any) => {
        dispatch(
            getLeadershipBehaviourDewscriptionDataAction(state.authData.userAuth.token, {
                emp_id: byManagerEmpId ? byManagerEmpId as string : queryData.employeeId as string,
                leadership_behaviours_title: item.name.split("-")[0].trim()
            })
        );
    };

    /**
     * This function is updating the competency feedback
     * @param btnValue value of button (accept/reject)
     * @param item data of selected competency
     */
    const feedbackEditValue = (btnValue: string, item: any) => {
        if (btnValue === "accept") {
            setCommentItem(item);
            setOpen(true);
        } else if (btnValue === "reject") {
            setEditableId({});
            setPreDefinedText("");
        }
    };
    React.useEffect(() => {
        if (state.cfmData.updateManagerCompetencyComment.isSuccess && state.cfmData.updateManagerCompetencyComment.status_code === "200" && editableId.comment !== "") {
            SuccessToast(state.cfmData.updateManagerCompetencyComment.message);
            dispatch(
                getCompetencyCommentDataAction(state.authData.userAuth.token, feedback?.id as string, feedback?.user_id as string, managerView ? false : true)
            );
            setEditableId({ comment: "" });
        } else if (state.cfmData.updateManagerCompetencyComment.isSuccess && state.cfmData.updateManagerCompetencyComment.status_code !== "200" && editableId.comment !== "") {
            ErrorToast(state.cfmData.updateManagerCompetencyComment.message);
        }
    }, [state.cfmData.updateManagerCompetencyComment.isSuccess]);
    React.useEffect(() => {
        if (state.cfmData.deleteManagerCompetencyComment.isSuccess && state.cfmData.deleteManagerCompetencyComment.status_code === "200" && editableId.isDelete) {
            SuccessToast(state.cfmData.deleteManagerCompetencyComment.message);
            dispatch(
                getCompetencyCommentDataAction(state.authData.userAuth.token, feedback?.id as string, feedback?.user_id as string, managerView ? false : true)
            );
            setEditableId({ isDelete: false });
        } else if (state.cfmData.deleteManagerCompetencyComment.isSuccess && state.cfmData.deleteManagerCompetencyComment.status_code !== "200" && editableId.isDelete) {
            ErrorToast(state.cfmData.deleteManagerCompetencyComment.message);
            setEditableId({ isDelete: false });
        }
    }, [state.cfmData.deleteManagerCompetencyComment.isSuccess]);
    const onClickPreDefine = (value: string) => {
        setPreDefinedText(value);
        setEditableId({ ...editableId, comment: value + " " });
    };
    const cancelDialog = () => {
        setOpen(false);
    };
    const confirmDialog = () => {
        const data = {
            item_id: commentItem?.item_id,
            sf_competency_comments_id: commentItem?.sf_competency_comments_id,
            comment: editableId?.comment?.trim() as string
        };
        dispatch(
            updateManagerCompetencyCommentDataAction(state.authData.userAuth.token, data)
        );
        trackEvent(appInsights, TraceEvent.TML_CFM_LEADERSHIP_MANAGER_UPDATE_COMMENT, data);
        setOpen(false);
    };
    return (
        <React.Fragment>
            {((queryData.isTaskModuleActive && !addDialog) || (!queryData.isTaskModuleActive)) &&
                <Segment
                    styles={{
                        borderRadius: "5px",
                        padding: "0",
                        margin: "0",
                        width: "100%",
                        height: `${managerView ? "calc(100vh - 121px)" : queryData.isTaskModuleActive ? "calc(100vh - 40px)" : "calc(100vh - 26px)"}`
                    }}
                >
                    {!queryData.isTaskModuleActive && <>
                        <Header
                            className="segmentHeader"
                            content="Leadership behaviours"
                            as="h3"
                        />
                        <Divider fitted />
                    </>}
                    {leadershipData.isSuccess &&
                        leadershipData.status_code === "200" &&
                        leadershipData.sf_competency_details.length > 0 &&
                        !feedback.isActive ? (
                        <CFMPermission permission={leadershipData.application_modes} message={leadershipData.message}>
                            <Box styles={{ height: `${managerView ? "calc(100vh - 166px)" : queryData.isTaskModuleActive ? "calc(100vh - 40px)" : "calc(100vh - 70px)"}`, overflow: "auto" }}>
                                {leadershipData.sf_competency_details.map(
                                    (item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Flex padding="padding.medium" gap="gap.medium">
                                                    <FlexItem grow>
                                                        <Text content={item.name} />
                                                    </FlexItem>
                                                    <FlexItem push>
                                                        <Flex column space="between" gap="gap.medium">
                                                            <Flex gap="gap.small" hAlign="end">
                                                                <CFMButtonPermission permission={leadershipData.application_modes} message={state.cfmData.getGoalCommentsData.message}>
                                                                    <Button
                                                                        flat
                                                                        icon={<AddIcon />}
                                                                        iconOnly
                                                                        onClick={() => addGoalComment(item)}
                                                                    />
                                                                </CFMButtonPermission>
                                                                <Popup
                                                                    content={
                                                                        <Flex column className="iButton">
                                                                            {
                                                                                state.cfmData.getLeadershipBehaviuorDescription.isSuccess && state.cfmData.getLeadershipBehaviuorDescription.status_code === "200" ?
                                                                                    <Box>
                                                                                        <Box className="iBtnBox">
                                                                                            <Text content={state.cfmData.getLeadershipBehaviuorDescription.result.leadership_behaviours_heading} />
                                                                                        </Box>
                                                                                        <Flex column gap="gap.medium" className="iBtnFlex">
                                                                                            <Flex gap="gap.smaller" >
                                                                                                <Text content="Level" weight="semibold" styles={{ fontSize: "16px", whiteSpace: "nowrap", minWidth: "40px", textAlign: "center" }} />
                                                                                                <Text content="Description" weight="semibold" styles={{ fontSize: "16px" }} />
                                                                                            </Flex>
                                                                                            {
                                                                                                state.cfmData.getLeadershipBehaviuorDescription.result.leadership_behaviours_description.map((obj, index) => {
                                                                                                    return (
                                                                                                        <Flex key={index} gap="gap.smaller" >
                                                                                                            <Text content={obj.leadership_level.split("Level ")[1]} styles={{ whiteSpace: "nowrap", minWidth: "40px", textAlign: "center" }} />
                                                                                                            <Text content={obj.leadership_behaviours_description} />
                                                                                                        </Flex>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </Flex>
                                                                                    </Box> :
                                                                                    state.cfmData.getLeadershipBehaviuorDescription.isSuccess && state.cfmData.getLeadershipBehaviuorDescription.status_code !== "200" ?
                                                                                        <Flex fill vAlign="center" hAlign="center" className="iBtnErrorFlex">
                                                                                            <DataNotFound message="No data found" img={NoLeave} />
                                                                                        </Flex>
                                                                                        :
                                                                                        <Skeleton animation="pulse" styles={{ width: "inherit", minWidth: "500px" }}>
                                                                                            <Box className="iBtnBox">
                                                                                                <Skeleton.Text width="50%" />
                                                                                            </Box>
                                                                                            <Flex column gap="gap.medium" className="iBtnFlex">
                                                                                                {[1, 2, 3].map((item) => {
                                                                                                    return (
                                                                                                        <Flex gap="gap.smaller" key={item} >
                                                                                                            <Skeleton.Button iconOnly size="smallest" />
                                                                                                            <Skeleton.Input fluid />
                                                                                                        </Flex>
                                                                                                    );
                                                                                                })}
                                                                                            </Flex>
                                                                                        </Skeleton>
                                                                            }
                                                                        </Flex>
                                                                    }
                                                                    pointing
                                                                    position="before"
                                                                    align="top"
                                                                    tabbableTrigger={false}
                                                                    trigger={<Button
                                                                        flat
                                                                        icon={<RedbangIcon rotate={180} />}
                                                                        iconOnly
                                                                        onClick={() => onClickLeadershipBehaviourDescription(item)}
                                                                    />}
                                                                />
                                                            </Flex>
                                                            <Flex vAlign="end">
                                                                <Button
                                                                    text
                                                                    icon={<ChatIcon />}
                                                                    iconPosition="before"
                                                                    content={"View feedback"}
                                                                    onClick={(e) =>
                                                                        viewLeadershipFeedback(index, item)
                                                                    }
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                    </FlexItem>
                                                </Flex>
                                                <Divider fitted />
                                            </Box>
                                        );
                                    }
                                )}
                            </Box>
                        </CFMPermission>
                    ) : leadershipData.isSuccess &&
                        leadershipData.status_code === "200" &&
                        leadershipData.sf_competency_details
                            .length === 0 ? (
                        <DataNotFound message={Constant.LEADERSHIP_CAUGHT_UP} img={CFM} />
                    ) : leadershipData.isSuccess &&
                        leadershipData.status_code !== "200" || (state.cfmData.getSuccessFactorUserDetailsData.isSuccess && state.cfmData.getSuccessFactorUserDetailsData.status_code !== "200") ? (
                        <ErrorComponent
                            message={leadershipData.message || state.cfmData.getSuccessFactorUserDetailsData.message}
                        />
                    ) : (
                        !feedback.isActive && (
                            <Box styles={{ height: `${managerView ? "calc(100vh - 166px)" : queryData.isTaskModuleActive ? "calc(100vh - 40px)" : "calc(100vh - 70px)"}`, overflow: "auto" }}>
                                {[1, 2, 3, 4, 5].map((item) => {
                                    return (
                                        <Skeleton animation="pulse" key={item}>
                                            <Flex
                                                fill
                                                gap="gap.medium"
                                                padding="padding.medium"
                                            >
                                                <Flex fill column gap="gap.medium">
                                                    <Skeleton.Text width="50%" />
                                                    <Skeleton.Input />
                                                </Flex>
                                                <Flex
                                                    hAlign="end"
                                                    column
                                                    gap="gap.medium"
                                                    space="between"
                                                >
                                                    <Skeleton.Button iconOnly />
                                                    <Skeleton.Button />
                                                </Flex>
                                            </Flex>
                                            <Divider fitted />
                                        </Skeleton>
                                    );
                                })}
                            </Box>
                        )
                    )
                    }
                    {
                        leadershipData.isSuccess &&
                        feedback.isActive &&
                        leadershipData.sf_competency_details[
                        feedback.index
                        ] && (
                            <Box>
                                <Flex padding="padding.medium" gap="gap.medium">
                                    <FlexItem grow>
                                        <Text
                                            content={
                                                leadershipData
                                                    .sf_competency_details[feedback.index].name
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem push>
                                        <Flex column space="between" gap="gap.medium">
                                            <Flex gap="gap.small" hAlign="end">
                                                <CFMButtonPermission permission={leadershipData.application_modes} message={state.cfmData.getGoalCommentsData.message}>
                                                    <Button
                                                        flat
                                                        icon={<AddIcon />}
                                                        iconOnly
                                                        onClick={() =>
                                                            addGoalComment(
                                                                leadershipData
                                                                    .sf_competency_details[feedback.index]
                                                            )
                                                        }
                                                    />
                                                </CFMButtonPermission>
                                                <Button
                                                    flat
                                                    icon={<RedbangIcon rotate={180} />}
                                                    iconOnly
                                                    onClick={() => onClickLeadershipBehaviourDescription(leadershipData.sf_competency_details[feedback.index])}
                                                />
                                            </Flex>
                                            <Flex vAlign="end">
                                                <Button
                                                    text
                                                    icon={<ChatIcon />}
                                                    primary
                                                    iconPosition="before"
                                                    content={"Hide feedback"}
                                                    onClick={(e) =>
                                                        viewLeadershipFeedback(
                                                            feedback.index,
                                                            leadershipData
                                                                .sf_competency_details[feedback.index]
                                                        )
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </FlexItem>
                                </Flex>
                                <Divider fitted />
                            </Box>
                        )
                    }
                    {
                        feedback.isActive && (
                            <Box
                                styles={({ theme: { siteVariables } }) => ({
                                    background: siteVariables.colorScheme.grey.backgroundActive,
                                    padding: "0rem, 1.5rem",
                                    height: `${managerView ? "calc(100vh - 276px)" : queryData.isTaskModuleActive ? "calc(100vh - 150px)" : "calc(100vh - 175px)"}`,
                                    overflow: "auto",
                                })}
                            >
                                {state.cfmData.getCompetencyCommentData.isSuccess &&
                                    state.cfmData.getCompetencyCommentData.status_code === "200" &&
                                    state.cfmData.getCompetencyCommentData.competency_comments.length >
                                    0 ? (
                                    state.cfmData.getCompetencyCommentData.competency_comments.filter((item: any) => item.status !== 3).map(
                                        (item, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Flex gap="gap.medium" padding="padding.medium">
                                                        <Avatar
                                                            name={Capitalize(`${item.commented_by_name}`)}
                                                            size="large"
                                                            label={{
                                                                styles: item.role === "DEM" ? { backgroundColor: "#3A83F8", color: "#FFFFFF" } : item.role === "FEM" ? { backgroundColor: "#3D1D76", color: "#FFFFFF" } : { backgroundColor: "#65D283", color: "#FFFFFF" }
                                                            }}
                                                        />
                                                        <Flex
                                                            column
                                                            gap="gap.smaller"
                                                            styles={({ theme: { siteVariables } }) => ({
                                                                background: siteVariables.bodyBackground,
                                                                borderRadius: "2px",
                                                                width: "80%",
                                                                padding: "1rem 1rem 0.4rem 1rem",
                                                            })}
                                                        >
                                                            {/* <Flex column styles={{ background: "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(91, 98, 192, 0.05) 100%)" }}> */}
                                                            <Flex space="between">
                                                                <Text
                                                                    weight="semibold"
                                                                    content={item.commented_by_name === state.userInfo.userManagerData.user_details[0].CompName ? "Self" : item.commented_by_name}
                                                                />
                                                                <Flex vAlign="center">
                                                                    <Text content={timeAgo.format(Date.parse(item.modified_date_time))} size="small" />
                                                                    {/* <CFMMenuComponent item={item} editableId={editableId} setEditableId={setEditableId} managerView={managerView} QueryData={QueryData}/> */}
                                                                </Flex>
                                                            </Flex>
                                                            {/* <Divider fitted/> */}
                                                            {/* </Flex> */}
                                                            {item.id === editableId.isActive ?
                                                                <Flex padding="padding.medium" column>
                                                                    <TextArea
                                                                        value={editableId.comment}
                                                                        fluid
                                                                        onChange={(e: any) => setEditableId({ ...editableId, comment: e.target.value.trimStart() })}
                                                                    />
                                                                    <Flex hAlign="end" vAlign="start">
                                                                        <Button text size="small" content="Consider" className="textStyle" primary={preDefinedText === "Consider"} onClick={() => onClickPreDefine("Consider")} styles={preDefinedText === "Consider" ? { textDecoration: "underline", minWidth: "1rem" } : { minWidth: "1rem" }} />
                                                                        <Button text size="small" content="Continue" className="textStyle" primary={preDefinedText === "Continue"} onClick={() => onClickPreDefine("Continue")} styles={preDefinedText === "Continue" ? { textDecoration: "underline", minWidth: "1rem" } : { minWidth: "1rem" }} />
                                                                    </Flex>
                                                                    <Flex hAlign="end" gap="gap.small" padding="padding.medium">
                                                                        <AcceptIcon size="small" className="pointer" disabled={editableId?.comment?.length === 0} onClick={() => editableId?.comment?.length !== 0 && feedbackEditValue("accept", item)} />
                                                                        <CloseIcon size="small" className="pointer" onClick={() => feedbackEditValue("reject", item)} />
                                                                    </Flex>
                                                                </Flex>
                                                                : <Flex space="between">
                                                                    <Text content={item.comment} styles={{ wordBreak: "break-word" }} />
                                                                    {
                                                                        item.status === "4" ?
                                                                            <AcceptIcon size="small" styles={{ color: "#407855" }} />
                                                                            : item.status === "5" ?
                                                                                <Flex>
                                                                                    <AcceptIcon size="small" styles={{ color: "#407855", marginRight: "0px !important", position: "relative", left: "12px", bottom: "4px" }} />
                                                                                    <AcceptIcon size="small" styles={{ color: "#407855" }} />
                                                                                </Flex>
                                                                                :
                                                                                <CFMMenuComponent item={item} editableId={editableId} setEditableId={setEditableId} managerView={managerView} QueryData={QueryData} />
                                                                    }
                                                                </Flex>
                                                            }
                                                        </Flex>
                                                    </Flex>
                                                    <Divider fitted />
                                                </Box>
                                            );
                                        }
                                    )
                                ) : state.cfmData.getCompetencyCommentData.isSuccess &&
                                    state.cfmData.getCompetencyCommentData.status_code === "200" &&
                                    state.cfmData.getCompetencyCommentData.competency_comments
                                        .length === 0 ? (
                                    <DataNotFound message={Constant.NO_FEEDBACK} img={CFM} />
                                ) : state.cfmData.getCompetencyCommentData.isSuccess &&
                                    state.cfmData.getCompetencyCommentData.status_code !== "200" ? (
                                    <ErrorComponent
                                        message={state.cfmData.getCompetencyCommentData.message}
                                    />
                                ) : (
                                    <Skeleton animation="pulse">
                                        <Flex gap="gap.medium" padding="padding.medium">
                                            <Skeleton.Avatar round size="large" />
                                            <Flex
                                                column
                                                gap="gap.smaller"
                                                styles={({ theme: { siteVariables } }) => ({
                                                    background: siteVariables.bodyBackground,
                                                    borderRadius: "2px",
                                                    width: "80%",
                                                    padding: "1rem",
                                                })}
                                            >
                                                <Skeleton.Text width="40%" />
                                                <Skeleton.Text width="100%" />
                                            </Flex>
                                        </Flex>
                                        <Divider fitted />
                                    </Skeleton>
                                )}
                            </Box>
                        )
                    }
                </Segment >
            }
            {
                addDialog && !queryData.isTaskModuleActive && (
                    <FeedbackDialog
                        QueryData={queryData}
                        handler={[addDialog, setAddDialog]}
                        feedbackProps={feedbackProps}
                        itemId="sa"
                        managerView={managerView ?? false}
                    />
                )
            }
            {
                addDialog && queryData.isTaskModuleActive && (
                    <Feedback
                        QueryData={queryData}
                        handler={[addDialog, setAddDialog]}
                        feedbackProps={feedbackProps}
                        itemId="sa"
                        managerView={managerView ?? false}
                    />
                )
            }
            <Dialog
                open={open}
                cancelButton="Cancel"
                confirmButton={{
                    content: "Confirm",
                    loading: state.cfmData.updateManagerCompetencyComment.isLoading
                }}
                content={Constant.FEEDBACK_CONFRIM}
                header="Confirmation"
                headerAction={{
                    icon: <CloseIcon />,
                    title: "Close",
                    onClick: () => setOpen(false),
                }}
                onCancel={() => cancelDialog()}
                onConfirm={() => confirmDialog()}
            />
        </React.Fragment >
    );
};
export default LeadershipBehaviour;