import { put, call, take, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/PaySpaceAction";
import { httpGet, httpPost } from "../../service";
import { API_BASE_URL, COMPENSATION_CONFIG } from "../../config";

function* getCompensationStructureInstructionsData(payload: { access_token: string; }) {
    try {
        yield put(actionCreators.getCompensationStructureInstructionLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpGet(
            API_BASE_URL + COMPENSATION_CONFIG.GET_COMPENSATION_STRUCTURE_INSTRUCTION,
            config
        );
        yield put(actionCreators.getCompensationStructureInstructionSuccessAction(data));
    } catch (error) {
        yield put(actionCreators.getCompensationStructureInstructionErrorAction(error));
    }
}

function* getCompensationStructureData(action: any) {
    try {
        yield put(actionCreators.getCompensationStructureLoadingAction(action.payload.isStandard));

        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };

        const { data } = yield httpPost(
            API_BASE_URL + COMPENSATION_CONFIG.GET_COMPENSATION_STRUCTURE,
            action.payload.payload,
            config
        );
        yield put(actionCreators.getCompensationStructureSuccessAction(data, action.payload.isStandard));

    } catch (error) {
        yield put(actionCreators.getCompensationStructureErrorAction(error, action.payload.isStandard));
    }
}

function* getCompensationStructurePDF(payload: {
    access_token: string,
    payload: actionCreators.IGetCompensationStructurePDFPayload;
}) {
    try {
        yield put(actionCreators.getCompensationStructurePDFLoadingAction());

        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
            responseType: "blob",
        };
        const urlA = `${API_BASE_URL}${COMPENSATION_CONFIG.GET_COMPENSATION_STRUCTURE_PDF}?emp_id=${payload.payload.emp_id}&comp_code=${payload.payload.comp_code}&egroup=${payload.payload.egroup}&esubgroup=${payload.payload.esubgroup}&category=${payload.payload.category}`;
     
        const { data } = yield httpGet(encodeURI(urlA), config);
      
        // Create a blob URL and trigger download
        const blob = new Blob([data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${payload.payload.emp_id}_${payload.payload.emp_name}_${payload.payload.categoryName}_${payload.payload.currentDate}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        yield put(actionCreators.getCompensationStructurePDFSuccessAction(data));
    } catch (error) {
        yield put(actionCreators.getCompensationStructurePDFErrorAction(error));
    }
}

export function* watchGetCompensationStructureInstructionsData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_DATA
        );
        yield call(getCompensationStructureInstructionsData, payload);
    }
}

export function* watchGetCompensationStructureData() {
    yield takeLatest(actionCreators.Actions.GET_COMPENSATION_STRUCTURE_DATA, getCompensationStructureData);
}

export function* watchGetCompensationStructurePDF() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_COMPENSATION_STRUCTURE_PDF_DATA
        );
        yield call(getCompensationStructurePDF, payload);
    }
}