import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    // Get Todo task
    POST_TODO_ORG_LIST_DATA: "POST_TODO_ORG_LIST_DATA",
    POST_TODO_ORG_LIST_DATA_LOADING: "POST_TODO_ORG_LIST_DATA_LOADING",
    POST_TODO_ORG_LIST_DATA_ERROR: "POST_TODO_ORG_LIST_DATA_ERROR",
    POST_TODO_ORG_LIST_DATA_SUCCESS: "POST_TODO_ORG_LIST_DATA_SUCCESS",

    POST_TODO_PERSONAL_LIST_DATA: "POST_TODO_PERSONAL_LIST_DATA",
    POST_TODO_PERSONAL_LIST_DATA_LOADING: "POST_TODO_PERSONAL_LIST_DATA_LOADING",
    POST_TODO_PERSONAL_LIST_DATA_ERROR: "POST_TODO_PERSONAL_LIST_DATA_ERROR",
    POST_TODO_PERSONAL_LIST_DATA_SUCCESS: "POST_TODO_PERSONAL_LIST_DATA_SUCCESS",


    // Create List
    POST_CREATE_LIST_DATA: "POST_CREATE_LIST_DATA",
    POST_CREATE_LIST_DATA_LOADING: "POST_CREATE_LIST_DATA_LOADING",
    POST_CREATE_LIST_DATA_ERROR: "POST_CREATE_LIST_DATA_ERROR",
    POST_CREATE_LIST_DATA_SUCCESS: "POST_CREATE_LIST_DATA_SUCCESS",

    // Create Task
    GET_CREATE_TASK_DATA: "GET_CREATE_TASK_DATA",
    GET_CREATE_TASK_DATA_LOADING: "GET_CREATE_TASK_DATA_LOADING",
    GET_CREATE_TASK_DATA_ERROR: "GET_CREATE_TASK_DATA_ERROR",
    GET_CREATE_TASK_DATA_SUCCESS: "GET_CREATE_TASK_DATA_SUCCESS",

    // Update Task
    POST_UPDATE_TASK_DATA: "POST_UPDATE_TASK_DATA",
    POST_UPDATE_TASK_DATA_LOADING: "POST_UPDATE_TASK_DATA_LOADING",
    POST_UPDATE_TASK_DATA_ERROR: "POST_UPDATE_TASK_DATA_ERROR",
    POST_UPDATE_TASK_DATA_SUCCESS: "POST_UPDATE_TASK_DATA_SUCCESS",

    //Delete Task
    GET_DELETE_TASK: "GET_DELETE_TASK",
    GET_DELETE_TASK_LOADING: "GET_DELETE_TASK_LOADING",
    GET_DELETE_TASK_ERROR: "GET_DELETE_TASK_ERROR",
    GET_DELETE_TASK_SUCCESS: "GET_DELETE_TASK_SUCCESS",

    // Todo List
    GET_TODO_LIST_DATA: "GET_TODO_LIST_DATA",
    GET_TODO_LIST_DATA_LOADING: "GET_TODO_LIST_DATA_LOADING",
    GET_TODO_LIST_DATA_ERROR: "GET_TODO_LIST_DATA_ERROR",
    GET_TODO_LIST_DATA_SUCCESS: " GET_TODO_LIST_DATA_SUCCESS",

    //Todo Master Data
    GET_TODO_MASTER_DATA: "GET_TODO_MASTER_DATA",
    GET_TODO_MASTER_LOADING: "GET_TODO_MASTER_LOADING",
    GET_TODO_MASTER_ERROR: "GET_TODO_MASTER_ERROR",
    GET_TODO_MASTER_SUCCESS: "GET_TODO_MASTER_SUCCESS"
};

// Get Todo task
export interface IToDoDataPayLaod {
    emp_id: string;
    filter_type: any;
    status_key: any;
    priority_key: any;
    todo_display_name: string;
    due_date_filter: any;
    platform: string;
    az_access_token: string;
}
export interface IToDoData extends SideEffectSchema {
    status: string;
    emp_id: string;
    message: string;
    status_code: string;
    personal_tasks: [];
}
export const postToDoOrgDataAction = (
    access_token: string,
    payload: IToDoDataPayLaod
) => {
    return {
        type: Actions.POST_TODO_ORG_LIST_DATA,
        payload: { access_token, payload },
    };
};
export const postToDoOrgDataLoadingAction = () =>
    loadingAction(Actions.POST_TODO_ORG_LIST_DATA_LOADING);
export const postToDOOrgDataErrorAction = (error: string) =>
    errorAction(Actions.POST_TODO_ORG_LIST_DATA_ERROR, error);
export const postToDoOrgDataSuccessAction = (
    data: IToDoData
) => {
    const payload: IToDoData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_TODO_ORG_LIST_DATA_SUCCESS,
        payload: payload,
    };
};

export const postToDoPersonalDataAction = (
    access_token: string,
    payload: IToDoDataPayLaod
) => {
    return {
        type: Actions.POST_TODO_PERSONAL_LIST_DATA,
        payload: { access_token, payload },
    };
};
export const postToDoPersonalDataLoadingAction = () =>
    loadingAction(Actions.POST_TODO_PERSONAL_LIST_DATA_LOADING);
export const postToDOPersonalDataErrorAction = (error: string) =>
    errorAction(Actions.POST_TODO_PERSONAL_LIST_DATA_ERROR, error);
export const postToDoPersonalDataSuccessAction = (
    data: IToDoData
) => {
    const payload: IToDoData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_TODO_PERSONAL_LIST_DATA_SUCCESS,
        payload: payload,
    };
};


// Create List
export interface ICreateListDataPayLaod {
    emp_id: string;
    displayName: string;
    platform: string;
    az_access_token: string;
}
export interface ICreateListData extends SideEffectSchema {
    status_code: string;
    emp_id: string;
    display_name: string;
    todo_list_id: string;
}
export const postCreateListDataAction = (
    access_token: string,
    payload: ICreateListDataPayLaod
) => {
    return {
        type: Actions.POST_CREATE_LIST_DATA,
        payload: { access_token, payload },
    };
};
export const postCreateListDataLoadingAction = () =>
    loadingAction(Actions.POST_CREATE_LIST_DATA_LOADING);
export const postCreateListDataErrorAction = (error: string) =>
    errorAction(Actions.POST_CREATE_LIST_DATA_ERROR, error);
export const postCreateListDataSuccessAction = (
    data: ICreateListData
) => {
    const payload: ICreateListData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_CREATE_LIST_DATA_SUCCESS,
        payload: payload,
    };
};


// Create Task
export interface ICreateTaskDataPayLaod {
    emp_id: string;
    todo_display_name: string;
    title: string;
    task_due_date: string;
    priority: string;
    status: string;
    platform: string;
    comp_code: string;
    az_access_token: string;
}
export interface ICreateTaskData extends SideEffectSchema {
    status_code: string;
    emp_id: string;
    message: string;
}
export const getCreateTaskDataAction = (
    access_token: string,
    payload: ICreateTaskDataPayLaod
) => {
    return {
        type: Actions.GET_CREATE_TASK_DATA,
        payload: { access_token, payload },
    };
};
export const getCreateTaskDataLoadingAction = () =>
    loadingAction(Actions.GET_CREATE_TASK_DATA_LOADING);
export const getCreateTaskDataErrorAction = (error: string) =>
    errorAction(Actions.GET_CREATE_TASK_DATA_ERROR, error);
export const getCreatetaskDataSuccessAction = (
    data: ICreateTaskData
) => {
    const payload: ICreateTaskData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_CREATE_TASK_DATA_SUCCESS,
        payload: payload,
    };
};


// Update Task

export interface IUpdateTaskDataPayLaod {
    emp_id: string;
    todo_display_name: string;
    title: string;
    task_due_date: string;
    priority: string;
    status: string;
    task_id: string;
    platform: string;
    comp_code: string;
    az_access_token: string;
}
export interface IUpdatetaskData extends SideEffectSchema {
    status_code: string;
    emp_id: string;
    message: string;
}
export const postUpdateTaskDataAction = (
    access_token: string,
    payload: IUpdateTaskDataPayLaod
) => {
    return {
        type: Actions.POST_UPDATE_TASK_DATA,
        payload: { access_token, payload },
    };
};
export const postUpdateTaskDataLoadingAction = () =>
    loadingAction(Actions.POST_UPDATE_TASK_DATA_LOADING);
export const postUpdateTaskDataErrorAction = (error: string) =>
    errorAction(Actions.POST_UPDATE_TASK_DATA_ERROR, error);
export const postUpdateTaskDataSuccessAction = (
    data: IUpdatetaskData
) => {
    const payload: IUpdatetaskData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_UPDATE_TASK_DATA_SUCCESS,
        payload: payload,
    };
};


//Delete Task
export interface IDeleteTaskPayLaod {
    emp_id: string;
    todo_display_name: string;
    task_id: string;
    platform: string;
    comp_code: string;
    az_access_token: string;
}
export interface IDeleteTask extends SideEffectSchema {
    status_code: string;
    emp_id: string;
    message: string;
}
export const getDeleteTaskAction = (
    access_token: string,
    payload: IDeleteTaskPayLaod
) => {
    return {
        type: Actions.GET_DELETE_TASK,
        payload: { access_token, payload },
    };
};
export const getDeleteTaskLoadingAction = () =>
    loadingAction(Actions.GET_DELETE_TASK_LOADING);
export const getDeleteTaskErrorAction = (error: string) =>
    errorAction(Actions.GET_DELETE_TASK_ERROR, error);
export const getDeleteTaskSuccessAction = (
    data: ICreateTaskData
) => {
    const payload: ICreateTaskData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_DELETE_TASK_SUCCESS,
        payload: payload,
    };
};

// Todo List

export interface ITodoListDataPayLaod {
    emp_id: string;
    platform: string;
    az_access_token: string;
}

export interface IValue {
    "@odata.etag": string,
    displayName: string,
    isOwner: boolean,
    isShared: boolean,
    wellknownListName: string,
    id: string
}
export interface IResponse {
    "@odata.context": string;
    value: IValue[];
}
export interface IToDoListData extends SideEffectSchema {
    status_code: string;
    emp_id: string;
    response: IResponse;
}
export const getToDoListDataAction = (
    access_token: string,
    payload: ITodoListDataPayLaod
) => {
    return {
        type: Actions.GET_TODO_LIST_DATA,
        payload: { access_token, payload },
    };
};
export const getToDoListDataLoadingAction = () =>
    loadingAction(Actions.GET_TODO_LIST_DATA_LOADING);
export const getToDoListDataErrorAction = (error: string) =>
    errorAction(Actions.GET_TODO_LIST_DATA_ERROR, error);
export const getTodoListDataSuccessAction = (
    data: IToDoListData
) => {
    const payload: IToDoListData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_TODO_LIST_DATA_SUCCESS,
        payload: payload,
    };
};


export interface IKeyValue {
    key: string;
    value: string
}
export interface ITodoMasterData {
    status: IKeyValue[],
    importance: IKeyValue[],
    task_due_date: IKeyValue[]
}

export interface ITodoMaster extends SideEffectSchema {
    status_code: string;
    message: string;
    data: ITodoMasterData
}
export const getToDoMasterDataAction = (
    access_token: string,
    emp_id: string
) => {
    return {
        type: Actions.GET_TODO_MASTER_DATA,
        payload: { access_token, emp_id }
    };
};
export const getToDoMasterDataLoadingAction = () =>
    loadingAction(Actions.GET_TODO_MASTER_LOADING);
export const getToDOMasterDataErrorAction = (error: string) =>
    errorAction(Actions.GET_TODO_MASTER_ERROR, error);
export const getToDOMasterDataSuccessAction = (
    data: ITodoMaster
) => {
    const payload: ITodoMaster = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_TODO_MASTER_SUCCESS,
        payload: payload
    };
};
