import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema
} from ".";

export const Actions = {
    // ------------------------ Success Factor User Details ----------------
    GET_SUCCESS_FACTOR_USER_DETAILS_DATA: "GET_SUCCESS_FACTOR_USER_DETAILS_DATA",
    GET_SUCCESS_FACTOR_USER_DETAILS_LOADING: "GET_SUCCESS_FACTOR_USER_DETAILS_LOADING",
    GET_SUCCESS_FACTOR_USER_DETAILS_ERROR: "GET_SUCCESS_FACTOR_USER_DETAILS_ERROR",
    GET_SUCCESS_FACTOR_USER_DETAILS_SUCCESS: "GET_SUCCESS_FACTOR_USER_DETAILS_SUCCESS",

    // ------------------------ Employee Goal -----------------------------
    GET_EMPLOYEE_GOAL_DATA: "GET_EMPLOYEE_GOAL_DATA",
    GET_EMPLOYEE_GOAL_LOADING: "GET_EMPLOYEE_GOAL_LOADING",
    GET_EMPLOYEE_GOAL_ERROR: "GET_EMPLOYEE_GOAL_ERROR",
    GET_EMPLOYEE_GOAL_SUCCESS: "GET_EMPLOYEE_GOAL_SUCCESS",

    // ------------------------ Add Goal Comments-----------------------------
    ADD_GOAL_COMMENTS_DATA: "ADD_GOAL_COMMENTS_DATA",
    ADD_GOAL_COMMENTS_LOADING: "ADD_GOAL_COMMENTS_LOADING",
    ADD_GOAL_COMMENTS_ERROR: "ADD_GOAL_COMMENTS_ERROR",
    ADD_GOAL_COMMENTS_SUCCESS: "ADD_GOAL_COMMENTS_SUCCESS",

    // ------------------------ Get Employee Competency-----------------------------
    GET_EMPLOYEE_COMPETENCY_DATA: "GET_EMPLOYEE_COMPETENCY_DATA",
    GET_EMPLOYEE_COMPETENCY_LOADING: "GET_EMPLOYEE_COMPETENCY_LOADING",
    GET_EMPLOYEE_COMPETENCY_ERROR: "GET_EMPLOYEE_COMPETENCY_ERROR",
    GET_EMPLOYEE_COMPETENCY_SUCCESS: "GET_EMPLOYEE_COMPETENCY_SUCCESS",

    // ------------------------ Add Competency Comments-----------------------------
    ADD_COMPETENCY_COMMENT_DATA: "ADD_COMPETENCY_COMMENT_DATA",
    ADD_COMPETENCY_COMMENT_LOADING: "ADD_COMPETENCY_COMMENT_LOADING",
    ADD_COMPETENCY_COMMENT_ERROR: "ADD_COMPETENCY_COMMENT_ERROR",
    ADD_COMPETENCY_COMMENT_SUCCESS: "ADD_COMPETENCY_COMMENT_SUCCESS",

    // ------------------------ Get Goal Comments-----------------------------
    GET_GOAL_COMMENTS_DATA: "GET_GOAL_COMMENTS_DATA",
    GET_GOAL_COMMENTS_LOADING: "GET_GOAL_COMMENTS_LOADING",
    GET_GOAL_COMMENTS_ERROR: "GET_GOAL_COMMENTS_ERROR",
    GET_GOAL_COMMENTS_SUCCESS: "GET_GOAL_COMMENTS_SUCCESS",

    // ------------------------ Get Competency Comments-----------------------------
    GET_COMPETENCY_COMMENT_DATA: "GET_COMPETENCY_COMMENT_DATA",
    GET_COMPETENCY_COMMENT_LOADING: "GET_COMPETENCY_COMMENT_LOADING",
    GET_COMPETENCY_COMMENT_ERROR: "GET_COMPETENCY_COMMENT_ERROR",
    GET_COMPETENCY_COMMENT_SUCCESS: "GET_COMPETENCY_COMMENT_SUCCESS",

    // ------------------------ Add Performace Timeline -----------------------------
    ADD_PERFORMANCE_TIMELINE_DATA: "ADD_PERFORMANCE_TIMELINE_DATA",
    ADD_PERFORMANCE_TIMELINE_LOADING: "ADD_PERFORMANCE_TIMELINE_LOADING",
    ADD_PERFORMANCE_TIMELINE_ERROR: "ADD_PERFORMANCE_TIMELINE_ERROR",
    ADD_PERFORMANCE_TIMELINE_SUCCESS: "ADD_PERFORMANCE_TIMELINE_SUCCESS",

    // ------------------------ Send Feedback Request -----------------------------
    SEND_FEEDBACK_REQUEST_DATA: "SEND_FEEDBACK_REQUEST_DATA",
    SEND_FEEDBACK_REQUEST_LOADING: "SEND_FEEDBACK_REQUEST_LOADING",
    SEND_FEEDBACK_REQUEST_ERROR: "SEND_FEEDBACK_REQUEST_ERROR",
    SEND_FEEDBACK_REQUEST_SUCCESS: "SEND_FEEDBACK_REQUEST_SUCCESS",

    //-------------------------- Leadership Behaviour Description ----------------------
    GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_DATA: "GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_DATA",
    GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_LOADING: "GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_LOADING",
    GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_ERROR: "GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_ERROR",
    GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_SUCCESS: "GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_SUCCESS",

    //-----------------------------Update Manager Goal Comment ------------------------------
    UPDATE_MANAGER_GOAL_COMMENT_DATA: "UPDATE_MANAGER_GOAL_COMMENT_DATA",
    UPDATE_MANAGER_GOAL_COMMENT_LOADING: "UPDATE_MANAGER_GOAL_COMMENT_LOADING",
    UPDATE_MANAGER_GOAL_COMMENT_ERROR: "UPDATE_MANAGER_GOAL_COMMENT_ERROR",
    UPDATE_MANAGER_GOAL_COMMENT_SUCCESS: "UPDATE_MANAGER_GOAL_COMMENT_SUCCESS",

    //-----------------------------Update Manager Competency Comment ------------------------------
    UPDATE_MANAGER_COMPETENCY_COMMENT_DATA: "UPDATE_MANAGER_COMPETENCY_COMMENT_DATA",
    UPDATE_MANAGER_COMPETENCY_COMMENT_LOADING: "UPDATE_MANAGER_COMPETENCY_COMMENT_LOADING",
    UPDATE_MANAGER_COMPETENCY_COMMENT_ERROR: "UPDATE_MANAGER_COMPETENCY_COMMENT_ERROR",
    UPDATE_MANAGER_COMPETENCY_COMMENT_SUCCESS: "UPDATE_MANAGER_COMPETENCY_COMMENT_SUCCESS",

    //-----------------------------Delete Manager Goal Comment ------------------------------
    DELETE_MANAGER_GOAL_COMMENT_DATA: "DELETE_MANAGER_GOAL_COMMENT_DATA",
    DELETE_MANAGER_GOAL_COMMENT_LOADING: "DELETE_MANAGER_GOAL_COMMENT_LOADING",
    DELETE_MANAGER_GOAL_COMMENT_ERROR: "DELETE_MANAGER_GOAL_COMMENT_ERROR",
    DELETE_MANAGER_GOAL_COMMENT_SUCCESS: "DELETE_MANAGER_GOAL_COMMENT_SUCCESS",

    //-----------------------------Delete Manager Competency Comment ------------------------------
    DELETE_MANAGER_COMPETENCY_COMMENT_DATA: "DELETE_MANAGER_COMPETENCY_COMMENT_DATA",
    DELETE_MANAGER_COMPETENCY_COMMENT_LOADING: "DELETE_MANAGER_COMPETENCY_COMMENT_LOADING",
    DELETE_MANAGER_COMPETENCY_COMMENT_ERROR: "DELETE_MANAGER_COMPETENCY_COMMENT_ERROR",
    DELETE_MANAGER_COMPETENCY_COMMENT_SUCCESS: "DELETE_MANAGER_COMPETENCY_COMMENT_SUCCESS"

};

export interface IMetadata {
    uri: string;
    type: string;
}

export interface IEmpData {
    __metadata: IMetadata;
    userId: string;
    empId: string;
    custom06: string;
    title: string;
    username: string;
}

export interface IReportees extends IEmpData {
    defaultFullName: string;
    department: string;
}

export interface IGetSuccessFactorUserDetails extends SideEffectSchema {
    status_code: string;
    message: string;
    user_details: {
        user_id: string;
        emp_id: string;
        manager: IEmpData;
        designation: string;
        level: string;
        matrix_manager: IEmpData;
        direct_reportees: IReportees[];
        matrix_reportees: IReportees[];
        all_reportees: [
            IReportees[],
            IReportees[]
        ];
        employee_level_report: {
            key: string;
            value: string;
        }[];
    }
}

export const getSuccessFactorUserDetailsDataAction = (
    access_token: string,
    emp_id: string
) => {
    return {
        type: Actions.GET_SUCCESS_FACTOR_USER_DETAILS_DATA,
        payload: { access_token, emp_id }
    };
};
export const getSuccessFactorUserDetailsLoadingAction = () =>
    loadingAction(Actions.GET_SUCCESS_FACTOR_USER_DETAILS_LOADING);
export const getSuccessFactorUserDetailsErrorAction = (error: string) =>
    errorAction(Actions.GET_SUCCESS_FACTOR_USER_DETAILS_ERROR, error);
export const getSuccessFactorUserDetailsSuccessAction = (data: any) => {
    const payload: IGetSuccessFactorUserDetails = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_SUCCESS_FACTOR_USER_DETAILS_SUCCESS,
        payload: payload
    };
};

export interface IGoals {
    sf_goal_details_id: string;
    emp_id: string;
    user_id: string;
    form_data_id: string;
    form_template_id: string;
    item_id: string;
    name: string;
    weight: string;
    state: string;
    category: string;
    target: string;
    is_deleted: boolean;
    created_date_time: string;
    modified_date_time: string;
    created_by: string;
    modified_by: string;
    comments: {
        commented_by_name: string;
    }[]
}

export interface IGetEmployeeGoal extends SideEffectSchema {
    application_modes: string;
    status_code: string;
    message: string;
    sf_goal_details: IGoals[];
    isTeam: boolean;
}

export const getEmployeeGoalsDataAction = (
    access_token: string,
    payload: {
        emp_id: string,
        user_id: string;
        is_employee: boolean;
    },
    isTeam?: boolean
) => {
    return {
        type: Actions.GET_EMPLOYEE_GOAL_DATA,
        payload: { access_token, payload, isTeam }
    };
};
export const getEmployeeGoalsLoadingAction = (isTeam: boolean) =>
    loadingAction(Actions.GET_EMPLOYEE_GOAL_LOADING, { isTeam: isTeam });
export const getEmployeeGoalsErrorAction = (error: string, isTeam: boolean) =>
    errorAction(Actions.GET_EMPLOYEE_GOAL_ERROR, error, { isTeam: isTeam });
export const getEmployeeGoalsSuccessAction = (data: any, isTeam: boolean) => {
    const payload: IGetEmployeeGoal = {
        ...data,
        ...successSideEffectState,
        isTeam
    };
    return {
        type: Actions.GET_EMPLOYEE_GOAL_SUCCESS,
        payload: payload
    };
};

export interface IAddGoalComments extends SideEffectSchema {
    status_code: string;
    message: string;
}

export interface IAddGoalCommentPayload {
    emp_id: string;
    item_id: string;
    comment: string;
    commented_by: string;
    commented_by_name: string;
    role: string;
    goal_name: string;
    form_data_id: string;
    form_template_id: string;
    prev_sf_goal_comments_id: string;
    user_id: string;
    dem_email?: string;
    fem_email?: string;
    emp_email: string;
}

export const addGoalCommentDataAction = (
    access_token: string,
    payload: IAddGoalCommentPayload
) => {
    return {
        type: Actions.ADD_GOAL_COMMENTS_DATA,
        payload: { access_token, payload }
    };
};
export const addGoalCommentLoadingAction = () =>
    loadingAction(Actions.ADD_GOAL_COMMENTS_LOADING);
export const addGoalCommentErrorAction = (error: string) =>
    errorAction(Actions.ADD_GOAL_COMMENTS_ERROR, error);
export const addGoalCommentSuccessAction = (data: any) => {
    const payload: IAddGoalComments = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.ADD_GOAL_COMMENTS_SUCCESS,
        payload: payload
    };
};

export interface ISfCompetencyDetails {
    id: string;
    sf_competency_details_id: string;
    emp_id: string;
    user_id: string;
    form_data_id: string;
    form_template_id: string;
    item_id: string;
    name: string;
    description: string;
    source: string;
    is_deleted: boolean;
    created_date_time: string;
    modified_date_time: string;
    created_by: string;
    modified_by: string;
    comments: {
        commented_by_name: string;
    }[];
}

export interface IGetEmployeeCompetencies extends SideEffectSchema {
    application_modes: string;
    status_code: string;
    message: string;
    sf_competency_details: ISfCompetencyDetails[];
    isTeam?: boolean;
}

export const getEmployeeCompetencyDataAction = (
    access_token: string,
    payload: {
        user_id: string;
        emp_id: string;
        is_employee: boolean;
    },
    isTeam?: boolean
) => {
    return {
        type: Actions.GET_EMPLOYEE_COMPETENCY_DATA,
        payload: { access_token, payload, isTeam }
    };
};
export const getEmployeeCompetencyLoadingAction = (isTeam?: boolean) =>
    loadingAction(Actions.GET_EMPLOYEE_COMPETENCY_LOADING, { isTeam: isTeam });
export const getEmployeeCompetencyErrorAction = (error: string, isTeam?: boolean) =>
    errorAction(Actions.GET_EMPLOYEE_COMPETENCY_ERROR, error, { isTeam: isTeam });
export const getEmployeeCompetencySuccessAction = (data: any, isTeam?: boolean) => {
    const payload: IGetEmployeeCompetencies = {
        ...data,
        ...successSideEffectState,
        isTeam
    };
    return {
        type: Actions.GET_EMPLOYEE_COMPETENCY_SUCCESS,
        payload: payload
    };
};

export interface IAddCompetenciesComments extends SideEffectSchema {
    status_code: string;
    message: string;
}

export interface IAddCompetenciesCommentsPayload {
    item_id: string;
    comment: string;
    commented_by: string;
    commented_by_name: string;
    role: string;
    competency_name: string;
    form_data_id: string;
    form_template_id: string;
    prev_sf_competency_comments_id: string;
    user_id: string;
    emp_id: string;
    fem_email: string;
    dem_email: string;
    emp_email: string;
}

export const addCompetencyCommentDataAction = (
    access_token: string,
    payload: IAddCompetenciesCommentsPayload
) => {
    return {
        type: Actions.ADD_COMPETENCY_COMMENT_DATA,
        payload: { access_token, payload }
    };
};
export const addCompetencyCommentLoadingAction = () =>
    loadingAction(Actions.ADD_COMPETENCY_COMMENT_LOADING);
export const addCompetencyCommentErrorAction = (error: string) =>
    errorAction(Actions.ADD_COMPETENCY_COMMENT_ERROR, error);
export const addCompetencyCommentSuccessAction = (data: any) => {
    const payload: IAddCompetenciesComments = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.ADD_COMPETENCY_COMMENT_SUCCESS,
        payload: payload
    };
};

export interface IGoalComment {
    id: number,
    sf_goal_comments_id: string;
    form_data_id: string;
    form_template_id: string;
    item_id_pk_id: string;
    comment: string;
    commented_by: string;
    commented_by_name: string;
    role: string;
    status: string;
    is_read: boolean;
    created_date_time: string;
    modified_date_time: string;
    created_by: string;
    modified_by: string;
    pushed_date_time_mtr: string;
    pushed_date_time_fa: string;
}

export interface IGetGoalComment extends SideEffectSchema {
    status_code: string;
    message: string;
    goal_comments: IGoalComment[];
}

export const getGoalCommentDataAction = (
    access_token: string,
    item_id: string,
    is_employee: boolean
) => {
    return {
        type: Actions.GET_GOAL_COMMENTS_DATA,
        payload: { access_token, item_id, is_employee }
    };
};
export const getGoalCommentLoadingAction = () =>
    loadingAction(Actions.GET_GOAL_COMMENTS_LOADING);
export const getGoalCommentErrorAction = (error: string) =>
    errorAction(Actions.GET_GOAL_COMMENTS_ERROR, error);
export const getGoalCommentSuccessAction = (data: any) => {
    const payload: IGetGoalComment = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_GOAL_COMMENTS_SUCCESS,
        payload: payload
    };
};

export interface ICompetencyComment {
    id: number,
    sf_competency_comments_id: string;
    form_data_id: string;
    form_template_id: string;
    item_id_pk_id: string;
    comment: string;
    commented_by: string;
    commented_by_name: string;
    role: string;
    status: string;
    is_read: boolean;
    created_date_time: string;
    modified_date_time: string;
    created_by: string;
    modified_by: string;
    pushed_date_time_mtr: string;
    pushed_date_time_fa: string;
}

export interface IGetCompetencyComment extends SideEffectSchema {
    status_code: string;
    message: string;
    competency_comments: ICompetencyComment[];
}

export const getCompetencyCommentDataAction = (
    access_token: string,
    item_id: string,
    user_id: string,
    is_employee: boolean
) => {
    return {
        type: Actions.GET_COMPETENCY_COMMENT_DATA,
        payload: { access_token, item_id, user_id, is_employee }
    };
};
export const getCompetencyCommentLoadingAction = () =>
    loadingAction(Actions.GET_COMPETENCY_COMMENT_LOADING);
export const getCompetencyCommentErrorAction = (error: string) =>
    errorAction(Actions.GET_COMPETENCY_COMMENT_ERROR, error);
export const getCompetencyCommentSuccessAction = (data: any) => {
    const payload: IGetCompetencyComment = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_COMPETENCY_COMMENT_SUCCESS,
        payload: payload
    };
};

export interface ITimelineData {
    item_id_pk_id: string;
    comment: string;
    commented_by_name: string;
    created_date_time: string;
    role: string;
    title: string;
    type: string;
}

export interface IGetPerformanceTimeline extends SideEffectSchema {
    status_code: string;
    message: string;
    timeline_data: ITimelineData[];
}

export const getPerformanceTimelineDataAction = (
    access_token: string,
    payload: {
        user_id: string,
        emp_id: string,
        filter: string
    }
) => {
    return {
        type: Actions.ADD_PERFORMANCE_TIMELINE_DATA,
        payload: { access_token, payload }
    };
};
export const getPerformanceTimelineLoadingAction = () =>
    loadingAction(Actions.ADD_PERFORMANCE_TIMELINE_LOADING);
export const getPerformanceTimelineErrorAction = (error: string) =>
    errorAction(Actions.ADD_PERFORMANCE_TIMELINE_ERROR, error);
export const getPerformanceTimelineSuccessAction = (data: any) => {
    const payload: IGetPerformanceTimeline = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.ADD_PERFORMANCE_TIMELINE_SUCCESS,
        payload: payload
    };
};

export interface ISendFeedbackRequest extends SideEffectSchema {
    status_code: string;
    message: string;
}

export interface ISendFeedbackPayload {
    item_ids: string[];
    emp_id: string;
    emp_dem_email: string;
    emp_fem_email: string;
    emp_name: string;
}

export const sendFeedbackRequestDataAction = (
    access_token: string,
    payload: ISendFeedbackPayload
) => {
    return {
        type: Actions.SEND_FEEDBACK_REQUEST_DATA,
        payload: { access_token, payload }
    };
};
export const sendFeedbackRequestLoadingAction = () =>
    loadingAction(Actions.SEND_FEEDBACK_REQUEST_LOADING);
export const sendFeedbackRequestErrorAction = (error: string) =>
    errorAction(Actions.SEND_FEEDBACK_REQUEST_ERROR, error);
export const sendFeedbackRequestSuccessAction = (data: any) => {
    const payload: ISendFeedbackRequest = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.SEND_FEEDBACK_REQUEST_SUCCESS,
        payload: payload
    };
};


export interface ILeadershipBehaviourDescription {
    leadership_behaviours_description: string,
    leadership_level: string
}
export interface IGetLeadershipBehaviourDescription extends SideEffectSchema {
    status_code: string,
    result: {
        leadership_behaviours_title: string,
        leadership_behaviours_heading: string,
        leadership_behaviours_description: ILeadershipBehaviourDescription[]
    }
}

export const getLeadershipBehaviourDewscriptionDataAction = (
    access_token: string,
    payload: {
        emp_id: string,
        leadership_behaviours_title: string
    }
) => {
    return {
        type: Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_DATA,
        payload: { access_token, payload }
    };
};
export const getLeadershipBehaviourDewscriptionLoadingAction = () =>
    loadingAction(Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_LOADING);
export const getLeadershipBehaviourDewscriptionErrorAction = (error: string) =>
    errorAction(Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_ERROR, error);
export const getLeadershipBehaviourDewscriptionSuccessAction = (data: any) => {
    const payload: IGetLeadershipBehaviourDescription = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_SUCCESS,
        payload: payload
    };
};

export interface IUpdateManagerGoalCommentPayload {
    item_id: string;
    sf_goal_comments_id: string;
    comment: string;
}

export interface IUpdateManagerGoalCommentData extends SideEffectSchema {
    status_code: string;
    message: string;
}

export const updateManagerGoalCommentDataAction = (
    access_token: string,
    payload: IUpdateManagerGoalCommentPayload
) => {
    return {
        type: Actions.UPDATE_MANAGER_GOAL_COMMENT_DATA,
        payload: { access_token, payload }
    };
};
export const updateManagerGoalCommentLoadingAction = () =>
    loadingAction(Actions.UPDATE_MANAGER_GOAL_COMMENT_LOADING);
export const updateManagerGoalCommentErrorAction = (error: string) =>
    errorAction(Actions.UPDATE_MANAGER_GOAL_COMMENT_ERROR, error);
export const updateManagerGoalCommentSuccessAction = (data: any) => {
    const payload: IUpdateManagerGoalCommentData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.UPDATE_MANAGER_GOAL_COMMENT_SUCCESS,
        payload: payload
    };
};

export interface IUpdateManagerCompetencyCommentPayload {
    item_id: string;
    sf_competency_comments_id: string;
    comment: string;
}

export interface IUpdateManagerCompetencyCommentData extends SideEffectSchema {
    status_code: string;
    message: string;
}

export const updateManagerCompetencyCommentDataAction = (
    access_token: string,
    payload: IUpdateManagerCompetencyCommentPayload
) => {
    return {
        type: Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_DATA,
        payload: { access_token, payload }
    };
};
export const updateManagerCompetencyCommentLoadingAction = () =>
    loadingAction(Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_LOADING);
export const updateManagerCompetencyCommentErrorAction = (error: string) =>
    errorAction(Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_ERROR, error);
export const updateManagerCompetencyCommentSuccessAction = (data: any) => {
    const payload: IUpdateManagerCompetencyCommentData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_SUCCESS,
        payload: payload
    };
};

export interface IDeleteManagerGoalCommentPayload {
    item_id: string;
    sf_goal_comments_id: string
}

export interface IDeleteManagerGoalCommentData extends SideEffectSchema {
    status_code: string;
    message: string
}

export const deleteManagerGoalCommentDataAction = (
    access_token: string,
    payload: IDeleteManagerGoalCommentPayload
) => {
    return {
        type: Actions.DELETE_MANAGER_GOAL_COMMENT_DATA,
        payload: { access_token, payload }
    };
};
export const deleteManagerGoalCommentLoadingAction = () =>
    loadingAction(Actions.DELETE_MANAGER_GOAL_COMMENT_LOADING);
export const deleteManagerGoalCommentErrorAction = (error: string) =>
    errorAction(Actions.DELETE_MANAGER_GOAL_COMMENT_ERROR, error);
export const deleteManagerGoalCommentSuccessAction = (data: any) => {
    const payload: IDeleteManagerGoalCommentData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.DELETE_MANAGER_GOAL_COMMENT_SUCCESS,
        payload: payload
    };
};

export interface IDeleteManagerCompetencyCommentPayload {
    item_id: string;
    sf_competency_comments_id: string
}

export interface IDeleteManagerCompetencyCommentData extends SideEffectSchema {
    status_code: string;
    message: string
}

export const deleteManagerCompetencyCommentDataAction = (
    access_token: string,
    payload: IDeleteManagerCompetencyCommentPayload
) => {
    return {
        type: Actions.DELETE_MANAGER_COMPETENCY_COMMENT_DATA,
        payload: { access_token, payload }
    };
};
export const deleteManagerCompetencyCommentLoadingAction = () =>
    loadingAction(Actions.DELETE_MANAGER_COMPETENCY_COMMENT_LOADING);
export const deleteManagerCompetencyCommentErrorAction = (error: string) =>
    errorAction(Actions.DELETE_MANAGER_COMPETENCY_COMMENT_ERROR, error);
export const deleteManagerCompetencyCommentSuccessAction = (data: any) => {
    const payload: IDeleteManagerCompetencyCommentData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.DELETE_MANAGER_COMPETENCY_COMMENT_SUCCESS,
        payload: payload
    };
};

