import {
    Actions,
    IGetMasterLocationData,
    IGetMasterFieldData,
    IGetDisplayFieldData,
    IGetMasterOfficeData,
    IGetAllStatusData,
    IGetSaveDigitalData,
    IGetSavePysicalData,
    IGetCardQuantityData,
    IGetDigitalVcData,
    IGetSystemShutdownData,
    IGetPhysicalVcData,
    IGetQRStringData,
    IUpdatePhysicalVCData
} from "../actions/VisitorAction";
import { combineReducers } from "redux";

export const getMasterLoactionReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_MASTER_LOCATION_LOADING:
        case Actions.GET_MASTER_LOCATION_ERROR:
        case Actions.GET_MASTER_LOCATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getMasterFieldReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_MASTER_FIELD_NAMES_LOADING:
        case Actions.GET_MASTER_FIELD_NAMES_ERROR:
        case Actions.GET_MASTER_FIELD_NAMES_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getMasterOfficeReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_OFFICE_LOCATION_LOADING:
        case Actions.GET_OFFICE_LOCATION_ERROR:
        case Actions.GET_OFFICE_LOCATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.GET_OFFICE_LOCATION_CLEAR:
            return {
                ...state,
                ...{ isSuccess: false }
            };
        default:
            return state;
    }
};

export const getDisplayFieldReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_DISPLAY_FIELD_LOADING:
        case Actions.GET_DISPLAY_FIELD_ERROR:
        case Actions.GET_DISPLAY_FIELD_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.GET_DISPLAY_FIELD_CLEAR:
            return {
                ...state,
                ...{ isSuccess: false }
            };
        default:
            return state;
    }
};

export const getAllStatusReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ALL_STATUS_LOADING:
        case Actions.GET_ALL_STATUS_ERROR:
        case Actions.GET_ALL_STATUS_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getSaveDigitalReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_SAVE_DIGITAL_LOADING:
        case Actions.GET_SAVE_DIGITAL_ERROR:
        case Actions.GET_SAVE_DIGITAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getSavePysicalReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_SAVE_PYSICAL_LOADING:
        case Actions.GET_SAVE_PYSICAL_ERROR:
        case Actions.GET_SAVE_PYSICAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getCardQuantityReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_CARD_QUANTITY_LOADING:
        case Actions.GET_CARD_QUANTITY_ERROR:
        case Actions.GET_CARD_QUANTITY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getSystemShutdownReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_SYSTEM_SHUTDOWN_LOADING:
        case Actions.GET_SYSTEM_SHUTDOWN_ERROR:
        case Actions.GET_SYSTEM_SHUTDOWN_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getDigitalVcReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_DIGITAL_VC_LOADING:
        case Actions.GET_DIGITAL_VC_ERROR:
        case Actions.GET_DIGITAL_VC_SUCCESS:
        case Actions.GET_DIGITAL_VC_CLEAR:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getPhysicalVcReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_PHYSICAL_VC_LOADING:
        case Actions.GET_PHYSICAL_VC_ERROR:
        case Actions.GET_PHYSICAL_VC_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getQrStringReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_QR_STRING_LOADING:
        case Actions.GET_QR_STRING_ERROR:
        case Actions.GET_QR_STRING_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const updatePhysicalVCReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.UPDATE_PHYSICAL_VC_LOADING:
        case Actions.UPDATE_PHYSICAL_VC_ERROR:
        case Actions.UPDATE_PHYSICAL_VC_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export interface IVisitorData {
    getMasterLocation: IGetMasterLocationData,
    getMasterField: IGetMasterFieldData,
    getMasterOffice: IGetMasterOfficeData,
    getDisplayField: IGetDisplayFieldData,
    getAllStatus: IGetAllStatusData,
    getSaveDigital: IGetSaveDigitalData,
    getSavePysical: IGetSavePysicalData
    getCardQuantity: IGetCardQuantityData,
    getDigitalVc: IGetDigitalVcData,
    getPhysicalVc: IGetPhysicalVcData,
    getSystemShutdown: IGetSystemShutdownData,
    getQrStringData: IGetQRStringData,
    updatePhysicalVc: IUpdatePhysicalVCData
}

const visitorData = combineReducers({
    getMasterLocation: getMasterLoactionReducer,
    getMasterField: getMasterFieldReducer,
    getMasterOffice: getMasterOfficeReducer,
    getDisplayField: getDisplayFieldReducer,
    getAllStatus: getAllStatusReducer,
    getSaveDigital: getSaveDigitalReducer,
    getSavePysical: getSavePysicalReducer,
    getCardQuantity: getCardQuantityReducer,
    getDigitalVc: getDigitalVcReducer,
    getPhysicalVc: getPhysicalVcReducer,
    getSystemShutdown: getSystemShutdownReducer,
    getQrStringData: getQrStringReducer,
    updatePhysicalVc: updatePhysicalVCReducer
});
export default visitorData;