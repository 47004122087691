/* eslint-disable max-len */
import {
    Flex, Text, CloseIcon, Dropdown, RadioGroup, Datepicker, FlexItem, Segment, Button, Loader, Table, Divider, TextArea, Pill, Skeleton, Box
} from "@fluentui/react-northstar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Capitalize,
    Constant, EmpValueByCode, getDateFormat, LeaveMode, LeaveTypeCatogary, LeaveTypes, ServiceName, TraceEvent, TraceTelemtry
} from "../../constant";
import * as LeaveAppliedAction from "../../redux/actions/LeaveActions";
import { IState } from "../../App";
import { RootSchema } from "../../redux/reducer";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import { getAppInsights } from "./../../telemetry/telemetryService";
import "./applyLeave.scss";
import ServicePermission from "../HOC/ServicePermission";

interface IApplyLeaveProps {
    QueryData: IState
}

interface ILeaveForm {
    type: { header: string, name: string, code: string },
    day: string,
    toDate: Date,
    fromDate: Date,
    remark: string
}

interface IActionProps {
    isWaiting: boolean;
    isPreview: boolean;
    isAddLeave: boolean;
}

const ApplyLeave: React.FC<IApplyLeaveProps> = ({ QueryData }) => {
    const { appInsights, trackEvent, trackMetrix } = getAppInsights();
    const [LeaveForm, setLeaveForm] = React.useState<ILeaveForm>({
        type: { header: "", name: "", code: "" },
        day: "FD",
        toDate: new Date(),
        fromDate: new Date(),
        remark: "",
    });
    const [isAction, setIsAction] = React.useState<IActionProps>({
        isWaiting: false,
        isPreview: false,
        isAddLeave: false,
    });

    const [appInsightData, setAppInsightData] = React.useState<any>({});
    const [leaveValidData, setLeaveValidData] = React.useState<LeaveAppliedAction.IApplyLeaveDataObj[]>([]);
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const accessToken = state.authData.userAuth.token;
    const empCode = EmpValueByCode[state.authData.userAuth.comp_code as keyof typeof EmpValueByCode];
    const ValidateButton: boolean = LeaveForm.type.name !== "" && LeaveForm.remark !== "";

    const leaveTypes = state.leaveData.leaveMaster.isSuccess && state.leaveData.leaveMaster.status_code === "200" ?
        state.leaveData.leaveMaster.data.leave_category.map((item, index) => {
            return {
                header: Capitalize(item.value),
                name: Capitalize(item.value),
                code: item.code,
            };
        })
        : [];

    // function getMinDate(date: string, month: string) {
    //     const year = (new Date().getFullYear() - 1).toString();
    // const year = state.leaveData.getActiveYear.data.active_year;
    //     return year + "/" + month + "/" + date;
    // }

    // function getMaxDate(date: string, month: string) {
    //     const year = new Date().getFullYear().toString();
    //     return year + "/" + month + "/" + date;
    // }

    function getMinMaxDate(date: string, month: string) {
        // const year = new Date().getFullYear().toString();
        const year = state.leaveData.getActiveYear?.data?.active_year;
        return year + "/" + month + "/" + date;
    }

    const ResetLeaveForm = () => {
        setLeaveForm({
            type: { header: "", name: "", code: "" },
            day: "FD",
            toDate: new Date(),
            fromDate: new Date(),
            remark: "",
        });
        setIsAction({ ...isAction, isPreview: false, isAddLeave: false });
        dispatch(LeaveAppliedAction.postValidLeaveUpdateAction());
        if (leaveValidData.length > 0) {
            // SuccessToast(Constant.APPLY_LEAVE_RESET)
            setLeaveValidData([]);
        }
    };

    const addOtherLeave = () => {
        setIsAction({ ...isAction, isAddLeave: true });
        setLeaveForm({
            type: { header: "", name: "", code: "" },
            day: "FD",
            toDate: new Date(),
            fromDate: new Date(),
            remark: "",
        });
        dispatch(LeaveAppliedAction.postValidLeaveUpdateAction());
    };

    const removeLeave = (value: number) => {
        setLeaveValidData(leaveValidData.filter((_item, index) => index !== value));
        dispatch(LeaveAppliedAction.postValidLeaveUpdateAction());
        if (leaveValidData.length === 1) {
            ResetLeaveForm();
        } else {
            SuccessToast(Constant.LEAVE_REMOVE);
        }
    };

    const LeaveValidateHandler = () => {
        if (leaveValidData.some((vendor) => vendor.from_date === getDateFormat(LeaveForm.fromDate)
            || vendor.to_date === getDateFormat(LeaveForm.fromDate)
            || (new Date(vendor.from_date) <= LeaveForm.fromDate && LeaveForm.fromDate <= new Date(vendor.to_date))
            || (new Date(vendor.from_date) <= LeaveForm.toDate && LeaveForm.toDate <= new Date(vendor.to_date)))) {
            ErrorToast("Leave Date already selected in Last Leave");
        } else {
            const payload: LeaveAppliedAction.ILeaveValidPayload = {
                emp_id: QueryData.employeeId as string,
                data: [
                    {
                        leave_type_code: LeaveForm.day,
                        approver: state.userInfo.userManagerData.manager_details[0]?.Perno,
                        approver_email: state.userInfo.userManagerData.manager_details[0]?.Imailid,
                        requestor_email: state.userInfo.userManagerData.user_details[0].Imailid,
                        from_date: getDateFormat(LeaveForm.fromDate),
                        // to_date: getDateFormat(LeaveForm.toDate),
                        to_date: LeaveForm.day === "FD" ? LeaveForm.toDate <= LeaveForm.fromDate ? getDateFormat(LeaveForm.fromDate) : getDateFormat(LeaveForm.toDate) : getDateFormat(LeaveForm.fromDate),
                        leave_category_code: LeaveForm.type.code,
                        leave_category_text: LeaveForm.type.name,
                        reason: LeaveForm.remark,
                    },
                ],
                emp_company_code: state.authData.userAuth.comp_code,
                manager_company_code: state.userInfo.userManagerData.manager_details[0]?.CompCode ?? ""
            };
            dispatch(LeaveAppliedAction.postValidLeaveAction(accessToken, empCode, payload));
            setIsAction({ ...isAction, isWaiting: true });
        }
    };

    function onPreviewHandler(value: string) {
        switch (value) {
            case "Next":
                return setIsAction({ ...isAction, isPreview: true });
            case "Back":
                return setIsAction({ ...isAction, isPreview: false });
            default:
                return null;
        }
    }

    const applyLeaveHandler = () => {
        const payload: LeaveAppliedAction.IApplyLeave = {
            emp_id: QueryData.employeeId as string,
            emp_name: state.userInfo.userManagerData.user_details[0].CompName,
            data: leaveValidData,
            pa: state.userInfo.userManagerData.user_details[0].PersArea,
            psa: state.userInfo.userManagerData.user_details[0].PSubarea,
            emp_company_code: state.authData.userAuth.comp_code,
            manager_company_code: state.userInfo.userManagerData.manager_details[0]?.CompCode ?? ""
        };
        dispatch(LeaveAppliedAction.postLeaveAction(accessToken, empCode, payload));
        setAppInsightData(payload);
    };

    // const cancelTaskModule = () => {
    //     //this.props.closeModal && this.props.closeModal(true);
    //     // microsoftTeams.tasks.submitTask("", QueryData.appId);
    // }

    const header = [
        {
            content: <Text content="Leave Type" size="medium" weight="semibold" />,
            key: "type",
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            content: <Text content="From Date" size="medium" weight="semibold" />,
            key: "from",
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            content: <Text content="To Date" size="medium" weight="semibold" />,
            key: "to",
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            content: <Text content="Day Type" size="medium" weight="semibold" />,
            key: "dayType",
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            key: "close",
            "aria-label": "close",
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
    ];

    const rows = leaveValidData.map((item: LeaveAppliedAction.IApplyLeaveDataObj, index) => ({
        key: index,
        items: [
            {
                content: item.leave_category_text,
                key: `${index}-1`,
                styles: {
                    justifyContent: "center",
                },
            },
            {
                content: item.from_date,
                key: `${index}-2`,
                styles: {
                    justifyContent: "center",
                },
            },
            {
                content: item.to_date,
                key: `${index}-3`,
                styles: {
                    justifyContent: "center",
                },
            },
            {
                content: item.leave_type_code,
                key: `${index}-4`,
                styles: {
                    justifyContent: "center",
                },
            },
            {
                content: <CloseIcon onClick={() => removeLeave(index)} />,
                key: `${index}-5`,
                styles: {
                    justifyContent: "center",
                },
            },
        ],
    }));

    React.useEffect(() => {
        if (state.leaveData.vaildLeave.isSuccess && state.leaveData.vaildLeave?.data.status_code === "200" && leaveValidData.length > 0) {
            setLeaveValidData(leaveValidData.filter((_item, index) => index !== leaveValidData.length - 1));
            dispatch(LeaveAppliedAction.postValidLeaveUpdateAction());
        }
    }, [LeaveForm]);

    React.useEffect(() => {
        dispatch(LeaveAppliedAction.postValidLeaveUpdateAction());
    }, []);

    React.useEffect(() => {
        if (state.leaveData.vaildLeave.isSuccess && state.leaveData.vaildLeave?.data.status_code !== "200" && isAction.isWaiting) {
            ErrorToast(state.leaveData.vaildLeave.isSuccess && state.leaveData.vaildLeave?.data.message);
        } else if (state.leaveData.vaildLeave.isSuccess && state.leaveData.vaildLeave?.data.status_code === "200" && isAction.isWaiting) {
            SuccessToast(state.leaveData.vaildLeave?.data.message === "Leave/Regularization request validated successfully. Kindly click on \"Submit\" button to complete submission." ? Constant.LEAVE_VALIDATE_SUCCESS : state.leaveData.vaildLeave?.data.message.replaceAll("Submit", "Preview"));
            const addInQueuePayload = {
                leave_type_code: LeaveForm.day,
                approver: state.userInfo.userManagerData.manager_details[0]?.Perno as string,
                approver_email: state.userInfo.userManagerData.manager_details[0]?.Imailid as string,
                requestor_email: state.userInfo.userManagerData.user_details[0].Imailid as string,
                from_date: getDateFormat(LeaveForm.fromDate),
                to_date: LeaveForm.day === "FD" ? LeaveForm.toDate <= LeaveForm.fromDate ? getDateFormat(LeaveForm.fromDate) : getDateFormat(LeaveForm.toDate) : getDateFormat(LeaveForm.fromDate),
                leave_category_code: LeaveForm.type.code,
                leave_category_text: LeaveForm.type.name,
                reason: LeaveForm.remark,
                total_days: state.leaveData.vaildLeave?.data.total_days as string,
                unique_quota_id: state.leaveData.vaildLeave?.data.unique_quota_id as string,
                is_lwop: state.leaveData.vaildLeave?.data.is_lwop as string,
            };
            setLeaveValidData([...leaveValidData, addInQueuePayload]);
            setIsAction({ ...isAction, isWaiting: false, isAddLeave: false });
        } else if (state.leaveData.applyLeave.isSuccess && state.leaveData.applyLeave?.status_code !== "200" && isAction.isPreview) {
            ErrorToast(state.leaveData.applyLeave.isSuccess && state.leaveData.applyLeave?.message);
            trackEvent(appInsights, TraceEvent.LEAVE_APPLY, appInsights);
        } else if (state.leaveData.applyLeave.isSuccess && state.leaveData.applyLeave?.status_code === "200" && isAction.isPreview) {
            SuccessToast(state.leaveData.applyLeave.isSuccess && state.leaveData.applyLeave?.message);
            ResetLeaveForm();
            trackEvent(appInsights, TraceEvent.LEAVE_APPLY_ERROR, appInsightData);
        }
    }, [state.leaveData.vaildLeave.isSuccess && state.leaveData.vaildLeave?.data.status_code, state.leaveData.applyLeave.isSuccess && state.leaveData.applyLeave?.status_code]);


    return (
        <React.Fragment>
            <ServicePermission service_name={[ServiceName.APPLY_LEAVE]} showReason={true}>
                {state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" && state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200" && state.leaveData.getActiveYear.isSuccess
                    ? (
                        <Flex className="ApplyLeave" column gap="gap.small" >
                            <Box styles={QueryData.isTaskModuleActive ? { position: "relative", height: "calc(100vh - 85px)", overflow: "auto" } : { position: "relative", height: "calc(100vh - 145px)", overflow: "auto" }}>
                                {!isAction.isPreview
                                    ? (
                                        <>
                                            <Flex column gap="gap.small" padding="padding.medium">
                                                <Flex column gap="gap.small" padding="padding.medium" >
                                                    <Text content="Quick Leave Selector" weight="semibold" />
                                                    {leaveTypes.length > 0 && <Flex gap="gap.medium" wrap>
                                                        <Pill rectangular content={<Text content="SL+CL" />} className={LeaveForm.type.name === leaveTypes.find((item: any) => item.code as string === "L2")?.name ? "quick-pill-active" : "quick-pill"} onClick={() => setLeaveForm({ ...LeaveForm, type: leaveTypes.find((item: any) => item.code as string === "L2") as ILeaveForm["type"] })} />
                                                        <Pill rectangular content={<Text content="PL" />} className={LeaveForm.type.name === leaveTypes.find((item: any) => item.code as string === "P0")?.name ? "quick-pill-active" : "quick-pill"} onClick={() => setLeaveForm({ ...LeaveForm, type: leaveTypes.find((item: any) => item.code as string === "P0") as ILeaveForm["type"] })} />
                                                        <Pill rectangular content={<Text content="COD" />} className={LeaveForm.type.name === leaveTypes.find((item: any) => item.code as string === "W0")?.name ? "quick-pill-active" : "quick-pill"} onClick={() => setLeaveForm({ ...LeaveForm, type: leaveTypes.find((item: any) => item.code as string === "W0") as ILeaveForm["type"] })} />
                                                        <Pill rectangular content={<Text content="WFH" />} className={LeaveForm.type.name === leaveTypes.find((item: any) => item.code as string === "WFH")?.name ? "quick-pill-active" : "quick-pill"} onClick={() => setLeaveForm({ ...LeaveForm, type: leaveTypes.find((item: any) => item.code as string === "WFH") as ILeaveForm["type"] })} />
                                                    </Flex>}
                                                </Flex>
                                                <Flex gap="gap.small" padding="padding.medium" fill column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                                    <Flex column gap="gap.small" fill>
                                                        <Text content="Select Leave Type" weight="semibold" />
                                                        <Dropdown
                                                            inverted
                                                            className="inputBody"
                                                            fluid
                                                            clearable={LeaveForm.type.name !== ""}
                                                            items={leaveTypes}
                                                            placeholder="Select Leave Type..."
                                                            noResultsMessage="We couldn't find any matches."
                                                            value={LeaveForm.type.name}
                                                            onChange={(_e: any, val: any) => (val.value === null ? setLeaveForm({ ...LeaveForm, type: { header: "", name: "", code: "" } }) : setLeaveForm({ ...LeaveForm, type: val.value }))}
                                                        />
                                                    </Flex>
                                                    <Flex column gap="gap.small" fill>
                                                        <Text content="Duration" weight="semibold" />
                                                        <RadioGroup
                                                            key={LeaveForm.day}
                                                            defaultCheckedValue={LeaveForm.day}
                                                            items={LeaveMode}
                                                            defaultValue={LeaveForm.day}
                                                            onCheckedValueChange={(_e: any, val: any) => (val.value === null ? setLeaveForm({ ...LeaveForm, day: "" }) : setLeaveForm({ ...LeaveForm, day: val.value }))}
                                                        />
                                                    </Flex>
                                                </Flex>
                                                <Flex gap="gap.small" padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} >
                                                    <FlexItem size={LeaveForm.day !== "FD" ? "100%" : "size.half"}>
                                                        <Flex column fill gap="gap.small">
                                                            <Text content={LeaveForm.day !== "FD" ? "Date" : "From"} weight="semibold" />
                                                            <Datepicker
                                                                key="fromDate"
                                                                as={Flex}
                                                                input={{
                                                                    clearable: LeaveForm.fromDate.getDate() !== new Date().getDate(),
                                                                    fluid: true,
                                                                    className: "inputBody",
                                                                    inverted: true,
                                                                }}
                                                                defaultSelectedDate={LeaveForm.fromDate}
                                                                selectedDate={LeaveForm.fromDate}
                                                                minDate={new Date(getMinMaxDate("01", "01").toString())}
                                                                isRequiredErrorMessage="e"
                                                                maxDate={new Date(getMinMaxDate("31", "12").toString())}
                                                                onDateChange={(_e: any, val: any) => {
                                                                    const formatDate = val.value;
                                                                    val.value === null ? setLeaveForm({ ...LeaveForm, fromDate: new Date() }) : setLeaveForm({ ...LeaveForm, fromDate: formatDate });
                                                                }}
                                                            />
                                                        </Flex>
                                                    </FlexItem>
                                                    {
                                                        LeaveForm.day === "FD"
                                                        && (
                                                            <FlexItem size="size.half">
                                                                <Flex column gap="gap.small">
                                                                    <Text content="To" weight="semibold" />
                                                                    <Datepicker
                                                                        key="toDate"
                                                                        as={Flex}
                                                                        input={{
                                                                            clearable: LeaveForm.toDate.getDate() !== new Date().getDate(),
                                                                            fluid: true,
                                                                            className: "inputBody",
                                                                            inverted: true,
                                                                        }}
                                                                        defaultSelectedDate={LeaveForm.toDate <= LeaveForm.fromDate ? LeaveForm.fromDate : LeaveForm.toDate}
                                                                        selectedDate={LeaveForm.toDate <= LeaveForm.fromDate ? LeaveForm.fromDate : LeaveForm.toDate}
                                                                        minDate={LeaveForm.fromDate}
                                                                        maxDate={new Date(getMinMaxDate("31", "12").toString())}
                                                                        onDateChange={(_e: any, val: any) => {
                                                                            const formatDate = val.value;
                                                                            val.value === null ? setLeaveForm({ ...LeaveForm, toDate: new Date() }) : setLeaveForm({ ...LeaveForm, toDate: formatDate });
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </FlexItem>
                                                        )
                                                    }

                                                </Flex>
                                                <Flex column gap="gap.small" padding="padding.medium" >
                                                    <Text content="Remark*" weight="semibold" />
                                                    <TextArea
                                                        className="inputBody"
                                                        inverted
                                                        placeholder="Enter your remark"
                                                        required
                                                        fluid
                                                        error={LeaveForm.remark.length >= 100}
                                                        maxLength={100}
                                                        // resize="vertical"
                                                        rows={4}
                                                        value={LeaveForm.remark.trimStart()}
                                                        onChange={(e: any) => {
                                                            setLeaveForm({ ...LeaveForm, remark: e.target.value.trimStart() });
                                                        }}
                                                    />
                                                    {
                                                        LeaveForm.remark.length >= 100 && <Text error content="Feedback should not be greater than 100 characters" />
                                                    }
                                                </Flex>
                                            </Flex>
                                            {state.leaveData.vaildLeave.isSuccess && state.leaveData.vaildLeave?.data.status_code === "200"
                                                && (
                                                    <Flex column gap="gap.small">
                                                        <Flex fill padding="padding.medium">
                                                            <Text content={Constant.APPLY_LEAVE_VALIDATE_NOTE} style={{ fontSize: "16px" }} weight="semibold" />
                                                        </Flex>
                                                        <Segment>
                                                            <Flex fill space="between" gap="gap.small">
                                                                <Text content="Total Days:" weight="bold" />
                                                                <Text content={`${state.leaveData.vaildLeave?.data.total_days} days`} weight="bold" />
                                                            </Flex>
                                                        </Segment>

                                                    </Flex>
                                                )}

                                            <Segment>
                                                <Flex space="between" styles={{ padding: "0rem 0.625rem" }}>
                                                    <Text content="Approver" weight="bold" className="approveText" />
                                                    <Text content={state.userInfo.userManagerData.manager_details[0]?.CompName} weight="bold" className="approveText" />
                                                </Flex>
                                            </Segment>

                                        </>
                                    )
                                    : (
                                        <Flex column>
                                            <Table
                                                header={{
                                                    items: header,
                                                    header: true,
                                                    styles: ({ theme: { siteVariables } }) => ({
                                                        background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                                        "&:hover": {
                                                            background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                                                        }
                                                    }),
                                                }}
                                                rows={rows}
                                            />
                                        </Flex>
                                    )}
                            </Box>
                            <Divider />
                            <Flex gap="gap.small" hAlign="end" padding="padding.medium" >
                                {leaveValidData.length > 0 && !isAction.isPreview && !state.leaveData.vaildLeave.isSuccess && (
                                    <Button
                                        content="Back"
                                        onClick={() => {
                                            setIsAction({ ...isAction, isAddLeave: false });
                                            onPreviewHandler("Next");
                                        }}
                                    />
                                )}
                                {leaveValidData.length > 0 && !isAction.isPreview && !isAction.isAddLeave &&
                                    <ServicePermission service_name={[ServiceName.ADD_ANOTHER_LEAVE]} showReason={false}>
                                        <Button content="Add Other Leave" onClick={() => addOtherLeave()} />
                                    </ServicePermission>
                                }

                                <Button
                                    disabled={leaveValidData.length === 0}
                                    content={isAction.isPreview ? "Back" : "Reset"}
                                    onClick={() => { isAction.isPreview ? onPreviewHandler("Back") : ResetLeaveForm(); }}
                                />
                                <Button
                                    loading={state.leaveData.vaildLeave.isLoading || state.leaveData.applyLeave.isLoading}
                                    content={state.leaveData.vaildLeave.isSuccess
                                        && state.leaveData.vaildLeave?.data.status_code === "200"
                                        && !isAction.isPreview ? "Preview" : leaveValidData.length > 0 && isAction.isPreview ? "Apply" : "Validate"}
                                    primary
                                    onClick={() => {
                                        state.leaveData.vaildLeave.isSuccess
                                            && state.leaveData.vaildLeave?.data.status_code === "200"
                                            && !isAction.isPreview ? onPreviewHandler("Next")
                                            : leaveValidData.length > 0
                                                && isAction.isPreview ? applyLeaveHandler()
                                                : LeaveValidateHandler();
                                    }}
                                    disabled={state.leaveData.vaildLeave.isSuccess
                                        && state.leaveData.vaildLeave?.data.status_code === "200"
                                        && !isAction.isPreview ? false : leaveValidData.length > 0 && isAction.isPreview ? false : !ValidateButton}
                                />
                            </Flex>
                        </Flex>
                    )
                    :
                    <Skeleton animation='pulse' styles={QueryData.isTaskModuleActive ? { position: "relative", height: "calc(100vh - 85px)", overflow: "auto" } : { position: "relative", height: "calc(100vh - 145px)", overflow: "auto" }} >
                        <Flex column gap="gap.small" padding="padding.medium">
                            <Flex column gap="gap.small" padding="padding.medium" >
                                <Skeleton.Text style={{ width: "15%" }} />
                                <Flex gap="gap.medium" wrap>
                                    <Skeleton.Button />
                                    <Skeleton.Button />
                                    <Skeleton.Button />
                                    <Skeleton.Button />
                                </Flex>
                            </Flex>
                            <Flex gap="gap.small" padding="padding.medium" fill column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                <Flex column gap="gap.small" fill>
                                    <Skeleton.Text style={{ width: "30%" }} />
                                    <Skeleton.Input fluid />
                                </Flex>
                                <Flex column gap="gap.small" fill>
                                    <Skeleton.Text style={{ width: "30%" }} />
                                    <Skeleton.Input fluid />
                                </Flex>
                            </Flex>
                            <Flex gap="gap.small" padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} >
                                <FlexItem size={LeaveForm.day !== "FD" ? "100%" : "size.half"}>
                                    <Flex column fill gap="gap.small">
                                        <Skeleton.Text style={{ width: "30%" }} />
                                        <Skeleton.Input fluid />
                                    </Flex>
                                </FlexItem>
                                <FlexItem size="size.half">
                                    <Flex column gap="gap.small">
                                        <Skeleton.Text style={{ width: "30%" }} />
                                        <Skeleton.Input fluid />
                                    </Flex>
                                </FlexItem>
                            </Flex>
                            <Flex column gap="gap.small" padding="padding.medium" >
                                <Skeleton.Text style={{ width: "15%" }} />
                                <Skeleton.Input fluid styles={{ height: "6rem" }} />
                            </Flex>
                        </Flex>
                        <Flex gap='gap.medium' padding='padding.medium' vAlign="center" hAlign='end'>
                            <Skeleton.Button />
                            <Skeleton.Button />
                        </Flex>
                    </Skeleton>
                }
            </ServicePermission>
        </React.Fragment>
    );
};

export default ApplyLeave;
