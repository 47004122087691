import {
    Actions,
    IGetNotification,
    IPostNotificationData
} from "../actions/NotificationAction";
import { combineReducers } from "redux";

export const getNotificationReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_NOTIFICATION_LOADING:
    case Actions.GET_NOTIFICATION_ERROR:
    case Actions.GET_NOTIFICATION_SUCCESS:
        return {
            ...state,
            ...action.payload
        };
    default:
        return state;
    }
};

export const itsmNotificationReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_NOTIFICATION_LOADING:
        case Actions.POST_NOTIFICATION_ERROR:
        case Actions.POST_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};


export interface INotificationData {
    getNotificationData: IGetNotification,
    itsmNotificationData: IPostNotificationData
}

const notificationData = combineReducers({
    getNotificationData: getNotificationReducer,
    itsmNotificationData: itsmNotificationReducer
});

export default notificationData;