import { Box } from "@fluentui/react-northstar";
import React from "react";
import Lottie from "lottie-react";
import * as animationData from "../../assets/loader.json";

const LazyFallBack = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }} >
            <Lottie
                animationData={animationData}
                height={250}
                width={250}
                style={{ height: "250px", width: "250px" }}
                loop={true}
                autoplay={true}
                rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            />
        </Box>

    );
};

export default LazyFallBack;