import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    //-----------------------Get Notification-----------------------------
    GET_NOTIFICATION_DATA: "GET_NOTIFICATION_DATA",
    GET_NOTIFICATION_LOADING: "GET_NOTIFICATION_LOADING",
    GET_NOTIFICATION_ERROR: "GET_NOTIFICATION_ERROR",
    GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",

    // -------------------- NOTIFICATION TRIGGER --------------------
    POST_NOTIFICATION_DATA: "POST_NOTIFICATION_DATA",
    POST_NOTIFICATION_LOADING: "POST_NOTIFICATION_LOADING",
    POST_NOTIFICATION_ERROR: "POST_NOTIFICATION_ERROR",
    POST_NOTIFICATION_SUCCESS: "POST_NOTIFICATION_SUCCESS",

};

export interface IGetNotificationData {
    notification_id: string;
    notification_owner: string;
    notification_from: string
    notification_type: string;
    notification_description: string;
    notification_details_id: string;
    is_read: boolean;
    created_datetime: string;
    created_by: string;
}

export interface IGetNotification extends SideEffectSchema {
    statusCode: string;
    message: string;
    data: IGetNotificationData[];
}

export interface IGetNotificationPayload {
    emp_id: string;
    platform: string
}

export const getNotificationDataAction = (
    access_token: string,
    emp_comp: string,
    notificationData: IGetNotificationPayload
) => {
    return {
        type: Actions.GET_NOTIFICATION_DATA,
        payload: { access_token, emp_comp, notificationData }
    };
};
export const getNotificationDataLoadingAction = () =>
    loadingAction(Actions.GET_NOTIFICATION_LOADING);
export const getNotificationDataErrorAction = (error: string) =>
    errorAction(Actions.GET_NOTIFICATION_ERROR, error);
export const getNotificationSuccessAction = (data: any) => {
    const payload: IGetNotificationData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_NOTIFICATION_SUCCESS,
        payload: payload
    };
};

export interface INotificationPayload {
    ticketType?: string;
    type: string;
    userObjId: string;
    empId: string;
    cardDetails: {
        ticketId: string;
        shortDescription?: string;
        note?: string;
        status?: string;
        attachment?: string[];
        reqName?: string;
    }
}

export interface IPostNotificationData extends SideEffectSchema {
    statusCode: string;
    message: string;
}

export const postNotificationDataAction = (
    payload: INotificationPayload
) => {
    return {
        type: Actions.POST_NOTIFICATION_DATA,
        payload: { payload }
    };
};
export const postNotificationLoadingAction = () =>
    loadingAction(Actions.POST_NOTIFICATION_LOADING);
export const postNotificationErrorAction = (error: string) =>
    errorAction(Actions.POST_NOTIFICATION_ERROR, error);
export const postNotificationSuccessAction = (data: any) => {
    const payload: IPostNotificationData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.POST_NOTIFICATION_SUCCESS,
        payload: payload
    };
};