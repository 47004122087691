
import * as React from "react";
import * as ReactDropzone from "react-dropzone";
import { Attachment, CloseIcon, Grid } from "@fluentui/react-northstar";
import "./attachment.scss";
import { ErrorToast } from "../ToastifyComponent";

interface IAttachmentProps {
  handleFiles: any;
  maxFiles: number;
  refresh: number;
}


// pdf, jpeg, jpg, png, doc, xlsx, xls, docx
const Attachments: React.FC<IAttachmentProps> = (props) => {
  const [files, setFiles] = React.useState([]);
  const [tempFile, setTempFile] = React.useState<any>([]);
  const extArray = ["pdf", "jpeg", "jpg", "png", "doc", "xlsx", "xls", "docx", "txt"];


  const text = `Drop files here, or click to select files. Max attachment at a time ${props.maxFiles} with max size per file 5MB and pdf, jpeg, jpg, png, doc, xlsx, xls, docx, txt extensions.`;

  React.useEffect(() => {
    if (props.refresh) {
      setFiles([]);
    }
  }, [props.refresh]);

  const { getRootProps, getInputProps } = ReactDropzone.useDropzone({
    onDrop: (acceptedFiles: any) => {
      const reader = new FileReader();

      for (let i = 0; i < acceptedFiles.length; i++) {
        let skip = 0;
        for (let j = 0; j < tempFile.length; j++) {
          if (tempFile[j].path === acceptedFiles[i].path) {
            alert(`File with "${acceptedFiles[j].path}" name already exists!`);
            skip = 1;
            break;
          }
        }
        if (skip === 0) {
          if (extArray.includes(acceptedFiles[i].name.split(".").pop().toLowerCase())) {
            if (acceptedFiles[i].size / Math.pow(1024, 2) < 5) {
              if (tempFile.length < props.maxFiles) {
                tempFile.push(acceptedFiles[i]);
              } else {
                ErrorToast(`Only ${props.maxFiles} attachment/s are acceptable.`);
                break;
              }
            } else {
              ErrorToast("File size is too big. It should be equals or less than 5MB");
            }
          }
          else {
            ErrorToast("Files with only pdf, jpeg, jpg, png, doc, xlsx, xls, docx, txt extensions are allowed.");
          }
        }
      }
      props.handleFiles(tempFile);
      setFiles(
        tempFile.map((file: any) => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      );
    }
  });

  const handleClick = (reomoveIndex: any) => {
    const deletedFiles = tempFile.filter((value: any, index: number) => {
      return index != reomoveIndex;
    });
    props.handleFiles(deletedFiles);
    setTempFile(deletedFiles);
    setFiles(deletedFiles);
  };

  const images = files.map((file: any, index) => {
    return (
      <Attachment
        key={file.name}
        header={file.name}
        actionable
        action={{
          icon: <CloseIcon />,
          onClick: () => handleClick(index),
          title: "Close",
        }}
      />
    );
  });
  return (
    <div>
      <Grid columns="repeat(3,1fr)" styles={{ gridGap: "5px" }}>
        {images}
      </Grid>
      <div style={{ paddingTop: "5px", textAlign: "center" }}>
        {props.maxFiles > files.length &&
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {/* <p className="drop_zone">{text}</p> */}
            <div className="drop_zone">
              <p>Drop files here, or click to select files</p>
              <em>({props.maxFiles} files are the maximum number of files you can drop here)</em>
              <em>(Max size of file should be 5MB.)</em>
              <em>(Only *.pdf, *.jpeg, *.jpg, *.png, *.doc, *.xlsx, *.xls, *.docx, *.txt files will be accepted)</em>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Attachments;