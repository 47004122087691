import React from "react";
import { Box, Button, Checkbox, Divider, Dropdown, Flex, gridCellWithFocusableElementBehavior, Input, InputBehaviorProps, InputProps, Loader, RadioGroup, Segment, Table, Text } from "@fluentui/react-northstar";
import * as SapIdAction from "../../redux/actions/SapIdAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootSchema } from "../../redux/reducer";
import { ICheckedDataProps, ISAPRoleData } from "../SAPReqIdCreation";
import { Constant } from "../../constant";
import DataNotFound from "../DNF";

interface ISapRoleSelection {
    checkedData: ICheckedDataProps[],
    setCheckedData: any;
    roleData: ISAPRoleData;
    setRoleData: React.Dispatch<React.SetStateAction<ISAPRoleData>>;
    setDynamicBtnText: React.Dispatch<React.SetStateAction<string>>;

}

export interface IRaiseRoleSelectionForm {
    systemtype: { id: number; header: string; value: string; };
    function: { id: number; header: string; value: string; };
    subfunction: { id: number; header: string; value: string; };
    department: { id: number; header: string; value: string; };
    subdepartment: { id: number; header: string; value: string; };
    roleowner: { id: number; header: string; value: string; };
    role: { id: number; header: string; value: string; };
    connector: { id: number; header: string; value: string; };
    refId: string;
    t_code: string;
    roleOwnerName: string;
}

interface IDropdownValues {
    systemtype: { id: number; header: string; value: string; }[];
    function: { id: number; header: string; value: string; }[];
    subfunction: { id: number; header: string; value: number; }[];
    department: { id: number; header: string; value: string; }[];
    subdepartment: { id: number; header: string; value: string; }[];
    roleowner: { id: number; header: string; value: string; }[];
    role: { id: number; header: string; value: string; }[];
    connector: { id: number; header: string; value: string; }[];
}

const SAPRoleSelection: React.FC<ISapRoleSelection> = ({ checkedData, setCheckedData, roleData, setRoleData, setDynamicBtnText }) => {
    const [dropdownValues, setDropdownValues] = React.useState<IDropdownValues>({
        systemtype: [],
        function: [],
        subfunction: [],
        department: [],
        subdepartment: [],
        roleowner: [],
        role: [],
        connector: []
    });
    const [roleSelectionFormData, setRoleSelectionFormData] = React.useState<IRaiseRoleSelectionForm>({
        systemtype: { id: 0, header: "", value: "" },
        function: { id: 0, header: "", value: "" },
        subfunction: { id: 0, header: "", value: "" },
        department: { id: 0, header: "", value: "" },
        subdepartment: { id: 0, header: "", value: "" },
        roleowner: { id: 0, header: "", value: "" },
        role: { id: 0, header: "", value: "" },
        connector: { id: 0, header: "", value: "" },
        refId: "",
        t_code: "",
        roleOwnerName: ""
    });
    const [idType, setIdType] = React.useState<string>("1");
    const [refId, setRefId] = React.useState<string>("");
    // const [selectedRole, setSelectedRole] = React.useState<string[]>([]);

    const state = useSelector((state: RootSchema) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchValidate = roleSelectionFormData.systemtype.value !== "" && (idType === "1" ? true :
        idType === "2" ? roleSelectionFormData.refId !== "" :
            idType === "3" ? roleSelectionFormData.connector.value !== "" && roleSelectionFormData.t_code !== "" : roleSelectionFormData.roleOwnerName !== "");

    const rowsData = idType === "1" ? state.sapIdData.getFindingOrgValuesRole.data as SapIdAction.IGetFindingOrgValuesRoleItem[] :
        idType === "2" ? state.sapIdData.getUserRolesAssign.data as SapIdAction.IGetUserRolesAssignItem[] :
            idType === "3" ? state.sapIdData.getFindingTCodesRole.data as SapIdAction.IGetFindingTCodesRoleItem[] :
                state.sapIdData.getListRoleAssignedToOwner.data as SapIdAction.IGetListRoleAssignedToOwnerItem[];

    const onCheckedHandler = (item: any, index: number) => {
        roleData.role.some((obj: any) => obj.ROLE_NAME === item.ROLE_NAME) ? setRoleData({ ...roleData, role: roleData.role.filter((obj: any) => obj.ROLE_NAME !== item.ROLE_NAME) }) : setRoleData({ ...roleData, role: [...roleData.role, item] });

        const value = {
            id: item.ROLE_NAME + index,
        };
        checkedData.some((sub: ICheckedDataProps) => sub["id"] === item.ROLE_NAME + index)
            ? setCheckedData(
                checkedData.filter(
                    (dept: ICheckedDataProps) => dept.id !== value.id
                )
            )
            : setCheckedData([...checkedData, value]);

    };

    const onCheckSelectAllHandler = () => {
        const checkAllList = rowsData.map((item, index) => {
            return ({
                id: item.ROLE_NAME + index
            });
        });
        if (checkedData.length === rowsData.length) {
            setCheckedData([]);
        } else {
            setCheckedData(checkAllList);
        }
        // const roleData =
        //     idType === "1" ? state.sapIdData.getFindingOrgValuesRole.data : idType === "2" ? state.sapIdData.getUserRolesAssign.data : idType === "3" ? state.sapIdData.getFindingTCodesRole.data : state.sapIdData.getListRoleAssignedToOwner.data;
        setRoleData({ ...roleData, role: idType === "1" ? state.sapIdData.getFindingOrgValuesRole.data : idType === "2" ? state.sapIdData.getUserRolesAssign.data : idType === "3" ? state.sapIdData.getFindingTCodesRole.data : state.sapIdData.getListRoleAssignedToOwner.data });
    };

    const rolesHeader = [
        {
            key: "checkboax",
            content: <Checkbox
                checked={checkedData.length === rowsData?.length}
                onChange={() => onCheckSelectAllHandler()} />,
            accessibility: gridCellWithFocusableElementBehavior,
            onClick: (e: any) => e.stopPropagation(),
            styles: { maxWidth: "50px" }
        },
        {
            key: "role",
            content: <Text content="Role" weight="bold" size="medium" />,
        },
        {
            key: "role-discription",
            content: <Text content="Role Description" weight="bold" size="medium" />,
        },
        {
            key: "owner",
            content: <Text content={idType === "3" ? "T-Code" : "Role Owner"} weight="bold" size="medium" />,
        },
        {
            key: "connector",
            content: <Text content="Connector" weight="bold" size="medium" />,
        },
    ];

    const rolesDataByManualRole = state.sapIdData.getFindingOrgValuesRole.isSuccess && Array.isArray(state.sapIdData.getFindingOrgValuesRole.data) ?
        state.sapIdData.getFindingOrgValuesRole.data.map((item: SapIdAction.IGetFindingOrgValuesRoleItem, index) => (
            {
                key: index,
                items: [{
                    key: index + "-1",
                    content: <Checkbox checked={checkedData.some((sub: ICheckedDataProps) => sub["id"] === item.ROLE_NAME + index)} onChange={() => onCheckedHandler(item, index)} />,
                    accessibility: gridCellWithFocusableElementBehavior,
                    onClick: (e: any) => e.stopPropagation(),
                    styles: { maxWidth: "50px" }
                },
                {
                    key: index + "-2",
                    content: item.ROLE_NAME,
                },
                {
                    key: index + "-3",
                    content: item.ROLE_DESC,
                    truncateContent: true
                },
                {
                    key: index + "-4",
                    content: item.OWNER_NAME,
                },
                {
                    key: index + "-5",
                    content: item.CONNECTOR,
                },]
            }
        )) : [];

    const rolesDataByExistingId = state.sapIdData.getUserRolesAssign.isSuccess && Array.isArray(state.sapIdData.getUserRolesAssign.data) ?
        state.sapIdData.getUserRolesAssign.data.map((item: SapIdAction.IGetUserRolesAssignItem, index) => (
            {
                key: index,
                items: [{
                    key: index + "-1",
                    content: <Checkbox checked={checkedData.some((sub: ICheckedDataProps) => sub["id"] === item.ROLE_NAME + index)} onChange={() => onCheckedHandler(item, index)} />,
                    accessibility: gridCellWithFocusableElementBehavior,
                    onClick: (e: any) => e.stopPropagation(),
                    styles: { maxWidth: "50px" }
                },
                {
                    key: index + "-2",
                    content: item.ROLE_NAME,
                },
                {
                    key: index + "-3",
                    content: item.ROLE_DESC,
                    truncateContent: true
                },
                {
                    key: index + "-4",
                    content: item.ROLE_OWNER_NAME,
                }]
            }

        )) : [];

    const rolesDataByTCodes = state.sapIdData.getFindingTCodesRole.isSuccess && Array.isArray(state.sapIdData.getFindingTCodesRole.data) ?
        state.sapIdData.getFindingTCodesRole.data.map((item: SapIdAction.IGetFindingTCodesRoleItem, index) => (
            {
                key: index,
                items: [{
                    key: index + "-1",
                    content: <Checkbox checked={checkedData.some((sub: ICheckedDataProps) => sub["id"] === item.ROLE_NAME + index)} onChange={() => onCheckedHandler(item, index)} />,
                    accessibility: gridCellWithFocusableElementBehavior,
                    onClick: (e: any) => e.stopPropagation(),
                    styles: { maxWidth: "50px" }
                },
                {
                    key: index + "-2",
                    content: item.ROLE_NAME,
                },
                {
                    key: index + "-3",
                    content: item.ROLE_DESC,
                    truncateContent: true
                },
                {
                    key: index + "-4",
                    content: item.TCODE,
                },
                {
                    key: index + "-5",
                    content: item.CONNECTOR,
                }]
            }

        )) : [];

    const rolesDataByRoleOwner = state.sapIdData.getListRoleAssignedToOwner.isSuccess && Array.isArray(state.sapIdData.getListRoleAssignedToOwner.data) ?
        state.sapIdData.getListRoleAssignedToOwner.data.map((item: SapIdAction.IGetListRoleAssignedToOwnerItem, index) => (
            {
                key: index,
                items: [{
                    key: index + "-1",
                    content: <Checkbox checked={checkedData.some((sub: ICheckedDataProps) => sub["id"] === item.ROLE_NAME + index)} onChange={() => onCheckedHandler(item, index)} />,
                    accessibility: gridCellWithFocusableElementBehavior,
                    onClick: (e: any) => e.stopPropagation(),
                    styles: { maxWidth: "50px" }
                },
                {
                    key: index + "-2",
                    content: item.ROLE_NAME,
                },
                {
                    key: index + "-3",
                    content: item.ROLE_DESC,
                    truncateContent: true
                },
                {
                    key: index + "-4",
                    content: item.OWNER_NAME,
                },
                {
                    key: index + "-5",
                    content: item.CONNECTOR,
                }]
            }

        )) : [];


    const handleRadioBtnValue = (value: string) => {
        setIdType(value);
        setCheckedData([]);
        setRoleData({ ...roleData, role: [], sap_id_type: { id: Number(value), value: "" } });
    };

    const onGetRolesSearchByManualRole = () => {
        const payload: SapIdAction.IGetFindingOrgValuesRolepayload = {
            sap_client: Constant.SAP_CLIENT,
            Function: "STV" || roleSelectionFormData.function.value,
            Subdepartment: roleSelectionFormData.subfunction.value,
            Department: roleSelectionFormData.department.value,
            Subfunction: roleSelectionFormData.subfunction.value,
            RoleName: roleSelectionFormData.role.value,
            RoleOwner: roleSelectionFormData.roleowner.value
        };
        dispatch(SapIdAction.getFindingOrgValuesRoleAction(payload));
    };


    const onGetRolesSearchByExistingId = () => {
        const payload: SapIdAction.IGetUserRolesAssignpayload = {
            sap_client: Constant.SAP_CLIENT,
            User_ID: roleSelectionFormData.refId,
            // CLIENTID: "",
            System_ID: roleSelectionFormData.systemtype.value
        };
        dispatch(SapIdAction.getUserRolesAssignAction(payload));
    };

    const onGetRolesSearchByTCodes = () => {
        const payload: SapIdAction.IGetFindingTCodesRolepayload = {
            sap_client: Constant.SAP_CLIENT,
            TCODE: roleSelectionFormData.t_code,
            CONNECTOR: roleSelectionFormData.connector.value
        };
        dispatch(SapIdAction.getFindingTCodesRoleAction(payload));
    };

    const onGetRolesSearchByRoleOwner = () => {
        const payload: SapIdAction.IGetListRoleAssignedToOwnerpayload = {
            sap_client: Constant.SAP_CLIENT,
            Roleowner: roleSelectionFormData.roleOwnerName
        };
        dispatch(SapIdAction.getListRoleAssignedToOwnerAction(payload));
    };

    // React.useEffect(() => {
    //     console.log("object");
    //     setRoleData({ ...roleData, role: [], sap_id_type: { id: Number(idType), value: "" } });
    // }, [idType]);

    React.useEffect(() => {
        setRoleData({ ...roleData, system_type_connector: roleSelectionFormData.systemtype.value, });
    }, [roleSelectionFormData.systemtype, idType]);
    // React.useEffect(() => {
    //     if (state.sapIdData.getSystemType.isSuccess) {
    //         const form_values = state.sapIdData.getSystemType.data.map(
    //             (item, index) => { return { id: index, header: item.name, value: item.name }; }
    //         );
    //         setDropdownValues(perv => ({ ...perv, systemtype: form_values }));
    //     }
    // }, [state.sapIdData.getSystemType.isSuccess]);

    React.useEffect(() => {
        if (state.sapIdData.getFunction.isSuccess && Array.isArray(state.sapIdData.getFunction.data)) {
            const form_values = state.sapIdData.getFunction.data.map(
                (item, index) => { return { id: index, header: item.name, value: item.id.toString() }; }
            );
            setDropdownValues(perv => ({ ...perv, function: form_values }));
        }
        const form_values = [{ id: 1, header: "ABCDE", value: "ABCDE" }];
        setDropdownValues(perv => ({ ...perv, function: form_values }));
    }, [state.sapIdData.getFunction.isSuccess]);

    React.useEffect(() => {
        if (state.sapIdData.getSubFunction.isSuccess && Array.isArray(state.sapIdData.getSubFunction.data)) {
            const form_values = state.sapIdData.getSubFunction.data.map(
                (item, index) => { return { id: index, header: item.name, value: item.id }; }
            );
            setDropdownValues(perv => ({ ...perv, subfunction: form_values }));
        }
        const form_values = [{ id: 1, header: "12345", value: 12345 }];
        setDropdownValues(perv => ({ ...perv, subfunction: form_values }));
    }, [state.sapIdData.getSubFunction.isSuccess]);

    React.useEffect(() => {
        if (state.sapIdData.getDepartment.isSuccess && Array.isArray(state.sapIdData.getSubFunction.data)) {
            const form_values = state.sapIdData.getDepartment.data.map(
                (item, index) => { return { id: index, header: item.name, value: item.id.toString() }; }
            );
            setDropdownValues(perv => ({ ...perv, department: form_values }));
        }
        const form_values = [{ id: 1, header: "QWC", value: "qwc" }];
        setDropdownValues(perv => ({ ...perv, department: form_values }));
    }, [state.sapIdData.getDepartment.isSuccess]);

    React.useEffect(() => {
        if (state.sapIdData.getSubDepartment.isSuccess && Array.isArray(state.sapIdData.getSubFunction.data)) {
            const form_values = state.sapIdData.getSubDepartment.data.map(
                (item, index) => { return { id: index, header: item.name, value: item.id.toString() }; }
            );
            setDropdownValues(perv => ({ ...perv, subdepartment: form_values }));
        }
        const form_values = [{ id: 1, header: "M00", value: "M00" }];
        setDropdownValues(perv => ({ ...perv, subdepartment: form_values }));
    }, [state.sapIdData.getSubDepartment.isSuccess]);

    React.useEffect(() => {
        if (state.sapIdData.getRoleOwner.isSuccess) {
            const form_values = state.sapIdData.getRoleOwner.data.map(
                (item, index) => { return { id: index, header: item.name, value: item.name }; }
            );
            setDropdownValues(perv => ({ ...perv, roleowner: form_values }));
        }
    }, [state.sapIdData.getRoleOwner.isSuccess]);

    React.useEffect(() => {
        if (state.sapIdData.getRole.isSuccess) {
            const form_values = state.sapIdData.getRole.data.map(
                (item, index) => { return { id: index, header: item.name, value: item.name }; }
            );
            setDropdownValues(perv => ({ ...perv, role: form_values }));
        }
    }, [state.sapIdData.getRole.isSuccess]);

    React.useEffect(() => {
        if (state.sapIdData.getSystemDescription.isSuccess) {
            const form_values = state.sapIdData.getSystemDescription.data.map(
                (item, index) => { return { id: index, header: item.RFCDOC1, value: item.CONNECTOR }; }
            );
            const sys_id = state.sapIdData.getSystemDescription.data.map(
                (item, index) => { return { id: index, header: item.RFCDOC1, value: item.CONNECTOR }; }
            );
            setDropdownValues(perv => ({ ...perv, connector: form_values, systemtype: sys_id }));
        }
    }, [state.sapIdData.getSystemDescription.isSuccess]);

    React.useEffect(() => {
        if (roleSelectionFormData.roleOwnerName === "") {
            dispatch(SapIdAction.getListRoleAssignedToOwnerClearAction());
        }
        if (roleSelectionFormData.t_code === "" || roleSelectionFormData.connector.value === "") {
            dispatch(SapIdAction.getFindingTCodesRoleClearAction());
        }
        if (roleSelectionFormData.refId === "") {
            dispatch(SapIdAction.getUserRolesAssignClearAction());
        }
        if (roleSelectionFormData.function.value === "" || roleSelectionFormData.subfunction.value === "" || roleSelectionFormData.department.value === "" || roleSelectionFormData.subdepartment.value === "") {
            dispatch(SapIdAction.getFindingOrgValuesRoleClearAction());
        }
    }, [roleSelectionFormData]);

    React.useEffect(() => {
        setDynamicBtnText("SOD Conflict");
    }, [idType, roleSelectionFormData]);

    return (
        <React.Fragment>
            <Flex gap="gap.medium" vAlign="center">
                <Text weight="semibold" >System Type {<Text content="*" color="red" />}</Text>
                <Dropdown
                    className="inputBody"
                    inverted
                    clearable={roleSelectionFormData.systemtype.header === "" ? false : true}
                    value={roleSelectionFormData.systemtype.header}
                    placeholder="Select System"
                    noResultsMessage="We couldn't find any matches."
                    loading={state.sapIdData.getSystemDescription.isLoading}
                    loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                    onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, systemtype: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, systemtype: v.value })}
                    items={dropdownValues.systemtype}
                    // onOpenChange={(e, v) => {
                    //     const checkOpen = v.open !== undefined ? v.open : true;
                    //     checkOpen && !state.sapIdData.getSystemType.isSuccess && dispatch(SapIdAction.getSystemTypeAction({ sap_client: Constant.SAP_CLIENT}));
                    // }}

                    onOpenChange={(e, v) => {
                        const checkOpen = v.open !== undefined ? v.open : true;
                        checkOpen && !state.sapIdData.getSystemDescription.isSuccess && dispatch(SapIdAction.getSystemDescriptionAction({ sap_client: Constant.SAP_CLIENT }));
                    }}
                />
            </Flex>
            <Flex gap="gap.medium" vAlign="center">
                <Text weight="semibold" >SAP ID Creation Type {<Text content="*" color="red" />}</Text>
                <RadioGroup items={[
                    {
                        key: "1",
                        label: "Manual Role Search",
                        value: "1",
                    },
                    {
                        key: "2",
                        label: "By Existing SAP ID",
                        value: "2",
                    },
                    {
                        key: "3",
                        label: "By T-Code",
                        value: "3",
                    },
                    {
                        key: "4",
                        label: "By Role Owner",
                        value: "4",
                    }
                ]} defaultCheckedValue="1" checkedValue={idType} onCheckedValueChange={(e: React.SyntheticEvent, v: any) => handleRadioBtnValue(v.value)} />
            </Flex>
            <Divider />
            {idType === "1" ?
                <Flex column gap="gap.medium">
                    {/* <Flex gap="gap.small">
                        <Flex fill column gap="gap.smaller">
                            <Text weight="semibold" >Role Owner {<Text content="*" color="red" />}</Text>
                            <Dropdown
                                fluid
                                className="inputBody"
                                inverted
                                clearable={roleSelectionFormData.roleowner.value === "" ? false : true}
                                value={roleSelectionFormData.roleowner.value}
                                placeholder="Select Role Owner"
                                noResultsMessage="We couldn't find any matches."
                                loading={state.sapIdData.getRoleOwner.isLoading}
                                loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                                onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, roleowner: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, roleowner: v.value })}
                                items={dropdownValues.roleowner}
                                onOpenChange={(e, v) => {
                                    const checkOpen = v.open !== undefined ? v.open : true;
                                    checkOpen && !state.sapIdData.getRoleOwner.isSuccess && dispatch(SapIdAction.getRoleOwnerAction({ sap_client: Constant.SAP_CLIENT }));
                                }}
                            />
                        </Flex>
                        <Flex fill column gap="gap.smaller">
                            <Text weight="semibold" >Role {<Text content="*" color="red" />}</Text>
                            <Dropdown
                                fluid
                                className="inputBody"
                                inverted
                                clearable={roleSelectionFormData.role.value === "" ? false : true}
                                value={roleSelectionFormData.role.value}
                                placeholder="Select Role"
                                noResultsMessage="We couldn't find any matches."
                                loading={state.sapIdData.getRole.isLoading}
                                loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                                onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, role: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, role: v.value })}
                                items={dropdownValues.role}
                                onOpenChange={(e, v) => {
                                    const checkOpen = v.open !== undefined ? v.open : true;
                                    checkOpen && !state.sapIdData.getRole.isSuccess && dispatch(SapIdAction.getRoleAction({ sap_client: Constant.SAP_CLIENT }));
                                }}
                            />
                        </Flex>
                    </Flex> */}
                    <Flex gap="gap.small">
                        <Flex fill column gap="gap.smaller">
                            <Text weight="semibold" >Function {<Text content="*" color="red" />}</Text>
                            <Dropdown
                                fluid
                                className="inputBody"
                                inverted
                                clearable={roleSelectionFormData.function.value !== ""}
                                value={roleSelectionFormData.function.header}
                                placeholder="Select Function"
                                noResultsMessage="We couldn't find any matches."
                                loading={state.sapIdData.getFunction.isLoading}
                                loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                                onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, function: { id: 0, header: "", value: "" }, subfunction: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, function: v.value, subfunction: { id: 0, header: "", value: "" } })}
                                items={dropdownValues.function}
                                onOpenChange={(e, v) => {
                                    const checkOpen = v.open !== undefined ? v.open : true;
                                    checkOpen && !state.sapIdData.getFunction.isSuccess && dispatch(SapIdAction.getFunctionAction({ sap_client: Constant.SAP_CLIENT }));
                                }}
                            />
                        </Flex>
                        <Flex fill column gap="gap.smaller">
                            <Text content="Sub Function" weight="semibold" />
                            <Dropdown
                                fluid
                                className="inputBody"
                                inverted
                                clearable={roleSelectionFormData.subfunction.value !== ""}
                                disabled={roleSelectionFormData.function.value === ""}
                                value={roleSelectionFormData.subfunction.header}
                                placeholder="Select Sub Function"
                                noResultsMessage="We couldn't find any matches."
                                loading={state.sapIdData.getSubFunction.isLoading}
                                loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                                onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, subfunction: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, subfunction: v.value })}
                                items={dropdownValues.subfunction}
                                onOpenChange={(e, v) => {
                                    const checkOpen = v.open !== undefined ? v.open : true;
                                    checkOpen && dispatch(SapIdAction.getSubFunctionAction({ sap_client: Constant.SAP_CLIENT, functionId: roleSelectionFormData.function.value }));
                                }}
                            />
                        </Flex>
                    </Flex>
                    <Flex gap="gap.small">
                        <Flex fill column gap="gap.smaller">
                            <Text content="Department" weight="semibold" />
                            <Dropdown
                                fluid
                                inverted
                                className="inputBody"
                                clearable={roleSelectionFormData.department.value !== ""}
                                value={roleSelectionFormData.department.header}
                                placeholder="Select Department"
                                noResultsMessage="We couldn't find any matches."
                                loading={state.sapIdData.getDepartment.isLoading}
                                loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                                onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, department: { id: 0, header: "", value: "" }, subdepartment: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, department: v.value, subdepartment: { id: 0, header: "", value: "" } })}
                                items={dropdownValues.department}
                                onOpenChange={(e, v) => {
                                    const checkOpen = v.open !== undefined ? v.open : true;
                                    checkOpen && !state.sapIdData.getDepartment.isSuccess && dispatch(SapIdAction.getDepartmentAction({ sap_client: Constant.SAP_CLIENT, subFunctionId: roleSelectionFormData.subdepartment.value }));
                                }}
                            />
                        </Flex>
                        <Flex fill column gap="gap.smaller">
                            <Text content="Sub-Department" weight="semibold" />
                            <Dropdown
                                fluid
                                className="inputBody"
                                inverted
                                clearable={roleSelectionFormData.subdepartment.value !== ""}
                                value={roleSelectionFormData.subdepartment.header}
                                disabled={roleSelectionFormData.department.value === ""}
                                placeholder="Select Sub Department"
                                noResultsMessage="We couldn't find any matches."
                                loading={state.sapIdData.getSubDepartment.isLoading}
                                loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                                onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, subdepartment: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, subdepartment: v.value })}
                                items={dropdownValues.subdepartment}
                                onOpenChange={(e, v) => {
                                    const checkOpen = v.open !== undefined ? v.open : true;
                                    checkOpen && dispatch(SapIdAction.getSubDepartmentAction({ sap_client: Constant.SAP_CLIENT, departmentId: roleSelectionFormData.department.value }));
                                }}
                            />
                        </Flex>
                    </Flex>
                    <Box><Button content="Search" disabled={roleSelectionFormData.function.value === ""} loading={state.sapIdData.getFindingOrgValuesRole.isLoading} primary onClick={() => onGetRolesSearchByManualRole()} /></Box>
                </Flex> :
                idType === "2" ?
                    <Flex gap="gap.medium">
                        <Text content="Enter your reference ID *" weight="semibold" />
                        <Input placeholder="Enter reference id" value={roleSelectionFormData.refId} onChange={(e: React.SyntheticEvent, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, refId: "" }) : setRoleSelectionFormData({ ...roleSelectionFormData, refId: v.value })} />
                        <Button content="Search" disabled={!searchValidate} loading={state.sapIdData.getUserRolesAssign.isLoading} primary onClick={() => onGetRolesSearchByExistingId()} />
                    </Flex> :
                    idType === "3" ?
                        <Flex column gap="gap.medium">
                            <Flex gap="gap.small">
                                <Flex fill column gap="gap.smaller">
                                    <Text weight="semibold" >Connector {<Text content="*" color="red" />}</Text>
                                    <Dropdown
                                        fluid
                                        className="inputBody"
                                        inverted
                                        clearable={roleSelectionFormData.connector.value === "" ? false : true}
                                        value={roleSelectionFormData.connector.header}
                                        placeholder="Select Connector"
                                        noResultsMessage="We couldn't find any matches."
                                        loading={state.sapIdData.getSystemDescription.isLoading}
                                        loadingMessage={<Flex fill vAlign="center" hAlign="center"><Loader /></Flex>}
                                        onChange={(e, v: any) => v.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, connector: { id: 0, header: "", value: "" } }) : setRoleSelectionFormData({ ...roleSelectionFormData, connector: v.value })}
                                        items={dropdownValues.connector}
                                        onOpenChange={(e, v) => {
                                            const checkOpen = v.open !== undefined ? v.open : true;
                                            checkOpen && !state.sapIdData.getSystemDescription.isSuccess && dispatch(SapIdAction.getSystemDescriptionAction({ sap_client: Constant.SAP_CLIENT }));
                                        }}
                                    />
                                </Flex>
                                <Flex fill column gap="gap.smaller">
                                    <Text weight="semibold" >T-Code {<Text content="*" color="red" />}</Text>
                                    <Input
                                        fluid
                                        className="inputBody"
                                        inverted
                                        clearable
                                        value={roleSelectionFormData.t_code}
                                        placeholder="Enter T-Code"
                                        onChange={(e, v) => v?.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, t_code: "" }) : setRoleSelectionFormData({ ...roleSelectionFormData, t_code: v?.value as string })}
                                    />
                                </Flex>
                            </Flex>
                            <Box><Button content="Search" disabled={!searchValidate} loading={state.sapIdData.getFindingTCodesRole.isLoading} primary onClick={() => onGetRolesSearchByTCodes()} /></Box>
                        </Flex>
                        : <Flex column gap="gap.medium">
                            <Flex column gap="gap.smaller">
                                <Text weight="semibold" >Role Owner {<Text content="*" color="red" />}</Text>
                                <Input
                                    fluid
                                    className="inputBody"
                                    inverted
                                    clearable={roleSelectionFormData.roleOwnerName === "" ? false : true}
                                    value={roleSelectionFormData.roleOwnerName}
                                    placeholder="Enter Role Owner"
                                    onChange={(e, v) => v?.value === null ? setRoleSelectionFormData({ ...roleSelectionFormData, roleOwnerName: "" }) : setRoleSelectionFormData({ ...roleSelectionFormData, roleOwnerName: v?.value as string })}
                                />
                            </Flex>
                            <Box><Button content="Search" disabled={!searchValidate} loading={state.sapIdData.getListRoleAssignedToOwner.isLoading} primary onClick={() => onGetRolesSearchByRoleOwner()} /></Box>
                        </Flex>
            }
            {((idType === "1" && state.sapIdData.getFindingOrgValuesRole.isSuccess && Array.isArray(state.sapIdData.getFindingOrgValuesRole.data))
                || (idType === "2" && state.sapIdData.getUserRolesAssign.isSuccess && Array.isArray(state.sapIdData.getUserRolesAssign.data))
                || (idType === "3" && state.sapIdData.getFindingTCodesRole.isSuccess && Array.isArray(state.sapIdData.getFindingTCodesRole.data))
                || (idType === "4" && state.sapIdData.getListRoleAssignedToOwner.isSuccess && Array.isArray(state.sapIdData.getListRoleAssignedToOwner.data))) &&
                <Table className="sap-roles-table"
                    header={{
                        items: rolesHeader,
                        header: true,
                        className: "tableHead",
                        styles: ({ theme: { siteVariables } }) => ({
                            background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                            "&:hover": {
                                background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                            }
                        })
                    }} rows={idType === "1" ? rolesDataByManualRole : idType === "2" ? rolesDataByExistingId : idType === "3" ? rolesDataByTCodes : rolesDataByRoleOwner}
                />}
            <Box>
                {
                    (idType === "1" && state.sapIdData.getFindingOrgValuesRole.isSuccess && (Array.isArray(state.sapIdData.getFindingOrgValuesRole.data) === false)) && <DataNotFound width="inherit" />
                }
                {
                    (idType === "2" && state.sapIdData.getUserRolesAssign.isSuccess && (Array.isArray(state.sapIdData.getUserRolesAssign.data) === false)) && <DataNotFound width="inherit" />
                }
                {
                    (idType === "3" && state.sapIdData.getFindingTCodesRole.isSuccess && (Array.isArray(state.sapIdData.getFindingTCodesRole.data) === false)) && <DataNotFound width="inherit" />
                }
                {
                    (idType === "4" && state.sapIdData.getListRoleAssignedToOwner.isSuccess && (Array.isArray(state.sapIdData.getListRoleAssignedToOwner.data) === false)) && <DataNotFound width="inherit" />

                }
            </Box>
        </React.Fragment>
    );
};

export default SAPRoleSelection;