import {
    Actions,
    ICreateOrUpdatePinData,
    IGetPinData
} from "../actions/PinActions";
import { combineReducers } from "redux";

export const getPinDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_PIN_LOADING:
        case Actions.GET_PIN_ERROR:
        case Actions.GET_PIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const createOrUpdateDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.CREATE_OR_UPDATE_PIN_LOADING:
        case Actions.CREATE_OR_UPDATE_PIN_ERROR:
        case Actions.CREATE_OR_UPDATE_PIN_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        case Actions.CREATE_OR_UPDATE_PIN_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export interface IPinData {
    getPin: IGetPinData;
    createOrUpdatePin: ICreateOrUpdatePinData;
}

const pinData = combineReducers({
    getPin: getPinDataReducer,
    createOrUpdatePin: createOrUpdateDataReducer
});

export default pinData;