import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    // Get dose1 data
    GET_DOSE1_DATA: "GET_DOSE1_DATA",
    GET_DOSE1_DATA_LOADING: "GET_DOSE1_DATA_LOADING",
    GET_DOSE1_DATA_ERROR: "GET_DOSE1_DATA_ERROR",
    GET_DOSE1_DATA_SUCCESS: "GET_DOSE1_DATA_SUCCESS",

    //For Dose 2
    GET_DOSE2_DATA: "GET_DOSE2_DATA",
    GET_DOSE2_DATA_LOADING: "GET_DOSE2_DATA_LOADING",
    GET_DOSE2_DATA_ERROR: "GET_DOSE2_DATA_ERROR",
    GET_DOSE2_DATA_SUCCESS: "GET_DOSE2_DATA_SUCCESS",

    UPDATE_DOSE_DATA: "UPDATE_DOSE_DATA",
    UPDATE_DOSE_DATA_LOADING: "UPDATE_DOSE_DATA_LOADING",
    UPDATE_DOSE_DATA_ERROR: "UPDATE_DOSE_DATA_ERROR",
    UPDATE_DOSE_DATA_SUCCESS: "UPDATE_DOSE_DATA_SUCCESS",

    POST_DOSE_DATA: "POST_DOSE_DATA",
    POST_DOSE_DATA_LOADING: "POST_DOSE_DATA_LOADING",
    POST_DOSE_DATA_ERROR: "POST_DOSE_DATA_ERROR",
    POST_DOSE_DATA_SUCCESS: "POST_DOSE_DATA_SUCCESS",

    // Clear data
    POST_PUT_CLEAR_REDUCER_DATA: "POST_PUT_CLEAR_REDUCER_DATA",
};

// Dose 1 Data
export interface IDose extends SideEffectSchema {
  metaData: any[];
  rows: any[];
}

export interface IDoseGetPayload {
  employeeid: string;
  vaccinetype: number;
}

export const getDose1Action = (dose: IDoseGetPayload) => {
    return {
        type: Actions.GET_DOSE1_DATA,
        payload: { dose },
    };
};

export const getDose1LoadingAction = () =>
    loadingAction(Actions.GET_DOSE1_DATA_LOADING);
export const getDose1ErrorAction = (error: string) =>
    errorAction(Actions.GET_DOSE1_DATA_ERROR, error);
export const getDose1SuccessAction = (data: any) => {
    const payload: IDose = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_DOSE1_DATA_SUCCESS,
        payload: payload,
    };
};

export const getDose2Action = (dose: IDoseGetPayload) => {
    return {
        type: Actions.GET_DOSE2_DATA,
        payload: { dose },
    };
};

export const getDose2LoadingAction = () =>
    loadingAction(Actions.GET_DOSE2_DATA_LOADING);
export const getDose2ErrorAction = (error: string) =>
    errorAction(Actions.GET_DOSE2_DATA_ERROR, error);
export const getDose2SuccessAction = (data: any) => {
    const payload: IDose = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_DOSE2_DATA_SUCCESS,
        payload: payload,
    };
};

// update dose 1 data
export interface IDosePut extends SideEffectSchema {
  data: {
    status_code: string;
    message: string;
  };
}

export interface IDosePutPayload {
  employeeid: string;
  updateddate: string;
  updatedbrand: string;
  vaccinetype: string;
  modifiedby: string;
  modifiedDate: string;
}

export const putDoseAction = (dose: IDosePutPayload) => {
    return {
        type: Actions.UPDATE_DOSE_DATA,
        payload: { dose },
    };
};
export const putDoseLoadingAction = () =>
    loadingAction(Actions.UPDATE_DOSE_DATA_LOADING);
export const putDoseErrorAction = (error: string | any) =>
    errorAction(Actions.UPDATE_DOSE_DATA_ERROR, error);
export const putDoseSuccessAction = (data: any) => {
    const payload: IDosePut = {
        data,
        ...successSideEffectState,
    };
    return {
        type: Actions.UPDATE_DOSE_DATA_SUCCESS,
        payload: payload,
    };
};

// add dose 1 data
export interface IDosePost extends SideEffectSchema {
  data: {
    status_code: string;
    message: string;
  };
}

export interface IDosePostPayload {
  employeeid: string;
  employeename: string;
  dosedate: string;
  vaccinebrand: string;
  vaccinetype: number;
  createdby: string;
  createddate: string;
  employeetype: string;
}

export const postDoseAction = (dose: IDosePostPayload) => {
    return {
        type: Actions.POST_DOSE_DATA,
        payload: { dose },
    };
};
export const postDoseLoadingAction = () =>
    loadingAction(Actions.POST_DOSE_DATA_LOADING);
export const postDoseErrorAction = (error: string | any) =>
    errorAction(Actions.POST_DOSE_DATA_ERROR, error);
export const postDoseSuccessAction = (data: any) => {
    const payload: any = {
        data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_DOSE_DATA_SUCCESS,
        payload: payload,
    };
};

export const postPutClearReducerData = () => {
    return {
        type: Actions.POST_PUT_CLEAR_REDUCER_DATA,
        payload: {},
    };
};
