import {
    Actions,
    ICurrentMasterData,
    IFormSixteenData,
    ICurrentDownloadDocumentData,
    IPreviousMasterData,
    IPreviousPayslipData,
    IOldDocumentData,
    IPreviousDownloadDocumentData,
    ICurrentPayslipWorksheetData,
    IEmployeeLatestData,
    IEmployeeApplicableData,
    IDownloadTrustFundData,
    IPassbookDetailData,
    IEmployeeAdditionalData,
    IGenerateBonafideData,
    IAdditionalDownloadDocumentData
} from "../actions/DocumentAction";
import { combineReducers } from "redux";

export const getCurrentMasterDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_CURRENT_MASTER_DATA_LOADING:
        case Actions.GET_CURRENT_MASTER_DATA_ERROR:
        case Actions.GET_CURRENT_MASTER_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

//--------------------------------------------------------------------------------------------------
export const getCurrentPayslipDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA_LOADING:
        case Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA_ERROR:
        case Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//-----------------------------------------------------------------------------------------------
export const getFormSixteenDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_FORM16_DATA_LOADING:
        case Actions.GET_FORM16_DATA_ERROR:
        case Actions.GET_FORM16_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//--------------------------------------------------------------------------------------------------

export const getCurrentDownloadDocumentDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_CURRENT_DOWNLOAD_DATA_LOADING:
        case Actions.GET_CURRENT_DOWNLOAD_DATA_ERROR:
        case Actions.GET_CURRENT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//--------------------------------------------------------------------------------------------

export const getPreviousMasterDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_PREVIOUS_MASTER_DATA_LOADING:
        case Actions.GET_PREVIOUS_MASTER_DATA_ERROR:
        case Actions.GET_PREVIOUS_MASTER_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//----------------------------------------------------------------------------------------------

export const getPreviousPayslipDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_LOADING:
        case Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_ERROR:
        case Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//-------------------------------------------------------------------------------------------------

export const getOldDocumentDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ALL_OLD_DOC_DATA_LOADING:
        case Actions.GET_ALL_OLD_DOC_DATA_ERROR:
        case Actions.GET_ALL_OLD_DOC_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//--------------------------------------------------------------------------------------------------


export const getPreviousDownloadDocumentDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_PREVIOUS_DOWNLOAD_DATA_LOADING:
        case Actions.GET_PREVIOUS_DOWNLOAD_DATA_ERROR:
        case Actions.GET_PREVIOUS_DOWNLOAD_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
// ---------------------------------------------------------------------------------------------------
export const getEmployeeLatestDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_EMPLOYEE_LATEST_DATA_LOADING:
        case Actions.GET_EMPLOYEE_LATEST_DATA_ERROR:
        case Actions.GET_EMPLOYEE_LATEST_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//-----------------------------------------------------------------------------------------------------------

export const getPassbookDetailDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_PASSBOOK_DETAILS_DATA_LOADING:
        case Actions.GET_PASSBOOK_DETAILS_DATA_ERROR:
        case Actions.GET_PASSBOOK_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//-------------------------------------------------------------------------------------------------------------

export const getDownloadTrustFundDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_TRUSTFUND_DATA_LOADING:
        case Actions.GET_TRUSTFUND_DATA_ERROR:
        case Actions.GET_TRUSTFUND_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//-------------------------------------------------------------------------------------------------------------
export const getEmployeeApplicableDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_EMPLOYEE_APPLICABLE_DATA_LOADING:
        case Actions.GET_EMPLOYEE_APPLICABLE_DATA_ERROR:
        case Actions.GET_EMPLOYEE_APPLICABLE_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
//-------------------------------------------------------------------------------------------------------------
export const getEmployeeAdditionalDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_EMPLOYEE_ADDITIONAL_DATA_LOADING:
        case Actions.GET_EMPLOYEE_ADDITIONAL_DATA_ERROR:
        case Actions.GET_EMPLOYEE_ADDITIONAL_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

//-------------------------------------------------------------------------------------------------------------

export const getDownloadAdditionalDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ADDITIONAL_DOWNLOAD_DATA_LOADING:
        case Actions.GET_ADDITIONAL_DOWNLOAD_DATA_ERROR:
        case Actions.GET_ADDITIONAL_DOWNLOAD_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

//-------------------------------------------------------------------------------------------------------------
export const getGenerateBonafideDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_GENERATE_BONAFIDE_DATA_LOADING:
        case Actions.GET_GENERATE_BONAFIDE_DATA_ERROR:
        case Actions.GET_GENERATE_BONAFIDE_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export interface IDocumentData {
    currentMasterData: ICurrentMasterData,
    currentpayslipWorksheetData: ICurrentPayslipWorksheetData,
    formSixteenData: IFormSixteenData,
    currentDownloadDocumentData: ICurrentDownloadDocumentData,
    previousMasterData: IPreviousMasterData,
    previousPayslipData: IPreviousPayslipData,
    oldDocumentData: IOldDocumentData,
    previousDownloadDocument: IPreviousDownloadDocumentData,
    employeeLatestData: IEmployeeLatestData,
    employeeApplicableData: IEmployeeApplicableData,
    downloadTrustFundData: IDownloadTrustFundData,
    passbookDetailData: IPassbookDetailData,
    employeeAdditionalData: IEmployeeAdditionalData,
    downloadAdditionalData: IAdditionalDownloadDocumentData,
    generateBonafideData: IGenerateBonafideData
}
const documentData = combineReducers({
    currentMasterData: getCurrentMasterDataReducer,
    currentpayslipWorksheetData: getCurrentPayslipDataReducer,
    formSixteenData: getFormSixteenDataReducer,
    currentDownloadDocumentData: getCurrentDownloadDocumentDataReducer,
    previousMasterData: getPreviousMasterDataReducer,
    previousPayslipData: getPreviousPayslipDataReducer,
    oldDocumentData: getOldDocumentDataReducer,
    previousDownloadDocument: getPreviousDownloadDocumentDataReducer,
    employeeLatestData: getEmployeeLatestDataReducer,
    passbookDetailData: getPassbookDetailDataReducer,
    downloadTrustFundData: getDownloadTrustFundDataReducer,
    employeeApplicableData: getEmployeeApplicableDataReducer,
    employeeAdditionalData: getEmployeeAdditionalDataReducer,
    downloadAdditionalData: getDownloadAdditionalDataReducer,
    generateBonafideData: getGenerateBonafideDataReducer
});
export default documentData;
