import { put, call, take, delay, takeLatest } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/LeaveActions";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, LEAVE_API_CONFIG } from "../../config";

function* postValidLeaveData(payload: {
    access_token: string;
    emp_comp: string;
    leaveData: actionCreators.ILeaveValidPayload;
}) {
    try {
        yield put(actionCreators.postValidLeaveLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };

        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            LEAVE_API_CONFIG.VALIDATE_LEAVE_REQUEST,
            payload.leaveData,
            config
        );
        yield put(actionCreators.postValidLeaveSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postValidLeaveErrorAction(error));
    }
}

function* postApplyLeaveData(payload: any) {
    try {
        yield put(actionCreators.postLeaveLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            LEAVE_API_CONFIG.APPLY_LEAVE_REQUEST,
            payload.payload,
            config
        );
        yield put(actionCreators.postLeaveSuccessAction(data));
    } catch (error: any) {
        if (error)
            yield put(actionCreators.postLeaveErrorAction(error.response.data.error));
    }
}

function* getQuotaLeaveData(payload: {
    emp_id: string;
    emp_comp: string;
    access_token: string;
}) {
    try {
        yield put(actionCreators.getQuotaLeaveLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + "/" + payload.emp_comp + LEAVE_API_CONFIG.GET_LEAVE_QUOTA,
            { emp_id: payload.emp_id },
            config
        );
        yield put(actionCreators.getQuotaLeaveSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getQuotaLeaveErrorAction(error.response.data.error)
        );
    }
}
function* getLeaveHistoryData(payload: {
    access_token: string;
    emp_comp: string;
    payload: actionCreators.ILeaveHistoryPayload;
}) {
    try {
        yield put(actionCreators.getLeaveHistoryLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };

        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            LEAVE_API_CONFIG.GET_LEAVE_REQUESTS,
            payload.payload,
            config
        );
        yield put(actionCreators.getLeaveHistorySuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getLeaveHistoryErrorAction(error.response.data.error)
        );
    }
}

//leave delete from user side
function* postLeaveDeleteHistoryData(payload: {
    access_token: string;
    emp_comp: string;
    payload: actionCreators.ILeaveDeleteHistoryPayload;
}) {
    try {
        yield put(actionCreators.postLeaveDeleteHistoryLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            LEAVE_API_CONFIG.DELETE_LEAVE_REQUEST,
            payload.payload,
            config
        );
        yield put(actionCreators.postLeaveDeleteHistorySuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.postLeaveDeleteHistoryErrorAction(
                error.response.data.error
            )
        );
    }
}

function* getLeaveMasterData(payload: {
    access_token: string;
    emp_comp: string;
    payload: actionCreators.IMasterPayload;
}) {
    try {
        yield put(actionCreators.getLeaveMasterLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            LEAVE_API_CONFIG.GET_LEAVE_REQUESTS_MASTER,
            payload.payload,
            config
        );
        yield put(actionCreators.getLeaveMasterSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getLeaveMasterErrorAction(error.response.data.error)
        );
    }
}

function* getLeaveRequestApprovalHistoryData(action: any) {
    try {
        yield put(actionCreators.getLeaveRequestApprovalHistoryLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            action.payload.emp_comp +
            LEAVE_API_CONFIG.GET_LEAVE_REQUESTS_APPROVAL,
            action.payload.payload,
            config
        );
        yield put(actionCreators.getLeaveRequestApprovalHistorySuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getLeaveRequestApprovalHistoryErrorAction(
                error.response.data.error
            )
        );
    }
}

//we can reject approve leave request for manager purpose
function* postLeaveRequestApprovalData(payload: {
    access_token: string;
    emp_comp: string;
    payload: actionCreators.ILeaveRequestApprovalPayLaod;
}) {
    try {
        yield put(actionCreators.postLeaveRequestApprovalLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            LEAVE_API_CONFIG.LEAVE_REQUEST_APPROVAL,
            payload.payload,
            config
        );
        yield put(actionCreators.postLeaveRequestApprovalSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.postLeaveRequestApprovalErrorAction(
                error.response.data.error
            )
        );
    }
}

function* postDeleteLeaveRequestApprovalData(payload: {
    access_token: string;
    emp_comp: string;
    payload: actionCreators.IDeleteLeaveRequestApprovalPayLaod;
}) {
    try {
        yield put(actionCreators.postDeleteLeaveRequestApprovalLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL +
            "/" +
            payload.emp_comp +
            LEAVE_API_CONFIG.DELETE_LEAVE_REQUEST_APPROVAL,
            payload.payload,
            config
        );
        yield put(actionCreators.postDeleteLeaveRequestApprovalSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.postDeleteLeaveRequestApprovalErrorAction(
                error.response.data.error
            )
        );
    }
}

function* getEmployeeIdData(payload: { access_token: string; context: any }) {
    try {
        yield put(actionCreators.getEmployeeIdUsingGraphLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                "Content-Type": "application/json",
            },
        };
        const { data } = yield httpGet(
            `https://graph.microsoft.com/v1.0/users/${payload.context}?$expand=manager($levels=1)&$select=EmployeeID,mailNickname,mail,userPrincipalName`,
            config
        );
        // yield put(
        //   // actionCreators.getTeamMemberAction(payload.access_token, data.manager.id)
        // );
        yield put(actionCreators.getEmployeeIdUsingGraphSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getEmployeeIdUsingGraphErrorAction(error));
    }
}

function* getTeamMemberData(payload: { access_token: string; id: string }) {
    try {
        yield put(actionCreators.getTeamMemberActionLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpGet(
            `https://graph.microsoft.com/v1.0/users/${payload.id}/directReports`,
            config
        );
        yield put(actionCreators.getTeamMemberSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getTeamMemberErrorAction(error.response.data.error)
        );
    }
}

function* getUpcomingHolidayData(payload: { token: string; emp_id: string, emp_comp_code: string }) {
    try {
        yield put(actionCreators.getUpcomingHolidayLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        };
        const { data } = yield httpPost(
            API_BASE_URL + LEAVE_API_CONFIG.GET_UPCOMING_HOLIDAY_LIST,
            {
                emp_id: payload.emp_id,
                emp_comp_code: payload.emp_comp_code
            },
            config
        );
        yield put(actionCreators.getUpcomingHolidaySuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getUpcomingHolidayErrorAction(error.response.data.error)
        );
    }
}

function* getActiveYearData(payload: { token: string; emp_comp: string }) {
    try {
        yield put(actionCreators.getActiveYearLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        };
        const { data } = yield httpGet(
            API_BASE_URL +
            "/" +
            payload.emp_comp + LEAVE_API_CONFIG.GET_ACTIVE_YEAR,
            config
        );
        yield put(actionCreators.getActiveYearSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getActiveYearErrorAction(error.response.data.error)
        );
    }
}

export function* watchGetEmployeeIdData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_EMP_ID_FROM_GRAPH
        );
        yield call(getEmployeeIdData, payload);
    }
}
export function* watchGetTeamMemberData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_TEAM_MEMBER);
        yield call(getTeamMemberData, payload);
    }
}

export function* watchValidLeaveData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.POST_VALID_LEAVE);
        yield call(postValidLeaveData, payload);
    }
}
export function* watchApplyLeaveData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.POST_CREATE_LEAVE_DATA
        );
        yield call(postApplyLeaveData, payload);
    }
}

export function* watchQuotaLeaveData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_QUOTA_LEAVE);
        yield call(getQuotaLeaveData, payload);
    }
}
export function* watchLeaveHistoryData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_LEAVE_HISTORY);
        yield call(getLeaveHistoryData, payload);
    }
}

export function* watchLeaveDeleteHistoryData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.POST_LEAVE_DELETE_HISTORY
        );
        yield call(postLeaveDeleteHistoryData, payload);
    }
}
export function* watchLeaveMasterData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_LEAVE_MASTER);
        yield call(getLeaveMasterData, payload);
    }
}

export function* watchLeaveRequestApprovalHistoryData() {
    // while (true) {
    //   const { payload } = yield take(
    //     actionCreators.Actions.GET_LEAVE_APPROVAL_HISTORY
    //   );
    //   yield call(getLeaveRequestApprovalHistoryData, payload);

    // }
    yield takeLatest(actionCreators.Actions.GET_LEAVE_APPROVAL_HISTORY, getLeaveRequestApprovalHistoryData);
}

export function* watchLeaveRequestApprovalData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.POST_LEAVE_APPROVAL);
        yield call(postLeaveRequestApprovalData, payload);
    }
}

export function* watchDeleteLeaveRequestApprovalData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.POST_DELETE_LEAVE_APPROVAL
        );
        yield call(postDeleteLeaveRequestApprovalData, payload);
    }
}

export function* watchGetUpcomingHolidayData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_UPCOMING_HOLIDAY);
        yield call(getUpcomingHolidayData, payload);
    }
}

export function* watchGetActiveYearData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_ACTIVE_YEAR);
        yield call(getActiveYearData, payload);
    }
}
