import {
    Actions,
    IGetServiceAvailabilityData
} from "../actions/serviceAction";
import { combineReducers } from "redux";

export const getServiceAvailabilityDataReducer = (state = {}, action: any) => {
    switch(action.type) {
        case Actions.GET_SERVICE_AVAILABILITY_LOADING:
        case Actions.GET_SERVICE_AVAILABILITY_ERROR:
        case Actions.GET_SERVICE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export interface IServiceData {
    getServiceData: IGetServiceAvailabilityData
}

const serviceData = combineReducers({
    getServiceData: getServiceAvailabilityDataReducer
});
export default serviceData;