import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_DATA: "GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_DATA",
    GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_LOADING: " GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_LOADING",
    GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_ERROR: " GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_ERROR",
    GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_SUCCESS: " GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_SUCCESS",

    GET_COMPENSATION_STRUCTURE_DATA: "GET_COMPENSATION_STRUCTURE_DATA",
    GET_COMPENSATION_STRUCTURE_LOADING: "GET_COMPENSATION_STRUCTURE_LOADING",
    GET_COMPENSATION_STRUCTURE_ERROR: "GET_COMPENSATION_STRUCTURE_ERROR",
    GET_COMPENSATION_STRUCTURE_SUCCESS: "GET_COMPENSATION_STRUCTURE_SUCCESS",

    GET_COMPENSATION_STRUCTURE_PDF_DATA: "GET_COMPENSATION_STRUCTURE_PDF_DATA",
    GET_COMPENSATION_STRUCTURE_PDF_LOADING: "GET_COMPENSATION_STRUCTURE_PDF_LOADING",
    GET_COMPENSATION_STRUCTURE_PDF_ERROR: "GET_COMPENSATION_STRUCTURE_PDF_ERROR",
    GET_COMPENSATION_STRUCTURE_PDF_SUCCESS: "GET_COMPENSATION_STRUCTURE_PDF_SUCCESS"
};

// Get_Compensation_Structure_Instructions

export interface IGetCompensationStructureInstruction extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        instructions: {
            line: string;
        }[];
    };
}

export const getCompensationStructureInstructionAction = (access_token: string) => {
    return {
        type: Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_DATA,
        payload: { access_token }
    };
};
export const getCompensationStructureInstructionLoadingAction = () =>
    loadingAction(Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_LOADING);
export const getCompensationStructureInstructionErrorAction = (error: string | any) =>
    errorAction(Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_ERROR, error);
export const getCompensationStructureInstructionSuccessAction = (data: any) => {
    const payload: IGetCompensationStructureInstruction = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_COMPENSATION_STRUCTURE_INSTRUCTIONS_SUCCESS,
        payload: payload
    };
};
///////////////////////////////////////////////////////////////////////////////////////

//Get_Compensation_Structure
export interface IGetCompensationStructurePayload {
    emp_id: string;
    comp_code: string;
    egroup: string;
    esubgroup: string;
    category: string;
}

export interface IGetCompensationStructureCommon {
    wage_type: string;
    wage_text: string;
    wage_long_text: string;
    annual_amount: number;
    monthly_amount: number;
    sequence: number;
    meessage: null;
}

export interface IGetCompensationStructure extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        employee_details: {
            emp_full_name: string;
            emp_comp: string;
            emp_level: string;
            emp_org_unit: string;
            emp_designation: string;
            emp_egroup: string;
            emp_esubgroup: string;
            emp_dob: string;
        },
        comp_structure: {
            start_date: string;
            end_date: string;
            conveyance_type: string;
            accomodation_type: string;
            emp_subgroup: string;
            fy_year: string;
            standard: IGetCompensationStructureCommon[];
            retirals: IGetCompensationStructureCommon[];
            choice_pay: IGetCompensationStructureCommon[];
            total_pay: IGetCompensationStructureCommon[];
            standard_others: [];
        };
    };
    isStandard: boolean;
}

export const getCompensationStructureAction = (
    access_token: string,
    payload: IGetCompensationStructurePayload,
    isStandard?: boolean
) => {
    return {
        type: Actions.GET_COMPENSATION_STRUCTURE_DATA,
        payload: { access_token, payload, isStandard }
    };
};
export const getCompensationStructureLoadingAction = (isStandard: boolean) =>
    loadingAction(Actions.GET_COMPENSATION_STRUCTURE_LOADING, { isStandard: isStandard });
export const getCompensationStructureErrorAction = (error: string | any, isStandard: boolean) =>
    errorAction(Actions.GET_COMPENSATION_STRUCTURE_ERROR, error, { isStandard: isStandard });
export const getCompensationStructureSuccessAction = (data: any, isStandard: boolean) => {
    const payload: IGetCompensationStructure = {
        ...data,
        ...successSideEffectState,
        isStandard
    };
    return {
        type: Actions.GET_COMPENSATION_STRUCTURE_SUCCESS,
        payload: payload
    };
};

export interface IGetCompensationStructurePDFPayload {
    emp_id: string,
    comp_code: string,
    egroup: string,
    esubgroup: string,
    category: string;
    emp_name: string;
    categoryName: string;
    currentDate: string;
}

export interface IGetCompensationStructurePDF extends SideEffectSchema {
    data: any;
}
export const getCompensationStructurePDFAction = (
    access_token: string,
    payload: IGetCompensationStructurePDFPayload
) => {
    return {
        type: Actions.GET_COMPENSATION_STRUCTURE_PDF_DATA,
        payload: { access_token, payload }
    };
};
export const getCompensationStructurePDFLoadingAction = () =>
    loadingAction(Actions.GET_COMPENSATION_STRUCTURE_PDF_LOADING);
export const getCompensationStructurePDFErrorAction = (error: string | any) =>
    errorAction(Actions.GET_COMPENSATION_STRUCTURE_PDF_ERROR, error);
export const getCompensationStructurePDFSuccessAction = (data: any) => {
    const payload: IGetCompensationStructurePDF = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_COMPENSATION_STRUCTURE_PDF_SUCCESS,
        payload: payload
    };
};
