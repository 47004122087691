import { put, call, take, delay, takeLatest, takeEvery } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/CFMActions";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_BASE_URL, CFM_API_CONFIG } from "../../config";

function* getSuccessFactorUserDetailsData(payload: {
    access_token: string;
    emp_id: string;
}) {
    try {
        yield put(actionCreators.getSuccessFactorUserDetailsLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.GET_SUCCESS_FACTOR_USER_DETAILS}`,
            payload,
            config
        );
        yield put(actionCreators.getSuccessFactorUserDetailsSuccessAction(data));
    } catch (error: any) {
        console.log(error);
        yield put(actionCreators.getSuccessFactorUserDetailsErrorAction(error));
    }
}

function* getEmployeeGoalsData(action: any) {
    try {
        yield put(actionCreators.getEmployeeGoalsLoadingAction(action.payload.isTeam));
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.GET_EMPLOYEE_GOALS}`,
            action.payload.payload,
            config
        );
        yield put(actionCreators.getEmployeeGoalsSuccessAction(data, action.payload.isTeam));
    } catch (error: any) {
        yield put(actionCreators.getEmployeeGoalsErrorAction(error, action.payload.isTeam));
    }
}

function* addGoalCommentData(payload: {
    access_token: string;
    payload: {
        item_id: string;
        comment: string;
        commented_by: string;
        commented_by_name: string;
        role: string;
        form_data_id: string;
        form_template_id: string;
        prev_sf_goal_comments_id: string;
    }
}) {
    try {
        yield put(actionCreators.addGoalCommentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.ADD_GOAL_COMMENTS}`,
            payload.payload,
            config
        );
        yield put(actionCreators.addGoalCommentSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.addGoalCommentErrorAction(error));
    }
}

function* getEmployeeCompetencyData(action: any) {
    try {
        yield put(actionCreators.getEmployeeCompetencyLoadingAction(action.payload.isTeam));
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.GET_EMPLOYEE_COMPETENCIES}`,
            action.payload.payload,
            config
        );
        yield put(actionCreators.getEmployeeCompetencySuccessAction(data, action.payload.isTeam));
    } catch (error: any) {
        yield put(actionCreators.getEmployeeCompetencyErrorAction(error, action.payload.isTeam));
    }
}

function* addCompetencyCommentData(payload: {
    access_token: string;
    payload: {
        item_id: string;
        comment: string;
        commented_by: string;
        commented_by_name: string;
        role: string;
        form_data_id: string;
        form_template_id: string;
        prev_sf_competency_comments_id: string;
    }
}) {
    try {
        yield put(actionCreators.addCompetencyCommentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.ADD_COMPETENCIES_COMMENT}`,
            payload.payload,
            config
        );
        yield put(actionCreators.addCompetencyCommentSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.addCompetencyCommentErrorAction(error));
    }
}

function* getGoalCommentData(payload: {
    access_token: string;
    item_id: string;
    is_employee: boolean;
}) {
    try {
        yield put(actionCreators.getGoalCommentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.GET_GOAL_COMMENTS}`,
            { item_id: payload.item_id, is_employee: payload.is_employee },
            config
        );
        yield put(actionCreators.getGoalCommentSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getGoalCommentErrorAction(error));
    }
}

function* getCompetencyCommentData(payload: {
    access_token: string;
    item_id: string;
    user_id: string;
    is_employee: boolean;
}) {
    try {
        yield put(actionCreators.getCompetencyCommentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.GET_COMPETENCY_COMMENTS}`,
            { item_id: payload.item_id, user_id: payload.user_id, is_employee: payload.is_employee },
            config
        );
        yield put(actionCreators.getCompetencyCommentSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getCompetencyCommentErrorAction(error));
    }
}

function* getPerformanceTimelineData(payload: {
    access_token: string;
    payload: {
        user_id: string;
        emp_id: string;
        filter: string;
    }
}) {
    try {
        yield put(actionCreators.getPerformanceTimelineLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.GET_PERFORMANCE_TIMELINE}`,
            payload.payload,
            config
        );
        yield put(actionCreators.getPerformanceTimelineSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getPerformanceTimelineErrorAction(error));
    }
}

function* sendFeedbackRequestData(payload: {
    access_token: string;
    payload: {
        item_ids: string[];
        emp_id: string;
        emp_dem_email: string;
        emp_fem_email: string;
        emp_name: string;
    }
}) {
    try {
        yield put(actionCreators.sendFeedbackRequestLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.SEND_FEEDBACK_REQUEST}`,
            payload.payload,
            config
        );
        yield put(actionCreators.sendFeedbackRequestSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.sendFeedbackRequestErrorAction(error));
    }
}

function* getLeadershipBehaviuorDescription(payload: {
    access_token: string;
    payload: {
        emp_id: string;
        leadership_behaviours_title: string
    }
}) {
    try {
        yield put(actionCreators.getLeadershipBehaviourDewscriptionLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.GET_LEADERSHIP_BEHAVIOUR_DESCRIPTION}`,
            payload.payload,
            config
        );
        yield put(actionCreators.getLeadershipBehaviourDewscriptionSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getLeadershipBehaviourDewscriptionErrorAction(error));
    }
}

function* updateManagerGoalCommentData(payload: {
    access_token: string,
    payload: actionCreators.IUpdateManagerCompetencyCommentPayload
}) {
    try {
        yield put(actionCreators.updateManagerGoalCommentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.UPDATE_MANAGER_GOAL_COMMENT}`,
            payload.payload,
            config
        );

        yield put(actionCreators.updateManagerGoalCommentSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.updateManagerGoalCommentErrorAction(error));
    }
}

function* updateManagerCompetencyCommentData(payload: {
    access_token: string,
    payload: actionCreators.IUpdateManagerCompetencyCommentPayload
}) {
    try {
        yield put(actionCreators.updateManagerCompetencyCommentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`
            }
        };
        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.UPDATE_MANAGER_COMPETENCY_COMMENT}`,
            payload.payload,
            config
        );

        yield put(actionCreators.updateManagerCompetencyCommentSuccessAction(data)); 
    } catch (error: any) {
        yield put(actionCreators.updateManagerCompetencyCommentErrorAction(error));
    }
}

function* deleteManagerGoalCommentData(payload: {
    access_token: string,
    payload: actionCreators.IDeleteManagerGoalCommentPayload
}) {
    try {
        yield put(actionCreators.deleteManagerGoalCommentLoadingAction());

        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`
            }
        };

        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.DELETE_MANAGER_GOAL_COMMENT}`,
            payload.payload,
            config
        );

        yield put(actionCreators.deleteManagerGoalCommentSuccessAction(data)); 
    } catch (error: any) {
        yield put(actionCreators.deleteManagerGoalCommentErrorAction(error));
    }
}

function* deleteManagerCompetencyCommentData(payload: {
    access_token: string,
    payload: actionCreators.IDeleteManagerCompetencyCommentPayload
}) {
    try {
        yield put(actionCreators.deleteManagerCompetencyCommentLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`
            }
        };

        const { data } = yield httpPost(
            `${API_BASE_URL}${CFM_API_CONFIG.DELETE_MANAGER_COMPETENCY_COMMENT}`,
            payload.payload,
            config
        );

        yield put(actionCreators.deleteManagerCompetencyCommentSuccessAction(data)); 
    } catch (error: any) {
        yield put(actionCreators.deleteManagerCompetencyCommentErrorAction(error));
    }

}

export function* watchGetSuccessFactorUserDetailsData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SUCCESS_FACTOR_USER_DETAILS_DATA
        );
        yield call(getSuccessFactorUserDetailsData, payload);
    }
}


export function* watchGetEmployeeGoalsData() {
    // while (true) {
    //   const { payload } = yield take(
    //     actionCreators.Actions.GET_EMPLOYEE_GOAL_DATA
    //   );
    //   yield call(getEmployeeGoalsData, payload);
    // }
    yield takeLatest(actionCreators.Actions.GET_EMPLOYEE_GOAL_DATA, getEmployeeGoalsData);
}

export function* watchAddGoalCommentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.ADD_GOAL_COMMENTS_DATA
        );
        yield call(addGoalCommentData, payload);
    }
}

export function* watchGetEmployeeCompetencyData() {
    // while (true) {
    //     const { payload } = yield take(
    //         actionCreators.Actions.GET_EMPLOYEE_COMPETENCY_DATA
    //     );
    //     yield call(getEmployeeCompetencyData, payload);
    // }
    yield takeLatest(actionCreators.Actions.GET_EMPLOYEE_COMPETENCY_DATA, getEmployeeCompetencyData);
}

export function* watchAddCompetencyCommentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.ADD_COMPETENCY_COMMENT_DATA
        );
        yield call(addCompetencyCommentData, payload);
    }
}

export function* watchGetGoalCommentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_GOAL_COMMENTS_DATA
        );
        yield call(getGoalCommentData, payload);
    }
}

export function* watchGetCompetencyCommentData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_COMPETENCY_COMMENT_DATA
        );
        yield call(getCompetencyCommentData, payload);
    }
}

export function* watchGetPerformanceTimelineData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.ADD_PERFORMANCE_TIMELINE_DATA
        );
        yield call(getPerformanceTimelineData, payload);
    }
}

export function* watchSendFeedbackRequestData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.SEND_FEEDBACK_REQUEST_DATA
        );
        yield call(sendFeedbackRequestData, payload);
    }
}

export function* watchGetLeadershipBehaviuorDescription() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_LEADERSHIP_BEHAVIUOR_DESCRIPTION_DATA
        );
        yield call(getLeadershipBehaviuorDescription, payload);
    }
}

export function* watchUpdateManagerGoalCommentData() {
    while(true) {
        const { payload } = yield take(
            actionCreators.Actions.UPDATE_MANAGER_GOAL_COMMENT_DATA
        );
        yield call(updateManagerGoalCommentData, payload);
    }
}

export function* watchUpdateManagerCompetencyCommentData() {
    while(true) {
        const { payload } = yield take(
            actionCreators.Actions.UPDATE_MANAGER_COMPETENCY_COMMENT_DATA
        );
        yield call(updateManagerCompetencyCommentData, payload);
    }
}

export function* watchDeleteManagerGoalCommentData() {
    while(true) {
        const { payload } = yield take(
            actionCreators.Actions.DELETE_MANAGER_GOAL_COMMENT_DATA
        );
        yield call(deleteManagerGoalCommentData, payload);
    }
}

export function* watchDeleteManagerCompetencyCommentData() {
    while(true) {
        const { payload } = yield take(
            actionCreators.Actions.DELETE_MANAGER_COMPETENCY_COMMENT_DATA
        );
        yield call(deleteManagerCompetencyCommentData, payload);
    }
}
