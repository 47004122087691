import React from "react";
import {
    EditIcon,
    TrashCanIcon,
    Dialog,
    AcceptIcon,
    CloseIcon,
    Flex,
    Button
} from "@fluentui/react-northstar";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import { deleteManagerCompetencyCommentDataAction, deleteManagerGoalCommentDataAction } from "../../redux/actions/CFMActions";
import { getAppInsights } from "./../../telemetry/telemetryService";
import { Constant, TraceEvent, TraceTelemtry } from "../../constant";
interface ICFMMenuComponent {
    item: any,
    setEditableId: any,
    managerView: any,
    editableId: any,
    showBtn?: any,
    QueryData: IState
}

const CFMMenuComponent: React.FC<ICFMMenuComponent> = (props) => {
    const {appInsights, trackEvent, trackMetrix} = getAppInsights();
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);

    const [ open, setOpen ] = React.useState(false);

    const onMenuClick = (text: string, item: any) => {
        if(text === "Edit") {
            props.setEditableId({isActive:item.id, comment: item.comment});
        } else if(text == "Delete") {
            setOpen(true);
            props.setEditableId({ isDelete: true });
        }
    };

    const feedbackEditValue = (btnValue: string, feedbackVal: any) => {
        props.setEditableId({});
    };

    const cancelDialog = () => {
        props.setEditableId({ isDelete: false });
        setOpen(false);
    };

    const confirmDialog = () => {
        if(props.item?.sf_competency_comments_id) {
            const data={
                item_id: props.item.item_id,
                sf_competency_comments_id: props.item.sf_competency_comments_id
            };
            dispatch(
                deleteManagerCompetencyCommentDataAction(
                    state.authData.userAuth.token, data
                )
            );

            trackEvent(appInsights, TraceEvent.TML_CFM_LEADERSHIP_MANAGER_DELETE_COMMENT, data );
        } else if(props.item?.sf_goal_comments_id) {
            const data={
                item_id: props.item.item_id_pk_id,
                sf_goal_comments_id: props.item.sf_goal_comments_id
            };
            dispatch(
                deleteManagerGoalCommentDataAction(
                    state.authData.userAuth.token, data
                )
            );
            trackEvent(appInsights, TraceEvent.TML_CFM_GOALS_MANAGER_DELETE_COMMENT, data );
        }
        setOpen(false);
    };

    const disableMenuButton = () => {
        if(props.QueryData.employeeId === props.item.commented_by) {
            if(props.item.status === "0") {
                return true;
            } else {
                return false;
            }
        }
        // if(props.QueryData.employeeId === props.item.commented_by) {
        //     const date = new Date(Date.now() - 5 * 24 * 60 * 60 * 1000);
        //     const modifiedData = new Date(props.item.modified_date_time);
        //     if(modifiedData >= date) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // } else {
        //     return false;
        // }
    };

    if(props.managerView) {
        if(props.editableId.isActive !== props.item.id) {
            return (
                <>
                    <Dialog
                        open={open}
                        cancelButton="Cancel"
                        confirmButton="Confirm"
                        header="Confirm Delete"
                        content="Are you sure you want to delete the selected feedback?"
                        headerAction={{
                            icon: <CloseIcon />,
                            title: "Close",
                            onClick: () => setOpen(false),
                        }}
                        onCancel={() => cancelDialog()}
                        onConfirm={() => confirmDialog()}
                    />
                    {
                        disableMenuButton() ?
                            <Flex vAlign="center" gap="gap.small">
                                <EditIcon size="small" styles={({ theme: { siteVariables } }) => ({color: siteVariables.bodyBackground === "#fff" ? "#4052AB" : "#fff", cursor: "pointer"})} outline onClick={ () => onMenuClick("Edit", props.item) }/>
                                <TrashCanIcon size="small" styles={({ theme: { siteVariables } }) => ({color: siteVariables.bodyBackground === "#fff" ? "#A4262C" : "#fff", cursor: "pointer"})} outline onClick={ () => onMenuClick("Delete", props.item) }/>
                            </Flex> : <></>
                    }
                </>
            );
        } else if(props.editableId.isActive === props.item.id && props.showBtn) {
            return (
                <Flex gap="gap.medium">
                    <AcceptIcon size="small" className="pointer" onClick={() => feedbackEditValue("accept", props.item.comment)}/>
                    <CloseIcon size="small" className="pointer" onClick={() => feedbackEditValue("reject", props.item.comment)}/>
                </Flex>
            );
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }

};

export default CFMMenuComponent;