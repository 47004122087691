import React from "react";
import {
    Avatar,
    Text,
    Flex,
    FlexItem,
    Button,
    Dropdown,
    Table,
    Dialog,
    Status,
    Loader,
    Portal,
    CloseIcon,
    TrashCanIcon,
    Divider,
    Skeleton,
    Box,
    gridCellWithFocusableElementBehavior,
} from "@fluentui/react-northstar";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import * as LeaveAppliedAction from "../../redux/actions/LeaveActions";
import { SuccessToast, ErrorToast } from "../ToastifyComponent";
import {
    LeaveTypes,
    InputStatus,
    InputMonth,
    Constant,
    LeaveTypeCatogary,
    InputStatusCode,
    EmpValueByCode,
    getDDMMYYYFormat,
    Capitalize,
    ServiceName,
} from "../../constant";
import { IState } from "../../App";
import "./leaveDetails.scss";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import ApprovalDetails from "../ApprovalDetails";
import NoLeave from "../../assets/img/NoLeave.svg";
import { KeyObject } from "crypto";
import ServicePermission from "../HOC/ServicePermission";

interface ILeaveDetailsProps {
    QueryData: IState;
}

interface IFillterLeave {
    type: { header: string; name: string; code: string };
    status: { header: string; name: string; code: string };
    month: number;
}

const LeaveDetails: React.FC<ILeaveDetailsProps> = ({ QueryData }) => {
    const [fillterLeave, setFillterLeave] = React.useState<IFillterLeave>({
        type: { header: "All", name: "All", code: "All" },
        month: new Date().getMonth() + 1,
        status: { header: "All", name: "All", code: "All" },
    });
    // const [leaveTypes, setLeaveTypes] = React.useState<any>([])
    // const [leaveStatus, setLeaveStatus] = React.useState<any>([])
    const [dialogOpen, setdialogOpen] = React.useState<{
        isOpen: boolean;
        itemData: any;
    }>({
        isOpen: false,

        itemData: {},
    });
    const [refresh, setRefresh] = React.useState<number>(0);
    const [employeeData, setEmployeeData] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const accessToken = state.authData.userAuth.token;
    const empCode =
        EmpValueByCode[
        state.authData.userAuth.comp_code as keyof typeof EmpValueByCode
        ];

    const leaveStatus =
        state.leaveData.leaveMaster.isSuccess &&
            state.leaveData.leaveMaster.status_code === "200"
            ? state.leaveData.leaveMaster.data.leave_status.map((item, index) => {
                return {
                    header: Capitalize(item.value),
                    name: Capitalize(item.value),
                    code: item.code,
                };
            })
            : [];

    const leaveTypes =
        state.leaveData.leaveMaster.isSuccess &&
            state.leaveData.leaveMaster.status_code === "200"
            ? state.leaveData.leaveMaster.data.leave_category.map((item, index) => {
                return {
                    header: Capitalize(item.value),
                    name: Capitalize(item.value),
                    code: item.code,
                };
            })
            : [];

    const getMonthChangeHandler = (item: string) => {
        const monthIndex = InputMonth.indexOf(item);
        setFillterLeave({ ...fillterLeave, month: monthIndex + 1 });
    };

    const TableHeader = [
        {
            content: <Text size="medium" content="Action" weight="semibold" />,
            key: "action",
            styles: {
                justifyContent: "center",
                margin: "0px",
                minWidth: "40px",
                maxWidth: "80px",
            },
        },
        {
            content: (
                <Text size="medium" content="Leave Category" weight="semibold" />
            ),
            key: "lc",
            styles: {
                justifyContent: "start",
                margin: "0px",
                minWidth: "120px",
                maxWidth: "220px",
            },
        },
        {
            content: <Text size="medium" content="From Date" weight="semibold" />,
            key: "fd",
            styles: {
                justifyContent: "start",
                margin: "0px",
                minWidth: "100px",
                maxWidth: "160px",
            },
        },
        {
            content: <Text size="medium" content="To Date" weight="semibold" />,
            key: "td",
            styles: {
                justifyContent: "start",
                margin: "0px",
                minWidth: "100px",
                maxWidth: "160px",
            },
        },
        {
            content: <Text size="medium" content="Status" weight="semibold" />,
            key: "status",
            styles: {
                justifyContent: "start",
                minWidth: "100px",
                margin: "0px",
            },
        },
    ];

    const rowsPlain =
        state.leaveData.leaveHistory.isSuccess &&
            state.leaveData.leaveHistory.status_code === "200" &&
            state.leaveData.leaveHistory.data.leave_requests.length > 0
            ? state.leaveData.leaveHistory.data.leave_requests.map((item, index) => {
                return {
                    key: index,
                    items: [
                        {
                            content:
                                item.leave_status_code === "3" ||
                                    item.leave_status_code === "9" ? (
                                    <TrashCanIcon disabled />
                                ) : (
                                    <TrashCanIcon
                                        onClick={() => onDeleteHandler(item)}
                                        style={{ cursor: "pointer" }}
                                    />
                                ),
                            key: index + "1",

                            styles: {
                                justifyContent: "center",
                                minWidth: "40px",
                                maxWidth: "80px"
                            },
                            accessibility: gridCellWithFocusableElementBehavior,
                            onClick: (e: any) => e.stopPropagation(),
                        },
                        {
                            content: item.leave_category,
                            key: index + "2",
                            styles: {
                                justifyContent: "start",
                                minWidth: "120px",
                                maxWidth: "220px"
                            },
                        },
                        {
                            content: getDDMMYYYFormat(item.from_date),
                            key: index + "3",
                            styles: {
                                justifyContent: "start",
                                minWidth: "100px",
                                maxWidth: "160px"
                            },
                        },
                        {
                            content: getDDMMYYYFormat(item.to_date),
                            key: index + "4",
                            styles: {
                                justifyContent: "start",
                                minWidth: "100px",
                                maxWidth: "160px"
                            },
                        },
                        {
                            key: "index +5",
                            content: item.leave_status,
                            styles: {
                                justifyContent: "start",
                                minWidth: "100px",

                            },
                        },
                    ],
                    onClick: (record: any) => {
                        if (!QueryData.isTaskModuleActive) {
                            setOpen(true);
                            setEmployeeData(item);
                        }
                    },
                    styles: { cursor: "pointer" },
                };
            })
            : [];

    const onDeleteHandler = (item?: any) => {
        dialogOpen.isOpen
            ? setdialogOpen({ isOpen: false, itemData: {} })
            : setdialogOpen({ isOpen: true, itemData: item });
    };

    const onDialogConfirmHandler = (item: any) => {
        const deletePayload: LeaveAppliedAction.ILeaveDeleteHistoryPayload = {
            emp_id: QueryData.employeeId as string,
            leave_request_id: item.leave_request_id,
            from_date: item.from_date,
            to_date: item.to_date,
            leave_status: item.leave_status_code,
            approver_email: state.userInfo.userManagerData.manager_details[0]?.Imailid,
            emp_name: state.userInfo.userManagerData.user_details[0].CompName,
            leave_type_code: item.leave_type_code,
            leave_category_code: item.leave_category_code,
            approver: state.userInfo.userManagerData.manager_details[0]?.Perno,
            reason: item.reason,
            pa: state.userInfo.userManagerData.user_details[0].PersArea,
            psa: state.userInfo.userManagerData.user_details[0].PSubarea,
            emp_company_code: state.authData.userAuth.comp_code,
            manager_company_code: state.userInfo.userManagerData.manager_details[0]?.CompCode ?? ""
        };
        dispatch(
            LeaveAppliedAction.postLeaveDeleteHistoryAction(
                accessToken,
                empCode,
                deletePayload
            )
        );
    };

    React.useEffect(() => {
        if (
            state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" &&
            state.userInfo.userManagerData.isSuccess
        ) {
            const historyPayload: LeaveAppliedAction.ILeaveHistoryPayload = {
                emp_id: QueryData.employeeId as string,
                leave_category_code: fillterLeave.type.code,
                leave_status_code: fillterLeave.status.code,
                filter_type: "month",
                month:
                    fillterLeave.month < 10
                        ? "0" + fillterLeave.month.toString()
                        : fillterLeave.month.toString(),
                pa: state.userInfo.userManagerData.user_details[0].PersArea,
                psa: state.userInfo.userManagerData.user_details[0].PSubarea,
            };
            dispatch(
                LeaveAppliedAction.getLeaveHistoryAction(
                    accessToken,
                    empCode,
                    historyPayload
                )
            );
        }
    }, [
        state.userInfo.userManagerData.isSuccess &&
        state.userInfo.userManagerData.status_code === "200",
        fillterLeave,
        refresh,
    ]);

    React.useEffect(() => {
        if (
            state.leaveData.postLeaveDelete.isSuccess &&
            state.leaveData.postLeaveDelete.status_code === "200" &&
            dialogOpen.isOpen
        ) {
            SuccessToast(state.leaveData.postLeaveDelete.message);
            onDeleteHandler();
            setRefresh(refresh + 1);
        } else if (
            state.leaveData.postLeaveDelete.isSuccess &&
            state.leaveData.postLeaveDelete.status_code !== "200" &&
            dialogOpen.isOpen
        ) {
            ErrorToast(state.leaveData.postLeaveDelete.message);
            onDeleteHandler();
        }
    }, [state.leaveData.postLeaveDelete.isSuccess]);

    // React.useEffect(() => {
    //     if (state.leaveData.leaveMaster.isSuccess && state.leaveData.leaveMaster.data.leave_category) {
    //         const leaveTypes = []
    //         for (let i in state.leaveData.leaveMaster.data.leave_category) {
    //             leaveTypes.push(state.leaveData.leaveMaster.data.leave_category[i].value)
    //         }
    //         setLeaveTypes(leaveTypes)
    //     }
    // }, [state.leaveData.leaveMaster.isSuccess])

    // React.useEffect(() => {
    //     const leaveStatus = []
    //     if (state.leaveData.leaveMaster.isSuccess && state.leaveData.leaveMaster.data.leave_status) {
    //         for (let i in state.leaveData.leaveMaster.data.leave_status) {
    //             leaveStatus.push(state.leaveData.leaveMaster.data.leave_status[i].value)
    //         }
    //     }
    //     setLeaveStatus(leaveStatus)
    // }, [state.leaveData.leaveMaster.isSuccess])

    return (
        <React.Fragment>
            <ServicePermission service_name={[ServiceName.LEAVE_HISTORY]} showReason={true}>
                <>
                    <Box className="LeaveDetail">
                        <Flex
                            gap="gap.large"
                            padding="padding.medium"
                            styles={{ padding: "1.5rem 1rem" }}
                            column={state.appConfig.dimension.deviceWidth >= 599 ? false : true}
                        >
                            <FlexItem grow>
                                <Dropdown
                                    fluid
                                    items={InputMonth}
                                    defaultValue={InputMonth[fillterLeave.month - 1]}
                                    inverted
                                    className="inputBody"
                                    onChange={(e: any, val: any) => getMonthChangeHandler(val.value)}
                                />
                            </FlexItem>
                            <FlexItem grow>
                                <Dropdown
                                    key="1"
                                    items={leaveTypes}
                                    clearable={fillterLeave.type.name === "All" ? false : true}
                                    placeholder={"All Leave Type"}
                                    fluid
                                    inverted
                                    // defaultValue={fillterLeave.type}
                                    className="inputBody"
                                    onChange={(e: any, val: any) =>
                                        val.value === null
                                            ? setFillterLeave({
                                                ...fillterLeave,
                                                type: { header: "All", name: "All", code: "All" },
                                            })
                                            : setFillterLeave({ ...fillterLeave, type: val.value })
                                    }
                                />
                            </FlexItem>
                            <FlexItem grow>
                                <Dropdown
                                    fluid
                                    items={leaveStatus}
                                    clearable={fillterLeave.status.name === "All" ? false : true}
                                    placeholder={"All Leave Status"}
                                    inverted
                                    className="inputBody"
                                    defaultValue={
                                        fillterLeave.status.name === "All"
                                            ? null
                                            : fillterLeave.status.name
                                    }
                                    onChange={(e: any, val: any) =>
                                        val.value === null
                                            ? setFillterLeave({
                                                ...fillterLeave,
                                                status: { header: "All", name: "All", code: "All" },
                                            })
                                            : setFillterLeave({ ...fillterLeave, status: val.value })
                                    }
                                />
                            </FlexItem>
                        </Flex>
                        <Box className="box-table" styles={QueryData.isTaskModuleActive ? { maxHeight: "calc(100vh - 85px)" } : { maxHeight: "calc(100vh - 146px)" }}>
                            {state.leaveData.leaveHistory.isSuccess &&
                                state.leaveData.leaveHistory.status_code === "200" &&
                                state.leaveData.leaveHistory?.data.leave_requests.length > 0 ? (
                                <Table
                                    role="gridcell"
                                    header={{
                                        items: TableHeader,
                                        header: true,
                                        className: "tableHead",
                                        styles: ({ theme: { siteVariables } }) => ({
                                            background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                            "&:hover": {
                                                background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                                            }
                                        })
                                    }}
                                    rows={rowsPlain}
                                />
                            ) : state.leaveData.leaveHistory.isSuccess &&
                                state.leaveData.leaveHistory.status_code === "200" &&
                                state.leaveData.leaveHistory?.data.leave_requests.length === 0 ? (
                                <Flex vAlign="center" hAlign="center">
                                    <DataNotFound img={NoLeave} />
                                </Flex>
                            ) : state.leaveData.leaveHistory.isSuccess &&
                                state.leaveData.leaveHistory.status_code !== "200" ? (
                                <Flex vAlign="center" hAlign="center">
                                    <ErrorComponent message={state.leaveData.leaveHistory.message} />
                                </Flex>
                            ) : (
                                // <Flex vAlign='center' hAlign='center'>
                                //     <Loader size="large" />
                                // </Flex>
                                <Skeleton
                                    animation="pulse"
                                //styles={{margin:'10px'}}
                                >
                                    <Flex gap="gap.small">
                                        <Skeleton styles={{ margin: "10px" }}>
                                            <Flex gap="gap.medium" column>
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                                <Skeleton.Text />
                                            </Flex>
                                        </Skeleton>
                                    </Flex>
                                </Skeleton>
                            )}{" "}
                        </Box>
                        <Dialog
                            open={dialogOpen.isOpen}
                            header="Confirm Delete"
                            headerAction={{
                                icon: <CloseIcon />,
                                title: "Close",
                                onClick: () => onDeleteHandler(),
                            }}
                            overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none", paddingBlock: "5rem" } : {} }}
                            content="Are you sure you want to confirm this action?"
                            confirmButton={{
                                content: "Submit",
                                loading: state.leaveData.postLeaveDelete.isLoading ? true : false,
                            }}
                            styles={QueryData.isTaskModuleActive ? { width: "500px", boxShadow: "5px 5px 5px 5px #888888" } : {}}
                            cancelButton="Cancel"
                            onConfirm={() => onDialogConfirmHandler(dialogOpen.itemData)}
                            onCancel={() => onDeleteHandler()}
                        />
                    </Box>
                    {open && (
                        <ApprovalDetails
                            setOpen={setOpen}
                            open={open}
                            employeeData={{ ...employeeData, ...{ requestor_name: state.userInfo.userManagerData.user_details[0].CompName } }}
                        />
                    )}
                </>
            </ServicePermission>
        </React.Fragment>
    );
};
export default LeaveDetails;
