import {
    Actions,
    ILeaveValidate,
    ILeave,
    ILeaveQuota,
    ILeaveDeleteHistoryList,
    ILeaveHistoryList,
    IMasterList,
    ILeaveRequestApprovalHistoryList,
    ILeaveRequestApprovalList,
    IDeleteLeaveRequestApprovalList,
    IGetUpcomingHolidayList,
    IGetActiveYearData,
    IGetEmployeeUsingGraph
} from "../actions/LeaveActions";
import { combineReducers } from "redux";

export const postValidLeaveReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_VALID_LEAVE_LOADING:
        case Actions.POST_VALID_LEAVE_ERROR:
        case Actions.POST_VALID_LEAVE_SUCCESS:
        case Actions.POST_VALID_LEAVE_CLEAR_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export const postLeaveReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_CREATE_LEAVE_DATA_LOADING:
        case Actions.POST_CREATE_LEAVE_DATA_ERROR:
        case Actions.POST_CREATE_LEAVE_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getQuotaLeaveReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_QUOTA_LEAVE_LOADING:
        case Actions.GET_QUOTA_LEAVE_ERROR:
        case Actions.GET_QUOTA_LEAVE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export const getLeaveHistoryReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_LEAVE_HISTORY_LOADING:
        case Actions.GET_LEAVE_HISTORY_ERROR:
        case Actions.GET_LEAVE_HISTORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export const postLeaveDeleteHistoryReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_LEAVE_DELETE_HISTORY_LOADING:
        case Actions.POST_LEAVE_DELETE_HISTORY_ERROR:
        case Actions.POST_LEAVE_DELETE_HISTORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export const getLeaveMasterReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_LEAVE_MASTER_LOADING:
        case Actions.GET_LEAVE_MASTER_ERROR:
        case Actions.GET_LEAVE_MASTER_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export const getLeaveApprovalHistoryReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_LEAVE_APPROVAL_HISTORY_LOADING:
        case Actions.GET_LEAVE_APPROVAL_HISTORY_ERROR:
        case Actions.GET_LEAVE_APPROVAL_HISTORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export const postLeaveApprovalReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_LEAVE_APPROVAL_LOADING:
        case Actions.POST_LEAVE_APPROVAL_ERROR:
        case Actions.POST_LEAVE_APPROVAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.POST_PUT_CLEAR_REDUCER_DATA:
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};

export const postDeleteLeaveApprovalReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_DELETE_LEAVE_APPROVAL_LOADING:
        case Actions.POST_DELETE_LEAVE_APPROVAL_ERROR:
        case Actions.POST_DELETE_LEAVE_APPROVAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.POST_PUT_CLEAR_REDUCER_DATA:
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getTeamMemberReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_TEAM_MEMBER_LOADING:
        case Actions.GET_TEAM_MEMBER_ERROR:
        case Actions.GET_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getEmployeeIdReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_EMP_ID_FROM_GRAPH_LOADING:
        case Actions.GET_EMP_ID_FROM_GRAPH_ERROR:
        case Actions.GET_EMP_ID_FROM_GRAPH_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getUpcomingHolidayReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_UPCOMING_HOLIDAY_LOADING:
        case Actions.GET_UPCOMING_HOLIDAY_ERROR:
        case Actions.GET_UPCOMING_HOLIDAY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getActiveYearReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ACTIVE_YEAR_LOADING:
        case Actions.GET_ACTIVE_YEAR_ERROR:
        case Actions.GET_ACTIVE_YEAR_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export interface ILeaveData {
    vaildLeave: ILeaveValidate;
    applyLeave: ILeave;
    leaveQuota: ILeaveQuota;
    leaveHistory: ILeaveHistoryList;
    postLeaveDelete: ILeaveDeleteHistoryList;
    leaveMaster: IMasterList;
    leaveApprovalHistory: ILeaveRequestApprovalHistoryList;
    leaveApproval: ILeaveRequestApprovalList;
    deleteLeaveApproval: IDeleteLeaveRequestApprovalList;
    upcomingHoliday: IGetUpcomingHolidayList;
    teamMember: any;
    employeeData: IGetEmployeeUsingGraph;
    getActiveYear: IGetActiveYearData
}
const leaveData = combineReducers({
    vaildLeave: postValidLeaveReducer,
    applyLeave: postLeaveReducer,
    leaveQuota: getQuotaLeaveReducer,
    leaveHistory: getLeaveHistoryReducer,
    leaveMaster: getLeaveMasterReducer,
    postLeaveDelete: postLeaveDeleteHistoryReducer,
    leaveApprovalHistory: getLeaveApprovalHistoryReducer,
    leaveApproval: postLeaveApprovalReducer,
    deleteLeaveApproval: postDeleteLeaveApprovalReducer,
    teamMember: getTeamMemberReducer,
    employeeData: getEmployeeIdReducer,
    upcomingHoliday: getUpcomingHolidayReducer,
    getActiveYear: getActiveYearReducer
});
export default leaveData;
