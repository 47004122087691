import { Box, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import "./dnf.scss";
import dnf from "../../assets/img/NoLeave.svg";

interface IDNF {
    size?: string
    height?: string;
    width?: string;
    message?: string
    img?: any;
}

const DataNotFound: React.FC<IDNF> = ({ size, height, width, message, img }) => {
    return (
        <Flex className='DataNotFound' gap="gap.medium" column vAlign='center' hAlign='center' fill style={{ minHeight: "100%", padding: "2rem" }}>
            <Box className='dnf-box'>
                <Flex fill vAlign='center' hAlign='center'>
                    <Image
                        src={img ? img : dnf}
                        height={height ? height : "200px"}
                        width={width ? width : "200px"}
                    />
                </Flex>
            </Box>
            <Text content={message ? message : "Data Not Found"} weight='semibold' styles={{ fontSize: size ? size : "20px", textAlign: "center" }} />
        </Flex>
    );
};

export default DataNotFound;