import { Actions, IGetUserToken, IGetManagerToken, IGetAzAccessToken, IGetNFAUserAccessToken } from "../actions/AuthActions";
import { combineReducers } from "redux";

const getUserTokenReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_VALID_USER_TOKEN_LOADING:
        case Actions.GET_VALID_USER_TOKEN_ERROR:
        case Actions.GET_VALID_USER_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

const getManagerTokenReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_VALID_MANAGER_TOKEN_LOADING:
        case Actions.GET_VALID_MANAGER_TOKEN_ERROR:
        case Actions.GET_VALID_MANAGER_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

const getAzAccessTokenReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_VALID_AZ_ACCESS_TOKEN_LOADING:
        case Actions.GET_VALID_AZ_ACCESS_TOKEN_ERROR:
        case Actions.GET_VALID_AZ_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

const getNFAUserTokenReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_NFA_USER_VALID_TOKEN_LOADING:
        case Actions.GET_NFA_USER_VALID_TOKEN_ERROR:
        case Actions.GET_NFA_USER_VALID_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export interface IAuthData {
    userAuth: IGetUserToken;
    managerAuth: IGetManagerToken;
    azAccessAuth: IGetAzAccessToken;
    nfaUserAuth: IGetNFAUserAccessToken;
}

const authData = combineReducers({
    userAuth: getUserTokenReducer,
    managerAuth: getManagerTokenReducer,
    azAccessAuth: getAzAccessTokenReducer,
    nfaUserAuth: getNFAUserTokenReducer
});

export default authData;
