/* eslint-disable indent */
import React from "react";
import {
    Dialog, Button, Flex, Text, CloseIcon, TextArea, Header, Divider, ArrowLeftIcon, Pill, Box, Grid, Loader, Input, SkeletonShape, Skeleton, SkeletonText, SkeletonInput, CalendarIcon
} from "@fluentui/react-northstar";
import * as ITSMAction from "../../redux/actions/ITSMAction";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import "./ITSMApprovalDetails.scss";
import { IState } from "../../App";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import * as NotificationAction from "../../redux/actions/NotificationAction";
import moment from "moment";
import { IRequestApprovals } from "../../container/ITSMApprovals";
import { getAppInsights } from "./../../telemetry/telemetryService";
import { TraceEvent } from "../../constant";
interface IpopupProps {
    employeeData: IRequestApprovals;
    QueryData: IState;
    setOpen: (a: boolean) => void;
    open: boolean;
}

interface ISeqActive {
    isActive: boolean;
    seqData?: {
        apprApprover: string;
        apprComment: string;
        apprCreatedBy: string;
        apprCreatedOn: string;
        apprDescription: string;
        apprIdNumber: string;
        apprModule: string;
        apprQueue: string;
        apprRecordId: string;
        apprRequester: string;
        apprSequence: number;
        apprStatus: string;
        approvedBy: string;
        approvedOn: string;
    }
}

const initialEmptyData = {
    apprApprover: "",
    apprComment: "",
    apprCreatedBy: "",
    apprCreatedOn: "",
    apprDescription: "",
    apprIdNumber: "",
    apprModule: "",
    apprQueue: "",
    apprRecordId: "",
    apprRequester: "",
    apprSequence: 0,
    apprStatus: "",
    approvedBy: "",
    approvedOn: ""
};

const ITSMApprovalDetails: React.FC<IpopupProps> = ({
    QueryData,
    employeeData,
    setOpen,
}) => {
    const { appInsights, trackEvent } = getAppInsights();
    const [seqActive, setSeqActive] = React.useState<ISeqActive>({
        isActive: false
    });
    const [approvalComment, setApprovalComment] = React.useState<string>("");
    const [isConfirmation, setIsConfirmation] = React.useState<{ isOpen: boolean, isAction: string }>({ isOpen: false, isAction: "" });
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);

    const approvalData = state.itsmData.getTicketApprovalList.isSuccess ? state.itsmData.getTicketApprovalList.approverList.filter((item: any) => {
        return item.apprSequence === employeeData.apprSequence;
    })[0] : initialEmptyData;

    const onConfirmationDialogClose = () => {
        setIsConfirmation({ ...isConfirmation, isOpen: false });
    };

    const onApproveRejectHandler = (status: string) => {
        const payload: ITSMAction.IUpdateApprovalPayload = {
            ticketIdNumber: employeeData.apprIdNumber,
            notes: approvalComment,
            status: status,
            loggedInUsername: QueryData.userId as string,
        };
        dispatch(ITSMAction.updateApprovalDataAction(state.itsmData.getTokenData.access_token, payload));
    };

    function onSeqDialogClose() {
        setSeqActive({ isActive: false });
    }

    const pillClickHandler = (sequenceOf: any) => {
        if (sequenceOf !== employeeData.apprSequence) {
            const data = state.itsmData.getTicketApprovalList.approverList.filter((item: any) => {
                return item.apprSequence === sequenceOf;
            })[0];
            setSeqActive({ isActive: true, seqData: data });
        }
    };

    React.useEffect(() => {
        if (state.itsmData.getTokenData.isSuccess) {
            const payload: ITSMAction.ITicketApprovalPayload = {
                ticketIdNumber: employeeData.apprRecordId
            };
            dispatch(ITSMAction.getTicketApprovalDataAction(state.itsmData.getTokenData.access_token, payload));
        }
    }, [state.itsmData.getTokenData.isSuccess]);

    React.useEffect(() => {
        if (state.itsmData.updateApproval.isError && approvalComment !== "") {
            ErrorToast(state.itsmData.updateApproval.message);
        } else if (state.itsmData.updateApproval.isSuccess && approvalComment !== "" && state.itsmData.updateApproval.ticketId === "") {
            ErrorToast(state.itsmData.updateApproval.message);
        } else if (state.itsmData.updateApproval.isSuccess && approvalComment !== "" && state.itsmData.updateApproval.ticketId !== "") {
            SuccessToast("Ticket id: " + state.itsmData.updateApproval.ticketId + " approval updated");
            setApprovalComment("");
            onConfirmationDialogClose();
            setOpen(false);
            const payload = {
                type: "manager",
                ticketType: isConfirmation.isAction === "APPROVED" ? "Approved" : "Rejected",
                empId: QueryData.employeeId as string,
                // userObjId: "e1767bb0-48f1-4464-ade0-0696c7c447df",
                userObjId: QueryData.userObjectId,
                cardDetails: {
                    ticketId: state.itsmData.updateApproval.ticketId,
                    apprDate: moment().format("DD-MM-YYYY"),
                    reqName: employeeData.apprRequester
                },
                env: process.env.REACT_APP_ENV === "production" ? null : QueryData.app_env === "staging" ? QueryData.app_env : "dev"
            };
            trackEvent(appInsights, isConfirmation.isAction === "APPROVED" ? TraceEvent.ITSM_IMAC_APPROVE : TraceEvent.ITSM_IMAC_REJECT, payload);
            dispatch(NotificationAction.postNotificationDataAction(payload));
        }
    }, [state.itsmData.updateApproval.isSuccess, state.itsmData.updateApproval.isError]);

    return (
        <React.Fragment>
            <Flex column >
                <Flex gap="gap.medium" vAlign="center" padding="padding.medium">
                    <ArrowLeftIcon
                        styles={({ theme: { siteVariables } }) => ({ color: siteVariables.colorScheme.brand.foreground, cursor: "pointer", paddingLeft: "0.5rem" })}
                        onClick={() => setOpen(false)}
                    />
                    <Text
                        size="large"
                        weight="bold"
                        content={employeeData.apprIdNumber}
                        color="brand"
                    />
                </Flex>
                <Divider fitted />
                <Flex column styles={{ height: "calc(100vh - 126px)", overflow: "auto" }}>
                    {state.itsmData.getTicketApprovalList.isSuccess && state.itsmData.getTicketApprovalList.approverList.length > 0 ?
                        <Flex column gap="gap.smaller" padding="padding.medium">
                            <Flex column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} vAlign="center" hAlign="center" space="evenly" styles={{ padding: "1rem 2.5rem" }} fill>
                                {/* {pillData} */}
                                {state.itsmData.getTicketApprovalList.isSuccess &&
                                    state.itsmData.getTicketApprovalList.approverList.map((item, index, array) => {
                                        const showBreak = index !== array.length - 1;
                                        return (
                                            <Flex fill={showBreak} vAlign="center" hAlign="center" key={item.apprSequence}>
                                                <Flex column vAlign="center" hAlign="center" >
                                                    <Pill
                                                        key={item.apprSequence}
                                                        styles={employeeData.apprSequence == item.apprSequence ? {
                                                            background: "#009E73",
                                                            minWidth: "unset",
                                                            cursor: "pointer",
                                                            color: "#fff",
                                                            fontWeight: "bold",
                                                            "&:hover": {
                                                                color: "black"
                                                            }
                                                        } : {
                                                            minWidth: "unset",
                                                            cursor: "pointer",
                                                        }}
                                                        content={{
                                                            content: "Approver-" + (index + 1),
                                                            styles: {
                                                                padding: "0rem 1rem",
                                                                whiteSpace: "nowrap",
                                                            },
                                                        }}
                                                        appearance="outline"
                                                        onClick={() => {
                                                            pillClickHandler(item.apprSequence);
                                                        }}
                                                        disabled={item.apprSequence > employeeData.apprSequence}
                                                    />
                                                    <Text content={item.apprApprover} weight="semibold" styles={{ whiteSpace: "nowrap" }} />
                                                    {item.apprSequence < employeeData.apprSequence && <Flex vAlign="center" hAlign="center" gap="gap.smaller"><Text content={item?.approvedOn !== null ? moment(item?.approvedOn).format("DD-MM-YYYY") : ""} styles={{ whiteSpace: "nowrap" }} /> <CalendarIcon /></Flex>}
                                                </Flex>
                                                {showBreak && state.appConfig.dimension.deviceWidth >= 600 && <Divider styles={{ width: "100%" }} />}
                                            </Flex>
                                        );
                                    })
                                }
                            </Flex>
                            <Header as="h2" color="brand" content="Approver Details" styles={{ margin: 0, fontSize: "18px", padding: 0 }} />
                            <Divider fitted />
                            <Flex column gap="gap.small" padding="padding.medium" space="evenly" >
                                <Flex column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" fill>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Approval For" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={approvalData?.apprRequester} />
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Status" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={approvalData?.apprStatus} />
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Approval Group" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={approvalData?.apprQueue} />
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Approver" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={approvalData?.apprApprover} />
                                    </Flex>
                                </Flex>
                                <Flex column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" fill>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Approval Date*" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={moment(approvalData?.apprCreatedOn).format("DD-MM-YYYY")} />
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Sequence of" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={approvalData?.apprSequence.toString()} />
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Authorised By" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={approvalData?.approvedBy} />
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" fill>
                                        <Text content="Authorised On" weight="semibold" />
                                        <Input readOnly
                                            inverted
                                            fluid
                                            className="inputBody"
                                            value={approvalData?.approvedOn !== null ? moment(approvalData?.approvedOn).format("DD-MM-YYYY") : ""} />
                                    </Flex>
                                </Flex>
                                <Flex column gap="gap.small" padding="padding.medium">
                                    <Text weight="semibold" > {"Approval Comment "}<Text content="*" color="red" /></Text>
                                    <TextArea
                                        className="inputBody"
                                        inverted
                                        placeholder="Type Description...."
                                        required
                                        fluid
                                        rows={4}
                                        onChange={(_e: any, val: any) => setApprovalComment(val.value)}
                                        value={approvalComment}
                                    />
                                </Flex>
                                <Flex column gap="gap.smaller" padding="padding.medium">
                                    <Text content="Comment*" weight="semibold" />
                                    <Text color='grey' dangerouslySetInnerHTML={{
                                        __html: employeeData.apprDescription
                                    }} />
                                </Flex>
                            </Flex>
                        </Flex> :
                        <Skeleton animation='pulse'>
                            <Flex column gap="gap.smaller" padding="padding.medium">
                                <Flex vAlign="center" hAlign="center" space="evenly" gap="gap.smaller" styles={{ padding: "1rem 2.5rem" }} fill>
                                    {[1, 2, 3, 4, 5].map((item, index, array) => {
                                        const showBreak = index !== array.length - 1;
                                        return (
                                            <Flex fill={showBreak} vAlign="center" key={item} gap="gap.smaller">
                                                <SkeletonShape height="2rem" styles={{ borderRadius: "20px", maxWidth: "80px" }} />
                                                {showBreak && <Divider styles={{ width: "100%" }} />}
                                            </Flex>
                                        );
                                    })
                                    }
                                </Flex>
                                <SkeletonShape width="150px" height="1.6rem" />
                                <Divider fitted />
                                <Flex column gap="gap.small" padding="padding.medium" space="evenly">
                                    <Flex gap="gap.small" fill>
                                        {[1, 2, 3, 4].map((item) => {
                                            return (
                                                <Flex key={item} column gap="gap.small" padding="padding.medium" fill>
                                                    <SkeletonShape width="100px" height="1.2rem" />
                                                    <SkeletonInput fluid />
                                                </Flex>
                                            );
                                        })}
                                    </Flex>
                                    <Flex gap="gap.small" fill>
                                        {[1, 2, 3, 4].map((item) => {
                                            return (
                                                <Flex key={item} column gap="gap.small" padding="padding.medium" fill>
                                                    <SkeletonShape width="100px" height="1.2rem" />
                                                    <SkeletonInput fluid />
                                                </Flex>
                                            );
                                        })}
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex column gap="gap.small" padding="padding.medium">
                                <SkeletonShape width="150px" height="1.4rem" />
                                <SkeletonShape width="100%" height="4rem" />
                            </Flex>
                            <Flex column gap="gap.smaller" padding="padding.medium">
                                <SkeletonShape width="150px" height="1.4rem" />
                                <SkeletonShape width="100%" height="4rem" />
                            </Flex>
                        </Skeleton>
                    }
                </Flex>
            </Flex >
            <Divider fitted />
            <Flex gap="gap.medium" hAlign="end" padding="padding.medium">
                <Button
                    content="Reject"
                    disabled={approvalComment.length === 0}
                    onClick={() => setIsConfirmation({ ...isConfirmation, isOpen: true, isAction: "REJECTED" })}
                />
                <Button
                    disabled={approvalComment.length === 0}
                    content="Approve"
                    primary
                    onClick={() => setIsConfirmation({ ...isConfirmation, isOpen: true, isAction: "APPROVED" })}
                />
            </Flex>
            <Dialog
                open={isConfirmation.isOpen}
                onCancel={() => onConfirmationDialogClose()}
                cancelButton="Cancel"
                confirmButton={{
                    content: "Confirm",
                    loading: state.itsmData.updateApproval.isLoading
                }}
                onConfirm={() => onApproveRejectHandler(isConfirmation.isAction)}
                header="Confirmation"
                content={"Are you sure you want to " + (isConfirmation.isAction === "APPROVED" ? "approve?" : "reject?")}
            />
            <Dialog
                overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none", paddingBlock: "5rem" } : {} }}
                styles={{ boxShadow: QueryData.isTaskModuleActive ? "5px 5px 5px 5px #888888" : "unset", minWidth: "calc(100vw - 500px)", maxHeight: "calc(100vh - 100px)", padding: "0.7rem 0" }}
                header={{
                    content: `Approval Details of Sequence ${seqActive?.seqData?.apprSequence}`,
                    styles: { padding: "0rem 1rem" },
                }}
                headerAction={{
                    icon: <CloseIcon />,
                    title: "Close",
                    styles: { padding: "0rem 1.5rem" },
                    onClick: () => onSeqDialogClose(),
                }}
                open={seqActive.isActive}
                content={<Box>
                    <Divider fitted />
                    <Grid columns={state.appConfig.dimension.deviceWidth >= 600 ? "repeat(4,1fr)" : "repeat(2,1fr)"} styles={{ padding: "0 1rem" }}>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Approval For" weight="semibold" />
                            <Text color="grey" content={`${seqActive?.seqData?.apprRequester}`} />
                        </Flex>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Status" weight="semibold" />
                            <Text color="grey" content={`${seqActive?.seqData?.apprStatus}`} />
                        </Flex>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Approval Group" weight="semibold" />
                            <Text color="grey" content={`${seqActive?.seqData?.apprQueue}`} />
                        </Flex>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Approver" weight="semibold" />
                            <Text color="grey" content={`${seqActive?.seqData?.apprApprover}`} />
                        </Flex>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Approval Date*" weight="semibold" />
                            <Text color="grey" content={moment(seqActive?.seqData?.apprCreatedOn).format("DD-MM-YYYY")} />
                        </Flex>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Sequence of" weight="semibold" />
                            <Text color="grey" content={`${seqActive?.seqData?.apprSequence}`} />
                        </Flex>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Authorised By" weight="semibold" />
                            <Text color="grey" content={`${seqActive?.seqData?.approvedBy}`} />
                        </Flex>
                        <Flex column gap="gap.smaller" padding="padding.medium" fill>
                            <Text content="Authorised On" weight="semibold" />
                            <Text color="grey" content={seqActive?.seqData?.approvedOn !== null ? moment(seqActive?.seqData?.approvedOn).format("DD-MM-YYYY") : ""} />
                        </Flex>
                    </Grid>
                    <Flex column gap="gap.smaller" styles={{ padding: "0.25rem 1.5rem" }}>
                        <Text content="Approval Comment*" weight="semibold" />
                        <TextArea
                            className="inputBody"
                            inverted
                            placeholder="No Approval Comments...."
                            required
                            fluid
                            value={seqActive?.seqData?.apprComment}
                            readOnly
                            rows={3}
                        />
                    </Flex>
                    <Flex column gap="gap.smaller" styles={{ padding: "0.25rem 1.5rem" }}>
                        <Text content="Comment*" weight="semibold" />
                        <Text color='grey' dangerouslySetInnerHTML={{
                            __html: seqActive?.seqData?.apprDescription as string
                        }} />
                    </Flex>
                </Box>}
            />
        </React.Fragment >
    );
};
export default ITSMApprovalDetails;