import { put, call, take, delay } from "redux-saga/effects";
import { httpPost } from "./../../service";
import { API_SECRET_TOKEN, ITSM_BASE_URL, PIN_CONFIG } from "../../config";
import * as actionCreators from "../actions/PinActions";

function* getPinData(payload: {
    emp_id: string;
}) {
    try {
        yield put(actionCreators.getPinDataLoadingAction());
        const { data } = yield httpPost(
            `${ITSM_BASE_URL}${PIN_CONFIG.GET_PIN_DATA}`,
            payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getPinDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getPinDataErrorAction(error));
    }
}

function* createOrUpdatePinData(payload: {
    emp_id: string,
    pin: {
        name: string,
        seq: number
    }[]
}) {
    try {
        yield put(actionCreators.createOrUpdateLoadingDataAction());
        const { data } = yield httpPost(
            `${ITSM_BASE_URL}${PIN_CONFIG.CREATE_OR_UPDATE_PIN_DATA}`,
            payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.createOrUpdateSuccessDataAction(data));
    } catch (error: any) {
        yield put(actionCreators.createOrUpdateErrorDataAction(error));
    }
}

export function* watchGetPinData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_PIN_DATA
        );
        yield call(getPinData, payload);
    }
}

export function* watchCreateOrUpdatePinData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.CREATE_OR_UPDATE_PIN_DATA
        );
        yield call(createOrUpdatePinData, payload);
    }
}