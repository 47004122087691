import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {


    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = () => {

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_INSIGHT_KEY : process.env.REACT_APP_INSIGHT_KEY,
                connectionString: process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_INSIGHT_CONN : process.env.REACT_APP_INSIGHT_CONN,
                maxBatchInterval: 0,
                disableFetchTracking: true,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                    }
                }
            }
        });
        appInsights.loadAppInsights();
    };


    return { reactPlugin, appInsights, initialize };
};



export const ai = createTelemetryService();
export const getAppInsights = () => {
    const trackTrace = (appInsights, message, prop) => {
        appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information, properties: prop });
    };
    const trackException = (appInsights, message, prop) => {
        appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error, properties: prop });
    };
    const trackEvent = (appInsights, eventName, prop) => {
        appInsights.trackEvent({ name: eventName, properties: prop });
    };
    const trackMetrix = (appInsights, metricData, additionalProperties) => {
        appInsights.trackMetric(metricData, additionalProperties);
    };
    return {
        appInsights: appInsights,
        trackTrace: trackTrace,
        trackException: trackException,
        trackEvent: trackEvent,
        trackMetrix: trackMetrix
    };
};
