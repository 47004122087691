import React, { useState, useRef } from "react";
import { Dialog, Button, Flex, Text, FlexItem, CloseIcon, TextArea, Popup, RedbangIcon, Box } from "@fluentui/react-northstar";
import * as ITSMAction from "../../redux/actions/ITSMAction";
import * as NotificationAction from "../../redux/actions/NotificationAction";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import "./IncidentDetails.scss";
import { IState } from "../../App";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import Attachments from "../Attachment";
import { getAppInsights } from "./../../telemetry/telemetryService";
import { TraceEvent } from "../../constant";
interface IpopupProps {
    setOpen: (a: boolean) => void,
    open: boolean,
    employeeData: any,
    QueryData: IState,
    btnClick: boolean,
    setBtnClick: (a: boolean) => void
}

interface IRaiseTicketForm {
    ticketIdNumber: string,
    notes: string,
    loggedInUsername: string
}
const IncidentDetails: React.FC<IpopupProps> = ({ setOpen, open, QueryData, employeeData, btnClick, setBtnClick }) => {
    const { appInsights, trackEvent } = getAppInsights();
    const myRef = useRef<HTMLInputElement>(null);
    const [showMore, setShowMore] = useState(false);
    const [addNotes, setAddNotes] = useState(false);
    const [noteInput, setNoteInput] = React.useState<string>("");
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(open);
    const [file, setFile] = React.useState<any[]>([]);
    const [refresh, setRefresh] = React.useState(0);
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const ValidateButton: boolean = noteInput.trim() !== "";
    const onNotesSubmitHandler = async () => {
        const payload: ITSMAction.IAddNotesPayload = {
            ticketIdNumber: employeeData.ticketIdNumber,
            notes: noteInput,
            loggedInUsername: QueryData.userId as string,
        };
        dispatch(ITSMAction.addNotesDataAction(state.itsmData.getTokenData.access_token, payload));
    };

    const onAddAttchmentSubmit = () => {
        if (file.length > 0) {
            const body = {
                file: file[0],
                ticketIdNumber: employeeData.ticketIdNumber,
                loggedInUsername: QueryData.userId as string
            };
            dispatch(ITSMAction.addAttachmentDataAction(state.itsmData.getTokenData.access_token, body));
        }
    };

    React.useEffect(() => {
        if (state.itsmData.addNotes.isSuccess && noteInput !== "") {
            SuccessToast(state.itsmData.addNotes.message + "  Ticket Id:- " + employeeData.ticketIdNumber);
            if (file.length) {
                onAddAttchmentSubmit();
            } else {
                const payload = {
                    type: "update",
                    userObjId: QueryData.userObjectId as string,
                    empId: QueryData.employeeId as string,
                    cardDetails: {
                        ticketId: employeeData.ticketIdNumber,
                        note: noteInput,
                        attachment: []
                    },
                    env: process.env.REACT_APP_ENV === "production" ? null : QueryData.app_env === "staging" ? QueryData.app_env : "dev"
                };
                trackEvent(appInsights, TraceEvent.ITSM_ADD_NOTE, payload);
                dispatch(NotificationAction.postNotificationDataAction(payload));
                setNoteInput("");
                setDialogOpen(false);
                setOpen(false);
                setBtnClick(false);
                setFile([]);
            }
        } else if (state.itsmData.createTicket.isError && noteInput !== "") {
            ErrorToast(state.itsmData.addNotes.message);
        }
    }, [state.itsmData.addNotes.isSuccess]);

    React.useEffect(() => {
        if (state.itsmData.addAttachment.isSuccess && state.itsmData.addAttachment.ticketId !== "" && file.length > 0) {
            SuccessToast("File is attached with ticket id :- " + state.itsmData.addAttachment.ticketId);
            const payload = {
                type: "update",
                userObjId: QueryData.userObjectId as string,
                empId: QueryData.employeeId as string,
                cardDetails: {
                    ticketId: employeeData.ticketIdNumber,
                    note: noteInput,
                    attachment: [file[0].name]
                },
                env: process.env.REACT_APP_ENV === "production" ? null : QueryData.app_env === "staging" ? QueryData.app_env : "dev"
            };
            trackEvent(appInsights, TraceEvent.ITSM_ATTACH_FILE, payload);
            dispatch(NotificationAction.postNotificationDataAction(payload));
            setNoteInput("");
            setDialogOpen(false);
            setOpen(false);
            setBtnClick(false);
            setFile([]);
        } else if (state.itsmData.addAttachment.isSuccess && state.itsmData.addAttachment.ticketId === "" && file.length > 0) {
            ErrorToast(state.itsmData.addAttachment.message?state.itsmData.addAttachment.message: state.itsmData.addAttachment.Message);
        }
    }, [state.itsmData.addAttachment.isSuccess]);

    React.useEffect(() => {
        if (btnClick && !addNotes) {
            setAddNotes(true);
        }
    }, [btnClick]);

    React.useEffect(() => {
        if (addNotes || showMore) {
            myRef.current?.scrollIntoView();
        }
    }, [addNotes, showMore]);

    function onDialogClose() {
        setOpen(false);
        setBtnClick(false);
    }

    const handleFiles = (files: any) => {
        if (files.length === 1) {
            setFile(files);
        } else {
            setFile([]);
        }
    };

    return (
        <Dialog
            overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none", paddingBlock: "5rem" } : {} }}
            styles={QueryData.isTaskModuleActive ? { boxShadow: "5px 5px 5px 5px #888888" } : {}}
            closeOnOutsideClick={true}
            content={{
                styles: {
                    height: "100%",
                    maxHeight: "360px",
                    overflow: "auto",
                },
                content: (
                    <Flex column gap='gap.smaller' space='between' className="IncidentDetails">
                        <FlexItem grow>
                            <Flex space='between' padding="padding.medium" vAlign="center" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                <Flex gap='gap.smaller'><Text truncated content='Ticket ID: ' weight='bold' /><Text color='grey' content={employeeData.ticketIdNumber} /></Flex>
                                <Flex gap='gap.smaller' vAlign="center"><Text content='Status' weight='bold' /><Text color='grey' content={employeeData.status} className="statusColor" styles={["ASSIGNED", "OPEN"].includes(employeeData.status) ? { borderColor: "#8EA9DB" } : ["CLOSED", "RESOLVED"].includes(employeeData.status) ? { borderColor: "#92D050" } : { borderColor: "#FFCC00" }} /></Flex>
                            </Flex>
                        </FlexItem>
                        <FlexItem grow>
                            <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} wrap><Text truncated content='Category' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.category} /></Flex>
                                <Flex column styles={{ width: "140px" }} ><Text content='Sub Category' weight='bold' /><Text color='grey' content={employeeData.subcategory} /></Flex>
                                <Flex column styles={{ width: "140px" }}><Text content='Item' weight='bold' /><Text color='grey' content={employeeData.item} /></Flex>
                            </Flex>
                        </FlexItem>
                        <FlexItem grow>
                            <Flex space='between' padding="padding.medium">
                                <Flex column><Text content='Short Description' weight='bold' /><Text color='grey' content={employeeData.description} /></Flex>
                            </Flex>
                        </FlexItem>
                        <FlexItem grow>
                            <Flex space='between' padding="padding.medium">
                                <Flex column><Text content='Additional Comments' weight='bold' /><Text color='grey' dangerouslySetInnerHTML={{ __html: employeeData.comments }} /></Flex>
                            </Flex>
                        </FlexItem>
                        {showMore &&
                            <Flex column gap='gap.large' space='between'>
                                <FlexItem grow>
                                    <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                        <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} wrap><Text truncated content='Type' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.company} /></Flex>
                                        <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} wrap><Text truncated content='Company' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.company} /></Flex>
                                        <Flex column styles={{ width: "140px" }}><Text content='VIP' weight='bold' /><Text color='grey' content={employeeData.vip} /></Flex>
                                    </Flex>
                                </FlexItem>
                                <FlexItem grow>
                                    <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                        <Flex column styles={{ width: "140px" }} ><Text content='Creation Time' weight='bold' /><Text color='grey' content={employeeData.creation_time} /></Flex>
                                        <Flex column styles={{ width: "140px" }}><Text content='Priority' weight='bold' /><Text color='grey' content={employeeData.priority} /></Flex>
                                        <Flex column styles={{ width: "140px" }} wrap><Text truncated content='Target Resolve Time' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.target_resolve_time} /></Flex>

                                    </Flex>
                                </FlexItem>
                                <FlexItem grow>
                                    <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                        <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }}><Text content='Assigned Group' weight='bold' /><Text color='grey' content={employeeData.assigned_data} /></Flex>
                                        <Flex column styles={{ width: "140px" }} wrap><Text truncated content='Assigned User' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.assigned_user} /></Flex>
                                        <Flex column styles={{ width: "140px" }} ><Text content='Contact' weight='bold' /><Text color='grey' content={employeeData.contact} /></Flex>
                                    </Flex>
                                </FlexItem>
                                <FlexItem grow>
                                    <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                        <Flex column styles={{ width: "140px", iteSpace: "break-spaces", wordBreak: "break-word" }}><Text content='Location' weight='bold' /><Text color='grey' content={employeeData.location} /></Flex>
                                        <Flex column styles={{ width: "140px" }} ><Text content='Agreed Contact Date' weight='bold' /><Text color='grey' content={employeeData.agreed_contact_date} /></Flex>
                                        <Flex column styles={{ width: "140px" }} ><Text content='Alt Location' weight='bold' /><Text color='grey' content={employeeData.alt_location} /></Flex>
                                    </Flex>
                                </FlexItem>
                                <FlexItem grow>
                                    <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                        <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} wrap><Text truncated content='Customer' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.customer} /></Flex>
                                        <Flex column styles={{ width: "140px" }}><Text content='Channel' weight='bold' /><Text color='grey' content={employeeData.channel} /></Flex>
                                        <Flex column styles={{ width: "140px" }}><Text content='Region' weight='bold' /><Text color='grey' content={employeeData.region} /></Flex>
                                    </Flex>
                                </FlexItem>
                                <FlexItem grow>
                                    <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                        <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} wrap><Text truncated content='SLA Status' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.sla_status} /></Flex>
                                        <Flex column styles={{ width: "140px" }} ><Text content='Last Updated' weight='bold' /><Text color='grey' content={employeeData.last_updated} /></Flex>
                                        <Flex column styles={{ width: "140px" }}><Text content='Reopened Date' weight='bold' /><Text color='grey' content={employeeData.reopened_date} /></Flex>
                                    </Flex>
                                </FlexItem>
                                <FlexItem grow>
                                    <Flex space='between' padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} gap="gap.small" >
                                        <Flex column styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} wrap><Text truncated content='Reopened By' weight='bold' /><Text truncated styles={{ width: "140px", whiteSpace: "break-spaces", wordBreak: "break-word" }} color='grey' content={employeeData.reopened_by} /></Flex>
                                        <Flex column styles={{ width: "140px" }} ><Text content='Dealer Code' weight='bold' /><Text color='grey' content={employeeData.dealer_code} /></Flex>
                                        <Flex column styles={{ width: "140px" }}></Flex>
                                    </Flex>
                                </FlexItem>
                            </Flex>
                        }
                        {addNotes && (<Flex ref={myRef} column gap="gap.small" padding="padding.medium">
                            {employeeData.type !== "Request" && <Flex column gap="gap.small" padding="padding.medium" >
                                <Text content="Add Notes" weight="bold" />
                                <TextArea
                                    className="inputBody"
                                    inverted
                                    placeholder="Add Notes..."
                                    required
                                    fluid
                                    rows={4}
                                    value={noteInput.trimStart()}
                                    onChange={(e: any) => setNoteInput(e.target.value.trimStart())}
                                />
                            </Flex>}
                            <Flex column gap="gap.small" padding="padding.medium">
                                <Flex gap="gap.smaller" vAlign="center">
                                    <Text content="Attachments (Optional)" weight='semibold' styles={{ marginBottom: "0px !important" }} />
                                    {/* <Popup trigger={<Button icon={<RedbangIcon size="small" rotate={180}  />} flat iconOnly />} position="after" align="center" pointing content={{ content: ToolTipContentAttachment(), styles: { maxWidth: "300px" } }} /> */}
                                </Flex>
                                <Attachments handleFiles={handleFiles} maxFiles={1} refresh={refresh} />
                            </Flex>
                        </Flex>)}
                    </Flex>
                )
            }}
            header={<Text content="Ticket Status" size='larger' weight="bold" />}
            headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => onDialogClose(),
            }}
            footer={{
                children: () => {
                    return (
                        <Flex gap="gap.medium" space="between">
                            {!showMore ? <Button content="View More" onClick={() => { setShowMore(true); }} /> : <Button content="Back" onClick={() => { setShowMore(false); }} />}
                            {!addNotes ? <Button content={employeeData.type !== "Request" ? "Add Notes" : "Add Attachments"} primary onClick={() => { setAddNotes(true); }} /> : <Button disabled={employeeData.type !== "Request" ? !ValidateButton : file.length === 0} loading={state.itsmData.addNotes.isLoading || state.itsmData.addAttachment.isLoading} content="Submit" primary onClick={() => employeeData.type !== "Request" ? onNotesSubmitHandler() : onAddAttchmentSubmit()} />}
                        </Flex>
                    );
                },
            }}
            open={dialogOpen}
        />
    );
};

export default IncidentDetails;