import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_PIN_DATA: "GET_PIN_DATA",
    GET_PIN_LOADING: "GET_PIN_LOADING",
    GET_PIN_ERROR: "GET_PIN_ERROR",
    GET_PIN_SUCCESS: "GET_PIN_SUCCESS",

    CREATE_OR_UPDATE_PIN_DATA: "CREATE_OR_UPDATE_PIN_DATA",
    CREATE_OR_UPDATE_PIN_LOADING: "CREATE_OR_UPDATE_PIN_LOADING",
    CREATE_OR_UPDATE_PIN_ERROR: "CREATE_OR_UPDATE_PIN_ERROR",
    CREATE_OR_UPDATE_PIN_SUCCESS: "CREATE_OR_UPDATE_PIN_SUCCESS",
    CREATE_OR_UPDATE_PIN_UPDATE: "CREATE_OR_UPDATE_PIN_UPDATE"
};
export interface IPin {
    name: string,
    seq: number
}

export interface IGetPinData extends SideEffectSchema {
    statusCode: string;
    message: string;
    id: number;
    emp_id: string;
    pin: IPin[];
}

export const getPinDataAction = (
    empId: string
) => {
    return {
        type: Actions.GET_PIN_DATA,
        payload: { empId }
    };
};
export const getPinDataLoadingAction = () =>
    loadingAction(Actions.GET_PIN_LOADING);
export const getPinDataErrorAction = (error: string) =>
    errorAction(Actions.GET_PIN_ERROR, error);
export const getPinDataSuccessAction = (data: any) => {
    const payload: IGetPinData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.GET_PIN_SUCCESS,
        payload: payload
    };
};

export interface ICreateOrUpdatePinData extends SideEffectSchema {
    statusCode: string;
    message: string;
}

export const createOrUpdateDataAction = (
    empId: string,
    pin: IPin[]
) => {
    return {
        type: Actions.CREATE_OR_UPDATE_PIN_DATA,
        payload: { empId, pin }
    };
};
export const createOrUpdateLoadingDataAction = () =>
    loadingAction(Actions.CREATE_OR_UPDATE_PIN_LOADING);
export const createOrUpdateErrorDataAction = (error: string) =>
    errorAction(Actions.CREATE_OR_UPDATE_PIN_ERROR, error);
export const createOrUpdateSuccessDataAction = (data: any) => {
    const payload: ICreateOrUpdatePinData = {
        ...data,
        ...successSideEffectState
    };
    return {
        type: Actions.CREATE_OR_UPDATE_PIN_SUCCESS,
        payload: payload
    };
};

export const createOrUpdateUpdateDataAction = (data: any) => {
    return {
        type: Actions.CREATE_OR_UPDATE_PIN_UPDATE,
        payload: data
    };
};