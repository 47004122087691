import { put, call, take, delay, takeEvery } from "redux-saga/effects";
import * as actionCreators from "../actions/ChannelAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_SECRET_TOKEN, CHANNEL_CONFIG, TATA_SHAREPOINT_BASE_URL, VC_BASE_URL } from "../../config";

function* getChannelData() {
    try {
        yield put(actionCreators.getChannelLoadingAction());
        const { data } = yield httpGet(
            VC_BASE_URL + CHANNEL_CONFIG.GET_CHANNEL_DATA,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getChannelSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getChannelErrorAction(error));
    }
}

function* getVideoLibraryData(payload: { access_token: string; }) {
    try {
        yield put(actionCreators.getVideoLibraryLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                "Content-Type": "application/json",
            },
        };
        const { data } = yield httpGet(
            TATA_SHAREPOINT_BASE_URL + CHANNEL_CONFIG.GET_VIDEO_LIBRARY,
            config
        );
        yield put(actionCreators.getVideoLibrarySuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getVideoLibraryErrorAction(error));
    }
}


export function* watchGetChannelData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_CHANNEL_DATA
        );
        yield call(getChannelData);
    }
}

export function* watchGetVideoLibraryData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_VIDEO_LIBRARY_DATA
        );
        yield call(getVideoLibraryData, payload);
    }
}