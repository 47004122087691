
export interface IIMACForm {
    cate_type: string;
    index: number;
    fields: {
        id: string;
        input_type: string;
        content: string;
        placeholder: string;
        value: string;
        required: boolean;
        isDisabled: boolean;
        className: string,
        regx: string,
        dropItems?: string
    }[]
}

export const IMACForm: IIMACForm[] = [
    {
        "cate_type": "N1",
        "index": 1,
        "fields": [
            {
                "id": "domainId",
                "input_type": "Dropdown",
                "content": "Domain ID",
                "placeholder": "Enter Select Domain ID",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "domainId"
            },
            {
                "id": "managerName",
                "input_type": "Text",
                "content": "Manager Name",
                "placeholder": "Enter Manager Name",
                "value": "",
                "required": true,
                "isDisabled": true,
                "className": "",
                "regx": ""
            },
            {
                "id": "department",
                "input_type": "Text",
                "content": "Department",
                "placeholder": "Enter Department",
                "value": "",
                "required": false,
                "isDisabled": true,
                "className": "",
                "regx": ""
            },
            {
                "id": "location",
                "input_type": "Dropdown",
                "content": "Location",
                "placeholder": "Select Location",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "location"
            },
            {
                "id": "costCenter",
                "input_type": "Text",
                "content": "Cost center",
                "placeholder": "Enter Cost center",
                "value": "",
                "required": false,
                "isDisabled": true,
                "className": "",
                "regx": ""
            }
        ]
    },
    {
        "cate_type": "CAT",
        "index": 2,
        "fields": [
            {
                "id": "categoryOne",
                "input_type": "Dropdown",
                "content": "Category 1",
                "placeholder": "Select Category 1",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "categoryOne"
            },
            {
                "id": "categoryTwo",
                "input_type": "Dropdown",
                "content": "Category 2",
                "placeholder": "Select Category 2",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "categoryTwo"
            }, {
                "id": "categoryThree",
                "input_type": "Dropdown",
                "content": "Category 3",
                "placeholder": "Select Category 3",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "categoryThree"
            },
        ]
    },
    {
        "cate_type": "N2",
        "index": 4,
        "fields": [
            {
                "id": "assetTag",
                "input_type": "Dropdown",
                "content": "Asset Tag",
                "placeholder": "Select Asset Tag",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "assetTag"
            },
            {
                "id": "assetType",
                "input_type": "Text",
                "content": "Asset Type",
                "placeholder": "Enter Asset Type",
                "value": "",
                "required": false,
                "isDisabled": true,
                "className": "",
                "regx": ""
            }
        ]
    },
    {
        "cate_type": "ASG",
        "index": 5,
        "fields": [
            {
                "id": "assignedUserName",
                "input_type": "Text",
                "content": "Assigned To - Username",
                "placeholder": "Enter Assigned To - Username",
                "value": "",
                "required": false,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "assignedUserOrganization",
                "input_type": "Text",
                "content": "Assigned to - Organisation",
                "placeholder": "Enter Assigned to - Organisation",
                "value": "",
                "required": false,
                "isDisabled": false,
                "className": "",
                "regx": ""
            }
        ]
    },
    {
        "cate_type": "INF",
        "index": 6,
        "fields": [
            {
                "id": "contactDetails",
                "input_type": "Text",
                "content": "Contact Details",
                "placeholder": "Enter Contact Details",
                "value": "",
                "required": false,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "site",
                "input_type": "Dropdown",
                "content": "Site",
                "placeholder": "Select Site",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "site"
            },
            {
                "id": "blockBuilding",
                "input_type": "Text",
                "content": "Block/Building",
                "placeholder": "Enter Block/Building",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "bay",
                "input_type": "Text",
                "content": "Bay",
                "placeholder": "Enter Bay",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "floor",
                "input_type": "Dropdown",
                "content": "Floor",
                "placeholder": "Select Floor",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "floor"
            },
            {
                "id": "assetPhysicalLocation",
                "input_type": "Text",
                "content": "Asset physical location",
                "placeholder": "Enter Asset physical location",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            }
        ]
    },
    {
        "cate_type": "N3",
        "index": 7,
        "fields": [
            {
                "id": "newDomainId",
                "input_type": "Dropdown",
                "content": "New User Name",
                "placeholder": "Select New User Name",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "newDomainId"
            },
            {
                "id": "newManagerName",
                "input_type": "Text",
                "content": "New User Manager Name",
                "placeholder": "Enter New User Manager Name",
                "value": "",
                "required": true,
                "isDisabled": true,
                "className": "",
                "regx": ""
            },
            {
                "id": "newUserDeparment",
                "input_type": "Text",
                "content": "New User Department",
                "placeholder": "Enter New User Department",
                "value": "",
                "required": true,
                "isDisabled": true,
                "className": "",
                "regx": ""
            },
            {
                "id": "newUserCostCenter",
                "input_type": "Text",
                "content": "New User Cost center",
                "placeholder": "Enter New User Cost center",
                "value": "",
                "required": false,
                "isDisabled": true,
                "className": "",
                "regx": ""
            },
            {
                "id": "newLocation",
                "input_type": "Dropdown",
                "content": "New User Location",
                "placeholder": "Select New User Location",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "newLocation"
            },
        ]
    },
    {
        "cate_type": "N4",
        "index": 8,
        "fields": [
            {
                "id": "newSite",
                "input_type": "Dropdown",
                "content": "New User Site",
                "placeholder": "Select New Site",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "newSite"
            },
            {
                "id": "newBlockBuilding",
                "input_type": "Text",
                "content": "New User Block/Building",
                "placeholder": "Enter New Block/Building",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "newBay",
                "input_type": "Text",
                "content": "New User Bay",
                "placeholder": "Enter New Bay",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "newFloor",
                "input_type": "Dropdown",
                "content": "New User Floor",
                "placeholder": "Select New Floor",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "newFloor"
            },
            {
                "id": "newAssetPhysicalLocation",
                "input_type": "Text",
                "content": "New User Asset physical location",
                "placeholder": "Enter New Asset physical location",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            }
        ]
    },
    {
        "cate_type": "N5",
        "index": 9,
        "fields": [
            {
                "id": "assetCategory",
                "input_type": "Dropdown",
                "content": "Asset Category",
                "placeholder": "Select Asset Category",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
                "dropItems": "assetCategory"
            },
            {
                "id": "resolutionCatOne",
                "input_type": "Text",
                "content": "Resolution Cat 1",
                "placeholder": "Enter Resolution Cat 1",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "resolutionCatTwo",
                "input_type": "Text",
                "content": "Resolution Cat 2",
                "placeholder": "Enter Resolution Cat 2",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            },
            {
                "id": "resolutionCatThree",
                "input_type": "Text",
                "content": "Resolution Cat 3",
                "placeholder": "Enter Resolution Cat 3",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": ""
            }
        ]
    },
    {
        "cate_type": "N6",
        "index": 3,
        "fields": [
            {
                "id": "softwareName",
                "input_type": "Text",
                "content": "Software Name",
                "placeholder": "Enter Software Name",
                "value": "",
                "required": true,
                "isDisabled": false,
                "className": "",
                "regx": "",
            }
        ]
    },
];
