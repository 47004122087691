import { put, call, take, delay, takeEvery } from "redux-saga/effects";
import * as actionCreators from "../actions/SapIdAction";
import { httpGet, httpPost, httpPost2 } from "../../service";
import { SAP_ID_CONFIG, SAP_ID_URL, VISITOR_CONFIG } from "../../config";
import { ICreateListDataPayLaod } from "../actions/TodoAction";

function* getUserDetailsData(query: actionCreators.IGetUserDetailspayload) {
  try {
    yield put(actionCreators.getUserDetailsLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_USER_DETAILS_DATA +
        `?sap-client=${query.sap_client}&lv_email=${query.lv_email}`
    );
    yield put(actionCreators.getUserDetailsSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getUserDetailsErrorAction(error));
  }
}

function* getUserRolesAssignData(
  query: actionCreators.IGetUserRolesAssignpayload
) {
  try {
    yield put(actionCreators.getUserRolesAssignLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_USER_ROLES_ASSIGN +
        `?sap-client=${query.sap_client}&User_ID=${query.User_ID}&System_ID=${query.System_ID}`
    );
    yield put(actionCreators.getUserRolesAssignSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getUserRolesAssignErrorAction(error));
  }
}

function* getUserSystemDetailsData(
  query: actionCreators.IGetUserSystemDetailspayload
) {
  try {
    yield put(actionCreators.getUserSystemDetailsLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_USER_SYSTEM_DETAILS +
        `?sap-client=${query.sap_client}&EmailID=${query.EmailID}`
    );
    yield put(actionCreators.getUserSystemDetailsSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getUserSystemDetailsErrorAction(error));
  }
}

function* getSystemDescriptionData(
  query: actionCreators.IGetSystemDescriptionpayload
) {
  try {
    yield put(actionCreators.getSystemDescriptionLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_SYSTEM_DESCRIPTION +
        `?sap-client=${query.sap_client}`
    );
    yield put(actionCreators.getSystemDescriptionSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getSystemDescriptionErrorAction(error));
  }
}

function* getReqUserDetailsData(
  query: actionCreators.IGetReqUserDetailspayload
) {
  try {
    yield put(actionCreators.getReqUserDetailsLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_REQ_USER_DETAILS +
        `?sap-client=${query.sap_client}&USERID=${query.USERID}`
    );
    yield put(actionCreators.getReqUserDetailsSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getReqUserDetailsErrorAction(error));
  }
}

function* getNoSystemAssignedUserData(
  query: actionCreators.IGetNoSystemAssignedUserpayload
) {
  try {
    yield put(actionCreators.getNoSystemAssignedUserLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_NO_SYSTEM_ASSIGNED_USER +
        `?sap-client=${query.sap_client}&USERID=${query.USERID}`
    );
    yield put(actionCreators.getNoSystemAssignedUserSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getNoSystemAssignedUserErrorAction(error));
  }
}

//todo
function* postUserCreateData(payload: any) {
  try {
    yield put(actionCreators.postUserCreateLoadingAction());
    const { data } = yield httpPost(
      `${SAP_ID_URL}${SAP_ID_CONFIG.POST_USER_CREATE}?sap-client=${payload.sap_client}`,
      payload.userPayload,
      { timeout: 25000 }
    );
    yield put(actionCreators.postUserCreateSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.postUserCreateErrorAction(error));
  }
}

function* getApproverInboxData(query: actionCreators.IGetApproverInboxPayload) {
  try {
    yield put(actionCreators.getApproverInboxLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_APPROVER_INBOX +
        `?sap-client=${query.sap_client}&userid=${query.userid}`
    );
    yield put(actionCreators.getApproverInboxSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getApproverInboxErrorAction(error));
  }
}

//todo
function* getFindingTCodesRoleData(
  query: actionCreators.IGetFindingTCodesRolepayload
) {
  try {
    yield put(actionCreators.getFindingTCodesRoleLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_FINDING_T_CODES_ROLE +
        `?sap-client=${query.sap_client}&TCODE=${query.TCODE}&CONNECTOR=${query.CONNECTOR}`
    );
    yield put(actionCreators.getFindingTCodesRoleSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getFindingTCodesRoleErrorAction(error));
  }
}

function* getFindingOrgValuesRoleData(
  query: actionCreators.IGetFindingOrgValuesRolepayload
) {
  try {
    yield put(actionCreators.getFindingOrgValuesRoleLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_FINDING_ORG_VALUES_ROLE +
        `?sap-client=${query.sap_client}&Function=${query.Function}&Subfunction=${query.Subfunction}&Department=${query.Department}&Subdepartment=${query.Subdepartment}&RoleName=${query.RoleName}&RoleOwner=${query.RoleOwner}`
    );
    yield put(actionCreators.getFindingOrgValuesRoleSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getFindingOrgValuesRoleErrorAction(error));
  }
}

function* getRoleOwnerRoleNameData(
  query: actionCreators.IGetRoleOwnerRoleNamepayload
) {
  try {
    yield put(actionCreators.getRoleOwnerRoleNameLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_ROLE_OWNER_ROLE_NAME +
        `?sap-client=${query.sap_client}&Connector=${query.Connector}&Rolename=${query.Rolename}`
    );
    yield put(actionCreators.getRoleOwnerRoleNameSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getRoleOwnerRoleNameErrorAction(error));
  }
}

//todo
function* getListBusinessProcessData(
  query: actionCreators.IGetListBusinessProcesspayload
) {
  try {
    yield put(actionCreators.getListBusinessProcessLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_LIST_BUSINESS_PROCESS +
        `?sap-client=${query.sap_client}`
    );
    yield put(actionCreators.getListBusinessProcessSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getListBusinessProcessErrorAction(error));
  }
}

function* getListRoleAssignedToOwnerData(
  query: actionCreators.IGetListRoleAssignedToOwnerpayload
) {
  try {
    yield put(actionCreators.getListRoleAssignedToOwnerLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_LIST_ROLE_ASSIGNED_TO_OWNER +
        `?sap-client=${query.sap_client}&Roleowner=${query.Roleowner}`
    );
    yield put(actionCreators.getListRoleAssignedToOwnerSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getListRoleAssignedToOwnerErrorAction(error));
  }
}
function* postApiForApproveReqData(query: actionCreators.ILogForAppreqpayload) {
  try {
    yield put(actionCreators.postApiForApproveReqLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.POST_API_FOR_APPROVE_REQ +
        `?sap-client=${query.sap_client}&Reqno=${query.Reqno}&Approver=${query.Approver}`
    );
    yield put(actionCreators.postApiForApproveReqSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.postApiForApproveReqErrorAction(error));
  }
}

function* getSystemTypeData(query: actionCreators.IGetSystemTypepayload) {
  try {
    yield put(actionCreators.getSystemTypeLoadingAction());
    const { data } = yield httpGet(SAP_ID_URL + SAP_ID_CONFIG.GET_SYSTEM_TYPE);
    yield put(actionCreators.getSystemTypeSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getSystemTypeErrorAction(error));
  }
}

function* getFunctionData(query: actionCreators.IGetFunctionpayload) {
  try {
    yield put(actionCreators.getFunctionLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL + SAP_ID_CONFIG.GET_FUNCTION_DATA
    );
    yield put(actionCreators.getFunctionSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getFunctionErrorAction(error));
  }
}

function* getSubFunctionData(query: actionCreators.IGetSubFunctionpayload) {
  try {
    yield put(actionCreators.getSubFunctionLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_SUBFUNCTION_DATA +
        "?functionId=" +
        query.functionId
    );
    yield put(actionCreators.getSubFunctionSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getSubFunctionErrorAction(error));
  }
}
function* getDepartmentData(query: actionCreators.IGetDepartmentpayload) {
  try {
    yield put(actionCreators.getDepartmentLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_DEPARTMENT_DATA +
        "?subFunctionId=" +
        query.subFunctionId
    );
    yield put(actionCreators.getDepartmentSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getDepartmentErrorAction(error));
  }
}

function* getSubDepartmentData(query: actionCreators.IGetSubDepartmentpayload) {
  try {
    yield put(actionCreators.getSubDepartmentLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL +
        SAP_ID_CONFIG.GET_SUBDEPARTMENT_DATA +
        "?departmentId=" +
        query.departmentId
    );
    yield put(actionCreators.getSubDepartmentSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getSubDepartmentErrorAction(error));
  }
}

function* getRoleOwnerData(query: actionCreators.IGetRoleOwnerpayload) {
  try {
    yield put(actionCreators.getRoleOwnerLoadingAction());
    const { data } = yield httpGet(
      SAP_ID_URL + SAP_ID_CONFIG.GET_ROLE_OWNER_DATA
    );
    yield put(actionCreators.getRoleOwnerSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getRoleOwnerErrorAction(error));
  }
}
function* getRoleData(query: actionCreators.IGetRolepayload) {
  try {
    yield put(actionCreators.getRoleLoadingAction());
    const { data } = yield httpGet(SAP_ID_URL + SAP_ID_CONFIG.GET_ROLE_DATA);
    yield put(actionCreators.getRoleSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getRoleErrorAction(error));
  }
}

function* postSodConflictData(query: actionCreators.IPostSodConflictPayload) {
  try {
    yield put(actionCreators.postSodConflictLoadingAction());
    const { data } = yield httpPost(
      SAP_ID_URL +
        SAP_ID_CONFIG.POST_SOD_CONFLICT +
        `?sap-client=${query.sap_client}`,
      query.sod_payload
    );
    yield put(actionCreators.postSodConflictSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.postSodConflictErrorAction(error));
  }
}

// =================================================WATCH FUNCTIONS=======================================
export function* watchGetUserDetailsData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_USER_DETAILS_DATA
    );
    yield call(getUserDetailsData, payload);
  }
}

export function* watchGetUserRolesAssignData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_USER_ROLES_ASSIGN_DATA
    );
    yield call(getUserRolesAssignData, payload);
  }
}

export function* watchGetUserSystemDetailsData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_USER_SYSTEM_DETAILS_DATA
    );
    yield call(getUserSystemDetailsData, payload);
  }
}

export function* watchGetSystemDescriptionData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_SYSTEM_DESCRIPTION_DATA
    );
    yield call(getSystemDescriptionData, payload);
  }
}

export function* watchGetReqUserDetailsData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_REQ_USER_DETAILS_DATA
    );
    yield call(getReqUserDetailsData, payload);
  }
}

export function* watchGetNoSystemAssignedUserData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_NO_SYSTEM_ASSIGNED_USER_DATA
    );
    yield call(getNoSystemAssignedUserData, payload);
  }
}

export function* watchPostUserCreateData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.POST_USER_CREATE_DATA
    );
    yield call(postUserCreateData, payload);
  }
}

export function* watchGetApproverInboxData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_APPROVER_INBOX_DATA
    );
    yield call(getApproverInboxData, payload);
  }
}

export function* watchGetFindingTCodesRoleData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_FINDING_T_CODES_ROLE_DATA
    );
    yield call(getFindingTCodesRoleData, payload);
  }
}

export function* watchGetFindingOrgValuesRoleData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_FINDING_ORG_VALUES_ROLE_DATA
    );
    yield call(getFindingOrgValuesRoleData, payload);
  }
}

export function* watchGetRoleOwnerRoleNameData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_ROLE_OWNER_ROLE_NAME_DATA
    );
    yield call(getRoleOwnerRoleNameData, payload);
  }
}

export function* watchGetListBusinessProcessData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_LIST_BUSINESS_PROCESS_DATA
    );
    yield call(getListBusinessProcessData, payload);
  }
}

export function* watchGetListRoleAssignedToOwnerData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_LIST_ROLE_ASSIGNED_TO_OWNER_DATA
    );
    yield call(getListRoleAssignedToOwnerData, payload);
  }
}

export function* watchPostApiForApproveReqData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.POST_API_FOR_APPROVE_REQ_DATA
    );
    yield call(postApiForApproveReqData, payload);
  }
}

export function* watchGetSystemTypeData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.GET_SYSTEM_TYPE_DATA);
    yield call(getSystemTypeData, payload);
  }
}

export function* watchGetFunctionData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.GET_FUNCTION_DATA);
    yield call(getFunctionData, payload);
  }
}

export function* watchGetSubFunctionData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_SUB_FUNCTION_DATA
    );
    yield call(getSubFunctionData, payload);
  }
}

export function* watchGetDepartmentData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.GET_DEPARTMENT_DATA);
    yield call(getDepartmentData, payload);
  }
}

export function* watchGetSubDepartmentData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_SUB_DEPARTMENT_DATA
    );
    yield call(getSubDepartmentData, payload);
  }
}

export function* watchGetRoleOwnerData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.GET_ROLE_OWNER_DATA);
    yield call(getRoleOwnerData, payload);
  }
}
export function* watchGetRoleData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.GET_ROLE_DATA);
    yield call(getRoleData, payload);
  }
}

export function* watchPostSodConflictData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.POST_SOD_CONFLICT_DATA
    );
    yield call(postSodConflictData, payload);
  }
}
