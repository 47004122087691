import { put, call, take, delay } from "redux-saga/effects";
import * as actionCreators from "../actions/ITtipsAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_SECRET_TOKEN, ITTIPS_API_CONFIG } from "../../config";

function* getIttipsData() {
    try {
        yield put(actionCreators.getIttipsDataLoadingAction());
        const { data } = yield httpGet(
            ITTIPS_API_CONFIG.GET_ITTIPS_DATA, {
            headers: {
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            }
        }
        );
        yield put(actionCreators.getIttipsDataSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getIttipsDataErrorAction(error));
    }
}

export function* watchIttipsData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_IT_TIPS_DATA
        );
        yield call(getIttipsData);
    }
}