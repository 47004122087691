import React from "react";
import {
    Segment,
    Avatar,
    Divider,
    Flex,
    Text,
    Header,
    FlexItem,
    Box,
    Button,
    AddIcon,
    ExclamationTriangleIcon,
    RedbangIcon,
    ChatIcon,
    Pill,
    Skeleton,
    SkeletonText,
    SkeletonInput,
    SkeletonButton,
    AcceptIcon,
    CloseIcon,
    EditIcon,
    Input,
    TextArea,
    Menu,
    MoreIcon,
    TrashCanIcon,
    Dialog
} from "@fluentui/react-northstar";
import { Capitalize, Constant, TraceEvent, TraceTelemtry } from "../../constant";
import FeedbackDialog from "../FeedbackDialog";
import Feedback from "../TeamFeedback";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import {
    getSuccessFactorUserDetailsDataAction,
    getEmployeeGoalsDataAction,
    addGoalCommentDataAction,
    getGoalCommentDataAction,
    getPerformanceTimelineDataAction,
    sendFeedbackRequestDataAction,
    updateManagerGoalCommentDataAction,
} from "../../redux/actions/CFMActions";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import CFM from "../../assets/img/CFM.svg";
import CFMPermission from "../HOC/CFMPermission";
import CFMButtonPermission from "../HOC/CFMButtonPermission";
import CFMMenuComponent from "../Common/CFMMenuComponent";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import { getAppInsights } from "./../../telemetry/telemetryService";
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-IN");
interface IMyGoals {
    QueryData: IState;
    byManagerUserId?: string;
    byManagerEmpId?: string;
    managerView?: boolean;
    activeBackButton?: any;
}
const MyGoals: React.FC<IMyGoals> = ({ QueryData, managerView, byManagerUserId, byManagerEmpId, activeBackButton }) => {
    const { appInsights, trackEvent, trackMetrix } = getAppInsights();
    const queryData = QueryData;
    const [addDialog, setAddDialog] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [feedback, setfeedback] = React.useState<{
        isActive: boolean;
        index: number;
        id?: string
    }>({
        isActive: false,
        index: 0,
    });
    const [feedbackProps, setFeedbackProps] = React.useState<{
        type: string;
        name: string;
        item_id: string;
        form_data_id: string;
        form_template_id: string;
        sf_goal_comments_id: string;
    }>({
        type: "Goal",
        name: "",
        item_id: "",
        form_data_id: "",
        form_template_id: "",
        sf_goal_comments_id: "",
    });
    const [editableId, setEditableId] = React.useState<{ isActive?: number; comment?: string, isDelete?: boolean }>({ isActive: 0, comment: "" });
    const [commentItem, setCommentItem] = React.useState<any>();
    const [preDefinedText, setPreDefinedText] = React.useState<string>("");
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const goalData = managerView ? state.cfmData.getEmployeeGoalsData.teamGoal : state.cfmData.getEmployeeGoalsData.selfGoal;
    React.useEffect(() => {
        if (queryData.employeeId && state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" && !managerView && !(state.cfmData.getSuccessFactorUserDetailsData.status_code === "200")) {
            dispatch(
                getSuccessFactorUserDetailsDataAction(
                    state.authData.userAuth.token,
                    queryData.employeeId
                )
            );
        }
    }, [queryData.employeeId, state.authData.userAuth.isSuccess]);

    React.useEffect(() => {
        setfeedback({
            isActive: false,
            index: 0,
        });
        if (
            state.cfmData.getSuccessFactorUserDetailsData.isSuccess &&
            state.cfmData.getSuccessFactorUserDetailsData.user_details?.user_id
            && !managerView && state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" && !(state.cfmData.getEmployeeGoalsData.selfGoal.status_code === "200")
        ) {
            dispatch(
                getEmployeeGoalsDataAction(state.authData.userAuth.token, {
                    emp_id: queryData.employeeId as string,
                    user_id: state.cfmData.getSuccessFactorUserDetailsData.user_details.user_id,
                    is_employee: managerView ? false : true
                })
            );
        }
    }, [
        state.cfmData.getSuccessFactorUserDetailsData.isSuccess,
        state.authData.userAuth.isSuccess,
    ]);

    React.useEffect(() => {
        if (managerView && state.authData.userAuth.isSuccess) {
            setfeedback({ isActive: false, index: 0 });
            dispatch(
                getEmployeeGoalsDataAction(state.authData.userAuth.token, {
                    emp_id: byManagerEmpId as string,
                    user_id: byManagerUserId as string,
                    is_employee: managerView ? false : true
                }, true)
            );
        }
    }, [
        state.authData.userAuth.isSuccess,
        byManagerEmpId, byManagerUserId
    ]);

    React.useEffect(() => {
        if (addDialog) {
            activeBackButton?.(false);
        } else {
            activeBackButton?.(true);
        }
    }, [addDialog]);

    /**
     * This function will return the feedbacks of goals,
     * after hitting the API for particular goal.
     * @param index index position of goal
     * @param item data of selected goal
     */
    const viewGoalFeedback = (index: number, item: any) => {
        if (feedback.isActive) {
            activeBackButton?.(true);
            setfeedback({ isActive: false, index: 0 });
        } else {
            activeBackButton?.(false);
            setfeedback({ isActive: true, index: index, id: item.item_id });
            dispatch(
                getGoalCommentDataAction(state.authData.userAuth.token, item.item_id, managerView ? false : true)
            );
        }
        setEditableId({});
    };

    /**
     * This function if add the feedback for the particular goal
     * @param item data of selected goal
     */
    const addGoalComment = (item: any) => {
        setAddDialog(true);
        setFeedbackProps({
            type: "Goal",
            name: item.name,
            item_id: item.item_id,
            form_data_id: item.form_data_id,
            form_template_id: item.form_template_id,
            sf_goal_comments_id: item.sf_goal_details_id,
        });
        setfeedback({ isActive: feedback.isActive, index: feedback.index });
    };

    /**
     * This function is updating the goal feedback
     * @param btnValue value of button (accept/reject)
     * @param item data of selected goal
     */
    const feedbackEditValue = (btnValue: string, item: any) => {
        if (btnValue === "accept") {
            setCommentItem(item);
            setOpen(true);
        } else if (btnValue === "reject") {
            setEditableId({});
            setPreDefinedText("");
        }
    };
    React.useEffect(() => {
        if (state.cfmData.updateManagerGoalComment.isSuccess && state.cfmData.updateManagerGoalComment.status_code === "200" && editableId.comment !== "") {
            SuccessToast(state.cfmData.updateManagerGoalComment.message);
            dispatch(
                getGoalCommentDataAction(state.authData.userAuth.token, feedback?.id as string, managerView ? false : true)
            );
            setEditableId({ comment: "" });
            setPreDefinedText("");
            setOpen(false);
        } else if (state.cfmData.updateManagerGoalComment.isSuccess && state.cfmData.updateManagerGoalComment.status_code !== "200" && editableId.comment !== "") {
            ErrorToast(state.cfmData.updateManagerGoalComment.message);
        }
    }, [state.cfmData.updateManagerGoalComment.isSuccess]);
    React.useEffect(() => {
        if (state.cfmData.deleteManagerGoalComment.isSuccess && state.cfmData.deleteManagerGoalComment.status_code === "200" && editableId.isDelete) {
            SuccessToast(state.cfmData.deleteManagerGoalComment.message);
            dispatch(
                getGoalCommentDataAction(state.authData.userAuth.token, feedback?.id as string, managerView ? false : true)
            );
            setEditableId({ isDelete: false });
        } else if (state.cfmData.deleteManagerGoalComment.isSuccess && state.cfmData.deleteManagerGoalComment.status_code !== "200" && editableId.isDelete) {
            ErrorToast(state.cfmData.deleteManagerGoalComment.message);
            setEditableId({ isDelete: false });
        }
    }, [state.cfmData.deleteManagerGoalComment.isSuccess]);

    /**
     * 
     * @param value value of ticks (consider/continue)
     */
    const onClickPreDefine = (value: string) => {
        setPreDefinedText(value);
        setEditableId({ ...editableId, comment: value + " " });
    };
    const cancelDialog = () => {
        setOpen(false);
    };
    const confirmDialog = () => {
        const data = {
            item_id: commentItem?.item_id_pk_id,
            sf_goal_comments_id: commentItem?.sf_goal_comments_id,
            comment: editableId?.comment?.trim() as string
        };
        dispatch(
            updateManagerGoalCommentDataAction(state.authData.userAuth.token, data)
        );
        trackEvent(appInsights, TraceEvent.TML_CFM_GOALS_MANAGER_UPDATE_COMMENT, data);
        setOpen(false);
    };
    return (
        <React.Fragment>
            {((queryData.isTaskModuleActive && !addDialog) || (!queryData.isTaskModuleActive)) &&
                <Segment
                    styles={{
                        borderRadius: "5px",
                        padding: "0",
                        margin: "0",
                        width: "100%",
                        height: `${managerView ? "calc(100vh - 121px)" : queryData.isTaskModuleActive ? "calc(100vh - 40px)" : "calc(100vh - 26px)"}`
                    }}
                >
                    {!queryData.isTaskModuleActive &&
                        <>
                            <Header className="segmentHeader" content={managerView ? "Goals" : "My Goals"} as="h3" />
                            <Divider fitted />
                        </>
                    }
                    {goalData.isSuccess &&
                        goalData.status_code === "200" &&
                        goalData.sf_goal_details.length > 0 &&
                        !feedback.isActive ? (
                        <CFMPermission permission={goalData.application_modes} message={goalData.message}>
                            <Box styles={{ height: `${managerView ? "calc(100vh - 166px)" : queryData.isTaskModuleActive ? "calc(100vh - 40px)" : "calc(100vh - 70px)"}`, overflow: "auto" }}>
                                {goalData.sf_goal_details.map(
                                    (item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Flex padding="padding.medium" gap="gap.medium">
                                                    <FlexItem grow>
                                                        <Flex column gap="gap.medium">
                                                            <Text content={item.name} />
                                                            <Pill
                                                                disabled
                                                                size="small"
                                                                content={
                                                                    <Text content={item.category} styles={({ theme: { siteVariables } }) => ({
                                                                        color: siteVariables.colorScheme.default.foreground,
                                                                    })} />
                                                                }
                                                            />
                                                        </Flex>
                                                    </FlexItem>
                                                    <FlexItem push>
                                                        <Flex column gap="gap.medium" space="between">
                                                            <CFMButtonPermission permission={goalData.application_modes} message={goalData.message}>
                                                                <Flex fill hAlign="end">
                                                                    <Button
                                                                        flat
                                                                        icon={<AddIcon />}
                                                                        iconOnly
                                                                        onClick={() => addGoalComment(item)}
                                                                    />
                                                                </Flex>
                                                            </CFMButtonPermission>
                                                            <Flex fill vAlign="end">
                                                                <Button
                                                                    text
                                                                    icon={<ChatIcon />}
                                                                    iconPosition="before"
                                                                    content={"View feedback"}
                                                                    onClick={(e) => viewGoalFeedback(index, item)}
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                    </FlexItem>
                                                </Flex>
                                                <Divider fitted />
                                            </Box>
                                        );
                                    }
                                )}
                            </Box>
                        </CFMPermission>
                    )
                        : goalData.isSuccess &&
                            goalData.status_code === "200"
                            &&
                            goalData.sf_goal_details.length === 0 ? (
                            <DataNotFound message={Constant.GOALS_CAUGHT_UP} img={CFM} />
                        ) : (goalData.isSuccess &&
                            goalData.status_code !== "200") || (state.cfmData.getSuccessFactorUserDetailsData.isSuccess && state.cfmData.getSuccessFactorUserDetailsData.status_code !== "200") ? (
                            <ErrorComponent
                                message={goalData.message || state.cfmData.getSuccessFactorUserDetailsData.message}
                            />
                        ) : (
                            !feedback.isActive && (
                                <Box styles={{ height: `${managerView ? "calc(100vh - 166px)" : queryData.isTaskModuleActive ? "calc(100vh - 40px)" : "calc(100vh - 70px)"}`, overflow: "auto" }}>
                                    {[1, 2, 3, 4, 5].map((item) => {
                                        return (
                                            <Skeleton animation="pulse" key={item}>
                                                <Flex
                                                    fill
                                                    gap="gap.medium"
                                                    padding="padding.medium"
                                                >
                                                    <Flex fill column gap="gap.medium">
                                                        <Skeleton.Text width="50%" />
                                                        <Skeleton.Input />
                                                    </Flex>
                                                    <Flex
                                                        hAlign="end"
                                                        column
                                                        gap="gap.medium"
                                                        space="between"
                                                    >
                                                        <Skeleton.Button iconOnly />
                                                        <Skeleton.Button />
                                                    </Flex>
                                                </Flex>
                                                <Divider fitted />
                                            </Skeleton>
                                        );
                                    })}
                                </Box>
                            )
                        )}
                    {goalData.isSuccess &&
                        goalData.status_code === "200" &&
                        feedback.isActive &&
                        goalData.sf_goal_details[
                        feedback.index
                        ] && (
                            <Box>
                                <Flex padding="padding.medium" gap="gap.medium">
                                    <FlexItem grow>
                                        <Flex column gap="gap.medium">
                                            <Text
                                                content={
                                                    goalData.sf_goal_details[
                                                        feedback.index
                                                    ].name
                                                }
                                            />
                                            <Pill
                                                disabled
                                                size="small"
                                                content={
                                                    <Text
                                                        content={
                                                            goalData.sf_goal_details[
                                                                feedback.index
                                                            ].category
                                                        }
                                                        styles={({ theme: { siteVariables } }) => ({
                                                            color: siteVariables.colorScheme.default.foreground,
                                                        })}
                                                    />
                                                }
                                            />
                                        </Flex>
                                    </FlexItem>
                                    <FlexItem push>
                                        <Flex column gap="gap.medium" space="between">
                                            <Flex fill hAlign="end">
                                                <CFMButtonPermission permission={goalData.application_modes} message={goalData.message}>
                                                    <Button
                                                        flat
                                                        icon={<AddIcon />}
                                                        iconOnly
                                                        onClick={() =>
                                                            addGoalComment(
                                                                goalData.sf_goal_details[
                                                                feedback.index
                                                                ]
                                                            )
                                                        }
                                                    />
                                                </CFMButtonPermission>
                                            </Flex>
                                            <Flex fill vAlign="end">
                                                <Button
                                                    text
                                                    icon={<ChatIcon />}
                                                    primary
                                                    iconPosition="before"
                                                    content={"Hide feedback"}
                                                    onClick={(e) =>
                                                        viewGoalFeedback(
                                                            feedback.index,
                                                            goalData.sf_goal_details[
                                                            feedback.index
                                                            ]
                                                        )
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </FlexItem>
                                </Flex>
                                <Divider fitted />
                            </Box>
                        )}
                    {feedback.isActive && (
                        <Box
                            styles={({ theme: { siteVariables } }) => ({
                                background: siteVariables.colorScheme.grey.backgroundActive,
                                padding: "0rem, 1.5rem",
                                height: `${managerView ? "calc(100vh - 276px)" : queryData.isTaskModuleActive ? "calc(100vh - 150px)" : "calc(100vh - 175px)"}`,
                                overflow: "auto",
                            })}
                        >
                            {state.cfmData.getGoalCommentsData.isSuccess &&
                                state.cfmData.getGoalCommentsData.status_code === "200" &&
                                state.cfmData.getGoalCommentsData.goal_comments.length > 0 ? (
                                state.cfmData.getGoalCommentsData.goal_comments.filter((item: any) => item.status !== 3).map(
                                    (item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Flex gap="gap.medium" padding="padding.medium">
                                                    <Avatar
                                                        name={Capitalize(`${item.commented_by_name}`)}
                                                        size="large"
                                                        label={{
                                                            styles: item.role === "DEM" ? { backgroundColor: "#3A83F8", color: "#FFFFFF" } : item.role === "FEM" ? { backgroundColor: "#3D1D76", color: "#FFFFFF" } : { backgroundColor: "#65D283", color: "#FFFFFF" }
                                                        }}
                                                    />
                                                    <Flex
                                                        column
                                                        gap="gap.smaller"
                                                        styles={({ theme: { siteVariables } }) => ({
                                                            background: siteVariables.bodyBackground,
                                                            borderRadius: "2px",
                                                            width: "80%",
                                                            padding: "1rem 1rem 0.4rem 1rem",
                                                        })}
                                                    >
                                                        <Flex space="between">
                                                            <Text
                                                                weight="semibold"
                                                                content={item.commented_by_name === state.userInfo.userManagerData.user_details[0].CompName ? "Self" : item.commented_by_name}
                                                            />
                                                            <Flex gap="gap.medium" vAlign="center">
                                                                <Text content={timeAgo.format(Date.parse(item.modified_date_time))} size="small" />
                                                            </Flex>
                                                        </Flex>
                                                        {item.id === editableId.isActive ?
                                                            <Flex column>
                                                                <TextArea
                                                                    value={editableId.comment}
                                                                    fluid
                                                                    onChange={(e: any) => setEditableId({ ...editableId, comment: e.target.value.trimStart() })}
                                                                />
                                                                <Flex hAlign="end" vAlign="start">
                                                                    <Button text size="small" content="Consider" className="textStyle" primary={preDefinedText === "Consider"} onClick={() => onClickPreDefine("Consider")} styles={preDefinedText === "Consider" ? { textDecoration: "underline", minWidth: "1rem" } : { minWidth: "1rem" }} />
                                                                    <Button text size="small" content="Continue" className="textStyle" primary={preDefinedText === "Continue"} onClick={() => onClickPreDefine("Continue")} styles={preDefinedText === "Continue" ? { textDecoration: "underline", minWidth: "1rem" } : { minWidth: "1rem" }} />
                                                                </Flex>
                                                                <Flex hAlign="end" gap="gap.small" padding="padding.medium">
                                                                    <AcceptIcon size="small" className="pointer" disabled={editableId?.comment?.length === 0} onClick={() => editableId?.comment?.length !== 0 && feedbackEditValue("accept", item)} />
                                                                    <CloseIcon size="small" className="pointer" onClick={() => feedbackEditValue("reject", item)} />
                                                                </Flex>
                                                            </Flex>
                                                            : <Flex vAlign="center" space="between">
                                                                <Text content={item.comment} styles={{ wordBreak: "break-word" }} />
                                                                {
                                                                    item.status === "4" ?
                                                                        <AcceptIcon size="small" styles={{ color: "#407855" }} />
                                                                        : item.status === "5" ?
                                                                            <Flex>
                                                                                <AcceptIcon size="small" styles={{ color: "#407855", marginRight: "0px !important", position: "relative", left: "12px", bottom: "4px" }} />
                                                                                <AcceptIcon size="small" styles={{ color: "#407855" }} />
                                                                            </Flex>
                                                                            :
                                                                            <CFMMenuComponent item={item} editableId={editableId} setEditableId={setEditableId} managerView={managerView} QueryData={QueryData} />
                                                                }
                                                            </Flex>
                                                        }
                                                    </Flex>
                                                </Flex>
                                                <Divider fitted />
                                            </Box>
                                        );
                                    }
                                )
                            ) : state.cfmData.getGoalCommentsData.isSuccess &&
                                state.cfmData.getGoalCommentsData.status_code === "200" &&
                                state.cfmData.getGoalCommentsData.goal_comments.length === 0 ? (
                                <DataNotFound message={Constant.NO_FEEDBACK} img={CFM} />
                            ) : state.cfmData.getGoalCommentsData.isSuccess &&
                                state.cfmData.getGoalCommentsData.status_code !== "200" ? (
                                <ErrorComponent
                                    message={state.cfmData.getGoalCommentsData.message}
                                />
                            ) : (
                                <Skeleton animation="pulse">
                                    <Flex gap="gap.medium" padding="padding.medium">
                                        <Skeleton.Avatar round size="large" />
                                        <Flex
                                            column
                                            gap="gap.smaller"
                                            styles={({ theme: { siteVariables } }) => ({
                                                background: siteVariables.bodyBackground,
                                                borderRadius: "2px",
                                                width: "80%",
                                                padding: "1rem",
                                            })}
                                        >
                                            <Skeleton.Text width="40%" />
                                            <Skeleton.Text width="100%" />
                                        </Flex>
                                    </Flex>
                                    <Divider fitted />
                                </Skeleton>
                            )}
                        </Box>
                    )}
                </Segment>
            }
            {addDialog && !queryData.isTaskModuleActive && (
                <FeedbackDialog
                    QueryData={queryData}
                    handler={[addDialog, setAddDialog]}
                    feedbackProps={feedbackProps}
                    managerView={managerView ?? false}
                    itemId="sa"
                />
            )}
            {addDialog && queryData.isTaskModuleActive && (
                <Feedback
                    QueryData={queryData}
                    handler={[addDialog, setAddDialog]}
                    feedbackProps={feedbackProps}
                    managerView={managerView ?? false}
                    itemId="sa"
                />
            )}
            <Dialog
                open={open}
                cancelButton="Cancel"
                confirmButton={{
                    content: "Confirm",
                    loading: state.cfmData.updateManagerGoalComment.isLoading
                }}
                content={Constant.FEEDBACK_CONFRIM}
                header="Confirmation"
                headerAction={{
                    icon: <CloseIcon />,
                    title: "Close",
                    onClick: () => setOpen(false),
                }}
                onCancel={() => cancelDialog()}
                onConfirm={() => confirmDialog()}
            />
        </React.Fragment>
    );
};
export default MyGoals;