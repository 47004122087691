import { put, call, take, delay, takeEvery } from "redux-saga/effects";
import * as actionCreators from "../actions/VisitorAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { VC_BASE_URL, VISITOR_CONFIG, API_SECRET_TOKEN } from "../../config";

function* getMasterLocationData() {
    try {
        yield put(actionCreators.getMasterLoadingAction());
        const { data } = yield httpGet(
            VC_BASE_URL + VISITOR_CONFIG.GET_MASTER_LOCATION_DATA,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getMasterSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getMasterErrorAction(error));
    }
}

function* getMasterFieldData() {
    try {

        yield put(actionCreators.getMasterFieldLoadingAction());
        const { data } = yield httpGet(
            VC_BASE_URL + VISITOR_CONFIG.GET_MASTER_FIELD_NAMES_DATA,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getMasterFieldSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getMasterFieldErrorAction(error));
    }
}

function* getMasterOfficeData(payload: {
    get_master_office: actionCreators.IGetMasterOfficePayload
}) {
    try {

        yield put(actionCreators.getMasterOfficeLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_OFFICE_LOCATION_DATA,
            payload.get_master_office,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getMasterOfficeSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getMasterOfficeErrorAction(error));
    }
}

function* getDisplayFieldData(payload: {
    get_display_field: actionCreators.IGetDisplayFieldPayload
}) {
    try {

        yield put(actionCreators.getDisplayFieldLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_DISPLAY_FIELD_DATA,
            payload.get_display_field,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getDisplayFieldSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getDisplayFieldErrorAction(error));
    }
}

function* getAllStatusData() {
    try {

        yield put(actionCreators.getAllStatusLoadingAction());
        const { data } = yield httpGet(
            VC_BASE_URL + VISITOR_CONFIG.GET_ALL_STATUS,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getAllStatusSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getAllStatusErrorAction(error));
    }
}

function* getSaveDigitalData(payload: {
    get_save_digital: actionCreators.IGetSaveDigitalPayload
}) {
    try {

        yield put(actionCreators.getSaveDigitalLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_SAVE_DIGITAL,
            payload.get_save_digital,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getSaveDigitalSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getSaveDigitalErrorAction(error));
    }
}
function* getSavePysicalData(payload: {
    get_save_pysical: actionCreators.IGetSavePysicalPayload
}) {
    try {

        yield put(actionCreators.getSavePysicalLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_SAVE_PHYSICAL,
            payload.get_save_pysical,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getSavePysicalSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getSavePysicalErrorAction(error));
    }
}

function* getCardQuantityData(payload: {
    get_card_quantity: actionCreators.IGetCardQuantityPayload
}) {
    try {

        yield put(actionCreators.getCardQuantityLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_CARD_QUANTITY,
            payload.get_card_quantity,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getCardQuantitySuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getCardQuantityErrorAction(error));
    }
}

function* getSystemShutdownData(payload: {
    get_system_shutdown: actionCreators.IGetSystemShutdownPayload
}) {
    try {

        yield put(actionCreators.getSystemShutdownLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_SYSTEM_SHUTDOWN,
            payload.get_system_shutdown,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getSystemShutdownSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getSystemShutdownErrorAction(error));
    }
}

function* getDigitalVcData(payload: {
    get_digital_vc: actionCreators.IGetDigitalVcPayload
}) {
    try {
        yield put(actionCreators.getDigitalVcLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_DIGITAL_VC,
            payload.get_digital_vc,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getDigitalVcSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getDigitalVcErrorAction(error));
    }
}

function* getPhysicalVcData(payload: {
    get_physical_vc: actionCreators.IGetPhysicalVcPayload
}) {
    try {

        yield put(actionCreators.getPhysicalVcLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_PHYSICAL_VC,
            payload.get_physical_vc,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getPhysicalVcSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getPhysicalVcErrorAction(error));
    }
}

function* getQrStringData(payload: {
    qr_string: actionCreators.IGetQRStringPayload
}) {
    try {

        yield put(actionCreators.getQRStringLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.GET_QR_STRING,
            payload.qr_string,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getQRStringSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getQRStringErrorAction(error));
    }
}

function* updatePhysicalVCData(payload: {
    id: string,
    remark: string,
    ModifiedBy: string
}) {
    try {
        yield put(actionCreators.updatePhysicalVCLoadingAction());
        const { data } = yield httpPost(
            VC_BASE_URL + VISITOR_CONFIG.UPDATE_PHYSICAL,
            {
                id: payload.id,
                remark: payload.remark,
                ModifiedBy: payload.ModifiedBy
            },
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.updatePhysicalVCSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.updatePhysicalVCErrorAction(error));
    }
}

export function* watchGetMasterLocationData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_MASTER_LOCATION_DATA
        );
        yield call(getMasterLocationData);
    }
}

export function* watchGetMasterFieldData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_MASTER_FIELD_NAMES_DATA
        );
        yield call(getMasterFieldData);
    }
}
export function* watchGetMasterOfficeData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_OFFICE_LOCATION_DATA
        );
        yield call(getMasterOfficeData, payload);
    }
}

export function* watchDisplayFieldData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_DISPLAY_FIELD_DATA
        );
        yield call(getDisplayFieldData, payload);
    }
}

export function* watchAllStatusData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ALL_STATUS_DATA
        );
        yield call(getAllStatusData);
    }
}

export function* watchSaveDigitalData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SAVE_DIGITAL_DATA
        );
        yield call(getSaveDigitalData, payload);
    }
}

export function* watchSavePhysicalData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SAVE_PYSICAL_DATA
        );
        yield call(getSavePysicalData, payload);
    }
}

export function* watchCardQuantityData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_CARD_QUANTITY_DATA
        );
        yield call(getCardQuantityData, payload);
    }
}

export function* watchSystemShutdownData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SYSTEM_SHUTDOWN_DATA
        );
        yield call(getSystemShutdownData, payload);
    }
}

export function* watchDigitalVcData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_DIGITAL_VC_DATA
        );
        yield call(getDigitalVcData, payload);
    }
}

export function* watchPhysicalVcData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_PHYSICAL_VC_DATA
        );
        yield call(getPhysicalVcData, payload);
    }
}

export function* watchQetQrStringData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_QR_STRING_DATA
        );
        yield call(getQrStringData, payload);
    }
}

export function* watchUpdatePhysicalVCData() {
    while(true) {
        const { payload } = yield take(
            actionCreators.Actions.UPDATE_PHYSICAL_VC_DATA
        );
        yield call(updatePhysicalVCData, payload);
    }
}