import {
    Actions,
    IGetAcrSapApprovalListData,
    IGetAcrSapEmpRowCountData,
    IGetAcrSapRequestDetailsData,
    IGetAcrSapRequestInitiatorData,
    IPostAcrSapRequestUpdateData
} from "../actions/AcrSapActions";
import { combineReducers } from "redux";


export const getAcrSapApprovalListReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ACR_SAP_APPROVAL_LIST_LOADING:
        case Actions.GET_ACR_SAP_APPROVAL_LIST_ERROR:
        case Actions.GET_ACR_SAP_APPROVAL_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getAcrSapEmpRowCountReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ACR_SAP_EMP_ROW_COUNT_LOADING:
        case Actions.GET_ACR_SAP_EMP_ROW_COUNT_ERROR:
        case Actions.GET_ACR_SAP_EMP_ROW_COUNT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getAcrSapRequestInitiatorReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ACR_SAP_REQUEST_INITIATOR_LOADING:
        case Actions.GET_ACR_SAP_REQUEST_INITIATOR_ERROR:
        case Actions.GET_ACR_SAP_REQUEST_INITIATOR_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const getAcrSapRequestDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ACR_SAP_REQUEST_DETAILS_LOADING:
        case Actions.GET_ACR_SAP_REQUEST_DETAILS_ERROR:
        case Actions.GET_ACR_SAP_REQUEST_DETAILS_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const postAcrSapRequestUpdateReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_ACR_SAP_REQUEST_UPDATE_LOADING:
        case Actions.POST_ACR_SAP_REQUEST_UPDATE_ERROR:
        case Actions.POST_ACR_SAP_REQUEST_UPDATE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};



export interface IAcrSapData {
    getAcrSapApprovalList: IGetAcrSapApprovalListData;
    getAcrSapEmpRowCountData: IGetAcrSapEmpRowCountData;
    getAcrSapRequestInitiatorData: IGetAcrSapRequestInitiatorData
    getAcrSapRequestDetailsData: IGetAcrSapRequestDetailsData;
    postAcrSapRequestUpdate: IPostAcrSapRequestUpdateData;
}
const acrSapData = combineReducers({
    getAcrSapApprovalList: getAcrSapApprovalListReducer,
    getAcrSapEmpRowCountData: getAcrSapEmpRowCountReducer,
    getAcrSapRequestInitiatorData: getAcrSapRequestInitiatorReducer,
    getAcrSapRequestDetailsData: getAcrSapRequestDetailsReducer,
    postAcrSapRequestUpdate: postAcrSapRequestUpdateReducer,
});
export default acrSapData;
