import React from "react";
import { Flex, Text, Button, Table, ChevronDownIcon, Box, Skeleton, Divider, Dialog, TrashCanIcon, ContactCardIcon, CloseIcon, RadioGroup } from "@fluentui/react-northstar";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import ViewStatusPagination from "../ViewStatusPagination";
import * as VistorAction from "../../redux/actions/VisitorAction";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import "./viewStatus.scss";
import { Constant, EmpCompanyByCode, getDDMMYYYFormat, getUserTitle, TraceEvent } from "../../constant";
import moment from "moment";
import { getSendMailDataAction, getSendMailLoadingAction, ISendMailPayload } from "../../redux/actions/SendMailAction";
import QRCodeStyling from "qr-code-styling";
import tmcaLogo from "../../assets/img/TMCA_Logo.jpg";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import { getAppInsights } from "./../../telemetry/telemetryService";
import ViewPhysicalPreview from "../ViewPhysicalPreview";

interface IViewStatusProps {
  QueryData: IState;
}

const qrCode = new QRCodeStyling({
  width: 350,
  height: 350,
  image: tmcaLogo,
  dotsOptions: {
    color: "#4267b2",
    type: "rounded"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 0
  }
});

const ViewStatus: React.FC<IViewStatusProps> = ({ QueryData }) => {
  const { appInsights, trackEvent, trackMetrix } = getAppInsights();

  const [isPhysicalTableVisible, setIsPhysicalTableVisible] = React.useState<boolean>(true);
  const [isDigitalTableVisible, setIsDigitalTableVisible] = React.useState<boolean>(true);
  const [digitalPagination, setDigitalPagination] = React.useState<{ page_size: number, start_index: number }>({ page_size: 5, start_index: 0 });
  const [PhyPagination, setPhyPagination] = React.useState<{ page_size: number, start_index: number }>({ page_size: 5, start_index: 0 });
  const [isAction, setIsAction] = React.useState<{ type?: string, item?: VistorAction.IGetDigitalVcDataItem }>({});
  const [appInsightData, setAppInsightData] = React.useState<any>({});
  const [deleteCard, setDeleteCard] = React.useState<{
    deleteId: string
    remark: string
    confirmDelete: boolean
  }>({
    deleteId: "",
    remark: "",
    confirmDelete: false
  });
  const [isPhysicalPreview, setIsPhysicalPreview] = React.useState<{ isActive: boolean, data?: VistorAction.IGetPhysicalVcDataitem }>();

  const ref = React.useRef<any>();
  const dispatch = useDispatch();
  const state = useSelector((state: RootSchema) => state);

  const remarkItems = [
    {
      name: "Accidental creation of visiting card",
      key: "1",
      label: "Accidental creation of visiting card",
      value: "1"
    },
    {
      name: "Wanted a different values of the fields",
      key: "2",
      label: "Wanted a different values of the fields",
      value: "2"
    },
    {
      name: "Entered an incorrect contact details",
      key: "3",
      label: "Entered an incorrect contact details",
      value: "3"
    },
    {
      name: "My department/role got changed",
      key: "4",
      label: "My department/role got changed",
      value: "4"
    },
    {
      name: "I no longer need physical visiting card",
      key: "5",
      label: "I no longer need physical visiting card",
      value: "5"
    }
  ];

  const showPhysical = () => {
    setIsPhysicalTableVisible(!isPhysicalTableVisible);
  };

  const showDigital = () => {
    setIsDigitalTableVisible(!isDigitalTableVisible);
  };

  const onDownloadClick = async (item: VistorAction.IGetDigitalVcDataItem) => {
    try {
      qrCode.update({ data: decodeURI(state.visitorData.getQrStringData.data as string) });
      setIsAction({});
      await qrCode.download({ extension: "png", name: item.EmployeeName.replaceAll(" ", "_") + moment().format("DDMMYYYYHHmmss") });
      trackEvent(appInsights, TraceEvent.VC_DOWNLOAD_QR, { message: Constant.VC_DOWNLOAD_SUCCESS_MESSAGE });
    } catch (error: any) {
      trackEvent(appInsights, TraceEvent.VC_DOWNLOAD_QR_ERROR, { message: error.message });
    }
  };

  const onSendMailHandler = async (item: VistorAction.IGetDigitalVcDataItem) => {
    // eslint-disable-next-line prefer-const
    let qr = qrCode._canvas?.toDataURL();
    const payload = {
      to: state.leaveData.employeeData.mail ?? "",
      subject: Constant.VC_QR_MAIL_SUBJECT,
      userDetails: {
        name: item.EmployeeName,
        designation: item.Designation,
        email: state.leaveData.employeeData.mail ?? "",
        company: EmpCompanyByCode[state.authData.userAuth.comp_code as keyof typeof EmpCompanyByCode],
        field_1: item.Division,
        field_2: item.OU1_TEXT,
        field_3: item.OU2_TEXT,
        office_address: item.OfficeAddress,
        contact_number: item.MobileNo.length === 10 ? "+91" + item.MobileNo : item.MobileNo,
        tel_number: item.TeleNo.length === 10 ? "+91" + item.TeleNo : item.TeleNo
      },
      fileName: item.EmployeeName.replaceAll(" ", "_") + moment().format("DDMMYYYYHHmmss"),
      // base64: qrCode._qr?.createDataURL(50,0).split(",")[1] as string
      base64: qr?.split(",")[1] as string
    };
    await dispatch(getSendMailDataAction(payload));
    setAppInsightData({ ...appInsightData, send_mail: payload });

  };

  const onAppendQR = async (item: VistorAction.IGetDigitalVcDataItem) => {
    qrCode.append(ref.current);
    qrCode.update({ data: decodeURI(state.visitorData.getQrStringData.data as string) });
    dispatch(getSendMailLoadingAction());
    await setTimeout(() => {
      onSendMailHandler(item);
    }, 2000);

  };

  const onGenerateQRCode = (type: string, item: VistorAction.IGetDigitalVcDataItem) => {
    const empName = item.EmployeeName.split(" ");
    const payload = {
      firstName: ((empName.some((item) => item === getUserTitle(state.userInfo.userManagerData.user_details[0]?.Title).trim())) ? getUserTitle(state.userInfo.userManagerData.user_details[0]?.Title) : "") + (empName.some((item) => item === state.userInfo.userManagerData.user_details[0]?.Firstname) ? state.userInfo.userManagerData.user_details[0]?.Firstname : ""),
      middleName: empName.some((item) => item === state.userInfo.userManagerData.user_details[0]?.Midnm) ? state.userInfo.userManagerData.user_details[0]?.Midnm : "",
      lastName: empName.some((item) => item === state.userInfo.userManagerData.user_details[0]?.LastName) ? state.userInfo.userManagerData.user_details[0]?.LastName : "",
      organization: state.userInfo.userManagerData.user_details[0]?.Ccodetxt,
      workPhone: item.TeleNo.length === 10 ? "+91" + item.TeleNo : item.TeleNo,
      cellPhone: item.MobileNo.length === 10 ? "+91" + item.MobileNo : item.MobileNo,
      workEmail: state.leaveData.employeeData.mail ?? "",
      workUrl: Constant.TML_URL,
      role: item.Designation.concat(item.Division && ", " + item.Division).concat(item.OU1_TEXT && ", " + item.OU1_TEXT).concat(item.OU2_TEXT && ", " + item.OU2_TEXT),
      workAddress: {
        label: "WorkAddress",
        street: item.OfficeAddress
      }
    };
    dispatch(VistorAction.getQRStringAction(payload));
    setIsAction({ type: type, item: item });
  };

  const physicalVisitingCardHeader = [
    {
      content: <Text size="medium" content="Request Id" weight="bold" />,
      key: "action",
      styles: { maxWidth: "120px", minWidth: "110px", justifyContent: "center" },
    },
    {
      content: <Text size="medium" content="Name" weight="bold" />,
      key: "name",
      styles: { minWidth: "100px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Designation" weight="bold" />,
      key: "designation",
      styles: { minWidth: "150px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Approval Officer" weight="bold" />,
      key: "approval",
      styles: { minWidth: "130px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Status" weight="bold" />,
      key: "status",
      styles: { minWidth: "80px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Request Date" weight="bold" />,
      key: "requestdate",
      styles: { minWidth: "110px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Preview" weight="bold" />,
      key: "preview",
      styles: { minWidth: "70px", maxWidth: "100px", justifyContent: "center" },
    },
    // {
    //   content: <Text size="medium" content="Action" weight="bold" />,
    //   key: "delete",
    //   styles: { minWidth: "50px", maxWidth: "80px", justifyContent: "center" },
    // },
  ];

  const digitalVisitingCardHeader = [
    {
      content: <Text size="medium" content="Request Id" weight="bold" />,
      key: "action",
      styles: { maxWidth: "120px", minWidth: "110px", justifyContent: "center" },
    },
    {
      content: <Text size="medium" content="Name" weight="bold" />,
      key: "lc",
      styles: { minWidth: "100px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Designation" weight="bold" />,
      key: "fd",
      styles: { minWidth: "150px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Request Date" weight="bold" />,
      key: "td",
      styles: { minWidth: "110px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Download" weight="bold" />,
      key: "download",
      styles: { minWidth: "100px", justifyContent: "start" },
    },
    {
      content: <Text size="medium" content="Sent Mail" weight="bold" />,
      key: "mail",
      styles: { minWidth: "110px", justifyContent: "start" },
    },
  ];

  const physicalVisitingCardRow = state.visitorData.getPhysicalVc.isSuccess && state.visitorData.getPhysicalVc.status_code === "200" && state.visitorData.getPhysicalVc.data[1].length ?
    state.visitorData.getPhysicalVc.data[1].map((item, index) => {
      return {
        key: index + ".",
        items: [
          {
            content: item.ID,
            key: index + ".1",
            styles: { maxWidth: "120px", minWidth: "110px", justifyContent: "center" },
          },
          {
            content: item.EmployeeName,
            key: index + ".2",
            styles: { minWidth: "100px", justifyContent: "start" },
          },
          {
            content: item.Designation,
            key: index + ".3",
            styles: { minWidth: "150px", justifyContent: "start" },
          },
          {
            content: item.ReportTo,
            key: index + ".4",
            styles: { minWidth: "130px", justifyContent: "start" },
          },
          {
            content: item.Status,
            key: index + ".5",
            styles: { minWidth: "80px", justifyContent: "start" },
          },
          {
            content: getDDMMYYYFormat(item.RequestDate),
            key: index + ".6",
            styles: { minWidth: "110px", justifyContent: "start" },
          },
          {
            content: <Button size="small" iconOnly icon={<ContactCardIcon />} flat onClick={() => setIsPhysicalPreview({ isActive: true, data: item })} />,
            key: index + ".7",
            styles: { minWidth: "70px", maxWidth: "100px", justifyContent: "center" },
          },
          // {
          //   content: <Button size="small" iconOnly disabled={item.Status !== "Pending For Verification"} icon={<TrashCanIcon />} flat onClick={() => { setDeleteCard({ deleteId: item.ID, confirmDelete: true, remark: "" }); }} />,
          //   key: index + ".8",
          //   styles: { minWidth: "50px", maxWidth: "80px", justifyContent: "center" },
          // }
        ]
      };
    })
    : [];

  const digitalVisitingCardRow = state.visitorData.getDigitalVc.isSuccess && state.visitorData.getDigitalVc.status_code === "200" && state.visitorData.getDigitalVc.data[1].length ?
    state.visitorData.getDigitalVc.data[1].map((item, index) => {
      return {
        key: index,
        items: [
          {
            content: item.ID,
            key: index + "1",
            styles: { maxWidth: "120px", minWidth: "110px", justifyContent: "center" },
          },
          {
            content: item.EmployeeName,
            key: index + "2",
            styles: { minWidth: "100px", justifyContent: "start" },
          },
          {
            content: item.Designation,
            key: index + "3",
            styles: { minWidth: "150px", justifyContent: "start" },
          },
          {
            content: getDDMMYYYFormat(item.RequestDate),
            key: index + "4",
            styles: { minWidth: "110px", justifyContent: "start" },
          },
          {
            content: {
              content: <Button size="small" content="Download" tinted loading={isAction.type === "download" && isAction.item?.ID === item.ID && state.visitorData.getQrStringData.isLoading} />,
              onClick: () => onGenerateQRCode("download", item),
              key: index + "_5"
            },
            key: index + "5",
            styles: { minWidth: "100px", justifyContent: "start" },
          },
          {
            content: {
              content: <Button size="small" content="Send Mail" tinted loading={isAction.type === "mail" && isAction.item?.ID === item.ID && (state.visitorData.getQrStringData.isLoading || state.sendMail.isLoading)} />,
              onClick: () => onGenerateQRCode("mail", item),
              key: index + "_6"
            },
            key: index + "6",
            styles: { minWidth: "110px", justifyContent: "start" },
          }
        ]
      };
    })
    : [];

  React.useEffect(() => {
    if (state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200") {
      const payload = {
        start_index: (digitalPagination.start_index * digitalPagination.page_size + 1).toString(),
        page_size: digitalPagination.page_size.toString(),
        person_area: state.userInfo.userManagerData.user_details[0].PersArea as string,
        emp_id: parseInt(QueryData.employeeId as string).toString()
      };
      dispatch(VistorAction.getDigitalVcAction(payload));
    }
  }, [digitalPagination, state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200"]);

  React.useEffect(() => {
    if (state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200") {
      const payload = {
        start_index: (PhyPagination.start_index * PhyPagination.page_size + 1).toString(),
        page_size: PhyPagination.page_size.toString(),
        person_area: state.userInfo.userManagerData.user_details[0].PersArea as string,
        emp_id: parseInt(QueryData.employeeId as string).toString(),
      };
      dispatch(VistorAction.getPhysicalVcAction(payload));
    }
  }, [PhyPagination, state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200"]);

  React.useEffect(() => {
    if (state.sendMail.isSuccess && state.sendMail.status_code === 202 && isAction.type === "mail") {
      SuccessToast(state.sendMail.message);
      setIsAction({});
      trackEvent(appInsights, TraceEvent.VC_SEND_MAIL_QR, appInsightData.send_mail);
    }
    else if (state.sendMail.isSuccess && state.sendMail.status_code !== 202 && isAction.type === "mail") {
      ErrorToast(state.sendMail.message);
      trackEvent(appInsights, TraceEvent.VC_SEND_MAIL_QR_ERROR, appInsightData.send_mail);
    }
  }, [state.sendMail.isSuccess]);

  React.useEffect(() => {
    if (isAction.type === "download") {
      onDownloadClick(isAction.item as VistorAction.IGetDigitalVcDataItem);
    } else if (isAction.type === "mail") {
      onAppendQR(isAction.item as VistorAction.IGetDigitalVcDataItem);
    }
  }, [state.visitorData.getQrStringData.isSuccess]);


  const deletePhysicalVisitingCard = () => {
    const modifiedBy = state.leaveData.employeeData.mail ?? "";
    dispatch(VistorAction.updatePhysicalVCAction(deleteCard.deleteId, deleteCard.remark, modifiedBy));
  };

  React.useEffect(() => {
    if (state.visitorData.updatePhysicalVc.isSuccess && state.visitorData.updatePhysicalVc.status_code === "200" && deleteCard.deleteId !== "") {
      SuccessToast(state.visitorData.updatePhysicalVc.message);
      setDeleteCard({ deleteId: "", confirmDelete: false, remark: "" });
      const payload = {
        start_index: (PhyPagination.start_index * PhyPagination.page_size + 1).toString(),
        page_size: PhyPagination.page_size.toString(),
        person_area: state.userInfo.userManagerData.user_details[0].PersArea as string,
        emp_id: parseInt(QueryData.employeeId as string).toString(),
      };
      dispatch(VistorAction.getPhysicalVcAction(payload));
    } else if (state.visitorData.updatePhysicalVc.isSuccess && state.visitorData.updatePhysicalVc.status_code !== "200" && deleteCard.deleteId !== "") {
      setDeleteCard({ deleteId: "", confirmDelete: false, remark: "" });
      ErrorToast(state.visitorData.updatePhysicalVc.message);
    }
  }, [state.visitorData.updatePhysicalVc.isSuccess]);

  return (
    <React.Fragment>
      {(state.visitorData.getDigitalVc.isError || state.visitorData.getPhysicalVc.isError || (state.visitorData.getDigitalVc.isSuccess && state.visitorData.getDigitalVc.status_code !== "200") || (state.visitorData.getPhysicalVc.isSuccess && state.visitorData.getPhysicalVc.status_code !== "200")) ?
        <Flex vAlign="center" hAlign="center" styles={{ height: "calc(100vh - 150px)" }} >
          <ErrorComponent message={"Something went wrong..."} />
        </Flex> :
        <Flex padding="padding.medium" gap="gap.small" column>
          <Box>
            <Flex vAlign="center" space="between" wrap>
              <Flex gap="gap.medium" onClick={() => showPhysical()} styles={{ cursor: "pointer" }} vAlign="center" padding="padding.medium" >
                <Text content="Printed Visiting Card Details" weight="semibold" styles={{ whiteSpace: "nowrap", fontSize: "16px" }} color="brand" />
                <ChevronDownIcon rotate={isPhysicalTableVisible ? 180 : 0} />
              </Flex>
              {isPhysicalTableVisible && state.visitorData.getPhysicalVc.isSuccess && state.visitorData.getPhysicalVc.status_code === "200" && state.visitorData.getPhysicalVc.data[1].length > 0 &&
                <ViewStatusPagination pagination={PhyPagination} setPagination={setPhyPagination} totalRowCount={state.visitorData.getPhysicalVc.data[1].length} />
              }
            </Flex>
            {isPhysicalTableVisible && <Box className="BoxField" styles={{ height: QueryData.isTaskModuleActive ? "calc(100vh/2 - 70px)" : "calc(100vh/2 - 100px)" }} >
              {state.visitorData.getPhysicalVc.isSuccess && state.visitorData.getPhysicalVc.status_code === "200" && state.visitorData.getPhysicalVc.data[1].length > 0 ? (
                <Table
                  role="gridcell"
                  header={{
                    items: physicalVisitingCardHeader,
                    header: true,
                    className: "viewTableHead",
                    styles: ({ theme: { siteVariables } }) => ({
                      background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                      "&:hover": {
                        background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                      }
                    })
                  }} rows={physicalVisitingCardRow} className="viewTable" />
              ) :
                state.visitorData.getPhysicalVc.isSuccess && state.visitorData.getPhysicalVc.status_code === "200" && state.visitorData.getPhysicalVc.data[1].length === 0 ? (
                  <Flex vAlign="center" hAlign="center"  >
                    <DataNotFound />
                  </Flex>
                ) :
                  (state.visitorData.getPhysicalVc.isError || (state.visitorData.getPhysicalVc.isSuccess && state.visitorData.getPhysicalVc.status_code !== "200")) ? (
                    <Flex vAlign="center" hAlign="center"  >
                      <ErrorComponent message={state.visitorData.getPhysicalVc.message} />
                    </Flex>
                  ) :
                    <Skeleton animation="pulse" >
                      <Flex gap="gap.medium" column padding='padding.medium'>
                        {[1, 2, 3, 4, 5, 6].map((item) => {
                          return <Skeleton.Line key={item} height="25px" />;
                        })}
                      </Flex>
                    </Skeleton>
              }
            </Box>
            }
          </Box>
          <Divider />
          <Box>
            <Flex vAlign="center" space="between" wrap>
              <Flex gap="gap.medium" onClick={() => showDigital()} styles={{ cursor: "pointer" }} vAlign="center" hAlign="center" padding="padding.medium" >
                <Text content="Digital Visiting Card Details" weight="semibold" styles={{ whiteSpace: "nowrap", fontSize: "16px" }} color="brand" />
                <ChevronDownIcon rotate={isDigitalTableVisible ? 180 : 0} />
              </Flex>
              {isDigitalTableVisible && state.visitorData.getDigitalVc.isSuccess && state.visitorData.getDigitalVc.status_code === "200" && state.visitorData.getDigitalVc.data[0][0].RowCount > 0 &&
                <ViewStatusPagination pagination={digitalPagination} setPagination={setDigitalPagination} totalRowCount={state.visitorData.getDigitalVc.data[0][0].RowCount} />
              }
            </Flex>
            {isDigitalTableVisible && <Box className="BoxField" styles={{ height: QueryData.isTaskModuleActive ? "calc(100vh/2 - 70px)" : "calc(100vh/2 - 100px)" }} >
              {state.visitorData.getDigitalVc.isSuccess && state.visitorData.getDigitalVc.status_code === "200" && state.visitorData.getDigitalVc.data[1].length > 0 ? (
                <Table
                  role="gridcell"
                  header={{
                    items: digitalVisitingCardHeader,
                    header: true,
                    className: "viewTableHead",
                    styles: ({ theme: { siteVariables } }) => ({
                      background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                      "&:hover": {
                        background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                      }
                    })
                  }} rows={digitalVisitingCardRow} className="viewTable" />
              ) :
                state.visitorData.getDigitalVc.isSuccess && state.visitorData.getDigitalVc.status_code === "200" && state.visitorData.getDigitalVc.data[1].length === 0 ? (
                  <Flex vAlign="center" hAlign="center"  >
                    <DataNotFound />
                  </Flex>
                ) :
                  (state.visitorData.getDigitalVc.isError || (state.visitorData.getDigitalVc.isSuccess && state.visitorData.getDigitalVc.status_code !== "200")) ? (
                    <Flex vAlign="center" hAlign="center"  >
                      <ErrorComponent message={state.visitorData.getDigitalVc.message} />
                    </Flex>
                  ) : <Skeleton animation="pulse" >
                    <Flex gap="gap.medium" column padding='padding.medium'>
                      {[1, 2, 3, 4, 5, 6].map((item) => {
                        return <Skeleton.Line key={item} height="25px" />;
                      })}
                    </Flex>
                  </Skeleton>
              }
            </Box>
            }
          </Box>
        </Flex>}
      <Dialog
        cancelButton="Cancel"
        confirmButton={{ content: "Confirm", loading: state.visitorData.updatePhysicalVc.isLoading, disabled: deleteCard.remark == "" ? true : false }}
        open={deleteCard.confirmDelete}
        content={
          <Flex column gap="gap.medium">
            <Divider />
            <Text content={`Are you sure you want to Delete Request Id - ${deleteCard.deleteId} ?`} weight="bold" />
            <Flex column>
              <Text content="Remark*" weight="bold" />
              <RadioGroup
                vertical
                items={remarkItems}
                onCheckedValueChange={(i, e: any) => { setDeleteCard({ ...deleteCard, remark: e.name }); }}
              />
            </Flex>
          </Flex>
        }
        header={`Delete Request Id - ${deleteCard.deleteId}`}
        onCancel={() => setDeleteCard({ deleteId: "", confirmDelete: false, remark: "" })}
        onConfirm={() => deletePhysicalVisitingCard()}
      />

      <Dialog
        open={isPhysicalPreview?.isActive}
        overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none" } : {} }}
        styles={QueryData.isTaskModuleActive ? { boxShadow: "5px 5px 5px 5px #888888" } : {}}
        content={<ViewPhysicalPreview item={isPhysicalPreview?.data as VistorAction.IGetPhysicalVcDataitem} />}
        closeOnOutsideClick
        onCancel={() => setIsPhysicalPreview({ isActive: false })}
        header={"Visiting Card Preview (Request Id - " + (isPhysicalPreview?.data?.ID) + ")"}
        headerAction={{
          content: <CloseIcon />,
          onClick: () => setIsPhysicalPreview({ isActive: false })
        }}
      />
    </React.Fragment>
  );
};

export default ViewStatus;
