import { put, call, take, delay, takeLatest, takeEvery } from "redux-saga/effects";
import * as actionCreators from "../actions/IMACAction";
import { httpGet, httpPost, httpPost2 } from "./../../service";
import { API_SECRET_TOKEN, IMAC_API_CONFIG, ITSM_BASE_URL } from "../../config";

function* getFloorData() {
    try {
        yield put(actionCreators.getFloorLoadinngAction());
        const { data } = yield httpGet(
            IMAC_API_CONFIG.GET_FLOOR_DATA,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getFloorSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getFloorErrorAction(error));
    }
}

function* getLocationData() {
    try {
        yield put(actionCreators.getLocationLoadinngAction());
        const { data } = yield httpGet(
            IMAC_API_CONFIG.GET_LOCATION_DATA,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getLocationSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getLocationErrorAction(error));
    }
}

function* getSiteData() {
    try {
        yield put(actionCreators.getSiteLoadinngAction());
        const { data } = yield httpGet(
            IMAC_API_CONFIG.GET_SITE_DATA,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getSiteSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getSiteErrorAction(error));
    }
}

function* getIMACCategoryOneData() {
    try {
        yield put(actionCreators.getIMACCategoryOneLoadinngAction());
        const { data } = yield httpGet(
            IMAC_API_CONFIG.GET_IMAC_CATEGORY_ONE_DATA,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getIMACCategoryOneSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getIMACCategoryOneErrorAction(error));
    }
}

function* getIMACCategoryTwoData(payload: number) {
    try {
        yield put(actionCreators.getIMACCategoryTwoLoadinngAction());
        const { data } = yield httpGet(
            IMAC_API_CONFIG.GET_IMAC_CATEGORY_TWO_DATA + "/" + payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getIMACCategoryTwoSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getIMACCategoryTwoErrorAction(error));
    }
}

function* getIMACCategoryThreeData(payload: number) {
    try {
        yield put(actionCreators.getIMACCategoryThreeLoadinngAction());
        const { data } = yield httpGet(
            IMAC_API_CONFIG.GET_IMAC_CATEGORY_THREE_DATA + "/" + payload,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getIMACCategoryThreeSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getIMACCategoryThreeErrorAction(error));
    }
}

function* getAssetDetailsData(payload: {
    access_token: string;
    payload: actionCreators.IGetAssetData
}) {
    try {
        yield put(actionCreators.getAssetLoadingAction());
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            ITSM_BASE_URL + IMAC_API_CONFIG.GET_ASSETS_API,
            payload.payload,
            config
        );
        yield put(actionCreators.getAssetSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getAssetErrorAction(error));
    }
}

function* postDomainIdByAdData(action: {
    type: string;
    payload: { access_token: string; name: string }
}) {
    try {
        yield put(actionCreators.postDomainIdLoadinngAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };

        const { data } = yield httpPost(
            // IMAC_API_CONFIG.POST_DOMIAN_ID_BY_AD + action.payload.name,
            IMAC_API_CONFIG.POST_DOMIAN_ID_BY_AD,
            {
                requests: [
                    {
                        entityTypes: ["person"],
                        query: { queryString: action.payload.name },
                        from: 0,
                        size: 50
                    }
                ]
            },
            config
        );
        yield put(actionCreators.postDomainIdSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postDomainIdErrorAction(error));
    }
}

function* postDomainIdManagerByAdData(action: {
    type: string;
    payload: { access_token: string; id: string }
}) {
    try {
        yield put(actionCreators.postDomainIdManagerLoadinngAction());
        const config = {
            headers: {
                Authorization: `Bearer ${action.payload.access_token}`,
            },
        };

        const { data } = yield httpGet(
            IMAC_API_CONFIG.POST_DOMIAN_ID_MANAGER_BY_AD + action.payload.id + "?$expand=manager($levels=1)&$select=EmployeeID,mailNickname,userPrincipalName",
            config
        );
        yield put(actionCreators.postDomainIdManagerSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postDomainIdManagerErrorAction(error));
    }
}

export function* watchGetFloorData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_FLOOR_DATA
        );
        yield call(getFloorData);
    }
}

export function* watchGetLocationData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_LOCATION_DATA
        );
        yield call(getLocationData);
    }
}

export function* watchGetSiteData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_SITE_DATA
        );
        yield call(getSiteData);
    }
}

export function* watchGetIMACCategoryOneData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_IMAC_CATEGORY_ONE_DATA
        );
        yield call(getIMACCategoryOneData);
    }
}

export function* watchGetIMACCategoryTwoData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_IMAC_CATEGORY_TWO_DATA
        );
        yield call(getIMACCategoryTwoData, payload);
    }
}

export function* watchGetIMACCategoryThreeData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_IMAC_CATEGORY_THREE_DATA
        );
        yield call(getIMACCategoryThreeData, payload);
    }
}

export function* watchGetAssetData() {
    while (true) {
        const { payload } = yield take(
            actionCreators.Actions.GET_ASSETS_DATA
        );
        yield call(getAssetDetailsData, payload);
    }
}

export function* watchPostDomainIdByAdData() {
    yield takeLatest(actionCreators.Actions.POST_DOMAIN_ID_DATA, postDomainIdByAdData);
}

export function* watchPostDomainIdManagerByAdData() {
    yield takeLatest(actionCreators.Actions.POST_DOMAIN_ID_MANAGER_DATA, postDomainIdManagerByAdData);
}