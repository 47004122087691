import { Actions } from "../actions/ChannelAction";

const getVideoLibraryReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_VIDEO_LIBRARY_LOADING:
        case Actions.GET_VIDEO_LIBRARY_ERROR:
        case Actions.GET_VIDEO_LIBRARY_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default getVideoLibraryReducer;