import React from "react";
import {
    Avatar,
    Text,
    Flex,
    FlexItem,
    Button,
    Dropdown,
    Table,
    Dialog,
    Status,
    Loader,
    Portal,
    CloseIcon,
    TrashCanIcon,
    Divider,
    Box,
    TableRow,
    TableCell,
    ChevronEndIcon,
    ChevronDownIcon,
    Skeleton,
} from "@fluentui/react-northstar";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import * as LeaveAppliedAction from "../../redux/actions/LeaveActions";
import { SuccessToast, ErrorToast } from "../ToastifyComponent";
import {
    LeaveTypes,
    InputStatus,
    InputMonth,
    Constant,
    LeaveTypeCatogary,
    InputStatusCode,
    EmpValueByCode,
    getDDMMYYYFormat,
} from "../../constant";
import { IState } from "../../App";
import "./viewleave.scss";
import DataNotFound from "../DNF";

interface ILeaveDetailsProps {
    QueryData: IState;
}

interface IFillterLeave {
    type: { header: string; name: string; code: string };
    status: { header: string; name: string; code: string };
    month: number;
}

const ViewLeave: React.FC<ILeaveDetailsProps> = ({ QueryData }) => {
    const [dialogOpen, setdialogOpen] = React.useState<{
        isOpen: boolean;
        itemData: any;
    }>({
        isOpen: false,
        itemData: {},
    });
    const [isExpand, setIsExpand] = React.useState<string>("");
    const [refresh, setRefresh] = React.useState<number>(0);
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const accessToken = state.authData.userAuth.token;
    const empCode =
        EmpValueByCode[
        state.authData.userAuth.comp_code as keyof typeof EmpValueByCode
        ];

    const TableHeader = [
        {
            content: <Text size="medium" content="Action" weight="semibold" />,
            key: "action",
            isActive: true,
            styles: {
                justifyContent: "center",
                margin: "0px",
                maxWidth: "80px",
            },
        },
        {
            content: (
                <Text size="medium" content="Leave Category" weight="semibold" />
            ),
            key: "lc",
            isActive: true,
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            content: <Text size="medium" content="From Date" weight="semibold" />,
            key: "fd",
            isActive: true,
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            content: <Text size="medium" content="To Date" weight="semibold" />,
            key: "td",
            isActive: true,
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            content: <Text size="medium" content="Status" weight="semibold" />,
            key: "status",
            isActive: true,
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
        {
            content: "",
            key: "expand",
            isActive: true,
            styles: {
                justifyContent: "center",
                margin: "0px",
            },
        },
    ];

    const rowsPlain =
        state.leaveData.leaveHistory.isSuccess &&
            state.leaveData.leaveHistory.status_code === "200" &&
            state.leaveData.leaveHistory.data.leave_requests.length > 0
            ? state.leaveData.leaveHistory.data.leave_requests.map((item, index) => {
                return {
                    key: index,
                    items: [
                        {
                            content:
                                item.leave_status_code === "3" ||
                                    item.leave_status_code === "9" ? (
                                    <TrashCanIcon disabled />
                                ) : (
                                    <TrashCanIcon
                                        onClick={() => onDeleteHandler(item)}
                                        style={{ cursor: "pointer" }}
                                    />
                                ),
                            key: index + "1",
                            isActive: true,
                            styles: {
                                justifyContent: "center",
                                maxWidth: "80px",
                            },
                        },
                        {
                            content: item.leave_category,
                            key: index + "2",
                            isActive: true,
                            styles: {
                                justifyContent: "center",
                            },
                        },
                        {
                            content: getDDMMYYYFormat(item.from_date),
                            key: index + "3",
                            isActive: true,
                            styles: {
                                justifyContent: "center",
                            },
                        },
                        {
                            content: getDDMMYYYFormat(item.to_date),
                            key: index + "4",
                            isActive: true,
                            styles: {
                                justifyContent: "center",
                            },
                        },
                        {
                            key: index + "5",
                            content: item.leave_status,
                            isActive: true,
                            styles: {
                                justifyContent: "center",
                            },
                        },
                        {
                            key: index + "7",
                            content: item.leave_type,
                            isActive: false,
                            styles: {
                                justifyContent: "center",
                            },
                        },
                        {
                            key: index + "8",
                            content: item.total_days,
                            isActive: false,
                            styles: {
                                justifyContent: "center",
                            },
                        },
                        {
                            key: index + "9",
                            content: item.reason,
                            isActive: false,
                            styles: {
                                justifyContent: "center",
                            },
                        },
                        {
                            content:
                                isExpand === String(index) ? (
                                    <ChevronEndIcon rotate={-90} />
                                ) : (
                                    <ChevronDownIcon />
                                ),
                            key: index + "6",
                            isActive: true,
                            styles: { justifyContent: "center", cursor: "pointer" },
                            onclick: () =>
                                isExpand === String(index)
                                    ? setIsExpand("")
                                    : setIsExpand(String(index)),
                        },
                    ],
                };
            })
            : [];

    const onDeleteHandler = (item?: any) => {
        dialogOpen.isOpen
            ? setdialogOpen({ isOpen: false, itemData: {} })
            : setdialogOpen({ isOpen: true, itemData: item });
    };

    const onDialogConfirmHandler = (item: any) => {
        const deletePayload: LeaveAppliedAction.ILeaveDeleteHistoryPayload = {
            emp_id: QueryData.employeeId as string,
            leave_request_id: item.leave_request_id,
            from_date: item.from_date,
            to_date: item.to_date,
            leave_status: item.leave_status_code,
            approver_email: state.userInfo.userManagerData.manager_details[0]?.Imailid,
            emp_name: state.userInfo.userManagerData.user_details[0].CompName,
            leave_type_code: item.leave_type_code,
            leave_category_code: item.leave_category_code,
            approver: state.userInfo.userManagerData.manager_details[0]?.Perno,
            reason: item.reason,
            pa: state.userInfo.userManagerData.user_details[0].PersArea,
            psa: state.userInfo.userManagerData.user_details[0].PSubarea,
            emp_company_code: state.authData.userAuth.comp_code,
            manager_company_code: state.userInfo.userManagerData.manager_details[0]?.CompCode ?? ""
        };
        dispatch(
            LeaveAppliedAction.postLeaveDeleteHistoryAction(
                accessToken,
                empCode,
                deletePayload
            )
        );
    };

    React.useEffect(() => {
        if (
            state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" &&
            state.userInfo.userManagerData.isSuccess
        ) {
            const historyPayload: LeaveAppliedAction.ILeaveHistoryPayload = {
                emp_id: QueryData.employeeId as string,
                leave_category_code: "All",
                leave_status_code: "0",
                filter_type: "month",
                month:
                    new Date().getMonth() + 1 < 10
                        ? "0" + (new Date().getMonth() + 1).toString()
                        : (new Date().getMonth() + 1).toString(),
                pa: state.userInfo.userManagerData.user_details[0].PersArea,
                psa: state.userInfo.userManagerData.user_details[0].PSubarea,
            };
            dispatch(
                LeaveAppliedAction.getLeaveHistoryAction(
                    accessToken,
                    empCode,
                    historyPayload
                )
            );
        }
    }, [
        state.userInfo.userManagerData.isSuccess &&
        state.userInfo.userManagerData.status_code === "200",
        refresh,
    ]);

    React.useEffect(() => {
        if (
            state.leaveData.postLeaveDelete.isSuccess &&
            state.leaveData.postLeaveDelete.status_code === "200" &&
            dialogOpen.isOpen
        ) {
            SuccessToast(state.leaveData.postLeaveDelete.message);
            onDeleteHandler();
            setRefresh(refresh + 1);
            setIsExpand("");
        } else if (
            state.leaveData.postLeaveDelete.isSuccess &&
            state.leaveData.postLeaveDelete.status_code !== "200" &&
            dialogOpen.isOpen
        ) {
            ErrorToast(state.leaveData.postLeaveDelete.message);
            onDeleteHandler();
        }
    }, [state.leaveData.postLeaveDelete.isSuccess]);

    return (
        <React.Fragment>
            <Box className="ViewLeave">
                {state.leaveData.leaveHistory.isSuccess &&
                    state.leaveData.leaveHistory?.data.leave_requests.length > 0 ? (
                    <Box className="box-table">
                        {/* <Table
     rows={rows}
     header={{
         items: header,
         header: true,
         className: "tableHead",
     }}
 /> */}
                        <TableRow className="tableHead"
                            styles={({ theme: { siteVariables } }) => ({
                                background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                "&:hover": {
                                    background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground
                                }
                            })}
                        >
                            {TableHeader.map((item, index) => {
                                return (
                                    item.isActive && (
                                        <TableCell
                                            content={item.content}
                                            key={item.key}
                                            style={item.styles}
                                        />
                                    )
                                );
                            })}
                        </TableRow>
                        {rowsPlain.map((item, index) => {
                            return (
                                <Box key={item.key}>
                                    <TableRow key={item.key}>
                                        {item.items.map((ele: any, index: any) => {
                                            return (
                                                ele.isActive && (
                                                    <TableCell
                                                        content={ele.content}
                                                        truncateContent={ele.truncateContent}
                                                        key={ele.key}
                                                        style={ele.styles}
                                                        onClick={ele.onclick}
                                                    />
                                                )
                                            );
                                        })}
                                    </TableRow>
                                    {isExpand === String(index) && (
                                        <Box>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    Type Of Leave
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.items[1].content}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    Request Type
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.items[5].content}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    From Date
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.items[2].content}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    To Date
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.items[3].content}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    Total Days
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.items[6].content}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    Reason
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        justifyContent: "start",
                                                        padding: "0 4rem",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.items[7].content}
                                                </TableCell>
                                            </TableRow>
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                ) : state.leaveData.leaveHistory.isSuccess &&
                    state.leaveData.leaveHistory?.data.leave_requests.length === 0 ? (
                    <Flex vAlign="center" hAlign="center" style={{ height: "100vh" }}>
                        <DataNotFound />
                    </Flex>
                ) : (
                    <Flex style={{ height: "100vh" }}>
                        <Skeleton
                            animation="pulse"
                        //styles={{margin:'10px'}}
                        >
                            <Flex gap="gap.small">
                                <Skeleton styles={{ margin: "10px" }}>
                                    <Flex gap="gap.medium" column>
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                        <Skeleton.Text />
                                    </Flex>
                                </Skeleton>
                            </Flex>
                        </Skeleton>
                    </Flex>
                )}

                <Dialog
                    open={dialogOpen.isOpen}
                    header="Confirm Delete"
                    headerAction={{
                        icon: <CloseIcon />,
                        title: "Close",
                        onClick: () => onDeleteHandler(),
                    }}
                    content="Are you sure you want to confirm this action?"
                    confirmButton={{
                        content: "Submit",
                        loading: state.leaveData.leaveHistory.isLoading ? true : false,
                    }}
                    cancelButton="Cancel"
                    onConfirm={() => onDialogConfirmHandler(dialogOpen.itemData)}
                    onCancel={() => onDeleteHandler()}
                />
            </Box>
        </React.Fragment>
    );
};
export default ViewLeave;
