/* eslint-disable indent */
import React from "react";
import {
    Dropdown,
    Segment,
    Flex,
    Button,
    Table,
    Text,
    Divider,
    Header,
    Skeleton,
    Box,
    Input,
    SearchIcon,
    ChevronStartIcon,
    ChevronEndIcon,
    ArrowLeftIcon,
} from "@fluentui/react-northstar";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import { Constant, getDDMMYYYFormat } from "../../constant";
import DataNotFound from "../../components/DNF";
import ErrorComponent from "../../components/ErrorComponent";
import { IState } from "../../App";
import * as ITSMAction from "../../redux/actions/ITSMAction";
import "./itsmApproval.scss";
import ITSMApprovalDetails from "../../components/ITSMApprovalDetails";
import { useNavigate } from "react-router-dom";

interface IITSMApprovalsProps {
    QueryData: IState;
    isManager: boolean;
}

export interface IRequestApprovals {
    apprIdNumber: string,
    apprModule: string,
    apprRecordId: string,
    apprStatus: string,
    apprDescription: string,
    apprQueue: string,
    apprApprover: string,
    apprCreatedBy: string,
    apprCreatedOn: string,
    apprRequester: string,
    apprSequence: number
}

const ITSMApprovals: React.FC<IITSMApprovalsProps> = ({ QueryData, isManager }) => {
    const [employeeData, setEmployeeData] = React.useState<IRequestApprovals>({
        apprIdNumber: "",
        apprModule: "",
        apprRecordId: "",
        apprStatus: "",
        apprDescription: "",
        apprQueue: "",
        apprApprover: "",
        apprCreatedBy: "",
        apprCreatedOn: "",
        apprRequester: "",
        apprSequence: 0
    });
    const [Status, setStatus] = React.useState("All");
    const [searchTicket, setSearchTicket] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState({
        listPerPage: isManager ? 10 : 8,
        currentPage: 0,
    });
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    function handleRowClick(record: any, data: any) {
        setEmployeeData(data);
        setOpen(true);
    }

    const navigate = useNavigate();
    const handleRoute = (path: string) => {
        navigate(path);
    };

    const rows: any =
        state.itsmData.getMyApproval.isSuccess &&
            Array.isArray(state.itsmData.getMyApproval.approvals)
            ? state.itsmData.getMyApproval.approvals
                .filter(
                    (item) =>
                        (item.apprIdNumber + " " + item.apprRequester + " " + item.apprRecordId)
                            .toLowerCase()
                            .search(searchTicket.toLowerCase().trimStart()) == -1 ? false : true
                )
                .map((item, index) => {
                    return {
                        key: index,
                        items: [
                            {
                                content: <Text content={index + 1} />,
                                key: index + "-2",
                                isActive: false,
                                styles: { maxWidth: "80px", minWidth: "60px", justifyContent: "center" },
                            },
                            {
                                content: item.apprIdNumber,
                                key: index + "1",
                                styles: {
                                    margin: "0px",
                                    minWidth: "120px",
                                    justifyContent: "start",
                                },
                            },
                            {
                                content: item.apprRecordId,
                                key: index + "6",
                                styles: {
                                    margin: "0px",
                                    minWidth: "120px",
                                    justifyContent: "start",
                                },
                            },
                            {
                                content: item.apprModule,
                                key: index + "2",
                                truncateContent: false,
                                styles: {
                                    margin: "0px",
                                    minWidth: "80px",
                                    justifyContent: "start",
                                },
                            },
                            {
                                content: getDDMMYYYFormat(item.apprCreatedOn),
                                key: index + "3",
                                styles: {
                                    margin: "0px",
                                    justifyContent: "start",
                                    minWidth: "100px",
                                },
                            },
                            {
                                content: item.apprRequester,
                                key: index + "4",
                                styles: {
                                    margin: "0px",
                                    justifyContent: "start",
                                    minWidth: "50px",
                                },
                            },
                        ],
                        onClick: (record: any) => handleRowClick(record, item),
                        styles: { cursor: "pointer" },
                    };
                })
            : [];

    const header = [
        {
            content: <Text content="Sr No." weight="bold" size="medium" />,
            key: "sr",
            isActive: false,
            styles: {
                margin: "0px",
                maxWidth: "80px",
                minWidth: "60px",
                justifyContent: "center",
            },
        },
        {
            content: <Text content="Approval ID" weight="bold" size="medium" />,
            key: "tid",
            styles: {
                margin: "0px",
                minWidth: "120px",
                justifyContent: "start",
            },
        },
        {
            content: <Text content="Ticket ID" weight="bold" size="medium" />,
            key: "tid",
            styles: {
                margin: "0px",
                minWidth: "120px",
                justifyContent: "start",
            },
        },
        {
            content: <Text content="Category" weight="bold" size="medium" />,
            key: "category",
            styles: {
                margin: "0px",
                minWidth: "80px",
                justifyContent: "start",
            },
        },
        {
            content: <Text content="Opening Date" weight="bold" size="medium" />,
            key: "odate",
            styles: {
                margin: "0px",
                justifyContent: "start",
                minWidth: "100px",
            },
        },
        {
            content: <Text content="Requested By" weight="bold" size="medium" />,
            key: "description",
            styles: {
                margin: "0px",
                justifyContent: "start",
                minWidth: "50px",
            },
        },
    ];

    React.useEffect(() => {
        if (!state.itsmData.getTokenData.isSuccess) {
            dispatch(ITSMAction.getTokenDataAction());
        }
    }, []);

    React.useEffect(() => {
        if (state.itsmData.getTokenData.isSuccess) {
            dispatch(
                ITSMAction.getUserApprovalDataAction(
                    state.itsmData.getTokenData.access_token,
                    QueryData.userId as string
                )
            );
        }
    }, [state.itsmData.getTokenData.isSuccess, state.itsmData.updateApproval.isSuccess && state.itsmData.updateApproval.ticketId !== ""]);

    React.useEffect(() => {
        setPage({
            ...page,
            currentPage: 0,
        });
    }, [searchTicket, Status]);

    return (
        <React.Fragment>
            <Segment styles={{ padding: "0px", margin: "0px", borderRadius: "5px" }}>
                <Flex column className="ITSMApprovals">
                    {!open ?
                        <Box>
                            <Flex gap="gap.small" vAlign="center" space="between" styles={{ padding: "0.3rem 1rem" }}>
                                <Header content="ITSM Approvals" as="h2" color="brand" />
                                <Flex gap="gap.medium" styles={{ width: "20rem" }}>
                                    <Input icon={<SearchIcon />} fluid placeholder="Search by id or requester" iconPosition="start" onChange={(e: any) => setSearchTicket(e.target.value)} />
                                    {!QueryData.isTaskModuleActive && isManager && <Flex vAlign="center" hAlign="start" gap="gap.smaller" onClick={() => handleRoute("/manager-view")} styles={{ cursor: "pointer" }}>
                                        <ArrowLeftIcon styles={({ theme: { siteVariables } }) => ({ color: siteVariables.colorScheme.brand.foreground })} />
                                        <Text content="Back" color="brand" weight="semibold" />
                                    </Flex>}
                                </Flex>
                            </Flex>
                            <Divider fitted />
                            <Box>
                                {state.itsmData.getMyApproval.isSuccess && rows.length > 0 ? (
                                    <Flex column>
                                        <Box styles={isManager ? { height: "calc(100vh - 159px)", overflow: "auto" } : { height: QueryData.isTaskModuleActive ? "calc(100vh - 135px)" : "calc(100vh - 188px)", overflow: "auto" }}>
                                            <Table
                                                rows={rows.slice(
                                                    page.listPerPage * page.currentPage,
                                                    page.listPerPage * page.currentPage + page.listPerPage
                                                )}
                                                header={{
                                                    items: header,
                                                    header: true,
                                                    className: "tableHead",
                                                    styles: ({ theme: { siteVariables } }) => ({
                                                        background:
                                                            siteVariables.bodyBackground === "#fff"
                                                                ? "#f9fbfc"
                                                                : siteVariables.bodyBackground,
                                                        "&:hover": {
                                                            background:
                                                                siteVariables.bodyBackground === "#fff"
                                                                    ? "#f9fbfc"
                                                                    : siteVariables.bodyBackground,
                                                        },
                                                    }),
                                                }}
                                            />
                                        </Box>
                                        <Divider fitted />
                                        <Flex
                                            gap="gap.small"
                                            vAlign="center"
                                            fill
                                            hAlign="end"
                                            padding="padding.medium"
                                        >
                                            <Text content="Items Per Page:" weight="semibold" />
                                            <Dropdown
                                                inverted
                                                items={[10, 15, 20, 25]}
                                                className="inputBody"
                                                fluid
                                                placeholder="10"
                                                styles={{ width: "70px" }}
                                                onChange={(e: any, v: any) => setPage({ ...page, listPerPage: v.value })}
                                            />
                                            <Button
                                                icon={<ChevronStartIcon />}
                                                iconOnly
                                                style={{ color: "#6264a7" }}
                                                disabled={
                                                    page.listPerPage * page.currentPage > 0 ? false : true
                                                }
                                                onClick={() => {
                                                    setPage({ ...page, currentPage: page.currentPage - 1 });
                                                }}
                                            />
                                            <Text>
                                                {page.currentPage + 1} of{" "}
                                                {Math.ceil(rows.length / page.listPerPage)}
                                            </Text>
                                            <Button
                                                icon={<ChevronEndIcon />}
                                                iconOnly
                                                style={{ color: "#6265a7" }}
                                                disabled={
                                                    rows
                                                        .slice(
                                                            page.listPerPage * page.currentPage,
                                                            page.listPerPage * page.currentPage + page.listPerPage
                                                        )
                                                        .includes(rows[rows.length - 1])
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => {
                                                    setPage({ ...page, currentPage: page.currentPage + 1 });
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                ) : state.itsmData.getMyApproval.isSuccess && rows.length === 0 ? (
                                    <Flex>
                                        <DataNotFound message={Constant.IMAC_APPROVALS_DATA_NOT_FOUND} />
                                    </Flex>
                                ) : state.itsmData.getMyApproval.isError ? (
                                    <Flex vAlign="center" hAlign="center">
                                        <ErrorComponent message="Something went wrong" />
                                    </Flex>
                                ) : (
                                    <Skeleton animation="pulse">
                                        <Flex gap="gap.medium" column padding="padding.medium">
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                                                return <Skeleton.Line key={item} />;
                                            })}
                                        </Flex>
                                    </Skeleton>
                                )}
                            </Box>
                        </Box> :
                        (
                            <ITSMApprovalDetails
                                setOpen={setOpen}
                                open={open}
                                QueryData={QueryData}
                                employeeData={employeeData}
                            />
                        )}
                </Flex>
            </Segment>
        </React.Fragment>
    );
};

export default ITSMApprovals;
