import {
    Actions,
    IGetAnnouncemnetData,
    IGetAnnouncemnetFeedbackData,
    IPostFeedbackData,
} from "../actions/AnnouncementAction";
import { combineReducers } from "redux";

export const getAnnouncementDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_ANNOUNCEMENT_DATA_LOADING:
    case Actions.GET_ANNOUNCEMENT_DATA_ERROR:
    case Actions.GET_ANNOUNCEMENT_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};
export const getAnnouncementFeedbackDataReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA_LOADING:
    case Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA_ERROR:
    case Actions.GET_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};
export const postAnnouncementFeedbackDataReducer = (
    state = {},
    action: any
) => {
    switch (action.type) {
    case Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA_LOADING:
    case Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA_ERROR:
    case Actions.POST_ANNOUNCEMENT_FEEDBACK_DATA_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export interface IAnnouncementData {
  getAnnouncementData: IGetAnnouncemnetData;
  getAnnouncementFeedbackData: IGetAnnouncemnetFeedbackData;
  postFeedbackData: IPostFeedbackData;
}

const announcementData = combineReducers({
    getAnnouncementData: getAnnouncementDataReducer,
    getAnnouncementFeedbackData: getAnnouncementFeedbackDataReducer,
    postFeedbackData: postAnnouncementFeedbackDataReducer,
});
export default announcementData;
