import { IState } from "../../App";
import React from "react";
import {
  Box,
  Button,
  EditIcon,
  Flex,
  Menu,
  Skeleton,
  Table,
  Text,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import * as SapIdAction from "../../redux/actions/SapIdAction";
import moment from "moment";
import "./sapStatus.scss";
import { Constant } from "../../constant";

interface ISAPIDStatusProps {
  QueryData: IState;
}

const SAPStatus: React.FC = () => {
  const state = useSelector((state: RootSchema) => state);
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = React.useState<number>(0);

  const onTabActive = (value: any) => {
    setTabActive(value.index);
  };

  const header = [
    {
      content: <Text size="medium" content="SAP ID " weight="bold" />,
      key: "lc",
    },
    {
      content: <Text size="medium" content="System" weight="bold" />,
      key: "fd",
    },
    {
      content: <Text size="medium" content="Client" weight="bold" />,
      key: "td",
    },
    {
      content: <Text size="medium" content="Valid till" weight="bold" />,
      key: "date",
    },
    {
      content: <Text size="medium" content="Status" weight="bold" />,
      key: "status",
    },
    {
      content: "",
      key: "actionForEdit",
      styles: { maxWidth: "50px" },
    },
    {
      content: "",
      key: "actionForDelete",
      styles: { maxWidth: "50px" },
    },
  ];

  const rows =
    state.sapIdData.getUserDetails.isSuccess &&
      Array.isArray(state.sapIdData.getUserDetails.data) ? state.sapIdData.getUserDetails.data.filter(
        (obj) => obj.STATUS === (tabActive === 2 ? "Inactive" : tabActive === 1 ? "Active" : obj.STATUS)
      ).map((item: SapIdAction.IGetUserDetailsItem, index: number) => ({
        key: index,
        items: [
          { content: item.SAP_ID, key: index + 1 },
          { content: item.SYSTEM, key: index + 2 },
          { content: item.USER_NAME, key: index + 3 },
          { content: moment(item.VALIDITY).format("DD-MM-YYYY"), key: index + 4 },
          { content: <Button size="small" content={item.STATUS} tinted />, key: index + 5 },
          { content: <EditIcon />, styles: { maxWidth: "50px" }, key: index + 6 },
          { content: <TrashCanIcon outline />, styles: { maxWidth: "50px" }, key: index + 7 },
        ],
      }))
      : [];

  React.useEffect(() => {
    const payload: SapIdAction.IGetUserDetailspayload = {
      sap_client: Constant.SAP_CLIENT,
      lv_email: state.leaveData.employeeData?.mail ?? "",
    };
    dispatch(SapIdAction.getUserDetailsAction(payload));
  }, []);

  return (
    <React.Fragment>
      <Menu
        styles={{
          minHeight: "2rem",
          fontSize: "initial",
          justifyContent:
            state.appConfig.dimension.deviceWidth >= 600 ? "start" : "center",
          alignItems: "center",
        }}
        onItemClick={(e: any, v: any) => onTabActive(v)}
        fluid
        defaultActiveIndex={tabActive}
        vertical={state.appConfig.dimension.deviceWidth >= 600 ? false : true}
        items={[
          {
            key: "1",
            content: (
              <Flex gap="gap.smaller" vAlign="center" hAlign="center" className="sap-menu-title">
                <Text content="All" weight="semibold" />
              </Flex>
            ),
            value: "1",
          },
          {
            key: "2",
            content: (
              <Flex gap="gap.smaller" vAlign="center" hAlign="center" className="sap-menu-title">
                {" "}
                <Text content="Active Id" weight="semibold" />
              </Flex>
            ),
            value: "2",
          },
          {
            key: "3",
            content: (
              <Flex gap="gap.smaller" vAlign="center" hAlign="center" className="sap-menu-title" >
                <Text content="Inactive Id" weight="semibold" />
              </Flex>
            ),
            value: "3",
          },
        ]}
        underlined
        primary
      />
      <Box className="tableOuter">
        {state.sapIdData.getUserDetails.isSuccess &&
          Array.isArray(state.sapIdData.getUserDetails.data) &&
          rows.length > 0 ? (
          <Table
            header={{
              items: header,
              header: true,
              className: "tableHead",
              styles: ({ theme: { siteVariables } }) => ({
                background:
                  siteVariables.bodyBackground === "#fff"
                    ? "#f9fbfc"
                    : siteVariables.bodyBackground,
                "&:hover": {
                  background:
                    siteVariables.bodyBackground === "#fff"
                      ? "#f9fbfc"
                      : siteVariables.bodyBackground,
                },
              }),
            }}
            rows={rows}
            aria-label="Compact view static table"
            style={{ width: "100%" }}
          />
        ) : state.sapIdData.getUserDetails.isSuccess &&
          Array.isArray(state.sapIdData.getUserDetails.data) &&
          rows.length === 0 ? (
          <Flex column vAlign="center" hAlign="center">
            {/* <Table
              header={{
                items: header,
                header: true,
                className: "tableHead",
                styles: ({ theme: { siteVariables } }) => ({
                  background:
                    siteVariables.bodyBackground === "#fff"
                      ? "#f9fbfc"
                      : siteVariables.bodyBackground,
                  "&:hover": {
                    background:
                      siteVariables.bodyBackground === "#fff"
                        ? "#f9fbfc"
                        : siteVariables.bodyBackground,
                  },
                }),
              }}
              rows={rows}
              aria-label="Compact view static table"
              style={{ width: "100%" }}
            /> */}
            <DataNotFound />
          </Flex>
        ) : state.sapIdData.getUserDetails.isError ? (
          <Flex vAlign="center" hAlign="center">
            <ErrorComponent message={"Error in api.."} />
          </Flex>
        ) : (
          <Skeleton animation="pulse">
            <Flex gap="gap.medium" column padding="padding.medium">
              {[1, 2, 3, 4].map((item) => {
                return <Skeleton.Line key={item} height="25px" />;
              })}
            </Flex>
          </Skeleton>
        )}
      </Box>
    </React.Fragment>
  );
};

export default SAPStatus;
