import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    GET_USER_WITH_MANAGER_DATA: "GET_USER_WITH_MANAGER_DATA",
    GET_USER_WITH_MANAGER_DATA_LOADING: "GET_USER_WITH_MANAGER_DATA_LOADING",
    GET_USER_WITH_MANAGER_DATA_ERROR: "GET_USER_WITH_MANAGER_DATA_ERROR",
    GET_USER_WITH_MANAGER_DATA_SUCCESS: "GET_USER_WITH_MANAGER_DATA_SUCCESS",

    GET_USER_DATA: "GET_USER_DATA",
    GET_USER_DATA_LOADING: "GET_USER_DATA_LOADING",
    GET_USER_DATA_ERROR: "GET_USER_DATA_ERROR",
    GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",

    //GET Team Members
    GET_TEAM_MEMBERS: "GET_TEAM_MEMBERS",
    GET_TEAM_MEMBERS_LOADING: "GET_TEAM_MEMBERS_LOADING",
    GET_TEAM_MEMBERS_ERROR: "GET_TEAM_MEMBERS_ERROR",
    GET_TEAM_MEMBERS_SUCCESS: "GET_TEAM_MEMBERS_SUCCESS",
};

// get user with Manager data only

export interface IUserManager extends SideEffectSchema {
  status_code: string;
  message: string;
  user_details: any[];
  manager_details: any[];
  is_field_level_employee: boolean;
  is_employee_reporting: boolean;
}

export const getUserManagerAction = (access_token: string, empId: any) => {
    return {
        type: Actions.GET_USER_WITH_MANAGER_DATA,
        payload: { access_token, empId },
    };
};
export const getUserManagerLoadingAction = () =>
    loadingAction(Actions.GET_USER_WITH_MANAGER_DATA_LOADING);
export const getUserManagerErrorAction = (error: string) =>
    errorAction(Actions.GET_USER_WITH_MANAGER_DATA_ERROR, error);
export const getUserManagerSuccessAction = (data: any) => {
    const payload: IUserManager = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_USER_WITH_MANAGER_DATA_SUCCESS,
        payload: payload,
    };
};

// get user data only

export interface IUser extends SideEffectSchema {
  status_code: string;
  message: string;
  user_details: any[];
  manager_details: any[];
  is_field_level_employee: boolean;
}

export const getUserAction = (access_token: string, empId: any) => {
    return {
        type: Actions.GET_USER_DATA,
        payload: { access_token, empId },
    };
};
export const getUserLoadingAction = () =>
    loadingAction(Actions.GET_USER_DATA_LOADING);
export const getUserErrorAction = (error: string) =>
    errorAction(Actions.GET_USER_DATA_ERROR, error);
export const getUserSuccessAction = (data: any) => {
    const payload: IUser = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_USER_DATA_SUCCESS,
        payload: payload,
    };
};

//Get Team Members Data
export interface IManager {
  __metadata: {
    uri: string;
    type: string;
  };
  userId: string;
  empId: string;
  username: string;
}

export interface IReportees {
  __metadata: {
    uri: string;
    type: string;
  };
  userId: string;
  defaultFullName: string;
  empId: string;
  custom06: string,
  department: string,
  title: string,
  username: string,
  employee_profile_picture: string,
  internal_role: string
}

export interface IUserDetails {
  user_id: string;
  user_name: string;
  emp_id: string;
  manager: IManager;
  designation: string;
  level: string;
  matrix_manager: IManager;
  direct_reportees: IReportees[];
  matrix_reportees: IReportees[];
  all_reportees: IReportees[];
}

export interface ITeamMembers extends SideEffectSchema {
  status_code: string;
  message: string;
  user_details: IUserDetails;
  isManager?: boolean
}

export const getTeamMembersAction = (access_token: string, emp_id: string, isManager?: boolean) => {
    return {
        type: Actions.GET_TEAM_MEMBERS,
        payload: { access_token, emp_id, isManager },
    };
};
export const getTeamMembersLoadingAction = (isManager?: boolean) =>
    loadingAction(Actions.GET_TEAM_MEMBERS_LOADING, { isManager: isManager });
export const getTeamMembersErrorAction = (error: string, isManager?: boolean) =>
    errorAction(Actions.GET_TEAM_MEMBERS_ERROR, error, { isManager: isManager });
export const getTeamMembersSuccessAction = (data: any, isManager?: boolean) => {
    const payload: ITeamMembers = {
        ...data,
        ...successSideEffectState,
        isManager
    };
    return {
        type: Actions.GET_TEAM_MEMBERS_SUCCESS,
        payload: payload,
    };
};
