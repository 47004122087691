import { put, call, take, delay } from "redux-saga/effects";
import * as axios from "axios";
import * as actionCreators from "../actions/VacinneAction";
import { httpGet, httpPost, httpPut } from "../../service";
import { VACCINE_API_CONFIG, API_VACCINE_URL, API_SECRET_TOKEN } from "../../config";

// get dose1 data
function* getDose1Data(payload: { dose: actionCreators.IDoseGetPayload }) {
    try {
        yield put(actionCreators.getDose1LoadingAction());

        // const { data } = yield httpPost(
        //   `https://${process.env.REACT_APP_VACCINATION_API_URL}/api/get-vaccine-by-id`,
        //   payload.dose1,
        //   {}
        // );
        const { data } = yield httpPost(
            API_VACCINE_URL + VACCINE_API_CONFIG.GET_VACCINE,
            payload.dose,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        yield put(actionCreators.getDose1SuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getDose1ErrorAction(error.response));
    }
}

function* getDose2Data(payload: { dose: actionCreators.IDoseGetPayload }) {
    try {
        yield put(actionCreators.getDose2LoadingAction());

        const { data } = yield httpPost(
            API_VACCINE_URL + VACCINE_API_CONFIG.GET_VACCINE,
            payload.dose,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );

        // const { data } = yield httpPost(
        //   `https://${process.env.REACT_APP_VACCINATION_API_URL}/api/get-vaccine-by-id`,
        //   payload.dose2,
        //   {}
        // );
        yield put(actionCreators.getDose2SuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.getDose2ErrorAction(error.response.data.error));
    }
}

// add dose 1 data
function* postDoseData(payload: { dose: actionCreators.IDosePostPayload }) {
    try {
        yield put(actionCreators.postDoseLoadingAction());

        const { data } = yield httpPost(
            API_VACCINE_URL + VACCINE_API_CONFIG.POST_VACCINE,
            payload.dose,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );
        // const { data } = yield httpPost(
        //   `https://${process.env.REACT_APP_VACCINATION_API_URL}/api/submit-vaccine-data`,
        //   payload.dose,
        //   {}
        // );
        yield put(actionCreators.postDoseSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.postDoseErrorAction(error.response.data.error));
    }
}

//update dose 1 data
function* putDoseData(payload: { dose: actionCreators.IDosePutPayload }) {
    try {
        yield put(actionCreators.putDoseLoadingAction());

        const { data } = yield httpPut(
            API_VACCINE_URL + VACCINE_API_CONFIG.PUT_VACCINE,
            payload.dose,
            {
                headers: {
                    token: API_SECRET_TOKEN,
                    "x-teams-origin": window.location.origin
                }
            }
        );

        // const { data } = yield httpPut(
        //   `https://${process.env.REACT_APP_VACCINATION_API_URL}/api/put-vaccine`,
        //   payload.dose1
        // );
        yield put(actionCreators.putDoseSuccessAction(data));
    } catch (error: any) {
        yield put(actionCreators.putDoseErrorAction(error));
    }
}

export function* watchDose1Data() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_DOSE1_DATA);
        yield call(getDose1Data, payload);
    }
}

export function* watchPostDoseData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.POST_DOSE_DATA);
        yield call(postDoseData, payload);
    }
}

export function* watchPutDoseData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.UPDATE_DOSE_DATA);

        yield call(putDoseData, payload);
    }
}

export function* watchDose2Data() {
    while (true) {
        // const { payload } = yield take(actionCreators.Actions.GET_DOSE1_DATA);
        const { payload } = yield take(actionCreators.Actions.GET_DOSE2_DATA);
        yield call(getDose2Data, payload);
    }
}
