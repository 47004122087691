import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    // Validate Leave
    POST_VALID_LEAVE: "POST_VALID_LEAVE",
    POST_VALID_LEAVE_LOADING: "POST_VALID_LEAVE_LOADING",
    POST_VALID_LEAVE_ERROR: "POST_VALID_LEAVE_ERROR",
    POST_VALID_LEAVE_SUCCESS: "POST_VALID_LEAVE_SUCCESS",
    POST_VALID_LEAVE_CLEAR_DATA: "POST_VALID_LEAVE_CLEAR_DATA",

    // Apply Leave
    POST_CREATE_LEAVE_DATA: "POST_CREATE_LEAVE_DATA",
    POST_CREATE_LEAVE_DATA_LOADING: "POST_CREATE_LEAVE_DATA_LOADING",
    POST_CREATE_LEAVE_DATA_ERROR: "POST_CREATE_LEAVE_DATA_ERROR",
    POST_CREATE_LEAVE_DATA_SUCCESS: "POST_CREATE_LEAVE_DATA_SUCCESS",

    // Leave Quota
    GET_QUOTA_LEAVE: "GET_QUOTA_LEAVE",
    GET_QUOTA_LEAVE_LOADING: "GET_QUOTA_LEAVE_LOADING",
    GET_QUOTA_LEAVE_ERROR: "GET_QUOTA_LEAVE_ERROR",
    GET_QUOTA_LEAVE_SUCCESS: "GET_QUOTA_LEAVE_SUCCESS",

    // Leave History
    GET_LEAVE_HISTORY: "GET_LEAVE_HISTORY",
    GET_LEAVE_HISTORY_LOADING: "GET_LEAVE_HISTORY_LOADING",
    GET_LEAVE_HISTORY_ERROR: "GET_LEAVE_HISTORY_ERROR",
    GET_LEAVE_HISTORY_SUCCESS: "GET_LEAVE_HISTORY_SUCCESS",

    // Leave DeleteHistory
    POST_LEAVE_DELETE_HISTORY: "POST_LEAVE_DELETE_HISTORY",
    POST_LEAVE_DELETE_HISTORY_LOADING: "POST_LEAVE_DELETE_HISTORY_LOADING",
    POST_LEAVE_DELETE_HISTORY_ERROR: "POST_LEAVE_DELETE_HISTORY_ERROR",
    POST_LEAVE_DELETE_HISTORY_SUCCESS: "POST_LEAVE_DELETE_HISTORY_SUCCESS",

    // Master Data
    GET_LEAVE_MASTER: "GET_LEAVE_MASTER",
    GET_LEAVE_MASTER_LOADING: "GET_LEAVE_MASTER_LOADING",
    GET_LEAVE_MASTER_ERROR: "GET_LEAVE_MASTER_ERROR",
    GET_LEAVE_MASTER_SUCCESS: "GET_LEAVE_MASTER_SUCCESS",

    // Leave Approval Request for approve and delete History
    GET_LEAVE_APPROVAL_HISTORY: "GET_LEAVE_APPROVAL_HISTORY",
    GET_LEAVE_APPROVAL_HISTORY_LOADING: "GET_LEAVE_APPROVAL_HISTORY_LOADING",
    GET_LEAVE_APPROVAL_HISTORY_ERROR: "GET_LEAVE_APPROVAL_HISTORY_ERROR",
    GET_LEAVE_APPROVAL_HISTORY_SUCCESS: "GET_LEAVE_APPROVAL_HISTORY_SUCCESS",

    //pending for approval - Leave Approval
    POST_LEAVE_APPROVAL: "POST_LEAVE_APPROVAL",
    POST_LEAVE_APPROVAL_LOADING: "POST_LEAVE_APPROVAL_LOADING",
    POST_LEAVE_APPROVAL_ERROR: "POST_LEAVE_APPROVAL_ERROR",
    POST_LEAVE_APPROVAL_SUCCESS: "POST_LEAVE_APPROVAL_SUCCESS",

    //Delete for pendoing - Leave Approval
    POST_DELETE_LEAVE_APPROVAL: "POST_DELETE_LEAVE_APPROVAL",
    POST_DELETE_LEAVE_APPROVAL_LOADING: "POST_DELETE_LEAVE_APPROVAL_LOADING",
    POST_DELETE_LEAVE_APPROVAL_ERROR: "POST_DELETE_LEAVE_APPROVAL_ERROR",
    POST_DELETE_LEAVE_APPROVAL_SUCCESS: "POST_DELETE_LEAVE_APPROVAL_SUCCESS",

    //---------------------------Service Avalability------------------------
    POST_LEAVE_NOTIFICATION: "POST_LEAVE_NOTIFICATION",
    POST_LEAVE_NOTIFICATION_LOADING: "POST_LEAVE_NOTIFICATION_LOADING",
    POST_LEAVE_NOTIFICATION_ERROR: "POST_LEAVE_NOTIFICATION_ERROR",
    POST_LEAVE_NOTIFICATION_SUCCESS: "POST_LEAVE_NOTIFICATION_SUCCESS",

    //----------------------------Attendance Enquiry-------------------------

    POST_ATTENDANCE_ENQUIRY: "POST_ATTENDANCE_ENQUIRY",
    POST_ATTENDANCE_ENQUIRY_LOADING: "POST_ATTENDANCE_ENQUIRY_LOADING",
    POST_ATTENDANCE_ENQUIRY_ERROR: "POST_ATTENDANCE_ENQUIRY_ERROR",
    POST_ATTENDANCE_ENQUIRY_SUCCESS: "POST_ATTENDANCE_ENQUIRY_SUCCESS",

    //   -----------------------------DELETE LEAVE APPROVALS----------------------------------------------

    GET_TEAM_MEMBER: "GET_TEAM_MEMBER",
    GET_TEAM_MEMBER_LOADING: "GET_TEAM_MEMBER_LOADING",
    GET_TEAM_MEMBER_ERROR: "GET_TEAM_MEMBER_ERROR",
    GET_TEAM_MEMBER_SUCCESS: "GET_TEAM_MEMBER_SUCCESS",

    GET_EMP_ID_FROM_GRAPH: "GET_EMP_ID_FROM_GRAPH",
    GET_EMP_ID_FROM_GRAPH_LOADING: "GET_EMP_ID_FROM_GRAPH_LOADING",
    GET_EMP_ID_FROM_GRAPH_ERROR: "GET_EMP_ID_FROM_GRAPH_ERROR",
    GET_EMP_ID_FROM_GRAPH_SUCCESS: "GET_EMP_ID_FROM_GRAPH_SUCCESS",

    // Clear data
    POST_PUT_CLEAR_REDUCER_DATA: "POST_PUT_CLEAR_REDUCER_DATA",

    GET_UPCOMING_HOLIDAY: "GET_UPCOMING_HOLIDAY",
    GET_UPCOMING_HOLIDAY_LOADING: "GET_UPCOMING_HOLIDAY_LOADING",
    GET_UPCOMING_HOLIDAY_ERROR: "GET_UPCOMING_HOLIDAY_ERROR",
    GET_UPCOMING_HOLIDAY_SUCCESS: "GET_UPCOMING_HOLIDAY_SUCCESS",

    //----------------------------GET ACTIVE YEAR-------------------------

    GET_ACTIVE_YEAR: "GET_ACTIVE_YEAR",
    GET_ACTIVE_YEAR_LOADING: "GET_ACTIVE_YEAR_LOADING",
    GET_ACTIVE_YEAR_ERROR: "GET_ACTIVE_YEAR_ERROR",
    GET_ACTIVE_YEAR_SUCCESS: "GET_ACTIVE_YEAR_SUCCESS",
};

/**
 * Post TO Validate Leave
 */
export interface ILeaveValidate extends SideEffectSchema {
    data: {
        status_code: string;
        total_days?: string;
        unique_quota_id?: string;
        is_lwop?: string;
        message: string;
    };
}
export interface ILeaveValidPayload {
    emp_id: string;
    data: [
        {
            leave_type_code: string;
            approver: string;
            approver_email: string;
            requestor_email: string;
            from_date: string;
            to_date: string;
            leave_category_code: string;
            leave_category_text: string;
            reason: string;
        }
    ];
    emp_company_code: string;
    manager_company_code: string;
}
export const postValidLeaveAction = (
    access_token: string,
    emp_comp: string,
    leaveData: ILeaveValidPayload
) => {
    return {
        type: Actions.POST_VALID_LEAVE,
        payload: { access_token, emp_comp, leaveData },
    };
};
export const postValidLeaveLoadingAction = () =>
    loadingAction(Actions.POST_VALID_LEAVE_LOADING);
export const postValidLeaveErrorAction = (error: string | any) =>
    errorAction(Actions.POST_VALID_LEAVE_ERROR, error);
export const postValidLeaveSuccessAction = (data: any) => {
    const payload: any = {
        data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_VALID_LEAVE_SUCCESS,
        payload: payload,
    };
};
export const postValidLeaveUpdateAction = () => {
    const payload = {
        isLoadig: false,
        isSuccess: false,
        isError: false,
    };
    return {
        type: Actions.POST_VALID_LEAVE_CLEAR_DATA,
        payload: payload,
    };
};

/**
 * Post TO  Leave
 */
export interface ILeave extends SideEffectSchema {
    status_code: string;
    message: string;
}

export interface IApplyLeaveDataObj {
    leave_type_code: string;
    approver: string;
    approver_email: string;
    requestor_email: string;
    from_date: string;
    to_date: string;
    leave_category_code: string;
    leave_category_text: string;
    reason: string;
    total_days: string;
    unique_quota_id: string;
    is_lwop: string;
}

export interface IApplyLeave {
    emp_id: string;
    emp_name: string;
    data: IApplyLeaveDataObj[];
    pa: string;
    psa: string;
    emp_company_code: string;
    manager_company_code: string;
}
export const postLeaveAction = (
    access_token: string,
    emp_comp: string,
    payload: IApplyLeave
) => {
    return {
        type: Actions.POST_CREATE_LEAVE_DATA,
        payload: { access_token, emp_comp, payload },
    };
};
export const postLeaveLoadingAction = () =>
    loadingAction(Actions.POST_CREATE_LEAVE_DATA_LOADING);
export const postLeaveErrorAction = (error: string) =>
    errorAction(Actions.POST_CREATE_LEAVE_DATA_ERROR, error);
export const postLeaveSuccessAction = (data: any) => {
    const payload: ILeave = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_CREATE_LEAVE_DATA_SUCCESS,
        payload: payload,
    };
};

/**
 * Post Leave Quota
 */
export interface IQuota {
    leave_quota_code: string;
    leave_quota_text: string;
    quota_valid_from: string;
    quota_valid_to: string;
    total_leaves: string;
    available_leaves: string;
    quota_value: number;
    leave_category_color: string;
}
export interface ILeaveQuota extends SideEffectSchema {
    status_code: string;
    leave_quota_details: IQuota[];
    message: string;
}

export const getQuotaLeaveAction = (
    access_token: string,
    emp_comp: string,
    emp_id: string
) => {
    return {
        type: Actions.GET_QUOTA_LEAVE,
        payload: { access_token, emp_comp, emp_id },
    };
};
export const getQuotaLeaveLoadingAction = () =>
    loadingAction(Actions.GET_QUOTA_LEAVE_LOADING);
export const getQuotaLeaveErrorAction = (error: string) =>
    errorAction(Actions.GET_QUOTA_LEAVE_ERROR, error);
export const getQuotaLeaveSuccessAction = (data: any) => {
    const payload: ILeaveQuota = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_QUOTA_LEAVE_SUCCESS,
        payload: payload,
    };
};

/**
 * Post Leave History
 */
export interface ILeaveHistoryPayload {
    emp_id: string;
    leave_category_code: string;
    leave_status_code: string;
    filter_type: string;
    month: string;
    pa: string;
    psa: string;
}
export interface ILeaveHistory {
    leave_request_id: string;
    requestor_emp_id: string;
    requestor_email: string;
    requestor_name: string;
    leave_category: string;
    leave_type: string;
    from_date: string;
    to_date: string;
    reason: string;
    total_days: string;
    leave_status: string;
    applied_date: string;
    approver_emp_id: any | null;
    approver_email: any | null;
    is_deleted: boolean;
    unique_quota_id: any | null;
    created_date_time: string;
    modified_date_time: string;
    created_by: string;
    modified_by: string;
    leave_category_code: string;
    leave_type_code: string;
    leave_status_code: string;
}
export interface ILeaveHistoryList extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        leave_requests: ILeaveHistory[];
    };
}
export const getLeaveHistoryAction = (
    access_token: string,
    emp_comp: string,
    payload: ILeaveHistoryPayload
) => {
    return {
        type: Actions.GET_LEAVE_HISTORY,
        payload: { access_token, emp_comp, payload },
    };
};
export const getLeaveHistoryLoadingAction = () =>
    loadingAction(Actions.GET_LEAVE_HISTORY_LOADING);
export const getLeaveHistoryErrorAction = (error: string) =>
    errorAction(Actions.GET_LEAVE_HISTORY_ERROR, error);
export const getLeaveHistorySuccessAction = (data: ILeaveHistoryList) => {
    const payload: ILeaveHistoryList = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_LEAVE_HISTORY_SUCCESS,
        payload: payload,
    };
};
/**
 * Post Delete Leave History
 */
export interface ILeaveDeleteHistoryPayload {
    emp_id: string;
    leave_request_id: string;
    from_date: string;
    to_date: string;
    leave_status: string;
    approver_email: string;
    emp_name: string;
    leave_type_code: string;
    leave_category_code: string;
    approver: string;
    reason: string;
    pa: string;
    psa: string;
    emp_company_code: string;
    manager_company_code: string;
}
export interface ILeaveDeleteHistoryList extends SideEffectSchema {
    status_code: string;
    message: string;
}

export const postLeaveDeleteHistoryAction = (
    access_token: string,
    emp_comp: string,
    payload: ILeaveDeleteHistoryPayload
) => {
    return {
        type: Actions.POST_LEAVE_DELETE_HISTORY,
        payload: { access_token, emp_comp, payload },
    };
};
export const postLeaveDeleteHistoryLoadingAction = () =>
    loadingAction(Actions.POST_LEAVE_DELETE_HISTORY_LOADING);
export const postLeaveDeleteHistoryErrorAction = (error: string) =>
    errorAction(Actions.POST_LEAVE_DELETE_HISTORY_ERROR, error);
export const postLeaveDeleteHistorySuccessAction = (data: any) => {
    const payload: ILeaveDeleteHistoryList = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_LEAVE_DELETE_HISTORY_SUCCESS,
        payload: payload,
    };
};
/**
 * Master Data
 */

export interface IMasterPayload {
    pa: string;
    psa: string;
    emp_id: string;
}
export interface IMasterList extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        leave_category: { value: string; code: string; color: string }[];
        leave_status: { value: string; code: string; color: string }[];
        emp_id: any[];
        month_list: { value: string; code: string }[];
    };
}
export const getLeaveMasterAction = (
    access_token: string,
    emp_comp: string,
    payload: IMasterPayload
) => {
    return {
        type: Actions.GET_LEAVE_MASTER,
        payload: { access_token, emp_comp, payload },
    };
};
export const getLeaveMasterLoadingAction = () =>
    loadingAction(Actions.GET_LEAVE_MASTER_LOADING);
export const getLeaveMasterErrorAction = (error: string) =>
    errorAction(Actions.GET_LEAVE_MASTER_ERROR, error);
export const getLeaveMasterSuccessAction = (data: IMasterList) => {
    const payload: IMasterList = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_LEAVE_MASTER_SUCCESS,
        payload: payload,
    };
};

// -------------------------Leave Approval History---------------------------
export interface ILeaveRequestApprovalHistoryPayLaod {
    emp_id: string;
    approval_type: string;
    leave_category_code: string; //WFH
    leave_status_code: string;
    emp_id_value: string;
    filter_type: string;
    approver_id: string;
    pa: string;
    psa: string;
}
export interface ILeaveRequestApprovalHistoryList extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        leave_requests_approval: ILeaveHistory[];
    };
}
export const getLeaveRequestApprovalHistoryAction = (
    access_token: string,
    emp_comp: string,
    payload: ILeaveRequestApprovalHistoryPayLaod
) => {
    return {
        type: Actions.GET_LEAVE_APPROVAL_HISTORY,
        payload: { access_token, emp_comp, payload },
    };
};
export const getLeaveRequestApprovalHistoryLoadingAction = () =>
    loadingAction(Actions.GET_LEAVE_APPROVAL_HISTORY_LOADING);
export const getLeaveRequestApprovalHistoryErrorAction = (error: string) =>
    errorAction(Actions.GET_LEAVE_APPROVAL_HISTORY_ERROR, error);
export const getLeaveRequestApprovalHistorySuccessAction = (
    data: ILeaveRequestApprovalHistoryList
) => {
    const payload: ILeaveRequestApprovalHistoryList = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_LEAVE_APPROVAL_HISTORY_SUCCESS,
        payload: payload,
    };
};
//-----------------------------------Leave Approval-----------------------------
export enum LeaveApproveReject {
    Approve = "0",
    Reject = "1",
}
export interface ILeaveRequestApprovalPayLaod {
    emp_id: string;
    data: { leave_request_id: string; requestor_emp_id: string }[];
    action: string;
    approval_type: string; //"Leave Request"
}
export interface ILeaveRequestApprovalList extends SideEffectSchema {
    status_code: string;
    message: string;
}
export const postLeaveRequestApprovalAction = (
    access_token: string,
    emp_comp: string,
    payload: ILeaveRequestApprovalPayLaod
) => {
    return {
        type: Actions.POST_LEAVE_APPROVAL,
        payload: { access_token, emp_comp, payload },
    };
};
export const postLeaveRequestApprovalLoadingAction = () =>
    loadingAction(Actions.POST_LEAVE_APPROVAL_LOADING);
export const postLeaveRequestApprovalErrorAction = (error: string) =>
    errorAction(Actions.POST_LEAVE_APPROVAL_ERROR, error);
export const postLeaveRequestApprovalSuccessAction = (
    data: ILeaveRequestApprovalList
) => {
    const payload: ILeaveRequestApprovalList = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_LEAVE_APPROVAL_SUCCESS,
        payload: payload,
    };
};

//-----------------------------------Delete Leave Approval-----------------------------
export enum DeleteLeaveApproveReject {
    Approve = "0",
    Reject = "1",
}
export interface IDeleteLeaveRequestApprovalPayLaod {
    emp_id: string;
    data: { leave_request_id: string; requestor_emp_id: string }[];
    action: string;
    approval_type: string; //"Leave Request"
}
export interface IDeleteLeaveRequestApprovalList extends SideEffectSchema {
    status_code: string;
    message: string;
}
export const postDeleteLeaveRequestApprovalAction = (
    access_token: string,
    emp_comp: string,
    payload: IDeleteLeaveRequestApprovalPayLaod
) => {
    return {
        type: Actions.POST_DELETE_LEAVE_APPROVAL,
        payload: { access_token, emp_comp, payload },
    };
};
export const postDeleteLeaveRequestApprovalLoadingAction = () =>
    loadingAction(Actions.POST_DELETE_LEAVE_APPROVAL_LOADING);
export const postDeleteLeaveRequestApprovalErrorAction = (error: string) =>
    errorAction(Actions.POST_DELETE_LEAVE_APPROVAL_ERROR, error);
export const postDeleteLeaveRequestApprovalSuccessAction = (
    data: IDeleteLeaveRequestApprovalList
) => {
    const payload: IDeleteLeaveRequestApprovalList = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.POST_DELETE_LEAVE_APPROVAL_SUCCESS,
        payload: payload,
    };
};

/**
 * GET TEAM Member
 *
 */

export const getTeamMemberAction = (
    access_token: string,
    emp_comp: string,
    id: string
) => {
    return {
        type: Actions.GET_TEAM_MEMBER,
        payload: { access_token, emp_comp, id },
    };
};
export const getTeamMemberActionLoadingAction = () =>
    loadingAction(Actions.GET_TEAM_MEMBER_LOADING);
export const getTeamMemberErrorAction = (error: string) =>
    errorAction(Actions.GET_TEAM_MEMBER_ERROR, error);
export const getTeamMemberSuccessAction = (data: any) => {
    const payload: any = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_TEAM_MEMBER_SUCCESS,
        payload: payload,
    };
};

/***
 * Get Emp ID Using Graph API
 */

export interface IGetEmployeeUsingGraph extends SideEffectSchema {
    employeeId: string;
    mailNickname: string;
    userPrincipalName: string;
    id: string;
    mail: string;
    manager: {
        employeeId: string;
        mailNickname: string;
        userPrincipalName: string;
        id: string;
        mail: string;
        department: string;
        displayName: string;
        givenName: string;
        surname: string;
        jobTitle: string;
        mobilePhone: string;
    }
}

export const getEmployeeIdUsingGraphAction = (
    access_token: string,
    context: any
) => {
    return {
        type: Actions.GET_EMP_ID_FROM_GRAPH,
        payload: { access_token, context },
    };
};
export const getEmployeeIdUsingGraphLoadingAction = () =>
    loadingAction(Actions.GET_EMP_ID_FROM_GRAPH_LOADING);
export const getEmployeeIdUsingGraphErrorAction = (error: string) =>
    errorAction(Actions.GET_EMP_ID_FROM_GRAPH_ERROR, error);
export const getEmployeeIdUsingGraphSuccessAction = (data: any) => {
    const payload: IGetEmployeeUsingGraph = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_EMP_ID_FROM_GRAPH_SUCCESS,
        payload: payload,
    };
};

export const postPutClearReducerData = () => {
    return {
        type: Actions.POST_PUT_CLEAR_REDUCER_DATA,
        payload: {},
    };
};

export interface IGetHolidays {
    holiday_date: string;
    holiday_name: string;
    weak_day: string;
}

export interface IGetUpcomingHolidayList extends SideEffectSchema {
    message: string;
    status_code: string;
    employee_holiday_data: IGetHolidays[];
}

export const getUpcomingHolidayAction = (token: string, emp_id: string, emp_comp_code: string) => {
    return {
        type: Actions.GET_UPCOMING_HOLIDAY,
        payload: { token, emp_id, emp_comp_code },
    };
};
export const getUpcomingHolidayLoadingAction = () =>
    loadingAction(Actions.GET_UPCOMING_HOLIDAY_LOADING);
export const getUpcomingHolidayErrorAction = (error: string) =>
    errorAction(Actions.GET_UPCOMING_HOLIDAY_ERROR, error);
export const getUpcomingHolidaySuccessAction = (data: any) => {
    const payload: IGetUpcomingHolidayList = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_UPCOMING_HOLIDAY_SUCCESS,
        payload: payload,
    };
};


export interface IGetActiveYearData extends SideEffectSchema {
    message: string;
    status_code: string;
    data: {
        active_year: string
    }
}

export const getActiveYearAction = (token: string, emp_comp: string) => {
    return {
        type: Actions.GET_ACTIVE_YEAR,
        payload: { token, emp_comp },
    };
};
export const getActiveYearLoadingAction = () =>
    loadingAction(Actions.GET_ACTIVE_YEAR_LOADING);
export const getActiveYearErrorAction = (error: string) =>
    errorAction(Actions.GET_ACTIVE_YEAR_ERROR, error);
export const getActiveYearSuccessAction = (data: any) => {
    const payload: IGetActiveYearData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_ACTIVE_YEAR_SUCCESS,
        payload: payload,
    };
};
