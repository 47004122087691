import { ArrowLeftIcon, Box, Button, Divider, Flex, Pill, Segment, Text } from "@fluentui/react-northstar";
import React from "react";
import GenericDetails from "../GenericDetails";
import SAPRoleSelection from "../SAPRoleSelection";
import "./sapReqId.scss";
import * as SapIdAction from "../../redux/actions/SapIdAction";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import { useNavigate } from "react-router-dom";
import { IState } from "../../App";
import { queries } from "@testing-library/react";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import { Constant } from "../../constant";

export interface IGenericForm {
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  manager: string;
  empPersNo: string;
  company: string;
  department: string;
  requestType: string;
  businessProcess: { id: string; header: string; value: string; };
  description: string;
  primaryModule: { id: string; header: string; value: string; };
  userType: string;
}

export interface ICheckedDataProps {
  id: string;
}

export interface ISAPReqIdCreation {
  QueryData: IState;
}
export interface ISAPRoleData {
  system_type_connector: string;
  sap_id_type: { id: number, value: string; };
  role: any;
}

const SAPReqIdCreation: React.FC<ISAPReqIdCreation> = ({ QueryData }) => {

  const navigate = useNavigate();

  const [validateFlag, setValidateFlag] = React.useState<boolean>(false);
  const [showFields, setShowFields] = React.useState<number>(1);
  const [genericForm, setGenericForm] = React.useState<IGenericForm>({
    firstName: "",
    lastName: "",
    email: "",
    location: "",
    manager: "",
    empPersNo: "",
    company: "",
    department: "",
    requestType: "",
    userType: "",
    businessProcess: { id: "", header: "", value: "" },
    description: "",
    primaryModule: { id: "", header: "", value: "" },
  });
  const [checkedData, setCheckedData] = React.useState<ICheckedDataProps[]>([]);
  const [roleData, setRoleData] = React.useState<ISAPRoleData>({
    system_type_connector: "",
    sap_id_type: { id: 0, value: "" },
    role: []
  });
  const [dynamicBtnText, setDynamicBtnText] = React.useState<string>("SOD Conflict");

  const state = useSelector((state: RootSchema) => state);
  const dispatch = useDispatch();
  // Form Submit validation
  const validateButton = async () => {
    const data = Object.entries(genericForm);
    const value = data.map((item) => {
      if (typeof item[1] === "string") {
        return item[1] !== "";
      } else if (typeof item[1] === "number") {
        return item[1] !== 0;
      } else {
        return item[1]?.header !== "";
      }
    });
    return await setValidateFlag(value.every((item) => item));
  };

  const onCancelHandler = (step: number) => {
    setShowFields(1);
    setCheckedData([]);
    dispatch(SapIdAction.getUserRolesAssignClearAction());
    dispatch(SapIdAction.getFindingOrgValuesRoleClearAction());
    dispatch(SapIdAction.getFindingTCodesRoleClearAction());
    dispatch(SapIdAction.getListRoleAssignedToOwnerClearAction());
  };

  const onSodCheckValidateHandler = () => {
    if (showFields !== 1) {
      if (dynamicBtnText === "SOD Conflict") {
        onSODConflictHandler();
      } else {
        onSubmitHandler();
      }
    } else {
      setShowFields(2);
      setDynamicBtnText("SOD Conflict");
    }

  };

  const onSubmitHandler = () => {
    const userPayload: SapIdAction.IPostUserCreatepayload = {
      REQTYPE: "001",
      REQUESTORID: QueryData.userId as string,
      USERID: QueryData.userId as string,
      BPROC: genericForm.businessProcess.value,
      FNAME: genericForm.firstName,
      LNAME: genericForm.lastName,
      MANAGER: state.userInfo.userManagerData.manager_details[0]?.Perno,
      PERSONNELNO: state.userInfo.userManagerData.user_details[0]?.Perno,
      COMPANY: genericForm.company,
      DEPARTMENT: genericForm.department,
      EMAIL: genericForm.email,
      LOCATION: genericForm.location,
      USER_TYPE: genericForm.userType,
      REQUEST_REASON: genericForm.description,
      EMPTYPE: state.userInfo.userManagerData.user_details[0]?.Ptext,
      CUSTOM_DATA: [{
        FIELDNAME: "ZZPRIMARY_MODULE",
        VALUE: genericForm.primaryModule.value
      }],
      ITEM_DATA: roleData.role.map(({ ROLE_NAME, CONNECTOR }: { ROLE_NAME: string; CONNECTOR: string; }) => ({ ROLE_NAME, CONNECTOR }))
    };
    const sap_client = Constant.SAP_CLIENT;
    dispatch(SapIdAction.postUserCreateAction(userPayload, sap_client));
  };

  const onSODConflictHandler = () => {

    const newRoleData = roleData.role.map(({ ROLE_NAME }: { ROLE_NAME: string; }) => ({ ROLE_NAME }));
    const newRoleData1 = newRoleData.map((obj: any) => ({ LOW: obj.ROLE_NAME, SIGN: "I", OPTION: "EQ", HIGH: "", }));

    const sod_payload = {
      IT_SYS_RANGE: {
        SIGN: "I",
        OPTION: "EQ",
        LOW: roleData.system_type_connector,
        HIGH: "",
      },
      IT_OBJECT_RANGE: newRoleData1,
      IV_OBJECT_TYPE: 2,
      IT_REPORT_TYPE: [{
        REPTYPE: 1,
      }],
      IV_SIMU_RISK_ONLY: "X",
      IV_ANALYSIS_MODE: 1
    };
    showFields === 2 && dispatch(SapIdAction.postSodConflictAction({ sap_client: "800", sod_payload }));

  };
  React.useEffect(() => {
    validateButton();
  }, [genericForm]);

  React.useEffect(() => {
    if (state.sapIdData.getSodConflict.isSuccess && Array.isArray(state.sapIdData.getSodConflict?.data) && ((state.sapIdData.getSodConflict?.data[0]?.EV_RETURN_MSG?.TYPE === "S") || (state.sapIdData.getSodConflict?.data[0]?.EV_RETURN_MSG?.TYPE === "SUCCESS"))) {
      setDynamicBtnText("Submit");
      SuccessToast(state.sapIdData.getSodConflict?.data[0]?.EV_RETURN_MSG?.MESSAGE);
      dispatch(SapIdAction.postSodConflictClearAction());
    }
    if (state.sapIdData.getSodConflict.isSuccess && Array.isArray(state.sapIdData.getSodConflict?.data) && (state.sapIdData.getSodConflict?.data[0]?.EV_RETURN_MSG?.TYPE !== "S")) {
      ErrorToast(state.sapIdData.getSodConflict?.data[0]?.EV_RETURN_MSG?.MESSAGE);
      dispatch(SapIdAction.postSodConflictClearAction());
    }
    if (state.sapIdData.getSodConflict.isSuccess && (Array.isArray(state.sapIdData.getSodConflict?.data) === false)) {
      ErrorToast("SOD Error");
      dispatch(SapIdAction.postSodConflictClearAction());
    }

  }, [state.sapIdData.getSodConflict.isSuccess]);
  React.useEffect(() => {
    if (state.sapIdData.postUserCreate.isSuccess && state.sapIdData.postUserCreate?.data?.MSG_RETURN?.MSG_TYPE === "ERROR") {
      ErrorToast(state.sapIdData.postUserCreate?.data?.MSG_RETURN?.MSG_STATEMENT);
      dispatch(SapIdAction.postUserCreateClearAction());
    }
    if (state.sapIdData.postUserCreate.isSuccess && state.sapIdData.postUserCreate?.data?.MSG_RETURN?.MSG_TYPE !== "ERROR") {
      SuccessToast(state.sapIdData.postUserCreate?.data?.MSG_RETURN?.MSG_STATEMENT);
      dispatch(SapIdAction.postUserCreateClearAction());
    }
    if (state.sapIdData.postUserCreate.isError) {
      ErrorToast("SAP  Error");
      dispatch(SapIdAction.postUserCreateClearAction());
    }

  }, [state.sapIdData.postUserCreate.isSuccess]);

  React.useEffect(() => {
    if (state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200") {
      const datafromAD = state.leaveData.employeeData;
      const datafromUserManager = state.userInfo.userManagerData;
      setGenericForm(prev => ({
        ...prev,
        firstName: datafromUserManager.user_details[0]?.Firstname,
        lastName: datafromUserManager.user_details[0]?.LastName,
        empPersNo: state.userInfo.userManagerData.user_details[0]?.Perno,
        email: datafromAD?.mail ? datafromAD?.mail : "",
        manager: datafromUserManager.user_details[0]?.Nameofreporting,
        company: datafromUserManager.user_details[0]?.Ccodetxt
      }));
    }
  }, [state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200"]);

  return (
    <React.Fragment>
      <Segment className="Sap-Id-Req">
        <Flex className="sap-id-title">
          {
            !QueryData.isTaskModuleActive && (
              <Flex hAlign="center" vAlign="center" gap="gap.medium">
                <ArrowLeftIcon onClick={() => navigate("/support-desk")} styles={{ color: "rgb(91, 95, 199)", cursor: "pointer" }} />
                <Text content="New Request ID Creation" weight="bold" styles={{ fontSize: "19px" }} color="brand" />
              </Flex>
            )
          }
        </Flex>
        <Flex gap="gap.smaller" padding="padding.medium" >
          <Pill
            className={showFields === 1 ? "sap-pill sap-pill-inProgress" : showFields > 1 ? "sap-pill sap-pill-complete" : "sap-pill"}
            content="Generic Details"
            appearance="outline"
          />
          <Divider color={showFields === 2 ? "brand" : ""} styles={{ width: "10%" }} />
          <Pill
            className={showFields === 2 ? "sap-pill sap-pill-inProgress" : showFields > 2 ? "sap-pill sap-pill-complete" : "sap-pill"}
            appearance="outline"
            content="Role Selection"
            disabled={showFields !== 2}
          />
        </Flex>
        <Divider />
        <Flex gap="gap.small" column className="sap-content" styles={{ height: QueryData.isTaskModuleActive ? "calc(100vh - 145px)" : "calc(100% - 170px)" }}>
          {
            showFields === 1 ?
              <GenericDetails genericForm={genericForm} setGenericForm={setGenericForm} /> :
              <SAPRoleSelection checkedData={checkedData} setCheckedData={setCheckedData} roleData={roleData} setRoleData={setRoleData} setDynamicBtnText={setDynamicBtnText} />
          }
        </Flex>
        <Divider />
        <Flex gap="gap.small" padding="padding.medium">
          <Button content={showFields === 1 ? "Next Step" : dynamicBtnText} primary onClick={onSodCheckValidateHandler}
            disabled={!validateFlag || (checkedData.length === 0 && showFields !== 1)}
            loading={showFields !== 1 && state.sapIdData.getSodConflict.isLoading || state.sapIdData.postUserCreate.isLoading}
          />
          <Button content="Cancel" disabled={showFields === 1} secondary onClick={() => onCancelHandler(1)} />
          {/* <Button content="Submit" disabled={!validateFlag || (checkedData.length === 0 && showFields !== 1)} onClick={onSubmitHandler} /> */}
        </Flex>
      </Segment>
    </React.Fragment>
  );
};

export default SAPReqIdCreation;
