import React from "react";
import {
    Menu,
    Flex,
    Divider,
    Text
} from "@fluentui/react-northstar";
import { IState } from "../../App";
import LeadershipBehaviour from "../LeadershipBehaviour";
import MyGoals from "../MyGoals";
import ServicePermission from "../HOC/ServicePermission";
import { ServiceName } from "../../constant";

interface ICFMTaskModules {
    QueryData : IState
    byManagerUserId?: string;
    byManagerEmpId?: string;
    managerView?: boolean;
    activeBackBtn?: any;
}

const CFMTaskModules: React.FC<ICFMTaskModules> = ({ QueryData, managerView, byManagerUserId, byManagerEmpId, activeBackBtn }) => {
    const items = [
        {
            key: "goal",
            content: <Flex gap="gap.small" vAlign='center' hAlign='center' styles={{ padding: "0.4rem 0", fontSize: "16px" }}><Text content="Goals" weight="semibold" /></Flex>,
        },
        {
            key: "leadership",
            content: <Flex gap="gap.small" vAlign='center' hAlign='center' styles={{ padding: "0.4rem 0", fontSize: "16px" }}><Text content="Leadership Behaviours" weight="semibold" /></Flex>,
        }
    ];

    const [tabActive, setTabActive] = React.useState(0);
    const [ backBtn,  setBackBtn] = React.useState(true);

    React.useEffect(() => {
        activeBackBtn?.(backBtn);
    }, [backBtn]);

    const onTabActive = (value: any) => {
        setTabActive(value.index);
    };

    return (
        <React.Fragment>
            <ServicePermission service_name={[ ServiceName.TEAM ]} showReason={true}>
                <>
                    <Flex column>
                        <Menu
                            styles={{ border: "none", height: "37px" }}
                            className='segmentHeader'
                            onItemClick={(e: any, v: any) => onTabActive(v)}
                            iconOnly
                            fluid
                            defaultActiveIndex={0}
                            items={items}
                            underlined
                            primary />
                        <Divider fitted />
                    </Flex>
                    {
                        tabActive === 0 ? 
                            <MyGoals
                                QueryData={QueryData} 
                                byManagerUserId={byManagerUserId}
                                byManagerEmpId={byManagerEmpId}
                                managerView={managerView ? managerView : false}
                                activeBackButton={setBackBtn}  
                            /> : 
                            <LeadershipBehaviour
                                QueryData={QueryData} 
                                byManagerUserId={byManagerUserId}
                                byManagerEmpId={byManagerEmpId}
                                managerView={managerView ? managerView : false}
                                activeBackButton={setBackBtn} 
                            />
                    }
                </>
            </ServicePermission>
        </React.Fragment>
    );
};

export default CFMTaskModules;