
import {
    Actions,
    IIMACCategoryThree,
    IIMACCategoryTwo,
    IIMACCommon,
    IGetAssetData,
    IDomainIdManagerData,
    IDomainIdData
} from "../actions/IMACAction";
import { combineReducers } from "redux";

export const getFloorDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_FLOOR_LOADING:
        case Actions.GET_FLOOR_ERROR:
        case Actions.GET_FLOOR_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getLocationDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_LOCATION_LOADING:
        case Actions.GET_LOCATION_ERROR:
        case Actions.GET_LOCATION_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getSiteDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_SITE_LOADING:
        case Actions.GET_SITE_ERROR:
        case Actions.GET_SITE_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getIMACcategoryOneDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_IMAC_CATEGORY_ONE_LOADING:
        case Actions.GET_IMAC_CATEGORY_ONE_ERROR:
        case Actions.GET_IMAC_CATEGORY_ONE_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getIAMCCategoryTwoDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_IMAC_CATEGORY_TWO_LOADING:
        case Actions.GET_IMAC_CATEGORY_TWO_ERROR:
        case Actions.GET_IMAC_CATEGORY_TWO_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getIMACcategoryThreeDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_IMAC_CATEGORY_THREE_LOADING:
        case Actions.GET_IMAC_CATEGORY_THREE_ERROR:
        case Actions.GET_IMAC_CATEGORY_THREE_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getAssetReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.GET_ASSETS_LOADING:
        case Actions.GET_ASSETS_ERROR:
        case Actions.GET_ASSETS_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const postDomainIdDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_DOMAIN_ID_LOADING:
        case Actions.POST_DOMAIN_ID_ERROR:
        case Actions.POST_DOMAIN_ID_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const postDomainIdManagerDataReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Actions.POST_DOMAIN_ID_MANAGER_LOADING:
        case Actions.POST_DOMAIN_ID_MANAGER_ERROR:
        case Actions.POST_DOMAIN_ID_MANAGER_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export interface IIMACData {
    getFloorData: IIMACCommon,
    getLocationData: IIMACCommon,
    getSiteData: IIMACCommon,
    getIMACcategoryOneData: IIMACCommon,
    getIAMCCategoryTwoData: IIMACCategoryTwo,
    getIMACcategoryThreeData: IIMACCategoryThree,
    getAssetData: IGetAssetData,
    postDomainIdData: IDomainIdData,
    postDomainIdManagerData: IDomainIdManagerData,
}

const imacData = combineReducers({
    getFloorData: getFloorDataReducer,
    getLocationData: getLocationDataReducer,
    getSiteData: getSiteDataReducer,
    getIMACcategoryOneData: getIMACcategoryOneDataReducer,
    getIAMCCategoryTwoData: getIAMCCategoryTwoDataReducer,
    getIMACcategoryThreeData: getIMACcategoryThreeDataReducer,
    getAssetData: getAssetReducer,
    postDomainIdData: postDomainIdDataReducer,
    postDomainIdManagerData: postDomainIdManagerDataReducer,
});

export default imacData;