import { all, fork } from "redux-saga/effects";
import {
    watchApplyLeaveData,
    watchValidLeaveData,
    watchQuotaLeaveData,
    watchLeaveHistoryData,
    watchLeaveMasterData,
    watchLeaveRequestApprovalHistoryData,
    watchLeaveRequestApprovalData,
    watchLeaveDeleteHistoryData,
    watchGetEmployeeIdData,
    watchDeleteLeaveRequestApprovalData,
    watchGetUpcomingHolidayData,
    watchGetActiveYearData,
} from "./leaveSaga";
import {
    watchToDoOrgData,
    watchToDoPersonalData,
    watchCreateListData,
    watchCreateTaskData,
    watchDeleteTask,
    watchUpdateTaskData,
    watchToDoListData,
    watchToDoMasterData
} from "./todoSaga";
import { watchAttendanceData } from "./attendanceSaga";
import {
    watchGetAnnouncementsData,
    watchGetAnnouncementsFeedbackData,
    watchPostAnnouncementsFeedbackData,
} from "./announcementSaga";
import {
    watchDose1Data,
    watchDose2Data,
    watchPutDoseData,
    watchPostDoseData,
} from "./vaccineSaga";
import {
    watchGetValidTokenData,
    watchGetValidManagerTokenData,
    watchGetValidAzAccessTokenData,
    watchGetNFAUserValidTokenData,
} from "./authSaga";
import {
    watchGetUserData,
    watchGetUserManagerData,
    watchGetTeamMemberData,
} from "./userDataSaga";
import {
    watchGetSuccessFactorUserDetailsData,
    watchGetEmployeeGoalsData,
    watchAddGoalCommentData,
    watchGetEmployeeCompetencyData,
    watchAddCompetencyCommentData,
    watchGetGoalCommentData,
    watchGetCompetencyCommentData,
    watchGetPerformanceTimelineData,
    watchSendFeedbackRequestData,
    watchGetLeadershipBehaviuorDescription,
    watchUpdateManagerGoalCommentData,
    watchUpdateManagerCompetencyCommentData,
    watchDeleteManagerGoalCommentData,
    watchDeleteManagerCompetencyCommentData
} from "./cfmData";
import {
    watchGetNotificationData,
    watchPostNotificationData
} from "./notificationSaga";
import {
    watchGetCurrentDownloadDocumentData,
    watchGetCurrentMasterData,
    watchGetCurrentPayslipData,
    watchGetFormSixteenData,
    watchGetOldDocumentData,
    watchGetPreviousDownloadDocumentData,
    watchGetPreviousMasterDataData,
    watchGetPreviousPayslipData,
    watchGetEmployeeApplicableData,
    watchGetEmployeeLatestData,
    watchGetPassbookDetailData,
    watchGetDownloadTrustFundData,
    watchGetGenerateBonafideData,
    watchGetEmployeeAdditionalData,
    watchGetAdditionalDownloadDocumentData
} from "./DocumentSaga";

import {
    watchGetTokenData,
    watchGetTicketsData,
    watchAddNotesData,
    watchCreateTicketData,
    watchUpdateTicketData,
    watchAddAttachmentData,
    watchGetUserApprovalList,
    watchUpdateApproval,
    watchGetItsmCategoryData,
    watchGetItsmSubCategoryData,
    watchGetItsmItemData,
    watchGetTciketApproval,
    watchCreateTicketCatalog
} from "./itsmSaga";

import {
    watchDisplayFieldData,
    watchGetMasterFieldData,
    watchGetMasterLocationData,
    watchGetMasterOfficeData,
    watchAllStatusData,
    watchSaveDigitalData,
    watchSavePhysicalData,
    watchCardQuantityData,
    watchDigitalVcData,
    watchPhysicalVcData,
    watchSystemShutdownData,
    watchQetQrStringData,
    watchUpdatePhysicalVCData
} from "./visitorSaga";

import {
    watchGetPinData,
    watchCreateOrUpdatePinData
} from "./pinSaga";
import {
    watchGetMomentFeedData,
    watchUpdateUserReactionData,
    watchGetEmployeeReactionData,
    watchGetMoomentMatterData,
    watchGetSendWishNotificationData,
    watchGetMyMilestonesData,
    watchGetUpcomingMilestonesData,
} from "./mtmSaga";

import {
    watchGetChannelData,
    watchGetVideoLibraryData,
} from "./channelSaga";

import {
    watchGetSendMailData,
}
    from "./sendMailSaga";

import {
    watchGetUserDetailsData,
    watchGetUserRolesAssignData,
    watchGetUserSystemDetailsData,
    watchGetSystemDescriptionData,
    watchGetReqUserDetailsData,
    watchGetNoSystemAssignedUserData,
    watchPostUserCreateData,
    watchGetApproverInboxData,
    watchGetFindingTCodesRoleData,
    watchGetFindingOrgValuesRoleData,
    watchGetRoleOwnerRoleNameData,
    watchGetListBusinessProcessData,
    watchGetListRoleAssignedToOwnerData,
    watchPostApiForApproveReqData,
    watchGetSystemTypeData,
    watchGetFunctionData,
    watchGetSubFunctionData,
    watchGetDepartmentData,
    watchGetSubDepartmentData,
    watchGetRoleOwnerData,
    watchGetRoleData,
    watchPostSodConflictData,
}
    from "./SapIdSaga";


import { watchIttipsData } from "./ITtipsSaga";
import { watchGetFloorData, watchGetIMACCategoryOneData, watchGetIMACCategoryThreeData, watchGetIMACCategoryTwoData, watchGetLocationData, watchGetSiteData, watchPostDomainIdByAdData, watchGetAssetData, watchPostDomainIdManagerByAdData } from "./imacSaga";
import { watchGetServiceStatusData } from "./serviceSaga";
import { watchGetAcrSapApprovalListData, watchGetAcrSapEmpRowCountData, watchGetAcrSapRequestDetailsData, watchGetAcrSapRequestInitiatorData, watchPostAcrSapRequestUpdateData } from "./acrSapSaga";
import { watchGetCompensationStructureData, watchGetCompensationStructureInstructionsData, watchGetCompensationStructurePDF } from "./PaySpaceSaga";
function* rootSaga() {
    yield all([
        watchGetValidTokenData(),
        watchGetValidManagerTokenData(),
        watchGetValidAzAccessTokenData(),
        watchGetNFAUserValidTokenData(),

        watchGetUserManagerData(),
        watchGetUserData(),
        watchGetTeamMemberData(),

        watchApplyLeaveData(),
        watchValidLeaveData(),
        watchQuotaLeaveData(),
        watchLeaveHistoryData(),
        watchLeaveDeleteHistoryData(),
        watchLeaveMasterData(),
        watchLeaveRequestApprovalHistoryData(),
        watchLeaveRequestApprovalData(),
        watchGetEmployeeIdData(),
        watchDeleteLeaveRequestApprovalData(),
        watchGetUpcomingHolidayData(),
        watchGetActiveYearData(),

        watchAttendanceData(),
        watchPostAnnouncementsFeedbackData(),
        watchGetAnnouncementsFeedbackData(),

        watchDose1Data(),
        watchDose2Data(),
        watchPutDoseData(),
        watchPostDoseData(),
        watchGetAnnouncementsData(),

        watchToDoOrgData(),
        watchToDoPersonalData(),
        watchCreateListData(),
        watchCreateTaskData(),
        watchUpdateTaskData(),
        watchDeleteTask(),
        watchToDoListData(),
        watchToDoMasterData(),

        watchGetSuccessFactorUserDetailsData(),
        watchGetEmployeeGoalsData(),
        watchAddGoalCommentData(),
        watchGetEmployeeCompetencyData(),
        watchAddCompetencyCommentData(),
        watchGetGoalCommentData(),
        watchGetCompetencyCommentData(),
        watchGetPerformanceTimelineData(),
        watchSendFeedbackRequestData(),
        watchGetLeadershipBehaviuorDescription(),
        watchUpdateManagerGoalCommentData(),
        watchUpdateManagerCompetencyCommentData(),
        watchDeleteManagerGoalCommentData(),
        watchDeleteManagerCompetencyCommentData(),

        watchGetNotificationData(),
        watchPostNotificationData(),

        watchGetCurrentDownloadDocumentData(),
        watchGetCurrentMasterData(),
        watchGetCurrentPayslipData(),
        watchGetFormSixteenData(),
        watchGetOldDocumentData(),
        watchGetPreviousDownloadDocumentData(),
        watchGetPreviousMasterDataData(),
        watchGetPreviousPayslipData(),
        watchGetEmployeeApplicableData(),
        watchGetEmployeeLatestData(),
        watchGetPassbookDetailData(),
        watchGetDownloadTrustFundData(),
        watchGetEmployeeAdditionalData(),
        watchGetAdditionalDownloadDocumentData(),
        watchGetGenerateBonafideData(),

        watchGetTokenData(),
        watchGetTicketsData(),
        watchAddNotesData(),
        watchCreateTicketData(),
        watchUpdateTicketData(),
        watchAddAttachmentData(),
        watchGetUserApprovalList(),
        watchUpdateApproval(),
        watchIttipsData(),
        watchGetItsmCategoryData(),
        watchGetItsmSubCategoryData(),
        watchGetItsmItemData(),
        watchGetTciketApproval(),
        watchCreateTicketCatalog(),


        watchPostDomainIdByAdData(),
        watchPostDomainIdManagerByAdData(),
        watchGetIMACCategoryOneData(),
        watchGetIMACCategoryThreeData(),
        watchGetIMACCategoryTwoData(),
        watchGetFloorData(),
        watchGetLocationData(),
        watchGetAssetData(),
        watchGetSiteData(),

        watchGetServiceStatusData(),

        watchDisplayFieldData(),
        watchGetMasterFieldData(),
        watchGetMasterLocationData(),
        watchGetMasterOfficeData(),
        watchAllStatusData(),
        watchSaveDigitalData(),
        watchSavePhysicalData(),
        watchCardQuantityData(),
        watchDigitalVcData(),
        watchPhysicalVcData(),
        watchSystemShutdownData(),
        watchQetQrStringData(),
        watchUpdatePhysicalVCData(),

        watchGetPinData(),
        watchCreateOrUpdatePinData(),

        watchGetMomentFeedData(),
        watchGetEmployeeReactionData(),
        watchGetMoomentMatterData(),
        watchGetSendWishNotificationData(),
        watchGetMyMilestonesData(),
        watchGetUpcomingMilestonesData(),
        watchUpdateUserReactionData(),

        watchGetChannelData(),
        watchGetVideoLibraryData(),

        watchGetSendMailData(),


        watchGetUserDetailsData(),
        watchGetUserRolesAssignData(),
        watchGetUserSystemDetailsData(),
        watchGetSystemDescriptionData(),
        watchGetReqUserDetailsData(),
        watchGetNoSystemAssignedUserData(),
        watchPostUserCreateData(),
        watchGetApproverInboxData(),
        watchGetFindingTCodesRoleData(),
        watchGetFindingOrgValuesRoleData(),
        watchGetRoleOwnerRoleNameData(),
        watchGetListBusinessProcessData(),
        watchGetListRoleAssignedToOwnerData(),
        watchPostApiForApproveReqData(),
        watchGetSystemTypeData(),
        watchGetFunctionData(),
        watchGetSubFunctionData(),
        watchGetDepartmentData(),
        watchGetSubDepartmentData(),
        watchGetRoleOwnerData(),
        watchGetRoleData(),
        watchPostSodConflictData(),

        watchGetAcrSapApprovalListData(),
        watchGetAcrSapRequestDetailsData(),
        watchPostAcrSapRequestUpdateData(),
        watchGetAcrSapRequestInitiatorData(),
        watchGetAcrSapEmpRowCountData(),

        watchGetCompensationStructureData(),
        watchGetCompensationStructureInstructionsData(),
        watchGetCompensationStructurePDF()
    ]);
}
export default rootSaga;
